import { BaseMapper } from "./base.mapper";
import { get } from "lodash";
import { CurrencyItem } from "infrastructure/interfaces";

export class CurrencyItemMapper extends BaseMapper<CurrencyItem, any> {
  responseToEntity(response: any): CurrencyItem {
    return {
      id: get(response, "id", 0),
      numCode: get(response, "numCode", 0),
      name: {
        ru: get(response, "name.ru", ""),
        en: get(response, "name.en", ""),
      },
      alphaCode: get(response, "alphaCode", ""),
      alternateKey: get(response, "alternateKey", ""),
    };
  }

  responsesToEntitys(responses: any[]): CurrencyItem[] {
    return [...responses.map((item) => this.responseToEntity(item))];
  }
}
