import React, { Fragment, useState, useRef, useEffect } from "react";
import Dropdown from "app/component/dropdown/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import EmployeeAutocomplete, {
  EmployeeAutocompleteOptionType,
} from "app/component/autocomplete/EmployeeAutocomplete";
import { useTranslation } from "react-i18next";
import DateInput from "app/component/date-input/DateInput";
import { hideFilter, showFilter } from "store/common/actions";
import {
  updateDownlandJournalList,
  JournalListFilter,
  setJournalPage,
  setJournalFilter,
} from "store/journal/actions";
import UploadButton from "app/component/buttons-control/UploadButton";
import analitiks from "services/AnaliticsService";
import {
  getCommonFilterState,
  getJournalFilter,
  getCommonUserDetail,
} from "../../../store/selectors";

interface JournalDetailFilterProps {
  id: number;
}

const JournalDownloadsFilter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let [downloadDateStart, setDownloadDateStart] = useState(null as any);
  let [downloadDateEnd, setDownloadDateEnd] = useState(null as any);
  let [employee, setEmployee] = useState({} as EmployeeAutocompleteOptionType);
  let [packageNumber, setPackageNumber] = useState("");
  let [width, setWidth] = useState(0);

  let dowloadDateStartRef = useRef({} as any);
  let downloadDateEndRef = useRef({} as any);
  let packageNumberRef = useRef({} as HTMLInputElement);
  let employeeAutocompleteRef = useRef({} as any);

  let filterState: boolean = useSelector(getCommonFilterState);
  let currentUser = useSelector(getCommonUserDetail);
  let filterParams = useSelector(getJournalFilter);

  let [isFilterOpen, setFilterOpen] = useState(filterState);
  let [filterTimer, setFilterTimer] = useState(
    null as unknown as NodeJS.Timeout,
  );
  let [isClearingFilter, setClearingFilter] = useState(false);
  let [isInited, setInited] = useState(false);

  const resizeEventHander = (e: any) => {
    if (width !== window.innerWidth) {
      resizeHandler();
      setWidth(window.innerWidth);
    }
  };

  const resizeHandler = () => {
    if (window.innerWidth <= 959) {
      dispatch(hideFilter());
    } else {
      dispatch(showFilter());
    }
  };

  useEffect(() => {
    setFilterOpen(filterState);
    dispatch(setJournalPage(1));
    dispatch(setJournalFilter({}));
    dispatch(updateDownlandJournalList(false));

    window.addEventListener("resize", resizeEventHander);
    return () => {
      window.removeEventListener("resize", resizeEventHander);
    };
  }, [filterState]);

  useEffect(() => {
    resizeHandler();
    setInited(true);
  }, []);

  useEffect(() => {
    if (isInited) {
      filterHandler();
      setClearingFilter(false);
    }
  }, [downloadDateStart, downloadDateEnd, employee]);

  useEffect(() => {
    if (isInited && !isClearingFilter) {
      clearTimeout(filterTimer);
      setFilterTimer(
        setTimeout(() => {
          filterHandler();
        }, 2000),
      );
    }
  }, [packageNumber]);

  const downloadDateStartSelectHandler = (value: any) => {
    setDownloadDateStart(value);
  };

  const downloadDateEndSelectHandler = (value: any) => {
    setDownloadDateEnd(value);
  };

  const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
    if (option.value !== employee.value) {
      setEmployee(option);
    }
  };

  const packageNumberChangeHandler = () => {
    setPackageNumber(packageNumberRef.current.value);
  };

  const clearAllClickHandler = () => {
    setEmployee({} as EmployeeAutocompleteOptionType);
    employeeAutocompleteRef.current.clear();
    setDownloadDateStart(null);
    dowloadDateStartRef.current.clear();
    setDownloadDateEnd(null);
    downloadDateEndRef.current.clear();
    setPackageNumber("");
    setClearingFilter(true);
  };

  const forceUpdateFilter = () => {
    clearTimeout(filterTimer);
    filterHandler();
  };

  const onEnterClickHandler = (event: any) => {
    if (event.key === "Enter") {
      forceUpdateFilter();
    }
  };

  const packageNumberBlurHandler = () => {
    const filterPackageNumber = filterParams.uploadNumber
      ? filterParams.uploadNumber
      : "";
    if (filterPackageNumber != packageNumber) {
      clearTimeout(filterTimer);
      forceUpdateFilter();
    }
  };

  const filterHandler = () => {
    let params = {} as JournalListFilter;

    if (packageNumber != "") {
      params.uploadNumber = packageNumber;
    }

    if (employee && employee.value) {
      params.employee = +employee.value;
    }

    if (downloadDateStart) {
      params.downloadDateStart = downloadDateStart as Date;
    }

    if (downloadDateEnd) {
      params.downloadDateEnd = downloadDateEnd as Date;
    }

    dispatch(setJournalPage(1));
    dispatch(setJournalFilter(params));
    dispatch(updateDownlandJournalList(false));
  };

  const successUploadHandler = () => {
    analitiks("UploadPostingsButton", {
      employeeId: currentUser.id,
      occupationId: currentUser.occupations[0].occupationId,
      companyId: currentUser.occupations[0].company.id,
    });
    dispatch(setJournalPage(1));
    dispatch(setJournalFilter({}));
    dispatch(updateDownlandJournalList(false));
  };

  return (
    <Fragment>
      <div
        className="filters"
        style={isFilterOpen ? { display: "block" } : { display: "none" }}
      >
        <UploadButton onUpload={successUploadHandler} />
        <div className="filters-wrap">
          <Dropdown label={t("downloads.filter.packageNumber")}>
            <div className="input-item">
              <input
                ref={packageNumberRef}
                onKeyPress={onEnterClickHandler}
                onBlur={packageNumberBlurHandler}
                onChange={packageNumberChangeHandler}
                className="input"
                type="text"
                placeholder={t("downloads.filter.packageNum")}
                value={packageNumber}
              />
            </div>
          </Dropdown>
          <Dropdown label={t("downloads.filter.downloadsTime")}>
            <div className="input-item">
              <div className="input-picker-title">
                {t("downloads.filter.with")}
              </div>
              <div className="input-picker-wrap">
                <DateInput
                  ref={dowloadDateStartRef}
                  placeholder={t("downloads.filter.enter_date")}
                  onSelect={downloadDateStartSelectHandler}
                  format={"D MMMM"}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-picker-title">
                {t("downloads.filter.by")}
              </div>
              <div className="input-picker-wrap">
                <DateInput
                  ref={downloadDateEndRef}
                  placeholder={t("downloads.filter.enter_date")}
                  onSelect={downloadDateEndSelectHandler}
                  format={"D MMMM"}
                />
              </div>
            </div>
          </Dropdown>
          <Dropdown label={t("downloads.filter.by_employee")}>
            <div className="input-item">
              <div className="input-search">
                <EmployeeAutocomplete
                  ref={employeeAutocompleteRef}
                  onChoose={employeeChooseHandler}
                  placeholder={t("downloads.filter.enterSoname")}
                />
              </div>
            </div>
          </Dropdown>
          <Dropdown>
            <div className="filter-btns-two m-t-15">
              <a
                className="btn btn_gray width-100 pointer"
                onClick={clearAllClickHandler}
              >
                {t("filters.reset")}
              </a>
            </div>
          </Dropdown>
        </div>
      </div>
    </Fragment>
  );
};

export default JournalDownloadsFilter;
