import ModalContainer from "app/component/modal/ModalContainer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeInfoModal } from "store/exception/actions";
import { ApplicationState } from "store/reducers";
import { getExceptionInfoArray } from "../../../store/selectors";

const Infos: React.FC = () => {
  const infoArray = useSelector(getExceptionInfoArray);

  return (
    <React.Fragment>
      {infoArray.map((info, index) => {
        return <InfoModal info={info} />;
      })}
    </React.Fragment>
  );
};
export default Infos;

interface InfoModalProps {
  info: any;
}

const InfoModal: React.FC<InfoModalProps> = (props) => {
  let isOpen = props.info.isOpenInfoModal;
  const dispatch = useDispatch();

  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  const closeHandler = () => {
    clearTimeout(counter);
    dispatch(closeInfoModal(props.info.id));
  };

  const { t } = useTranslation();
  let [closeCounter, setCloseCounter] = useState(45);
  let counter = 0;

  useEffect(() => {
    if (isOpen) {
      if (closeCounter > 0) {
        counter = countdown();
      } else {
        closeHandler();
      }
    }
  }, [closeCounter, isOpen, props.info.id]);

  const countdown = () => {
    let counter = window.setTimeout(() => {
      if (closeCounter > 0) {
        setCloseCounter(closeCounter - 1);
        clearTimeout(counter);
      }
    }, 1000);
    return counter;
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      destroy={true}
      highestModal={isHighestModal}
      overlayClick={closeHandler}
    >
      <div className="box-modal" id="modal-auth">
        <div
          className="box-modal_close arcticmodal-close pointer"
          onClick={closeHandler}
        ></div>
        <div className="box-modal-title">
          {props.info.title ? props.info.title : t("modals.info.title")}
        </div>
        <div className="box-modal-content">
          <div className="text">
            <div className="tx-center">{props.info.message}</div>
          </div>
          <img src="/img/img-modal-auth.png" />
        </div>
        <div className="box-modal-footer">
          <div className="tx-center">
            <a
              className="arcticmodal-close btn btn_black pointer"
              onClick={closeHandler}
              tabIndex={isTabIndexDisabled ? -1 : 0}
            >
              {t("modals.info.close")}
            </a>
          </div>
        </div>
        <div className="box-modal-descr">
          <div className="tx-center">{`${t("modals.info.close_message")}  ...${closeCounter} ${t("modals.info.time_unit")}`}</div>
        </div>
      </div>
    </ModalContainer>
  );
};
