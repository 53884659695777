import { ActionCreator } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "store/reducers";
import {
  CERTIFICATES,
  CertificatesActions,
  SetApiRootCertificates,
} from "./actionTypes";
import { getAuthorityCertificates } from "services/ApiService";
import { Certificate } from "crypto";
export type Certificates = Certificate & {
  checked?: boolean;
  thumbprint: string;
  subjectKeyIdentifier?: string;
  authorityKeyIdentifier?: string;
};

export const setApiRootCertificates: ActionCreator<SetApiRootCertificates> = (
  ApiRootCertificates,
) => ({
  type: CERTIFICATES.SET_API_ROOT,
  payload: ApiRootCertificates,
});

export const setListOfApiRootCertificates = (): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  CertificatesActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, CertificatesActions>,
    getState,
  ): Promise<void> => {
    try {
      const result = await getAuthorityCertificates({
        params: { CertificationAuthorityType: 1, PageSize: 1000 },
      }); // 1 - корневые, 2 - промежуточные

      if (result.headers.success) {
        dispatch(setApiRootCertificates(result.data.data));
      }
    } catch (error) {
      console.log("error:", error);
    }
  };
};

export const setClientRootCertificates: ActionCreator<
  SetApiRootCertificates
> = (ClientRootCertificates) => ({
  type: CERTIFICATES.SET_CLIENT_ROOT,
  payload: ClientRootCertificates,
});

export const setClientCaCertificates: ActionCreator<SetApiRootCertificates> = (
  ClientCaCertificates,
) => ({
  type: CERTIFICATES.SET_CLIENT_CA,
  payload: ClientCaCertificates,
});

export const setClientMyCertificates: ActionCreator<SetApiRootCertificates> = (
  ClientMyCertificates,
) => ({
  type: CERTIFICATES.SET_CLIENT_MY,
  payload: ClientMyCertificates,
});

let canPromise = !!window.Promise;
export const setListOfClientCertificates = (
  type: string,
  setNoticeType?: (id: string) => void,
  setOpenNotice?: (a: boolean) => void,
): ThunkAction<Promise<void>, ApplicationState, any, CertificatesActions> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, CertificatesActions>,
    getState,
  ): Promise<void> => {
    return new Promise(async () => {
      let result: Certificates[] = [];
      if ("CreateObjectAsync" in cadesplugin && canPromise) {
        try {
          const store = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");

          await store.Open(cadesplugin.CAPICOM_CURRENT_USER_STORE, type, 0);
          let certificates = await store.Certificates;

          certificates = await certificates.Find(
            cadesplugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID,
          );
          let certsCount = await certificates.Count;
          for (let i = 1; i <= certsCount; i++) {
            let cert = await certificates.Item(i);
            result.push(cert);
          }
          await store.Close();
          if (type === "ROOT") {
            dispatch(setClientRootCertificates(result));
          }
          if (type === "CA") {
            dispatch(setClientCaCertificates(result));
          }
          if (type === "MY" && result.length) {
            dispatch(setClientMyCertificates(result));
          }
          if (
            type === "MY" &&
            !result.length &&
            setNoticeType &&
            setOpenNotice
          ) {
            setNoticeType("no_certificates");
            setOpenNotice(true);
          }
        } catch (error) {
          console.log("error:", error);
        }
      }
    });
  };
};
