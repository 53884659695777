import { Substitution } from "infrastructure/interfaces";
import { Reducer } from "redux";
import { SUBSTITUTION, SubstitutionActions } from "./actionsTypes";

export interface SubstitutionState {
  substitutionList: Substitution[];
  itemsCount: number;
}

export const initialState: SubstitutionState = {
  itemsCount: 0,
  substitutionList: [],
};

export const reducer: Reducer<SubstitutionState> = (
  state: SubstitutionState = initialState,
  action,
) => {
  switch ((action as SubstitutionActions).type) {
    case SUBSTITUTION.SET_LIST:
      const loaded = action.payload.substitutionList;
      const old = state.substitutionList ? state.substitutionList : [];
      if (action.payload.isAdd) {
        action.payload.substitutionList = [...old, ...loaded];
      }
      return {
        ...state,
        substitutionList: action.payload.substitutionList,
        itemsCount: action.payload.itemsCount,
      };
    default:
      return state;
  }
};

export default reducer;
