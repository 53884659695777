import React from "react";
import { RadioButtonProps } from "./types";
import {
  Item,
  RadioButtonLabel,
  RadioButton,
  RadioButtonLabelText,
} from "./StyledRadioButton";

const Radio: React.FC<RadioButtonProps> = (props) => {
  const { disabled, name, checked, id, label, onClick } = props;

  const clickHandler = () => {
    !disabled && onClick(id);
  };

  return (
    <Item>
      <RadioButton
        disabled={disabled}
        onChange={clickHandler}
        type="radio"
        name={name}
        id={name + "-" + id}
        checked={checked}
      />
      <RadioButtonLabel htmlFor={name + "-" + id} />
      <RadioButtonLabelText> {label}</RadioButtonLabelText>
    </Item>
  );
};

export default Radio;
