import { BaseMapper } from "./base.mapper";
import { ExpenseType, ExpenseTypeResponse } from "infrastructure/interfaces";

export class ExpenseTypeMapper extends BaseMapper<
  ExpenseType,
  ExpenseTypeResponse
> {
  responseToEntity(response: ExpenseTypeResponse): ExpenseType {
    return {
      id: response.applicationType.id,
      code: response.code,
      name: {
        ru: response.applicationType.name.ru,
        en: response.applicationType.name.en,
      },
    };
  }

  fullResponseToEntitys(response: any): ExpenseType[] {
    return this.responsesToEntitys(response.data);
  }
}
