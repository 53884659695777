import { ButtonColorsStyle } from "./types";

const buttonColorsStyles: ButtonColorsStyle = {
  default: {
    background: "none",
    hover: "#DFE1E3",
    boxShadow: "none",
    textColor: "#5D6C74",
    border: "1px solid #5D6C74",
  },
  green: {
    background: "#4CD964",
    hover: "#3FB553",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
    textColor: "#FFFFFF",
    border: "none",
  },
  gray: {
    background: "#5D6C74",
    hover: "#4D5A61",
    boxShadow: "none",
    textColor: "#FFFFFF",
    border: "none",
  },
  red: {
    background: "#E00000",
    hover: "#A20000",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
    textColor: "#FFFFFF",
    border: "none",
  },
  orange: {
    background: "#EA780E",
    hover: "#D16603",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
    textColor: "#FFFFFF",
    border: "none",
  },
  blank: {
    background: "none",
    hover: "none",
    boxShadow: "none",
    textColor: "#5D6C74",
    border: "none",
  },
  disabled: {
    background: "#EDEDED",
    hover: "#EDEDED",
    boxShadow: "none",
    textColor: "#9E9E9E",
    border: "#E0E0E0",
  },
};

export { buttonColorsStyles };
