import React, { useState } from "react";
import { SvgIcon } from "ui/SvgIcon";
import {
  StyledReportStatus,
  StyledReportStatusSpan,
  StyledReportStatusContainer,
  StyledReportSign,
} from "./StyledReportStatus";
import { ReportStatusProps } from "./types";
import Config, { ConfigTypes } from "services/ConfigService";
import { useSelector } from "react-redux";
import { getAdvanceReportApplication } from "store/selectors";
import { NameFormats, nameFormatter } from "app/utils";
import { useTranslation } from "react-i18next";
import agreementService from "services/entity/agreement.service";

const ReportStatus: React.FC<ReportStatusProps> = (props) => {
  const [isSign, setIsSign] = useState<boolean | null>(false);

  const { color, text, advanceReportApplication } = props;
  const { i18n } = useTranslation();

  const report = useSelector(getAdvanceReportApplication);
  const companyId = report && report.company ? report.company.id : 0;
  const isSigningEnabled = Config.getConfigToCompany(
    ConfigTypes.ADVANCE_REPORT_APPLICATION_SIGNING_ENABLED,
    companyId,
  );
  const isApproved =
    advanceReportApplication.status === "Approved" ||
    advanceReportApplication.status === "Posted";

  const isSigned = async (id: number) => {
    const agreementList = await agreementService.getList();
    const result =
      agreementList &&
      agreementList.approvalTiers
        .flatMap((item) => item.employees)
        .flatMap((item) => item.approvalItemSignatures)
        .filter((item) => item.objectId === id && item.signedAt !== "").length >
        0;
    setIsSign(result);
  };
  isSigned(report.id);

  return (
    <StyledReportStatusContainer>
      <StyledReportStatus>
        {text}
        {advanceReportApplication.paperWork.id
          ? `: ${nameFormatter(advanceReportApplication.paperWork.employeeName, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)}`
          : null}
      </StyledReportStatus>
      <StyledReportStatusSpan color={color} />
      {isApproved && isSigningEnabled && isSign && (
        <StyledReportSign>
          Подписано <SvgIcon id={"fingerprint"} color={"#008243"} size={24} />
        </StyledReportSign>
      )}
    </StyledReportStatusContainer>
  );
};

export default ReportStatus;
