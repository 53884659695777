import { AOActions, REPORTS } from "./actionTypes";
import { Reducer } from "redux";
import { IAOList, IAO } from "infrastructure/interfaces";

export interface ReportState {
  advanceReportList: IAOList;
  advanceReportApplication: IAO;
  isReportListDownloaded: boolean;
  isReportDetailDownloaded: boolean;
}

export const initialState: ReportState = {
  advanceReportList: {
    data: [],
    itemsCount: 0,
    itemsPerPage: 0,
    pageNumber: 0,
  },
  advanceReportApplication: {} as IAO,
  isReportListDownloaded: false,
  isReportDetailDownloaded: false,
};

export const reducer: Reducer<ReportState> = (
  state: ReportState = initialState,
  action,
) => {
  switch ((action as AOActions).type) {
    case REPORTS.SET_AO_LIST:
      if (action.payload.reloading) {
        const loadedReports = action.payload.advanceReportList.data;
        const oldReports = state.advanceReportList
          ? state.advanceReportList.data
          : [];
        action.payload.advanceReportList.data = [
          ...oldReports,
          ...loadedReports,
        ];
        return {
          ...state,
          advanceReportList: action.payload.advanceReportList,
        };
      }
      return { ...state, advanceReportList: action.payload.advanceReportList };
    case REPORTS.SET_DETAILED_AO:
      return {
        ...state,
        advanceReportApplication: action.payload.advanceReportApplication,
      };
    case REPORTS.SET_AO_LIST_DOWNLOADED:
      return {
        ...state,
        isReportListDownloaded: action.payload.isReportListDownloaded,
      };
    case REPORTS.SET_AO_DETAIL_DOWNLOADED:
      return {
        ...state,
        isReportDetailDownloaded: action.payload.isReportDetailDownloaded,
      };
    default:
      return state;
  }
};

export default reducer;
