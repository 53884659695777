import { Action } from "redux";
import { PowerOfAttorneyType } from "infrastructure/interfaces";

export const POWEROFATTORNEY = {
  ENABLED: "POWEROFATTORNEY/ENABLED",
  SET_LIST: "POWEROFATTORNEY/SET_LIST",
  SET_DETAIL: "POWEROFATTORNEY/SET_DETAIL",
};

export interface CheckIsPowerOfAttorneyEnabled extends Action {
  type: typeof POWEROFATTORNEY.ENABLED;
  payload: { isEnabled: boolean };
}
export interface SetPowerOfAttorneyList extends Action {
  type: typeof POWEROFATTORNEY.SET_LIST;
  payload: { PowerOfAttorneyList: PowerOfAttorneyType[] };
}
export interface SetPowerOfAttorneyDetail extends Action {
  type: typeof POWEROFATTORNEY.SET_DETAIL;
  payload: { PowerOfAttorneyDetail: PowerOfAttorneyType[] };
}

export type PowerOfAttorneyActions =
  | CheckIsPowerOfAttorneyEnabled
  | SetPowerOfAttorneyList
  | SetPowerOfAttorneyDetail;
