import SvgIcon from "app/component/svg-icon/SvgIcon";
import React, { useEffect, useRef, useState } from "react";
import { HeaderOptions } from "./Grid";

interface GridHeaderCellProps {
  header: HeaderOptions;
  onSort: (key: string, sortOrder: SortOrder) => void;
  last?: boolean;
}

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

const GridHeaderCell: React.FC<GridHeaderCellProps> = (props) => {
  const [sortOrder, setSortOrder] = useState(SortOrder.ASC);

  const sortIconRef = useRef({} as HTMLDivElement);

  useEffect(() => {
    if (props.header.sortable) {
      if (sortOrder === SortOrder.ASC) {
        sortIconRef.current.style.transform = "rotate(0deg)";
      } else {
        sortIconRef.current.style.transform = "rotate(180deg)";
      }
    }
  }, [sortOrder]);

  const sortClickHandler = (key: string) => {
    if (props.header.sortable) {
      if (sortOrder === SortOrder.ASC) {
        setSortOrder(SortOrder.DESC);
        props.onSort(key, SortOrder.DESC);
      } else {
        setSortOrder(SortOrder.ASC);
        props.onSort(key, SortOrder.ASC);
      }
    }
  };

  return (
    <div
      className={`profile-calc-table-th ${props.last ? "last" : ""}`}
      onClick={() => {
        sortClickHandler(props.header.key);
      }}
    >
      {props.header.headerText}
      {props.header.sortable ? (
        <div ref={sortIconRef}>
          <SvgIcon className={"icon icon-arrow"} href={"#svg_icon_arrow"} />
        </div>
      ) : null}
    </div>
  );
};

export default GridHeaderCell;
