import { BaseMapper } from "./base.mapper";
import { TravelDates } from "infrastructure/interfaces";
import { get } from "lodash";

export class TravelDatesMapper extends BaseMapper<TravelDates, any> {
  responseToEntity(response: any): TravelDates {
    return {
      startDate: get(response, "startDate", new Date()),
      endDate: get(response, "endDate", new Date()),
    };
  }

  responsesToEntitys(responses: any[]): TravelDates[] {
    return [...responses.map((item) => this.responseToEntity(item))];
  }
}
