import React from "react";
import moment from "moment";
import { CertificateProps } from "./types";
import { Radio } from "ui/Radio";
import {
  StyledSign,
  StyledCertificate,
  StyledName,
  StyledTitle,
  StyledText,
  DateBox,
  DateBoxStart,
  DateBoxEnd,
  StyledRadio,
  ThumbprintBox,
  StyledType,
} from "./StyledSertificate";
import { useTranslation } from "react-i18next";

const Certificate: React.FC<CertificateProps> = (props) => {
  const {
    name,
    subjectName,
    serialNumber,
    validFrom,
    validTo,
    thumbprint,
    checked = false,
    type,
    onClick,
  } = props;

  const { t } = useTranslation();
  const getDate = (date: string) => {
    return moment(date).format("DD.MM.YYYY");
  };
  const filterSubjectName =
    props.issuerName &&
    props.issuerName
      .trim()
      .split(",")
      .filter((iss) => iss.includes("CN="));
  const filterSubjectName2 =
    props.issuerName &&
    props.issuerName
      .trim()
      .split(" + ")
      .filter((iss) => iss.includes("CN="));

  let shortName = "";
  name &&
    name.split(" ").map((el, i) => {
      i === 0 ? (shortName += `${el} `) : (shortName += `${el.slice(0, 1)}. `);
    });

  let certName;
  props.issuerName && props.issuerName.trim().split(" + ").length > 1
    ? (certName = filterSubjectName2[0])
    : (certName = filterSubjectName[0]);

  certName = certName.trim().slice(3);

  return (
    <>
      <StyledSign checked={checked}>
        <StyledCertificate>
          {certName}
          <StyledRadio>
            <Radio
              checked={checked}
              name="cert"
              id={thumbprint}
              onClick={onClick}
            />
          </StyledRadio>
        </StyledCertificate>
        <StyledName>{shortName}</StyledName>
        <DateBox>
          <DateBoxStart>
            <StyledTitle>{t("certificate.issued")}</StyledTitle>
            <StyledText>{getDate(validFrom)}</StyledText>
          </DateBoxStart>
          <DateBoxEnd>
            <StyledTitle>{t("certificate.will_be_expired")}</StyledTitle>
            <StyledText>{getDate(validTo)}</StyledText>
          </DateBoxEnd>
        </DateBox>
        <StyledTitle>{t("certificate.certificate_thumbprint")}</StyledTitle>
        <ThumbprintBox>{thumbprint}</ThumbprintBox>
        {type && (
          <StyledType>
            {type === "AQES" ? t("certificate.AQES") : t("certificate.AES")}
          </StyledType>
        )}
      </StyledSign>
    </>
  );
};

export default Certificate;
