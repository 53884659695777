import React from "react";
import { IconProps } from "../types";

const VectorLeft: React.FC<IconProps> = (props) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6668 8H3.3335"
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00016 12.6663L3.3335 7.99967L8.00016 3.33301"
        stroke="#5D6C74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VectorLeft;
