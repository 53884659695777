import React from "react";
import { IconProps } from "../types";

const CheckCircle: React.FC<IconProps> = (props) => {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2666 6.32499L7.69163 9.90832L6.31663 8.53332C6.24192 8.44609 6.14999 8.37524 6.0466 8.32522C5.94322 8.2752 5.83061 8.24709 5.71584 8.24266C5.60107 8.23823 5.48663 8.25756 5.37969 8.29946C5.27276 8.34135 5.17563 8.4049 5.09442 8.48611C5.01321 8.56733 4.94966 8.66445 4.90776 8.77139C4.86587 8.87833 4.84653 8.99277 4.85096 9.10753C4.8554 9.2223 4.88351 9.33491 4.93353 9.4383C4.98354 9.54169 5.05439 9.63362 5.14163 9.70832L7.09996 11.675C7.17783 11.7522 7.27018 11.8133 7.37171 11.8548C7.47324 11.8963 7.58196 11.9173 7.69163 11.9167C7.91025 11.9157 8.11975 11.8289 8.27496 11.675L12.4416 7.50832C12.5197 7.43085 12.5817 7.33869 12.624 7.23714C12.6663 7.13559 12.6881 7.02667 12.6881 6.91666C12.6881 6.80665 12.6663 6.69773 12.624 6.59618C12.5817 6.49463 12.5197 6.40246 12.4416 6.32499C12.2855 6.16978 12.0743 6.08266 11.8541 6.08266C11.634 6.08266 11.4228 6.16978 11.2666 6.32499ZM8.99996 0.666656C7.35179 0.666656 5.74062 1.1554 4.37021 2.07108C2.9998 2.98675 1.9317 4.28824 1.30097 5.81096C0.670237 7.33368 0.505209 9.00923 0.826752 10.6257C1.1483 12.2423 1.94197 13.7271 3.10741 14.8925C4.27284 16.058 5.7577 16.8517 7.37421 17.1732C8.99072 17.4947 10.6663 17.3297 12.189 16.699C13.7117 16.0683 15.0132 15.0002 15.9289 13.6297C16.8446 12.2593 17.3333 10.6482 17.3333 8.99999C17.3333 7.90564 17.1177 6.82201 16.699 5.81096C16.2802 4.79991 15.6663 3.88125 14.8925 3.10743C14.1187 2.33361 13.2 1.71978 12.189 1.30099C11.1779 0.882205 10.0943 0.666656 8.99996 0.666656ZM8.99996 15.6667C7.68142 15.6667 6.39249 15.2757 5.29616 14.5431C4.19983 13.8106 3.34535 12.7694 2.84077 11.5512C2.33618 10.333 2.20416 8.99259 2.46139 7.69939C2.71863 6.40618 3.35357 5.2183 4.28592 4.28594C5.21827 3.35359 6.40615 2.71866 7.69936 2.46142C8.99257 2.20419 10.333 2.33621 11.5512 2.84079C12.7694 3.34538 13.8105 4.19986 14.5431 5.29619C15.2756 6.39252 15.6666 7.68145 15.6666 8.99999C15.6666 10.7681 14.9643 12.4638 13.714 13.714C12.4638 14.9643 10.7681 15.6667 8.99996 15.6667Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckCircle;
