import styled from "styled-components";
import { ReportStyledProps } from "./types";

export const ReportHeader = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
`;

export const ReportBreadcrumbs = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #9e9e9e;
  margin: 9px 0 17px;
`;

export const ContentReportTitle = styled.h6`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1d2021;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9eff2;
  width: 100%;
  margin-bottom: 20px;
`;

export const Label = styled.h6<ReportStyledProps>`
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.error ? "#E00000" : "#1d2021")};
`;

export const CalendarContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
  @media screen and (min-width: 980px) {
    margin-bottom: 3px;
  }
`;

export const CalendarInput = styled.input<ReportStyledProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 12px;
  width: 100%;
  height: 40px;
  background: ${(props) => (props.disabled ? "#E9EFF2" : "#ffffff")};
  color: ${(props) => (props.disabled ? "#A2AFB5" : "#1D2021")};
  border: 1px solid ${(props) => (props.error ? "#E00000" : "#cad5db")};
  box-shadow: 0px 1px 2px rgba(41, 41, 64, 0.07);
  border-radius: 8px;
  margin-bottom: 24px;

  @media screen and (min-width: 980px) {
    margin-bottom: 3px;
    width: 100%;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding: 22px 20px 20px;
  border: 1px solid #e9eff2;
  border-radius: 8px;
  margin-bottom: 8px;
`;

export const ReportType = styled.div`
  position: relative;
  cursor: pointer;
`;

export const TypeSelect = styled.input`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 12px;
  isolation: isolate;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cad5db;
  box-shadow: 0px 1px 2px rgba(41, 41, 64, 0.07);
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  z-index: 0;
`;

export const OptionContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  left: 1px;
  top: 46px;
  background: #ffffff;
  border: 1px solid #e9eff2;
  box-shadow:
    0px 4px 6px -2px rgba(41, 41, 64, 0.04),
    0px 10px 15px -3px rgba(41, 41, 64, 0.08);
  border-radius: 8px;
  z-index: 4;
`;

export const ButtonContainer = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonReset = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cad5db;
  box-shadow: 0px 1px 2px rgba(41, 41, 64, 0.07);
  border-radius: 8px;
  cursor: pointer;
  margin-right: 12px;

  &:hover {
    background: #cad5db;
  }
  @media screen and (max-width: 640px) {
    margin-bottom: 12px;
    justify-content: center;
    margin-right: 0;
  }
`;

export const ButtonCreate = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 16px;
  height: 40px;
  color: #ffffff;
  background: #4cd964;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    background: rgb(63, 181, 83);
  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }
`;

export const MultiSelectContainer = styled.div`
  position: relative;
`;

export const MultiSelectInput = styled.div<ReportStyledProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 12px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid ${(props) => (props.error ? "#E00000" : "#cad5db")};
  box-shadow: 0px 1px 2px rgba(41, 41, 64, 0.07);
  border-radius: 8px;
`;

export const MultiSelectInputValue = styled.div`
  position: relative;
  margin-right: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  width: 134px;
  height: 28px;
  background: #f5fafc;
  border: 1px solid #cad5db;
  border-radius: 6px;
`;

export const MultiSelectInputLength = styled.div`
  padding: 3px 3px;
  width: auto;
  height: auto;
  background: #879196;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 4px;
  display: flex;
  align-items: center;
`;

export const MultiSelectOptions = styled.ul`
  z-index: 4;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 0px;
  top: 47px;
  background: #ffffff;
  border: 1px solid #e9eff2;
  box-shadow:
    0px 4px 6px -2px rgba(41, 41, 64, 0.04),
    0px 10px 15px -3px rgba(41, 41, 64, 0.08);
  border-radius: 8px;
`;

export const Container = styled.div`
  width: 100%;
  min-width: 200px;
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 22px;

  &:hover {
    background: #c2c2c2;
  }
`;

export const ReportContainer = styled.div`
  width: 100%;
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;

  &:hover {
    background: #c2c2c2;
  }
`;

export const Option = styled.h1`
  padding: 10px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1d2021;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const CheckboxLabelTextEmployee = styled.label`
  padding-left: 8px;
  padding-top: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
`;

export const OptionEmployee = styled.h1`
  padding: 0px 12px;
  font-size: 16px;
  line-height: 20px;
  color: #1d2021;
  flex: none;
`;
export const OptionID = styled.h5`
  padding: 0px 12px 12px;
  font-size: 14px;
  line-height: 18px;
  color: #a2afb5;
  flex: none;
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 8px;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  &:hover {
    border: 1px solid #5d6c74;
    border-radius: 4px;
  }
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 100%;
  &:focus ~ ${Checkmark} {
    outline: 3px solid rgba(11, 92, 215, 0.5);
  }
  &:disabled ~ ${Checkmark} {
    border: 1px solid #e0e0e0;
    background: #f5f5f5;
  }
  &:indeterminate ~ ${Checkmark} {
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      left: 4px;
      top: 4px;
      background-color: #5d6c74;
    }
  }
  ${(props) =>
    props.checked &&
    ` 
  &:checked ~ ${Checkmark} {
    background-color: #5D6C74;
    display: block;
    &::after {
      content: "";
      display: block;
      left: 6px;
      top: 2px;
      width: 6px;
      height: 12px;
      background-color: #5D6C74;
      border: solid white;
      border-width: 0 1px 1px 0;
      transform: rotate(50deg);
    }
  }
&:disabled ~ ${Checkmark} {
    background: #C2C2C2;
    &::after {
      content: "";
      display: block;
      left: 6px;
      top: 2px;
      width: 6px;
      height: 12px;
      background-color: #C2C2C2;
      border: solid white;
      border-width: 0 1px 1px 0;
      transform: rotate(50deg);
    }
}
&:indeterminate ~ ${Checkmark} {
  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    left: 4px;
    top: 4px;
    background-color: #C2C2C2;
  }
}
`}
`;

export const CheckboxLabelText = styled.label`
  padding-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
`;

export const Wrapper = styled.div`
  margin-bottom: 24px;
  z-index: 3;
  @media screen and (min-width: 980px) {
    width: 49.7%;
  }
`;

export const InputTokenContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  isolation: isolate;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cad5db;
  box-shadow: 0px 1px 2px rgba(41, 41, 64, 0.07);
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  z-index: 3;
  padding: 5px 12px;

  @media screen and (max-width: 640px) {
    height: auto;
  }
`;

export const TokenList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  min-width: 177px;
`;

export const Token = styled.div`
  padding: 4px 8px;
  margin-right: 4px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f5fafc;
  border: 1px solid #cad5db;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1d2021;
  @media screen and (max-width: 640px) {
    margin-bottom: 4px;
  }
`;

export const EmployeeList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const EmployeeOptionContainer = styled.div`
  overflow-y: scroll;
  height: auto;
  max-height: 454px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: -1px;
  top: 47px;
  background: #ffffff;
  border: 1px solid #e9eff2;
  box-shadow:
    0px 4px 6px -2px rgba(41, 41, 64, 0.04),
    0px 10px 15px -3px rgba(41, 41, 64, 0.08);
  border-radius: 8px;
`;

export const Input = styled.input`
  all: unset;
  height: 30px;
  border: none;
  width: 100%;
`;

export const Adaptive = styled.div`
  @media screen and (min-width: 980px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const MultiContainer = styled.div`
  margin-bottom: 24px;

  @media screen and (min-width: 980px) {
    width: 49.7%;
  }
`;

export const CalendarContainerWidth = styled.div`
  margin-bottom: 24px;

  @media screen and (min-width: 980px) {
    width: 49.7%;
  }
`;

export const Calendar = styled.div`
  margin-bottom: 24px;
  position: relative;

  @media screen and (min-width: 980px) {
    width: 100%;
  }
`;

export const AllSelected = styled.div`
  margin-top: 7px;
  margin-right: 4px;
`;
