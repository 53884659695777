import React, { useEffect, useState } from "react";
//component
import List from "app/component/list/List";
import { MENU_FIELD_PERMISSIONS } from "infrastructure/enum/field-permissions.enum";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { ApplicationState } from "store/reducers";

const ZNRList: React.FC = () => {
  const [route, setRoute] = useState("");
  const menuPermissions = useSelector(
    (state: ApplicationState) => state.common.menuFieldPermissions
  );

  useEffect(() => {
    if (
      menuPermissions.length &&
      !menuPermissions.includes(MENU_FIELD_PERMISSIONS.EXPENSE_APPLICATION)
    ) {
      setRoute("/AdvanceReportApplication");
    }
  }, [menuPermissions]);

  if (route !== "") {
    return <Redirect to={route} />;
  } else return <List />;
};

export default ZNRList;
