import styled from "styled-components";
import { InputProps } from "./types";

export const PersonalData = styled.div`
  position: relative;
  width: 49.5%;
  @media screen and (max-width: 980px) {
    width: 49.4%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const PassportDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 640px) {
    display: block;
  }
`;
export const PassportData = styled.div`
  position: relative;
  width: 24.2%;
  @media screen and (max-width: 980px) {
    width: 24%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const BottomLabel = styled.label`
  width: 165px;
  height: 16px;
  font-family: "Harmonia Sans Pro Cyr";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #616161;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`;

export const WarningIconInput = styled.div<InputProps>`
  position: absolute;
  bottom: 26px;
  right: 0px;
  visibility: ${(props) => (props.error ? "visible" : "hidden")};
`;

export const PassportIssuedByContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
  justify-content: space-between;
  max-height: 100%;
  margin-bottom: 28px;

  @media screen and (max-width: 640px) {
    display: block;
  }
`;

export const PassportIssuedBy = styled.label`
  position: relative;
  min-height: "70px";
  max-height: "100%";
  font-size: 16px;
  width: 100%;
  font-family: "HarmoniaSansProCyr";
  max-height: 100%;
`;

export const WarningIconTextarea = styled.div<InputProps>`
  position: absolute;
  top: 44px;
  right: 0;
  visibility: ${(props) => (props.error ? "visible" : "hidden")};
`;
