import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, composeInitialProps } from "react-i18next";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";

//store
import { ApplicationState } from "store/reducers";
//component
import { Checkbox } from "ui/Checkbox";
import SvgIcon from "app/component/svg-icon/SvgIcon";
//common
import moment from "moment";
import If from "../util/If";
import ModalContainer from "../modal/ModalContainer";
import { loaderLock, loaderUnlock } from "store/common/actions";
import {
  updateDownlandJournalList,
  setJournalPage,
  setJournalFilter,
} from "store/journal/actions";
import { showErrors } from "store/exception/actions";
import { getJournalList } from "../../../store/selectors";
import { deleteBatchesById } from "../../../services/ApiService";

interface Downloads {
  isAdvance?: boolean;
}

const Downloads: React.FC<Downloads> = (props) => {
  const { t } = useTranslation();

  let [chosenItems, setChosenItems] = useState([] as number[]);
  let [deleteDownloadModalOpen, setDeleteDownloadModalOpen] = useState(false);
  let [selectedId, setSelectedId] = useState(-1);

  const dispatch = useDispatch();

  let downloadsList = useSelector(getJournalList);

  useEffect(() => {
    return () => {
      setChosenItems([]);
    };
  }, []);

  const checkAllItem = () => {
    if (
      !!downloadsList &&
      chosenItems.length === downloadsList.length &&
      downloadsList.length !== 0
    ) {
      setChosenItems([]);
    } else if (downloadsList) {
      setChosenItems([...downloadsList.map((item) => item.id)]);
    }
  };

  const checkChosenItem = (id: number): boolean => {
    return chosenItems.includes(id);
  };

  const clearAllItem = () => {
    setChosenItems([]);
  };

  const toggleClickItem = (id: number) => {
    const copy = chosenItems.slice();
    if (checkChosenItem(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      copy.push(id);
    }
    setChosenItems(copy);
  };

  const checkedAll = (): boolean => {
    return (
      !!downloadsList &&
      chosenItems.length === downloadsList.length &&
      downloadsList.length !== 0
    );
  };

  const deleteHandler = (id?: number) => {
    setDeleteDownloadModalOpen(true);
    setSelectedId(id ? id : -1);
  };

  const closeHandler = () => {
    setDeleteDownloadModalOpen(false);
    setSelectedId(-1);
  };

  const submitHandler = async (id?: number) => {
    if (id != -1) {
      dispatch(loaderLock());
      let response = await deleteBatchesById(id);
      if (response.headers.success) {
        dispatch(setJournalPage(1));
        dispatch(setJournalFilter({}));
        dispatch(updateDownlandJournalList(false));
      } else {
        dispatch(
          showErrors({
            code: "delete_downland",
            message: "Что-то пошло не так",
          }),
        );
      }
      dispatch(loaderUnlock());
    } else {
      let chosenDownloadPromises = chosenItems.map((item) => {
        return deleteBatchesById(item);
      });

      let allPromise = Promise.all(chosenDownloadPromises);
      allPromise
        .then((results) => {
          let allOk = true;
          results.forEach((item) => {
            if (!item.headers.success) {
              allOk = false;
            }
          });

          if (allOk) {
            dispatch(setJournalPage(1));
            dispatch(setJournalFilter({}));
            dispatch(updateDownlandJournalList(false));
          } else {
            dispatch(
              showErrors({
                code: "delete_downland",
                message: "Что-то пошло не так",
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(
            showErrors({
              code: "delete_downland",
              message: "Что-то пошло не так",
            }),
          );
        });
    }
    setDeleteDownloadModalOpen(false);
    setSelectedId(-1);
  };

  let timerDate = new Date().getTime();

  const lifeScrolling = () => {
    let newTimerDate = new Date().getTime();
    if (window.pageYOffset === 0 || (downloadsList && !downloadsList.length))
      return;
    if (
      document.body.clientHeight - window.pageYOffset - window.innerHeight <
        1000 &&
      newTimerDate - timerDate >= 1000
    ) {
      timerDate = newTimerDate;
      dispatch(updateDownlandJournalList(true));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", lifeScrolling);
    return () => {
      window.removeEventListener("scroll", lifeScrolling);
    };
  }, []);

  return (
    <Fragment>
      <div className="journal-swap-controls">
        <Checkbox
          label={!isEmpty(chosenItems) ? "" : t("journal.select_all")}
          onClick={checkAllItem}
          className={"check-all m-b-0"}
          checked={checkedAll()}
          name={"chooseAll"}
          id={-1}
        />
        <If condition={!isEmpty(chosenItems)}>
          <div className="journal-swap-controls-counter">
            <span>
              {t("journal.selected", { count: chosenItems.length })} |
            </span>
            <a className={"pointer"} onClick={clearAllItem}>
              {t("journal.cancel_selection")}
            </a>
          </div>
          <a
            className="flex-center control pointer"
            onClick={() => {
              deleteHandler();
            }}
          >
            <SvgIcon className={"icon icon-remove"} href={"#svg_icon_remove"} />
            <span>{t("journal.delete_selected")}</span>
          </a>
        </If>
      </div>
      <div className="request-expenses-wrap journal-swap">
        <DownloadsList
          downloadsList={downloadsList}
          toggleClickItem={toggleClickItem}
          checkChosenItem={checkChosenItem}
          onDeleteClick={deleteHandler}
        />
      </div>
      <DeleteDownloadModal
        isOpen={deleteDownloadModalOpen}
        onClose={closeHandler}
        onSubmit={submitHandler}
        id={selectedId}
      />
    </Fragment>
  );
};

interface DownloadsListProps {
  downloadsList: any[];
  isAdvance?: boolean;
  toggleClickItem: (id: number) => void;
  checkChosenItem: (id: number) => boolean;
  onDeleteClick: (id: number) => void;
}

const DownloadsList: React.FC<DownloadsListProps> = (props) => {
  const { t, i18n } = useTranslation();

  const clickHandler = (e: any, id: number) => {
    const type: any = (e.target as HTMLElement).getAttribute("data-type");
    if (type) {
      e.preventDefault();
    }
    switch (type) {
      case "delete":
        return props.onDeleteClick(id);
      case "checkbox":
        props.toggleClickItem(id);
        return;
    }
  };

  return (
    <Fragment>
      {props.downloadsList.map((item, index) => {
        return (
          <Link
            to={`/Journal/Downloads/${item.id}`}
            onClick={(e) => clickHandler(e, item.id)}
          >
            <div className="request-expenses-item">
              <div
                style={{ display: "flex" }}
                className="request-expenses-block"
              >
                <label
                  onClick={(e) => {
                    e.preventDefault();
                    props.toggleClickItem(1);
                  }}
                  style={{
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  htmlFor={`check${index}`}
                >
                  <Checkbox
                    id={index}
                    name={`check${index}`}
                    label={""}
                    onClick={() => props.toggleClickItem(1)}
                    checked={props.checkChosenItem(item.id)}
                    type={"checkbox"}
                  />
                </label>
              </div>
              <div className="request-expenses-block info">
                <div className="request-expenses-item-text">
                  {moment
                    .utc(item.createdAt, "YYYY-MM-DDThh:mm:ss")
                    .locale(i18n.language as "ru")
                    .local()
                    .format("DD MMMM YYYY HH:mm")}
                </div>
                <div className="request-item-name">№{item.id}</div>
              </div>
              <div className="request-expenses-block-wrap">
                <div className="request-expenses-block">
                  <div className="request-list-item-status">
                    {item.employee.lastName[i18n.language as "ru"]}{" "}
                    {item.employee.firstName[i18n.language as "ru"][0]}
                  </div>
                </div>
                <div className="flex-sm-row flex-sm-between">
                  <div>
                    <div className="request-list-item-cost">
                      Проводок: {item.transactionsCount}
                    </div>
                  </div>
                </div>
              </div>
              <div className="request-expenses-block">
                <a className="delete pointer">
                  <SvgIcon
                    className={"icon icon-remove"}
                    href={"#svg_icon_remove"}
                    type={"delete"}
                  />
                </a>
              </div>
            </div>
          </Link>
        );
      })}
    </Fragment>
  );
};

interface DeleteDownloadModalProps {
  isOpen: boolean;
  onSubmit: (id?: number) => void;
  onClose: () => void;
  id?: number;
}

export const DeleteDownloadModal: React.FC<DeleteDownloadModalProps> = (
  props,
) => {
  const { t } = useTranslation();

  const closeHandler = () => {
    props.onClose();
  };

  const submitHandler = () => {
    props.onSubmit(props.id);
  };

  return (
    <ModalContainer
      isOpen={props.isOpen}
      destroy={true}
      overlayClick={closeHandler}
    >
      <div className="box-modal" id="note-delete">
        <div
          className="box-modal_close arcticmodal-close"
          onClick={closeHandler}
        />
        <div className="box-modal-title">
          <div className="tx-center">
            {"Действительно хотите удалить проводки?"}
          </div>
        </div>
        <div className="box-modal-content">
          <div className="text">
            <div className="tx-center">
              {t("modals.delete_expense_modal.impossible_to_restore")}
            </div>
          </div>
        </div>
        <div className="box-modal-footer">
          <a className="arcticmodal-close btn btn_black" onClick={closeHandler}>
            {t("modals.delete_expense_modal.no")}
          </a>
          <a className="btn-expense btn_green" onClick={submitHandler}>
            {t("modals.delete_expense_modal.yes")}
          </a>
        </div>
      </div>
    </ModalContainer>
  );
};

export default Downloads;
