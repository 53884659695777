import { ActionCreator } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  SetPowerOfAttorneyList,
  POWEROFATTORNEY,
  PowerOfAttorneyActions,
  SetPowerOfAttorneyDetail,
  CheckIsPowerOfAttorneyEnabled,
} from "./actionsTypes";
import { ApplicationState } from "store/reducers";
import {
  changePowerOfAttorneyToSigningRequires,
  declinePowerOfAttorney,
  getPowerOfAttorney,
  getPowersOfAttorney,
  postPowerOfAttorney,
  revokePowerOfAttorney,
  updatePowerOfAttorney,
} from "services/ApiService";
import { showErrors, showInfo } from "store/exception/actions";
import Config, { ConfigTypes } from "services/ConfigService";
import moment from "moment";

export const checkIsPowerOfAttorneyEnabled: ActionCreator<
  CheckIsPowerOfAttorneyEnabled
> = (isEnabled) => ({
  type: POWEROFATTORNEY.ENABLED,
  payload: isEnabled,
});

export const IsPowerOfAttorneyEnabled = (
  userDetail: any,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    for (let i = 0; i < userDetail.occupations.length; i++) {
      if (
        Config.getConfigToCompany(
          ConfigTypes.POWER_OF_ATTORNEY_ENABLED,
          userDetail.occupations[i].company.id,
        )
      ) {
        dispatch(checkIsPowerOfAttorneyEnabled(true));
      } else {
        dispatch(checkIsPowerOfAttorneyEnabled(false));
      }
    }
  };
};

export const setPowerOfAttorneyList: ActionCreator<SetPowerOfAttorneyList> = (
  PowerOfAttorneyList,
) => ({
  type: POWEROFATTORNEY.SET_LIST,
  payload: PowerOfAttorneyList,
});

export const setListOfPowerOfAttorney = (
  params: {},
  setItemsCount?: (a: number) => void,
  setIsLoading?: (a: boolean) => void,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    try {
      let result = await getPowersOfAttorney({
        params,
      });
      if (result.headers.success) {
        dispatch(setPowerOfAttorneyList(result.data.data));
        setItemsCount && setItemsCount(result.data.itemsCount);
        setIsLoading && setIsLoading(false);
      }
    } catch {}
  };
};

export const searchPowerOfAttorney = (
  params: {},
  setItemsCount: (a: number) => void,
  setSearched: (a: boolean) => void,
  setCurrentPage: (a: number) => void,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    try {
      let result = await getPowersOfAttorney({
        params,
      });
      if (result.headers.success && result.data.data.length) {
        dispatch(setPowerOfAttorneyList(result.data.data));
        setSearched(true);
        setItemsCount(result.data.itemsCount);
        setCurrentPage(1);
      } else {
        setSearched(false);
        setItemsCount(0);
      }
    } catch {}
  };
};

export const setPowerOfAttorneyDetail: ActionCreator<
  SetPowerOfAttorneyDetail
> = (PowerOfAttorneyDetail) => ({
  type: POWEROFATTORNEY.SET_DETAIL,
  payload: PowerOfAttorneyDetail,
});

export const getPowerOfAttorneyDetailed = (
  id: number,
  setIsModalToEdit?: (a: boolean) => void,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    try {
      let result = await getPowerOfAttorney(id);
      if (result.headers.success) {
        dispatch(setPowerOfAttorneyDetail(result.data));
        setIsModalToEdit && setIsModalToEdit(true);
      }
    } catch {}
  };
};

export const saveDraft = (
  data: {},
  isManager: boolean,
  setIsModalToCreate?: (a: boolean) => void,
  setIsRequestProcessing?: (a: boolean) => void,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    const result = await postPowerOfAttorney(data);
    setIsRequestProcessing && setIsRequestProcessing(false);
    if (result.headers.success) {
      dispatch(setPowerOfAttorneyDetail(result.data));
      if (!isManager && setIsModalToCreate) {
        setIsModalToCreate(false);
        dispatch(
          showInfo({
            code: "",
            message: "Доверенность была успешно сохранена",
          }),
        );
      }
    } else {
      dispatch(
        showErrors({
          code: "",
          message: "Не удалось сохранить доверенность",
        }),
      );
    }
  };
};

export const saveAndSendForSigning = (
  data: {},
  setPowerOfAttorneyId: (a: number) => void,
  setSigningBlobFileId: (a: number) => void,
  isManager: boolean,
  setIsModalToCreate: (a: boolean) => void,
  setCert: (a: boolean) => void,
  setIsRequestProcessing?: (a: boolean) => void,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    postPowerOfAttorney(data)
      .then((dat) => dat.data.id)
      .then((id) => changePowerOfAttorneyToSigningRequires(id))
      .then((result) => {
        setIsRequestProcessing && setIsRequestProcessing(false);
        if (result.headers.success) {
          dispatch(setPowerOfAttorneyDetail(result.data));
          setPowerOfAttorneyId(result.data.id);
          setSigningBlobFileId(result.data.signingBlobFileId);
          if (!isManager && setIsModalToCreate) {
            setIsModalToCreate(false);
            dispatch(
              showInfo({
                code: "",
                message: "Доверенность ожидает подписания",
              }),
            );
          } else if (isManager) {
            setCert(true);
          }
        } else {
          dispatch(
            showErrors({
              code: "",
              message:
                "Не удалось сохранить доверенность и отправить на подписание",
            }),
          );
        }
      });
  };
};

export const updateAndSave = (
  POW: any,
  data: {},
  isManager: boolean,
  setIsModalToEdit: (a: boolean) => void,
  setIsRequestProcessing?: (a: boolean) => void,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    const result = await updatePowerOfAttorney(POW.id, data);
    setIsRequestProcessing && setIsRequestProcessing(false);
    if (result.headers.success) {
      dispatch(getPowerOfAttorneyDetailed(POW.id));
      if (!isManager && setIsModalToEdit) {
        setIsModalToEdit(false);
        dispatch(
          showInfo({
            code: "",
            message: "Доверенность была успешно сохранена",
          }),
        );
      }
    } else {
      dispatch(
        showErrors({
          code: "",
          message: "Не удалось сохранить доверенность",
        }),
      );
    }
  };
};

export const updateAndChangeStatus = (
  POW: any,
  data: {},
  setIsModalToEdit: (a: boolean) => void,
  setIsRequestProcessing?: (a: boolean) => void,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    const result = await updatePowerOfAttorney(POW.id, data);
    if (result.headers.success) {
      const res = await changePowerOfAttorneyToSigningRequires(POW.id);
      setIsRequestProcessing && setIsRequestProcessing(false);
      if (res.headers.success) {
        dispatch(getPowerOfAttorneyDetailed(POW.id));
        if (setIsModalToEdit) {
          setIsModalToEdit(false);
          dispatch(
            showInfo({
              code: "",
              message: "Доверенность ожидает подписания",
            }),
          );
        }
      } else {
        dispatch(
          showErrors({
            code: "",
            message:
              "Не удалось сохранить доверенность и отправить на подписание",
          }),
        );
      }
    } else {
      setIsRequestProcessing && setIsRequestProcessing(false);
      dispatch(
        showErrors({
          code: "",
          message:
            "Не удалось сохранить доверенность и отправить на подписание",
        }),
      );
    }
  };
};

export const changeStatusToSigning = (
  id: number,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    const result = await changePowerOfAttorneyToSigningRequires(id);
    if (result.headers.success) {
      dispatch(setPowerOfAttorneyDetail(result.data));
    } else {
      dispatch(
        showErrors({
          code: "",
          message: "Не удалось отправить доверенность на подписание",
        }),
      );
    }
  };
};

export const revoke = (
  id: number,
  data: {},
  setIsModalToRevoke: (a: boolean) => void,
  isInfoModal: (a: boolean) => void,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    const result = await revokePowerOfAttorney(id, data);
    if (result.headers.success) {
      dispatch(setPowerOfAttorneyDetail(result.data));
      setIsModalToRevoke(false);
      isInfoModal(false);
    } else {
      dispatch(
        showErrors({
          code: "",
          message: "Не удалось отозвать доверенность",
        }),
      );
    }
  };
};

export const decline = (
  id: number,
  data: {},
  setIsModalToDecline: (a: boolean) => void,
  isInfoModal: (a: boolean) => void,
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    const result = await declinePowerOfAttorney(id, data);
    if (result.headers.success && setIsModalToDecline) {
      dispatch(setPowerOfAttorneyDetail(result.data));
      setIsModalToDecline(false);
      isInfoModal(false);
    } else {
      dispatch(
        showErrors({
          code: "",
          message: "Не удалось отклонить доверенность",
        }),
      );
    }
  };
};

export const setListOfPowerOfAttorneyForSigning = (
  name: string,
  objectLogicalNames: string[],
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  PowerOfAttorneyActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, PowerOfAttorneyActions>,
    getState,
  ): Promise<void> => {
    let revokedButValidPowersofAttorneys;
    let signedValidPowersofAttorneys;

    let revoked = await getPowersOfAttorney({
      params: {
        state: "Revoked",
        queryParameter: "representativeFullName",
        queryValue: name,
        pageSize: 1000,
        authorityObjectLogicalNames: objectLogicalNames[0],
      },
    });
    if (revoked.headers.success) {
      revokedButValidPowersofAttorneys = revoked.data.data.filter(
        (el: { revokeDate: Date }) => moment(el.revokeDate).isAfter(),
      );
    }

    let signed = await getPowersOfAttorney({
      params: {
        state: "Signed",
        queryParameter: "representativeFullName",
        queryValue: name,
        pageSize: 1000,
        authorityObjectLogicalNames: objectLogicalNames[0],
      },
    });
    if (signed.headers.success) {
      signedValidPowersofAttorneys = signed.data.data.filter(
        (el: { validTo: Date }) => moment(el.validTo).isAfter(),
      );
    }

    const validPowerOfAttorneys = [
      ...revokedButValidPowersofAttorneys,
      ...signedValidPowersofAttorneys,
    ];
    validPowerOfAttorneys.sort((a, b) => a.id - b.id);
    dispatch(setPowerOfAttorneyList(validPowerOfAttorneys));
  };
};
