import { BaseMapper } from "./base.mapper";
import {
  ApplicationForExpenseList,
  ApplicationForExpenseItem,
  Expense,
} from "infrastructure/interfaces";
import { get } from "lodash";
import { ApplicationForExpenseItemMapper } from "./ApplicationForExpenseItem.mapper";

export class ApplicationForExpenseListMapper extends BaseMapper<
  ApplicationForExpenseList,
  any
> {
  responseToEntity(response: any): ApplicationForExpenseList {
    const applicationForExpenseItemMapper =
      new ApplicationForExpenseItemMapper();
    return {
      data: applicationForExpenseItemMapper.responsesToEntitys(
        get(response, "data", []),
      ),
      itemsCount: response.itemsCount,
      itemsPerPage: response.itemsPerPage,
      pageNumber: response.pageNumber,
    };
  }
}
