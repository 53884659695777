import { ApproveList } from "infrastructure/interfaces";
import { Reducer } from "redux";
import { AGREEMENT, AgreementActions } from "./actionsTypes";

export interface AgreementState {
  agreementList: ApproveList;
}

export const initialState: AgreementState = {
  agreementList: {
    data: [],
    itemsCount: 0,
    itemsPerPage: 0,
    pageNumber: 0,
  },
};

export const reducer: Reducer<AgreementState> = (
  state: AgreementState = initialState,
  action,
) => {
  switch ((action as AgreementActions).type) {
    case AGREEMENT.SET_LIST:
      if (action.payload.reloading) {
        const loaded = action.payload.agreementList.data;
        const old = state.agreementList ? state.agreementList.data : [];
        action.payload.agreementList.data = [...old, ...loaded];
        return { ...state, agreementList: action.payload.agreementList };
      }
      return { ...state, agreementList: action.payload.agreementList };
    default:
      return state;
  }
};

export default reducer;
