import styled from "styled-components";

export interface Error {
  error?: boolean;
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid var(--neutrals-300, #cad5db);
`;

export const HeaderTitle = styled.div`
  color: var(--neutrals-900, #1d2021);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const Body = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 20px;
`;

export const BodyTitle = styled.div`
  color: var(--neutrals-900, #1d2021);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const BodyTextarea = styled.textarea<Error>`
  display: flex;
  padding-left: 0px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${({ error }) => (error ? "#FF3B30" : "#cad5db")};
  margin-bottom: 16px;
`;

export const CheckInfoContainer = styled.div`
  border-top: 1px solid var(--neutrals-300, #cad5db);
  padding-top: 16px;
`;

export const CheckInfoLine = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const CheckInfoLineName = styled.div`
  width: 134px;
  color: var(--neutrals-500, #879196);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const CheckInfoLineValue = styled.div`
  color: var(--neutrals-900, #1d2021);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const CheckInfoLineInput = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const CheckInfoLineValueInput = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutrals-200, #e9eff2);
  background: var(--neutrals-100, #f5fafc);
  color: #a2afb5;
  box-shadow: 0px 1px 2px 0px rgba(41, 41, 64, 0.07);
`;

export const Footer = styled.div`
  color: var(--neutrals-900, #1d2021);
  background: var(--neutrals-100, #fff);
  position: fixed;
  bottom: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100%;
  padding: 20px;
  border-top: 1px solid var(--neutrals-300, #cad5db);
  display: flex;
  justify-content: flex-end;
`;

export const ButtonBack = styled.button`
  padding: 10px 16px;
  background: none;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #5d6c74;
  color: #5d6c74;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.03);
  }
`;

export const ButtonSave = styled.button`
  padding: 10px 16px;
  background: #4cd964;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: var(--neutrals-0, #fff);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 12px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.03);
  }
`;
