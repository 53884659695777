import React from "react";
import ButtonsControl from "app/component/buttons-control/ButtonsControl";

interface AOButtonsControl {
  id: number;
  isPostingsMode: boolean;
  setPostingsMode: () => void;
  showRevokeButtonOnly?: boolean;
}

const AOButtonsControl: React.FC<AOButtonsControl> = (props) => {
  return (
    <ButtonsControl
      showRevokeButtonOnly={props.showRevokeButtonOnly}
      id={props.id}
      isPostingsMode={props.isPostingsMode}
      isAdvance={true}
      setPostingsMode={props.setPostingsMode}
    />
  );
};

export default AOButtonsControl;
