import styled from "styled-components";
import { StyledBoxBorderProps } from "./types";

const StyledBoxBorder = styled.div<StyledBoxBorderProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 12px;
  border: 1px ${(props) => props.border} #98a2a8;
  border-radius: 3px;
  min-height: 134px;
`;

const StyledBoxBorderText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #5d6c74;
`;

export { StyledBoxBorder, StyledBoxBorderText };
