import React, { useState, useEffect, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Config, { ConfigTypes } from "services/ConfigService";
import { RadioButton } from "ui/RadioButton";
import Select, {
  SelectOptionType,
  SelectRef,
} from "app/component/select/Select";
import ModalContainer from "app/component/modal/ModalContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  updateExpenseType,
  updateBusinessGoal,
  setBusinessGoal as setBusinessGoalAction,
  loaderLock,
  loaderUnlock,
} from "store/common/actions";
import { showErrors } from "store/exception/actions";
import { ExpenseType, TravelDates } from "infrastructure/interfaces";
import {
  nameFormatter,
  NameFormats,
  getNextKey,
  toDate,
  returnDateWithoutUTC,
  betweenTwoDates,
} from "app/utils";
import _, { get } from "lodash";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { updateApplicationForExpenseDetail } from "store/applicationForExpense/actions";
import Validator from "app/component/validator/Validator";
import EmployeeAutocomplete, {
  EmployeeAutocompleteOptionType,
} from "../autocomplete/EmployeeAutocomplete";
import { OCCUPATION_ROLE_TYPE } from "infrastructure/enum/user-role.enum";
import Road, { RoadObj } from "./Road";
import CreateOrderModal from "./CreateOrderModal";
import moment from "moment";
import analitiks from "services/AnaliticsService";
import { isIE } from "react-device-detect";
import { EXPENSE_APPLICATION_PROPETIES } from "infrastructure/enum/object-properties.enum";
import CustomProperty from "../custom-property/CustomProperty";
import {
  getApplicationForExpenseDetail,
  getCommonBusinessGoals,
  getCommonExpenseTypes,
  getCommonUserDetail,
} from "../../../store/selectors";
import {
  availableCustomProperties,
  deleteExpensesById,
  getAssistancesById,
  getFieldPermissionsCreateExpenseApplications,
  getFieldPermissionsUpdateExpenseApplications,
  getOccupationsShortByEmployeeId,
  getPerDiemRecalculationBusinessGoals,
  postExpenseApplications,
  updateExpensesById,
  updatePerDiemCalculations,
} from "../../../services/ApiService";
import { Button } from "ui/Button";
import { FilterCustomProperties } from "./CreateAOModal";

interface CreateExpenseModal extends RouteComponentProps<any> {
  isOpen: boolean;
  onClose: (isSimpleClose?: boolean) => void;
  editId?: number;
  hasNext?: boolean;
}

interface ExpenseApplications {
  applicationTypeId?: number;
  businessTargetId: number;
  description?: string;
  businessTripOption?: {
    departureFromCityId: number;
    startOn: Date | null;
    arrivalToCityId: number;
    endOn: Date | null;
    personalDaysNumber?: number;
    applicationTravelDates?: { startDate: Date; endDate: Date }[];
    businessTripDestination?: {
      cityId: number;
      arrivalOn: Date | null;
      leaveOn: Date | null;
      ordinal: number;
    }[];
  };
  assigneeEmployeeId?: number;
  occupationId?: number;
}

const CreateExpenseModal: React.FC<CreateExpenseModal> = (props) => {
  let { t, i18n } = useTranslation();

  const expenseTypes = useSelector(getCommonExpenseTypes);
  const applicationForExpenseDetail = useSelector(
    getApplicationForExpenseDetail
  );
  const businessGoalOptions = useSelector(getCommonBusinessGoals);
  const userDetail = useSelector(getCommonUserDetail);

  const [hideBusinessGoal, setHideBusinessGoal] = useState(false);

  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [multiRoadMode, setMultiRoadMode] = useState(false);

  const [expenseType, setExpenseType] = useState(11);
  const [expenseTypeCode, setExpenseTypeCode] = useState("General");
  const [description, setDescription] = useState("");
  const [businessGoal, setBusinessGoal] = useState<SelectOptionType>();
  const [businessGoalOnlyOne, setBusinessGoalOnlyOne] = useState(false);
  const [assistance, setAssistance] = useState({ text: "", value: 0 });
  const [employeeAutocompleteReadOnly, setEmployeeAutocompleteReadOnly] =
    useState(false);
  const [roads, setRoads] = useState([] as RoadObj[]);
  const [bookerAndOwner, setBookerAndOwner] = useState(false);
  const [isShowDailyRenew, setShowDailyRenew] = useState(false);
  const [assistanceOptions, setAssistanceOptions] = useState<
    SelectOptionType[]
  >([]);
  const [responsibleOccupation, setResponsibleOccupation] =
    useState<SelectOptionType>();
  const [responsibleOccupationOptions, setResponsibleOccupationOptions] =
    useState<SelectOptionType[]>([]);
  const [creatorOccupationOptions, setCreatorOccupationOptions] = useState<
    SelectOptionType[]
  >([]);
  const [selectedOccupation, setSelectedOccupation] = useState({} as any);
  const [editPermissions, setEditPermissions] = useState([] as string[]);
  const [createPermissions, setCreatePermissions] = useState([] as string[]);
  const [readPermissions, setReadPermissions] = useState([] as string[]);
  const [assigneeDetail, setAssigneeDetail] = useState({
    userId: 0,
    occupationId: 0,
    companyId: 0,
  });
  const [isTabIndexDisabled, setTabIndexDisabled] = useState(false);
  const [isBusinessGoalChange, setBusinessGoalChange] = useState(false);
  const [recalculationBusinessGoal, setRecalculationBusinessGoals] = useState(
    [] as number[]
  );
  const [isSelectOccupation, setSelectOccupation] = useState(false);
  const [filterCustomProperties, setFilterCustomProperties] = useState<
    FilterCustomProperties[]
  >([]);

  const descriptionRef = useRef({} as HTMLInputElement);
  const businessGoalRef = useRef<SelectRef>({} as SelectRef);
  const assistanceRef = useRef<SelectRef>({} as SelectRef);
  const creatorOccupationRef = useRef<SelectRef>({} as SelectRef);
  const customPropertyRef = useRef({} as any);

  const businessGoalValidatorRef = useRef({} as any);
  const assistanceValidatorRef = useRef({} as any);
  const responsibleValidatorRef = useRef({} as any);
  const creatorOccupationValidatorRef = useRef({} as any);
  const responsibleOccupationRef = useRef({} as any);
  const dataRef = useRef({} as any);
  const arrayExpenseTypes = useRef<Array<any>>();

  useEffect(() => {
    if (editMode) {
      const businessTargetIdDefault: number = applicationForExpenseDetail
        ? applicationForExpenseDetail.businessTarget.id
        : -1;

      if (recalculationBusinessGoal.includes(businessTargetIdDefault)) {
        businessGoal && recalculationBusinessGoal.includes(+businessGoal.value)
          ? setBusinessGoalChange(false)
          : setBusinessGoalChange(true);
      } else {
        businessGoal && !recalculationBusinessGoal.includes(+businessGoal.value)
          ? setBusinessGoalChange(false)
          : setBusinessGoalChange(true);
      }
    }
  }, [
    applicationForExpenseDetail,
    businessGoal,
    editMode,
    isBusinessGoalChange,
    recalculationBusinessGoal,
  ]);

  const getAvailableCustomProperties = async () => {
    if (businessGoal && businessGoal.value) {
      const data = await availableCustomProperties(Number(businessGoal.value));
      setFilterCustomProperties(data.data.customProperties);
      if (
        customPropertyRef &&
        customPropertyRef.current &&
        customPropertyRef.current.build &&
        !hideBusinessGoal &&
        businessGoalOptions.length > 1
      ) {
        customPropertyRef.current.build();
      }
    }
  };

  useEffect(() => {
    if (businessGoalOptions.length > 0 && props.isOpen)
      getAvailableCustomProperties();
  }, [businessGoal]);

  useEffect(() => {
    if (props.isOpen) {
      setSelectedOccupation({} as any);
      setAssistanceOptions([]);
      setResponsibleOccupationOptions([]);
	  setFilterCustomProperties([]);
      if (props.editId) {
        //getEditPermissions();
        initEditMode();
        if (
          applicationForExpenseDetail &&
          applicationForExpenseDetail.properties
        ) {
          setReadPermissions(applicationForExpenseDetail.properties);
        }
      } else {
        if (userDetail.occupations.length <= 1) {
          creatorOccupationtChooseHandler({
            text: "",
            value: userDetail.occupations[0].occupationId,
          });
          setAssigneeDetail({
            userId: userDetail.id,
            occupationId: userDetail.occupations[0].occupationId,
            companyId: userDetail.occupations[0].company.id,
          });

          dispatch(updateExpenseType(userDetail.occupations[0].company.id));
        }
      }

      initCreatorOccupationOptions(userDetail.occupations);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (_.isEqual(expenseTypes, arrayExpenseTypes.current)) {
      return;
    }
    arrayExpenseTypes.current = expenseTypes;
    if (expenseTypes && expenseTypes.length > 0) {
      let expenseTypeGeneral = {} as ExpenseType;
      expenseTypes.forEach((item) => {
        if (item.code === "General") {
          expenseTypeGeneral = item;
        }
      });
      if (!expenseTypeGeneral.id && expenseTypes.length > 0) {
        expenseTypeGeneral = expenseTypes[0];
      }
      setExpenseType(expenseTypeGeneral.id);
      setExpenseTypeCode(expenseTypeGeneral.code);
      dispatch(updateBusinessGoal(expenseTypeGeneral.id));
    } else {
      dispatch(setBusinessGoalAction([]));
    }
  }, [expenseTypes]);

  useEffect(() => {
    if (
      applicationForExpenseDetail &&
      applicationForExpenseDetail.assigneeEmployee.id != userDetail.id &&
      isBookerOccupation()
    ) {
      setBookerAndOwner(true);
    } else {
      setBookerAndOwner(false);
    }
  }, [applicationForExpenseDetail]);

  useEffect(() => {
    if (!businessGoalOptions) {
      return;
    }

    const businessGoals = businessGoalOptions.map((businessGoalOption) => {
      return {
        value: businessGoalOption.id,
        text: businessGoalOption.name[i18n.language as "ru"],
      };
    });

    if (businessGoals.length == 1) {
      setBusinessGoal(businessGoals[0]);
      setBusinessGoalOnlyOne(true);
    } else {
      setBusinessGoal({} as SelectOptionType);
      setBusinessGoalOnlyOne(false);
    }

    if (props.editId) {
      const businessTargetId = applicationForExpenseDetail
        ? applicationForExpenseDetail.businessTarget.id
        : -1;
      const businessTargetName = applicationForExpenseDetail
        ? applicationForExpenseDetail.businessTarget.name[i18n.language as "ru"]
        : "";
      setBusinessGoal({
        value: businessTargetId,
        text: businessTargetName,
      });
    }
  }, [businessGoalOptions]);

  const getEditPermissions = async () => {
    let result = await getFieldPermissionsUpdateExpenseApplications(
      applicationForExpenseDetail ? applicationForExpenseDetail.id : 0
    );
    if (result.headers.success) {
      let permissions = result.data;
      setEditPermissions(permissions);
    }
  };

  const getCreatePermissions = async () => {
    let result = await getFieldPermissionsCreateExpenseApplications();
    if (result.headers.success) {
      let permissions = result.data;
      setCreatePermissions(permissions);
    }
  };

  const getRecalculationBusinessGoals = async (
    companyId: number,
    objectTypeId: string
  ) => {
    let result = await getPerDiemRecalculationBusinessGoals(companyId);
    if (result.headers.success) {
      let recalculationBusinessGoals = result.data
        .filter(
          (item: { objectTypeId: string }) => item.objectTypeId === objectTypeId
        )
        .map((item: { valueInt: number }) => item.valueInt);
      setRecalculationBusinessGoals(recalculationBusinessGoals);
    }
  };

  const generateClassName = (properties: string): string => {
    let classes = [];
    if (editMode) {
      !readPermissions.includes(properties) && classes.push("d-none");
      !editPermissions.includes(properties) && classes.push("disabled");
      return classes.join(" ");
    }
    !createPermissions.includes(properties) && classes.push("disabled");
    return classes.join(" ");
  };

  const isBookerOccupation = (): boolean => {
    if (!selectedOccupation.occupationId) {
      return false;
    }
    let occupations = selectedOccupation.roles.map((role: any) => role.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  const isAssistantManagerOccupation = (): boolean => {
    if (!selectedOccupation.occupationId) {
      return false;
    }
    let occupations = selectedOccupation.roles.map((role: any) => role.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.ASSISTANT_MANAGER);
  };

  const isCoordinatorOccupation = (): boolean => {
    if (!selectedOccupation.occupationId) {
      return false;
    }
    let occupations = selectedOccupation.roles.map((role: any) => role.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.COORDINATOR);
  };

  const initClearMode = async () => {
    setEditMode(false);
    await getCreatePermissions();
    setDescription("");
    setRoads([
      {
        departureFromCityId: 0,
        departureFromCityName: "",
        arrivalToCityId: 0,
        arrivalToCityName: "",
        startOn: null as any,
        endOn: null as any,
        validate: false,
      },
    ] as RoadObj[]);
  };

  const initEditMode = async () => {
    setEditMode(true);

    let companyId = applicationForExpenseDetail
      ? applicationForExpenseDetail.company.id
      : 0;
    await getRecalculationBusinessGoals(companyId, "ExpenseApplication");

    await getEditPermissions();
    let businessTargetId = applicationForExpenseDetail
      ? applicationForExpenseDetail.businessTarget.id
      : -1;
    let businessTargetName = applicationForExpenseDetail
      ? applicationForExpenseDetail.businessTarget.name[i18n.language as "ru"]
      : "";
    let applicationTypeId =
      applicationForExpenseDetail != null
        ? applicationForExpenseDetail.applicationType.id
        : -1;
    let applicationTypeCode =
      applicationForExpenseDetail != null
        ? applicationForExpenseDetail.applicationType.code
        : "";

    setAssigneeDetail({
      userId: applicationForExpenseDetail
        ? applicationForExpenseDetail.assigneeEmployee.id
        : 0,
      occupationId: applicationForExpenseDetail
        ? applicationForExpenseDetail.assigneeEmployee.occupationId
        : 0,
      companyId: applicationForExpenseDetail
        ? applicationForExpenseDetail.company.id
        : 0,
    });

    let hasExternalExpense = false;
    applicationForExpenseDetail &&
      applicationForExpenseDetail.expenses.forEach((expense) => {
        if (!hasExternalExpense && expense.external) {
          hasExternalExpense = true;
        }
      });

    await dispatch(
      updateExpenseType(
        applicationForExpenseDetail ? applicationForExpenseDetail.company.id : 0
      )
    );
    await dispatch(updateBusinessGoal(applicationTypeId));

    setExpenseType(applicationTypeId);
    setExpenseTypeCode(applicationTypeCode);
    setBusinessGoal({
      value: businessTargetId,
      text: businessTargetName,
    });
    setDescription(
      applicationForExpenseDetail != null
        ? applicationForExpenseDetail.description
        : ""
    );

    if (applicationTypeCode == "Travel" && applicationForExpenseDetail) {
      let mapEditRoads = ([] as RoadObj[])
        .concat({
          id: getNextKey("road"),
          departureFromCityId: applicationForExpenseDetail
            ? applicationForExpenseDetail.departureFromCity.id
            : -1,
          departureFromCityName: applicationForExpenseDetail
            ? applicationForExpenseDetail.departureFromCity.name[
                i18n.language as "ru"
              ]
            : "",
          arrivalToCityId: applicationForExpenseDetail
            ? applicationForExpenseDetail.businessTripDestinations.length > 0
              ? applicationForExpenseDetail.businessTripDestinations[0].city.id
              : applicationForExpenseDetail.arrivalToCity.id
            : -1,
          arrivalToCityName: applicationForExpenseDetail
            ? applicationForExpenseDetail.businessTripDestinations.length > 0
              ? applicationForExpenseDetail.businessTripDestinations[0].city
                  .name[i18n.language as "ru"]
              : applicationForExpenseDetail.arrivalToCity.name[
                  i18n.language as "ru"
                ]
            : "",
          startOn: applicationForExpenseDetail
            ? toDate(applicationForExpenseDetail.startOn)
            : "",
          endOn: applicationForExpenseDetail
            ? applicationForExpenseDetail.businessTripDestinations.length > 0
              ? toDate(
                  applicationForExpenseDetail.businessTripDestinations[0]
                    .leaveOn
                )
              : toDate(applicationForExpenseDetail.endOn)
            : "",
          validate: false,
        })
        .concat(
          applicationForExpenseDetail.businessTripDestinations
            .filter((road, index) => {
              return index != 0;
            })
            .map((road) => {
              return {
                id: getNextKey("road"),
                departureFromCityId: -1,
                departureFromCityName: "",
                arrivalToCityId: road.city.id,
                arrivalToCityName: road.city.name[i18n.language as "ru"],
                startOn: toDate(road.arrivalOn),
                endOn: toDate(road.leaveOn),
                validate: false,
              };
            })
        );

      setRoads(mapEditRoads);
      setMultiRoadMode(mapEditRoads.length > 1);
    }

    if (applicationForExpenseDetail) {
      setAssistance({
        text: nameFormatter(
          applicationForExpenseDetail.assigneeEmployee.name,
          NameFormats.FULL,
          i18n.language
        ),
        value: applicationForExpenseDetail.assigneeEmployee.id,
      });
      setEmployeeAutocompleteReadOnly(true);
    }
    await customPropertyRef.current.build();
  };

  const changeDescription = () => {
    setDescription(descriptionRef.current.value);
  };

  const typeRadioClickHandler = (id: number) => {
    setExpenseType(+id);
    expenseTypes.forEach((expType) => {
      if (expType.id === id) {
        setExpenseTypeCode(expType.code);
        dispatch(updateBusinessGoal(expType.id));
        setBusinessGoal({} as SelectOptionType);
        businessGoalRef.current.clear();
      }
    });
    clearValidation();
    if (
      customPropertyRef &&
      customPropertyRef.current &&
      customPropertyRef.current.build
    ) {
      customPropertyRef.current.build();
    }
  };

  const businessGoalChooseHandler = (option: SelectOptionType) => {
    setBusinessGoal(option);
  };

  const initCreatorOccupationOptions = (data: any) => {
    let newOccupationOptions = [] as SelectOptionType[];
    let occupationOptions = data.map((item: any) => {
      return {
        occupationId: item.occupationId ? item.occupationId : item.id,
        company: item.company.name[i18n.language as "ru" | "en"], //get(item.company.name., `company.name.${}`)
        code: item.code,
        name: item.name[i18n.language as "ru" | "en"],
      };
    });
    occupationOptions.forEach((item: any) => {
      newOccupationOptions.push({
        value: item.occupationId,
        text: `${item.company} - ${item.name}`,
      });
    });
    setCreatorOccupationOptions(newOccupationOptions);
  };

  const initAssistanceOptions = async (occupationId: number) => {
    dispatch(loaderLock());
    let newAssistantOptions = [] as SelectOptionType[];

    let selectedOccupation = userDetail.occupations.find(
      (item) => item.occupationId == occupationId
    );

    let selectedOccupationName = selectedOccupation
      ? selectedOccupation.name[i18n.language as "ru"]
      : "";
    let selectedOccupationCompany = selectedOccupation
      ? selectedOccupation.company.name[i18n.language as "ru"]
      : "";

    setAssigneeDetail({
      userId: userDetail.id,
      occupationId: selectedOccupation ? selectedOccupation.occupationId : 0,
      companyId: selectedOccupation ? selectedOccupation.company.id : 0,
    });

    let response = await getAssistancesById(occupationId);
    if (response.headers.success) {
      let assistanceList = response.data.value.assistances.map((item: any) => {
        return {
          name: {
            ru: get(item, "employeeName.ru", ""),
            en: get(item, "employeeName.en", ""),
          },
          employeeOccupationName: {
            ru: get(item, "employeeOccupationName.ru", ""),
            en: get(item, "employeeOccupationName.en", ""),
          },
          company: {
            id: get(item, "company.id", 0),
            name: {
              ru: get(item, "company.name.ru", ""),
              en: get(item, "company.name.en", ""),
            },
          },
          employeeOccupationId: get(item, "employeeOccupationId", ""),
          employeeId: get(item, "employeeId", 0),
        };
      });
      if (assistanceList.length > 0) {
        newAssistantOptions.push({
          value: occupationId,
          text: `${
            userDetail.name[i18n.language as "ru"]
          } - ${selectedOccupationCompany} - ${selectedOccupationName}`,
          detail: {
            name: get(selectedOccupation, "employee.name", { ru: "", en: "" }),
            employeeOccupationName: get(selectedOccupation, "name", {
              ru: "",
              en: "",
            }),
            company: {
              id: get(selectedOccupation, "company.id", 0),
              name: get(selectedOccupation, "company.name", { ru: "", en: "" }),
            },
            employeeOccupationId: get(selectedOccupation, "occupationId", 0),
            employeeId: userDetail.id,
          },
        } as any);
        assistanceList.forEach((item: any) => {
          newAssistantOptions.push({
            value: item.employeeId,
            text: `${item.name[i18n.language as "ru"]} - ${
              item.company.name[i18n.language as "ru"]
            } - ${item.employeeOccupationName[i18n.language as "ru"]}`,
            detail: item,
          } as any);
        });
      }
    } else {
      dispatch(
        showErrors({
          code: "del_substituation",
          message: "Не удалось загрузить сотрудников",
        })
      );
    }
    setAssistanceOptions(newAssistantOptions);
    if (
      newAssistantOptions.length > 0 &&
      !isAssistantManagerOccupation() &&
      !isCoordinatorOccupation()
    ) {
      setAssistance({
        value: occupationId,
        text: `${
          userDetail.name[i18n.language as "ru"]
        } - ${selectedOccupationCompany} - ${selectedOccupationName}`,
      });
    }
    dispatch(loaderUnlock());
  };

  const creatorOccupationtChooseHandler = async (option: any) => {
    setSelectOccupation(true);
    const selectedOccupation = userDetail.occupations.find(
      (item) => item.occupationId == option.value
    );

    setSelectedOccupation(selectedOccupation);
    setResponsibleOccupationOptions([]);
	setFilterCustomProperties([]);

    const companyId = selectedOccupation ? selectedOccupation.company.id : 0;
    const occupationId = selectedOccupation
      ? selectedOccupation.occupationId
      : 0;
    setAssigneeDetail({
      userId: userDetail.id,
      occupationId: occupationId,
      companyId: companyId,
    });
    await dispatch(updateExpenseType(companyId));
    customPropertyRef &&
      customPropertyRef.current &&
      customPropertyRef.current.build();

    await initClearMode();
    await initAssistanceOptions(occupationId);
  };

  const assistanceChooseHandler = async (option: any) => {
    setAssistance(option);
    setAssigneeDetail({
      userId: option.detail.employeeId,
      occupationId: option.detail.employeeOccupationId,
      companyId: option.detail.company.id,
    });
    await dispatch(updateExpenseType(option.detail.company.id));

    customPropertyRef &&
      customPropertyRef.current &&
      customPropertyRef.current.build();
    await initClearMode();
  };

  const responsibleChooseHandler = async (
    option: EmployeeAutocompleteOptionType
  ) => {
    setAssistance(option);

    if (option && option.value) {
      dispatch(loaderLock);
      let result = await getOccupationsShortByEmployeeId(
        option.value,
        assigneeDetail.companyId
      );

      if (result.headers.success) {
        let newOption = result.data.map((item: any) => {
          return {
            text: `${get(item, `company.name.${i18n.language}`, "")} - ${get(
              item,
              `name.${i18n.language}`,
              ""
            )}`,
            value: get(item, "id", 0),
            detail: {
              companyId: get(item, "company.id", 0),
            },
          };
        });

        setResponsibleOccupationOptions(newOption);
        setResponsibleOccupation(newOption[0]);
        setAssigneeDetail({
          userId: option.value,
          occupationId: newOption[0].value,
          companyId: newOption[0].detail.companyId,
        });
        customPropertyRef &&
          customPropertyRef.current &&
          customPropertyRef.current.build();
        await dispatch(updateExpenseType(newOption[0].detail.companyId));
      } else {
        dispatch(
          showErrors({
            code: "expense_applications_error",
            message: "Не удалось получить список назначений",
          })
        );
      }
      dispatch(loaderUnlock);
    }
  };

  const responsibleOccupationChooseHandler = async (option: any) => {
    setResponsibleOccupation(option);
    setAssigneeDetail({
      userId: assigneeDetail.userId,
      occupationId: option.value,
      companyId: option.detail.companyId,
    });
    customPropertyRef &&
      customPropertyRef.current &&
      customPropertyRef.current.build();
    await dispatch(updateExpenseType(option.detail.companyId));
    await initClearMode();
  };

  const changeRoads = (roads: RoadObj[]) => {
    setRoads(roads);
  };

  const changeRoadMode = () => {
    let newRoads = [] as RoadObj[];
    newRoads.push({
      id: getNextKey("road"),
      departureFromCityId: roads[0].departureFromCityId,
      departureFromCityName: roads[0].departureFromCityName,
      arrivalToCityId: roads[0].arrivalToCityId,
      arrivalToCityName: roads[0].arrivalToCityName,
      startOn: roads[0].startOn,
      endOn: roads[0].endOn,
      validate: roads[0].validate,
    });
    if (!multiRoadMode) {
      newRoads.push({
        id: getNextKey("road"),
        departureFromCityId: 0,
        departureFromCityName: "",
        arrivalToCityId: 0,
        arrivalToCityName: "",
        startOn: null as any,
        endOn: null as any,
        validate: false,
      });
    }
    setRoads(newRoads);
    setMultiRoadMode(!multiRoadMode);
  };

  const closeHandler = () => {
    props.onClose(true);
    setSelectOccupation(false);
  };

  const validate = () => {
    let isValid = true;
    if (typeof businessGoalValidatorRef.current.validate !== "function") {
      return false;
    }
    let validateList = [businessGoalValidatorRef.current.validate()];
    if (creatorOccupationOptions.length > 1 && !editMode) {
      validateList.push(
        ...validateList,
        creatorOccupationValidatorRef.current.validate()
      );
    }
    if (isCoordinatorOccupation()) {
      validateList = [
        ...validateList,
        responsibleValidatorRef.current.validate(),
      ];
    }
    if (
      assistanceOptions.length > 0 &&
      !isAssistantManagerOccupation() &&
      !isCoordinatorOccupation()
    ) {
      validateList = [
        ...validateList,
        assistanceValidatorRef.current.validate(),
      ];
    }
    if (expenseTypeCode === "Travel") {
      setRoadsValidation(true);
      roads.forEach((road, index) => {
        if (!validateRoad(road, index)) {
          isValid = false;
        }
      });
    }
    validateList.forEach((item) => {
      if (!item) {
        isValid = false;
        return;
      }
    });
    if (!customPropertyRef.current.validate()) {
      isValid = false;
    }
    return isValid;
  };

  const setRoadsValidation = (validate: boolean) => {
    let newRoads: RoadObj[] = roads.map((road) => {
      road.validate = validate;
      return road;
    });
    setRoads(newRoads);
  };

  const validateRoad = (road: RoadObj, index: number): boolean => {
    if (road.departureFromCityId === 0 && index === 0) {
      return false;
    }
    if (road.arrivalToCityId === 0) {
      return false;
    }
    if (!road.startOn) {
      return false;
    }
    if (!road.endOn) {
      return false;
    }
    if (!multiRoadMode && road.startOn.valueOf() > road.endOn.valueOf()) {
      return false;
    }
    return true;
  };

  const saveHandler = () => {
    if (!validate()) {
      return;
    }
    if (editMode) {
      edit();
    } else {
      create();
    }
    setRoadsValidation(false);

    if (
      isBusinessGoalChange &&
      applicationForExpenseDetail &&
      applicationForExpenseDetail.expenses.filter(
        (expense) => expense.expenseType.groupCode === "Perdiem"
      ).length > 0
    ) {
      setShowDailyRenew(isBusinessGoalChange);
      dailyRenew();
    }
  };

  const create = () => {
    saveCreate({
      ...dataRef.current,
      occupationId: assigneeDetail.occupationId,
    });
  };

  const saveCreate = async (expenseApplications: ExpenseApplications) => {
    dispatch(loaderLock());
    let response = await postExpenseApplications(expenseApplications);
    if (response.headers.success) {
      await customPropertyRef.current.saveById(
        response.data.id,
        response.data.company.id
      );
      analitiks("SaveFormCreateZNR", {
        znrId: response.data.id,
        type: response.data.applicationType.id,
        employeeId: userDetail.id,
        occupationId: userDetail.occupations[0].occupationId,
        companyId: userDetail.occupations[0].company.id,
      });
      props.history.push(`/ExpenseApplication/Detail/${response.data.id}`);
      props.onClose(false);
    } else {
      dispatch(
        showErrors({
          code: "expense_applications_error",
          message: `Не удалось создать заявку на расход`,
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const daysCount = (applicationTravelDates: TravelDates[]) => {
    let daysCount = 0;
    applicationTravelDates.forEach((dates: TravelDates) => {
      daysCount = daysCount + betweenTwoDates(dates.startDate, dates.endDate);
    });
    return daysCount;
  };

  const edit = async () => {
    saveEdit(dataRef.current);
  };

  useEffect(() => {
    dataRef.current.applicationTypeId = expenseType;
  }, [expenseType]);

  useEffect(() => {
    dataRef.current.businessTargetId = businessGoal && +businessGoal.value;
  }, [businessGoal]);

  useEffect(() => {
    dataRef.current.description = description;
  }, [description]);

  useEffect(() => {
    dataRef.current.assigneeEmployeeId =
      assigneeDetail && assigneeDetail.userId;
  }, [assigneeDetail]);

  useEffect(() => {
    if (expenseTypeCode !== "Travel") {
      delete dataRef.current.businessTripOption;
    }
  }, [expenseTypeCode]);

  useEffect(() => {
    props.isOpen && checkBusinessGoalVisibillity();
  }, [selectedOccupation, props.isOpen]);

  const checkBusinessGoalVisibillity = () => {
    const id =
      selectedOccupation && selectedOccupation.company
        ? selectedOccupation.company.id
        : applicationForExpenseDetail && applicationForExpenseDetail.company
          ? applicationForExpenseDetail.company.id
          : 0;
    const isVisible = Config.getConfigToCompany(
      ConfigTypes.HIDE_BUSINESS_TARGET,
      id
    );
    setHideBusinessGoal(isVisible);
  };

  useEffect(() => {
    if (expenseTypeCode === "Travel") {
      let dayNumber = (date: Date) => {
        return +moment(date).format("YYYYMMDD");
      };

      if (roads.length > 0) {
        let applicationTravelDates = applicationForExpenseDetail
          ? applicationForExpenseDetail.expenseApplicationTravelDates.filter(
              (item) => {
                if (dayNumber(roads[0].startOn) > dayNumber(item.startDate)) {
                  return false;
                }
                return (
                  dayNumber(roads[roads.length - 1].endOn) >=
                  dayNumber(item.endDate)
                );
              }
            )
          : [];

        dataRef.current.businessTripOption = {
          departureFromCityId: roads[0] ? roads[0].departureFromCityId : 0,
          startOn:
            roads[0] && roads[0].startOn
              ? returnDateWithoutUTC(roads[0].startOn)
              : null,
          arrivalToCityId: roads[roads.length - 1]
            ? roads[roads.length - 1].arrivalToCityId
            : 0,
          endOn:
            roads[roads.length - 1] && roads[roads.length - 1].endOn
              ? returnDateWithoutUTC(roads[roads.length - 1].endOn)
              : null,
          personalDaysNumber: applicationTravelDates
            ? daysCount(applicationTravelDates)
            : 0,
          applicationTravelDates: applicationTravelDates,
        };

        dataRef.current.businessTripOption.businessTripDestination = roads.map(
          (road, index) => {
            return {
              cityId: road ? road.arrivalToCityId : 0,
              arrivalOn:
                road && road.startOn
                  ? returnDateWithoutUTC(road.startOn)
                  : null,
              leaveOn:
                road && road.endOn ? returnDateWithoutUTC(road.endOn) : null,
              ordinal: index + 1,
            };
          }
        );
      }
    }
  }, [roads]);

  const saveEdit = async (expenseApplications: ExpenseApplications) => {
    dispatch(loaderLock());
    let perDiemCalculationType = Config.getConfigToCompany(
      ConfigTypes.PERDIEM_CALCULATION_TYPE,
      assigneeDetail.companyId
    );

    try {
      let needRenew = false;
      let dateDiffer = (dateA: any, dateB: any) => {
        return (
          +moment(dateA).format("YYYYMMDD") == +moment(dateB).format("YYYYMMDD")
        );
      };

      if (
        perDiemCalculationType != 0 &&
        applicationForExpenseDetail &&
        applicationForExpenseDetail.expenses.filter(
          (item) => item.expenseType.expenseGroup.code == "Perdiem"
        ).length > 0
      ) {
        if (
          expenseApplications.businessTripOption &&
          !dateDiffer(
            expenseApplications.businessTripOption.startOn,
            applicationForExpenseDetail.startOn
          )
        ) {
          needRenew = true;
        }
        if (
          expenseApplications.businessTripOption &&
          !dateDiffer(
            expenseApplications.businessTripOption.endOn,
            applicationForExpenseDetail.endOn
          )
        ) {
          needRenew = true;
        }

        if (
          expenseApplications.businessTripOption &&
          expenseApplications.businessTripOption.departureFromCityId !=
            applicationForExpenseDetail.departureFromCity.id
        ) {
          needRenew = true;
        }
        if (
          expenseApplications.businessTripOption &&
          expenseApplications.businessTripOption.arrivalToCityId !=
            applicationForExpenseDetail.arrivalToCity.id
        ) {
          needRenew = true;
        }

        let oldTrip = applicationForExpenseDetail
          ? applicationForExpenseDetail.businessTripDestinations
          : [];
        let newTrip = expenseApplications.businessTripOption
          ? expenseApplications.businessTripOption.businessTripDestination
            ? expenseApplications.businessTripOption.businessTripDestination
            : []
          : [];
        if (oldTrip.length == newTrip.length) {
          oldTrip.sort((a, b) => a.ordinal - b.ordinal);
          newTrip.sort((a, b) => a.ordinal - b.ordinal);

          for (let index = 0; index < oldTrip.length; index++) {
            const oldElement = oldTrip[index];
            const newElement = newTrip[index];
            if (oldElement.city.id != newElement.cityId) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.leaveOn, newElement.leaveOn)) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.arrivalOn, newElement.arrivalOn)) {
              needRenew = true;
            }
          }
        } else {
          needRenew = true;
        }
      }

      let response = await updateExpensesById(
        props.editId,
        expenseApplications
      );
      if (!response.headers.success) {
        dispatch(
          showErrors({
            code: "expense_applications_error",
            message: response.data.Errors
              ? String(response.data.Errors)
              : String(response.data),
          })
        );
        // dispatch(showErrors({ code: 'expense_applications_error', message: String(response.data) }));
      } else {
        await customPropertyRef.current.edit();
        props.editId &&
          dispatch(updateApplicationForExpenseDetail(props.editId));
        if (needRenew) {
          dailyRenew();
        }
        analitiks("EditingZNR", {
          id: applicationForExpenseDetail
            ? applicationForExpenseDetail.id
            : null,
          employeeId: userDetail.id,
          occupationId: userDetail.occupations[0].occupationId,
          companyId: userDetail.occupations[0].company.id,
        });
      }
      props.onClose(false);
    } catch (e) {
      dispatch(
        showErrors({
          code: "expense_applications_error",
          message: `Create expense applications failure. ${e}`,
        })
      );
    }

    dispatch(loaderUnlock());
  };

  const clearValidation = () => {
    businessGoalValidatorRef.current.clear();
    if (isAssistantManagerOccupation() || isCoordinatorOccupation()) {
      //responsibleValidatorRef.current.clear();
    }
    if (
      assistanceOptions.length > 0 &&
      !isAssistantManagerOccupation() &&
      !isCoordinatorOccupation()
    ) {
      //assistanceValidatorRef.current.clear();
    }
  };

  const dailyRenew = async () => {
    dispatch(loaderLock());
    let a = applicationForExpenseDetail
      ? applicationForExpenseDetail.expenses
          .filter((item: any) => {
            return item.expenseType.expenseGroup.code == "Perdiem";
          })
          .map((item: any) => item.id)
      : [];
    for (let index = 0; index < a.length; index++) {
      const element = a[index];
      await deleteExpensesById(element);
    }

    let data = {
      applicationRef: {
        id: applicationForExpenseDetail ? applicationForExpenseDetail.id : 0,
        logicalName: "ExpenseApplication",
        name: {
          ru: "ExpenseApplication",
          en: "ExpenseApplication",
        },
      },
      calculations: [],
    };
    updatePerDiemCalculations(data);
    props.editId && dispatch(updateApplicationForExpenseDetail(props.editId));
    dispatch(loaderUnlock());
    openDailyRenew();
  };

  const openDailyRenew = () => {
    setShowDailyRenew(true);
  };

  const closeDailyRenew = () => {
    setShowDailyRenew(false);
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  const rights = {
    businessTrip:
      createPermissions.includes(
        EXPENSE_APPLICATION_PROPETIES.BUSINESS_TARGET
      ) ||
      editPermissions.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TARGET),
    travel:
      createPermissions.includes(
        EXPENSE_APPLICATION_PROPETIES.BUSINESS_TRIP_OPTION
      ) ||
      editPermissions.includes(
        EXPENSE_APPLICATION_PROPETIES.BUSINESS_TRIP_OPTION
      ),
  };

  return (
    <Fragment>
      <ModalContainer
        onEnter={saveHandler}
        isOpen={props.isOpen}
        destroy={true}
        highestModal={isHighestModal}
        overlayClick={closeHandler}
      >
        <div className="box-modal" id="expense-modal">
          <div className="box-modal_close" onClick={closeHandler}></div>
          <div className="box-modal-title">
            {t(
              editMode
                ? "create_expense.title_edit"
                : "create_expense.title_create"
            )}
          </div>
          <div className="box-modal-content">
            <div className="box-modal-form">
              {creatorOccupationOptions.length > 1 && !editMode ? (
                <Validator
                  type={"select"}
                  ref={creatorOccupationValidatorRef}
                  errorMessage={t("create_expense.select_occupation")}
                >
                  <React.Fragment>
                    <label className="input-label">
                      {t("create_expense.select_occupation")}
                      <i className="input-required">*</i>
                    </label>
                    <Select
                      ref={creatorOccupationRef}
                      options={creatorOccupationOptions}
                      onChoose={creatorOccupationtChooseHandler}
                      defaultText={t("create_expense.select_occupation_list")}
                      disabled={!!props.editId}
                      style={{ marginRight: "-12px" }}
                    />
                  </React.Fragment>
                </Validator>
              ) : null}
              <br />
              {(editMode ||
                isSelectOccupation ||
                creatorOccupationOptions.length < 1) && (
                <>
                  <div
                    className={`box-modal-form-block ${generateClassName(
                      EXPENSE_APPLICATION_PROPETIES.APPLICATION_TYPE
                    )}`}
                  >
                    <div className="title">
                      {t("create_expense.application_type")}
                    </div>
                    <div className="input-item">
                      <div className="box-modal-checkbox-wrap">
                        {expenseTypes.map((expType, index) => {
                          return (
                            <RadioButton
                              key={expType.id}
                              name="expenseTypeRadio"
                              id={expType.id}
                              label={expType.name[i18n.language as "ru" | "en"]}
                              checked={expType.id === expenseType}
                              onClick={typeRadioClickHandler}
                              disabled={editMode}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="box-modal-form-block">
                    <div className="title">
                      {t("create_expense.application_details")}
                    </div>
                    {expenseTypeCode === "Travel" &&
                      (rights.travel ? (
                        <div className="box-modal-form-block">
                          <Road
                            defaultOptions={roads}
                            onChange={changeRoads}
                            onChangeRoadMode={changeRoadMode}
                            multiRoadMode={multiRoadMode}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                          />
                        </div>
                      ) : (
                        <div className="box-modal-form-block">
                          <Road
                            defaultOptions={roads}
                            isReadOnly={true}
                            onChange={changeRoads}
                            onChangeRoadMode={changeRoadMode}
                            multiRoadMode={multiRoadMode}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                          />
                        </div>
                      ))}
                    {assistanceOptions.length > 0 &&
                    !isAssistantManagerOccupation() &&
                    !isCoordinatorOccupation() ? (
                      <Validator type={"select"} ref={assistanceValidatorRef}>
                        <React.Fragment>
                          <label className="input-label">
                            {t("create_expense.assignee_employee")}
                            <i className="input-required">*</i>
                          </label>
                          <Select
                            ref={assistanceRef}
                            defaultValue={assistance}
                            options={assistanceOptions}
                            onChoose={assistanceChooseHandler}
                            defaultText={t(
                              "create_expense.placeholder.select_employee"
                            )}
                            disabled={!!props.editId}
                            style={{ marginRight: "-12px" }}
                          />
                        </React.Fragment>
                      </Validator>
                    ) : null}
                    {isCoordinatorOccupation() ? (
                      <Validator
                        type={"text"}
                        ref={responsibleValidatorRef}
                        isRoad={isIE}
                      >
                        <React.Fragment>
                          <label className="input-label">
                            {t("create_expense.assignee_employee")}
                            <i className="input-required">*</i>
                          </label>
                          <div
                            className={`input-item  ${
                              bookerAndOwner ? "disabled" : ""
                            }`}
                            style={{ marginBottom: "0px" }}
                          >
                            <div
                              className="input-search"
                              style={{ marginRight: "-12px" }}
                            >
                              <EmployeeAutocomplete
                                placeholder={t(
                                  "create_expense.placeholder.enter_employee"
                                )}
                                onChoose={responsibleChooseHandler}
                                defaultText={""}
                                readOnly={employeeAutocompleteReadOnly}
                                tabIndex={isTabIndexDisabled ? -1 : 0}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      </Validator>
                    ) : null}
                    {responsibleOccupationOptions.length > 0 ? (
                      <Validator type={"select"} ref={responsibleOccupationRef}>
                        <React.Fragment>
                          <label className="input-label">
                            {t("create_expense.assignee_employee_occupation")}
                            <i className="input-required">*</i>
                          </label>
                          <Select
                            //ref={assistantRef}
                            defaultValue={responsibleOccupation}
                            options={responsibleOccupationOptions}
                            onChoose={responsibleOccupationChooseHandler}
                            defaultText={t(
                              "create_expense.placeholder.select_employee"
                            )}
                            disabled={!!props.editId}
                            style={{ marginRight: "-12px" }}
                          />
                        </React.Fragment>
                      </Validator>
                    ) : null}

                    {businessGoal && (
                      <Validator
                        type={"select"}
                        ref={businessGoalValidatorRef}
                        errorMessage={t("create_expense.select_business_goal")}
                        style={{ display: hideBusinessGoal ? "none" : "block" }}
                      >
                        <div>
                          <label className="input-label">
                            {t("create_expense.business_goal")}
                            <i className="input-required">*</i>
                          </label>
                          <Select
                            disabled={!rights.businessTrip}
                            ref={businessGoalRef}
                            defaultValue={
                              editMode || businessGoalOnlyOne
                                ? businessGoal
                                : null
                            }
                            options={businessGoalOptions.map(
                              (businessGoalOption) => {
                                return {
                                  value: businessGoalOption.id,
                                  text: businessGoalOption.name[
                                    i18n.language as "ru"
                                  ],
                                };
                              }
                            )}
                            onChoose={businessGoalChooseHandler}
                            defaultText={t(
                              "modals.create_advance_report_modal.enter_business_goal"
                            )}
                            style={{ marginRight: "-12px" }}
                          />
                        </div>
                      </Validator>
                    )}
                    <div className="box-modal-form-block">
                      <div className="input-item-row">
                        <div className="input-item">
                          <label className="input-label">
                            {t("create_expense.description")}
                          </label>
                          <input
                            className={`input ${generateClassName(
                              EXPENSE_APPLICATION_PROPETIES.DESCRIPTION
                            )}`}
                            type="text"
                            ref={descriptionRef}
                            value={description}
                            maxLength={400}
                            onChange={changeDescription}
                            placeholder={t(
                              "create_expense.placeholder.description"
                            )}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                          />
                        </div>
                      </div>
                    </div>
                    <CustomProperty
                      availableProperties={filterCustomProperties}
                      dataExpense={dataRef.current}
                      type={"ExpenseApplication"}
                      id={props.editId}
                      isEdit={!!props.editId}
                      isZNR={true}
                      ref={customPropertyRef}
                      permissions={
                        !!props.editId ? editPermissions : createPermissions
                      }
                      occupationsId={assigneeDetail.occupationId}
                      companyId={
                        props.editId
                          ? applicationForExpenseDetail &&
                            applicationForExpenseDetail.company
                            ? applicationForExpenseDetail.company.id
                            : undefined
                          : assigneeDetail.companyId
                      }
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="box-modal-footer">
            <Button
              onClick={closeHandler}
              buttonWidth="153px"
              buttonText={t("modals.create_advance_report_modal.cancel")}
            />
            <Button
              onClick={saveHandler}
              buttonWidth="153px"
              buttonColor="green"
              buttonText={
                props.hasNext
                  ? t("request_detail.request_button_controls.next")
                  : t("modals.create_advance_report_modal.complete")
              }
            />
          </div>
        </div>
      </ModalContainer>
      <CreateOrderModal
        id={props.editId ? props.editId : 0}
        isOpen={isShowDailyRenew}
        onClose={closeDailyRenew}
        dailyRenew={true}
      />
    </Fragment>
  );
};

export default withRouter(CreateExpenseModal);
