import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//component
import DateInput from "app/component/date-input/DateInput";
import Dropdown from "app/component/dropdown/Dropdown";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { updateCalculations } from "store/common/actions";
import EmployeeAutocomplete, {
  EmployeeAutocompleteOptionType,
} from "app/component/autocomplete/EmployeeAutocomplete";
import If from "app/component/util/If";
import { isArray } from "lodash";
import { getCommonUserDetail } from "../../../store/selectors";
import { getClearingCanread } from "../../../services/ApiService";
import { PROFILE_FIELD_PERMISSIONS } from "../../../infrastructure/enum/field-permissions.enum";

interface CalculationsFilterProps {
  getEmployee: Function;
}

const CalculationsFilter: React.FC<CalculationsFilterProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userDetail = useSelector(getCommonUserDetail);

  let [dateStart, setDateStart] = useState(new Date() as any);
  let [dateEnd, setDateEnd] = useState(new Date() as any);
  let [employee, setEmployee] = useState({
    value: 0,
    text: "",
  } as EmployeeAutocompleteOptionType);

  let [employeeAccess, setEmployeeAccess] = useState(false);
  const profileFieldPermissions = useSelector(
    (state: ApplicationState) => state.common.profileFieldPermissions,
  );

  let dateStartRef = useRef({} as any);
  let dateEndRef = useRef({} as any);
  let employeeAutocompleteRef = useRef({} as any);

  useEffect(() => {
    dispatch(updateCalculations(dateStart, dateEnd, employee));
    getAccessRule();
  }, []);

  const dateStartSelectHandler = (value: any) => {
    setDateStart(value);
  };

  const dateEndSelectHandler = (value: any) => {
    setDateEnd(value);
  };

  const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
    setEmployee(option);
  };

  const filterClickHandler = () => {
    dispatch(updateCalculations(dateStart, dateEnd, employee));
    props.getEmployee(employee);
  };

  const clearAllClickHandler = () => {
    setDateStart(new Date());
    dateStartRef.current.clear();
    setDateEnd(new Date());
    dateEndRef.current.clear();
    setEmployee({ value: 0, text: "" });
    employeeAutocompleteRef.current.clear();
    dispatch(
      updateCalculations(new Date(), new Date(), { value: 0, text: "" }),
    );
    props.getEmployee({ value: 0, text: "" });
  };

  const getAccessRule = async () => {
    setEmployeeAccess(
      profileFieldPermissions.includes(
        PROFILE_FIELD_PERMISSIONS.FILTER_EMPLOYEE,
      ),
    );
  };

  return (
    <div className="profile-calculation-filter">
      <div className="profile-calculation-filter-title">
        <a className="link-filter js-link-filter-profile">
          <SvgIcon className="icon icon-filter" href="#svg_icon_filter" />
          <span>фильтры</span>
        </a>
        <span>{t("calculations.filters")}</span>
      </div>
      <div className="filters-wrap create-app-filter">
        <Dropdown label={t("calculations.by_date")}>
          <div className="input-item">
            <div className="input-picker-title">
              {t("calculations.start_date")}
            </div>
            <div className="input-picker-wrap">
              <DateInput
                ref={dateStartRef}
                placeholder={t("calculations.placeholders.select_date")}
                onSelect={dateStartSelectHandler}
                format={"D MMMM"}
              />
            </div>
          </div>
          <div className="input-item">
            <div className="input-picker-title">
              {t("calculations.end_date")}
            </div>
            <div className="input-picker-wrap">
              <DateInput
                ref={dateEndRef}
                placeholder={t("calculations.placeholders.select_date")}
                onSelect={dateEndSelectHandler}
                format={"D MMMM"}
              />
            </div>
          </div>
        </Dropdown>
        {/* <If condition={[ROLE_TYPE.BOOKER.toString(), ROLE_TYPE.MAIN_BOOKER.toString(), ROLE_TYPE.CFO.toString()].includes(userDetail.roleCode)}> */}
        <If condition={employeeAccess}>
          <Dropdown label={t("filters.by_employee")} isDefaultOpen={false}>
            <div className="input-item">
              <div className="input-search">
                <EmployeeAutocomplete
                  ref={employeeAutocompleteRef}
                  onChoose={employeeChooseHandler}
                  placeholder={t("filters.enter_name")}
                />
                <SvgIcon
                  className={"icon icon-search"}
                  href={"#svg_icon-search"}
                />
              </div>
            </div>
          </Dropdown>
        </If>
        <Dropdown>
          <div className="input-item pointer">
            <a
              className="btn btn_gray btn_gray-reverse width-100 pointer"
              onClick={filterClickHandler}
            >
              {t("calculations.apply")}
            </a>
          </div>
          <div className="input-item pointer">
            <a
              className="btn btn_gray width-100 pointer"
              onClick={clearAllClickHandler}
            >
              {t("calculations.reset_all")}
            </a>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default CalculationsFilter;
