import "./Grid.css";
import { GridOptions, HeaderOptions } from "app/component/grid/Grid";
import { get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

interface GridBodyProps {
  data: any[];
  options: GridOptions;
  styles?: string;
  isTransaction?: boolean;
  substitution?: boolean;
}

const GridBody: React.FC<GridBodyProps> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      {props.data.map((item: any, index: number) => {
        return (
          <div className={`profile-calc-table-row`} key={index}>
            {props.options.headers.map(
              (header: HeaderOptions, cellIndex: number) => {
                return !props.substitution ? (
                  <div
                    key={header.key}
                    children={get(item, header.key)}
                    className={`profile-calc-table-td ${props.options.isLastRowNarrow && cellIndex === props.options.headers.length - 1 ? "last" : ""} ${item.doesCostCenterDiffer ? "grayRow" : ""}`}
                  />
                ) : (
                  <div
                    key={header.key}
                    style={{ display: "grid" }}
                    className={`profile-calc-table-td ${props.options.isLastRowNarrow && cellIndex === props.options.headers.length - 1 ? "last" : ""} ${item.doesCostCenterDiffer ? "grayRow" : ""}`}
                  >
                    <div children={get(item, header.key)} />
                    {header.key === "first" || header.key === "second" ? (
                      <div
                        style={{ color: "#879196", display: "inline-grid" }}
                        children={item.сompany}
                      />
                    ) : null}
                  </div>
                );
              },
            )}
            {props.isTransaction ? (
              <div className={`postings_alert_message`}>
                {t("postings.postings_alert")}
              </div>
            ) : null}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default GridBody;
