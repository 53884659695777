/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { showErrors } from "store/exception/actions";
import { ApplicationState } from "store/reducers";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import ExpenseAttachments from "app/component/expense-attachments/ExpenseAttachments";
import { IOccupations } from "infrastructure/interfaces/index";
import {
  expenseStylesMap,
  expenseHrefMap,
  EXPENSE_TYPE,
} from "infrastructure/enum/expense-type.enum";
import {
  statusNameMap,
  STATUS_TYPE,
} from "infrastructure/enum/status-type.enum";
import {
  statusExpenseStylesMap,
  statusExpenseNameMap,
} from "infrastructure/enum/status-expense.enum";
import { UserDetail } from "infrastructure/interfaces";
import { OCCUPATION_ROLE_TYPE } from "infrastructure/enum/user-role.enum";
import { toLocaleNumberFormatter, getCurrencieCode } from "app/utils";
import If from "app/component/util/If";
import Else from "app/component/util/Else";
import TextCutter from "app/component/text-cutter/TextCutter";
import Config, { ConfigTypes } from "services/ConfigService";
import {
  getApplicationForExpenseDetail,
  getCommonAttachmentPermission,
  getCommonUserDetail,
} from "../../../../store/selectors";
import { EXPENSE_APPLICATION_PROPETIES } from "../../../../infrastructure/enum/object-properties.enum";
import { EXPENSE_PROPETIES } from "../../../../infrastructure/enum/object-properties.enum";
import { Card } from "react-bootstrap";
import FullCalculation from "app/component/full-calculation/FullCalculation";

interface ApplicationExpensesItemProps {
  id: number;
  expense: any;
  setExpenseId: Function;
  clickHandler: () => void;
  openDeleteExpenseModal: (isOpen: boolean) => void;
  openEditExpenseModal: (isOpen: boolean) => void;
  isShowAll: boolean;
  isCanShowAll?: boolean;
  index?: number;
  original?: boolean;
  isWidget?: boolean;
}

const ApplicationExpensesItem: React.FC<ApplicationExpensesItemProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let [width, setWidth] = useState(0);

  const { isExpenseApplicationAttachmentPermission } = useSelector(
    getCommonAttachmentPermission
  );
  const applicationForExpenseDetail = useSelector(
    getApplicationForExpenseDetail
  );
  const attachments = useSelector((state: ApplicationState) =>
    state.common.attachmentsExpense.find((attach) => attach.id === props.id)
  );
  const user: UserDetail = useSelector(getCommonUserDetail);
  const expenseAmountFullCalculation = Config.getConfigToCompany(
    ConfigTypes.EXPENSE_APPLICATION_AMOUNT_FULL_CALCULATION,
    applicationForExpenseDetail.company.id
  );

  const isAvailableToUpdateExpenseApplicationByCoordinator =
    Config.getConfigToCompany(
      ConfigTypes.UPDATE_EXPENSE_APPLICATION_ON_APPROVAL_BY_COORDINATOR,
      applicationForExpenseDetail.company.id
    );

  const isAvailableToUpdateExpenseByAssigneeAsCurrentApprover =
    Config.getConfigToCompany(
      ConfigTypes.UPDATE_EXPENSE_APPLICATION_BY_ASSIGNEE_AS_CURRENT_APPROVER,
      applicationForExpenseDetail.company.id
    );

  const isAvailableToUpdateExpenseByCurrentApprover = Config.getConfigToCompany(
    ConfigTypes.UPDATE_EXPENSE_APPLICATION_BY_CURRENT_APPROVER,
    applicationForExpenseDetail.company.id
  );

  const attachmentsList = attachments ? attachments.attachments : [];
  let nameRef = useRef(null as any);

  const fieldPermissions = useMemo(() => {
    return {
      type:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.TYPE}`),
      description:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.DESCRIPTION}`),
      amountRub:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.AMOUNT_RUB}`),
      isExternal:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.IS_EXTERNAL}`),
      status:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.STATUS}`),
    };
  }, [applicationForExpenseDetail]);

  useEffect(() => {
    resizeHandler();
  }, []);

  let [isOpenPopup, setOpenPopup] = useState(false);

  const onDelete = () => {
    togglePopup();
    openDeleteExpenseModalHandler();
  };

  const onEdit = () => {
    togglePopup();
    openEditExpenseModalHandler();
  };

  const togglePopup = () => {
    setOpenPopup(!isOpenPopup);
  };

  const resizeHandler = () => {
    setWidth(nameRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  });

  const openDeleteExpenseModalHandler = () => {
    const isAvailableToDeleteByCoordinator =
      applicationForExpenseDetail.status === STATUS_TYPE.ON_APPROVAL &&
      isAvailableToUpdateExpenseApplicationByCoordinator;

    const isAvailableToDeleteByCurrentApproval =
      applicationForExpenseDetail.status === STATUS_TYPE.ON_APPROVAL &&
      isAvailableToUpdateExpenseByAssigneeAsCurrentApprover &&
      isAvailableToUpdateExpenseByCurrentApprover;

    if (applicationForExpenseDetail) {
      if (
        isAvailableToDeleteByCoordinator ||
        isAvailableToDeleteByCurrentApproval
      ) {
        props.setExpenseId(props.id);
        props.openDeleteExpenseModal(true);
      }

      if (
        !isAvailableToDeleteByCoordinator &&
        !isAvailableToDeleteByCurrentApproval &&
        (applicationForExpenseDetail.status === STATUS_TYPE.APPROVED ||
          applicationForExpenseDetail.status === STATUS_TYPE.ON_APPROVAL)
      ) {
        dispatch(
          showErrors({
            code: "",
            message: t("status_error_message.status_expense_delete_EA", {
              status: t(
                `status.${
                  statusNameMap[
                    applicationForExpenseDetail.status as STATUS_TYPE
                  ]
                }`
              ),
            }),
          })
        );
      } else {
        if (
          !isAvailableToDeleteByCoordinator &&
          !isAvailableToDeleteByCurrentApproval &&
          (applicationForExpenseDetail.status === STATUS_TYPE.POSTED ||
            applicationForExpenseDetail.status ===
              STATUS_TYPE.REPORT_PREPARED ||
            applicationForExpenseDetail.status === STATUS_TYPE.EXECUTED)
        ) {
          dispatch(
            showErrors({
              code: "",
              message: t("status_error_message.status__expense_delete_EA", {
                status: t(
                  `status.${
                    statusNameMap[
                      applicationForExpenseDetail.status as STATUS_TYPE
                    ]
                  }`
                ),
              }),
            })
          );
        } else {
          props.setExpenseId(props.id);
          props.openDeleteExpenseModal(true);
        }
      }
    }
  };

  const openEditExpenseModalHandler = () => {
    if (applicationForExpenseDetail) {
      props.setExpenseId(props.id);
      props.openEditExpenseModal(true);
    }
  };

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  const renderExpenseItem = () => {
    return (
      <>
        <div className="request-expenses-block pic">
          <SvgIcon
            className={
              expenseStylesMap[
                props.expense.expenseType.groupCode as EXPENSE_TYPE
              ]
            }
            href={
              expenseHrefMap[
                props.expense.expenseType.groupCode as EXPENSE_TYPE
              ]
            }
          />
        </div>
        <div className="request-expenses-block info">
          {fieldPermissions.type && (
            <div className="request-expenses-item-text">
              {props.expense.expenseType.name[i18n.language as "en" | "ru"]}
            </div>
          )}
          <If condition={props.isCanShowAll && props.index === 1}>
            <div className={"expense-exchanged"} onClick={props.clickHandler}>
              <div
                className={"transition " + (props.isShowAll ? "rotate" : "")}
              >
                <SvgIcon
                  className="icon icon-table-arrow"
                  href="#svg_icon_table-arrow"
                />
              </div>
            </div>
          </If>
          <div
            className="request-item-name"
            style={{
              display: fieldPermissions.description ? undefined : "none",
            }}
            ref={nameRef}
          >
            <SvgIcon
              className={
                expenseStylesMap[
                  props.expense.expenseType.groupCode as EXPENSE_TYPE
                ]
              }
              href={
                expenseHrefMap[
                  props.expense.expenseType.groupCode as EXPENSE_TYPE
                ]
              }
            />
            <TextCutter
              text={props.expense.description}
              parentWidth={width}
              options={[
                {
                  maxLength: 50,
                  minWidth: 401,
                  maxWidth: width > 700 ? width + 1 : 700,
                  symbolSize: 12,
                },
                { maxLength: 25, minWidth: 0, maxWidth: 400, symbolSize: 14 },
              ]}
            />
          </div>
          {isExpenseApplicationAttachmentPermission ? (
            <div className="request-expenses-item-file">
              {attachmentsList && attachmentsList.length !== 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    flexWrap: "wrap",
                  }}
                >
                  <SvgIcon
                    href={"#svg_icon_attach"}
                    className={"icon icon-attach"}
                  />
                  {attachmentsList.map((attachFile: any, index: number) => (
                    <ExpenseAttachments
                      width={width}
                      attachFile={attachFile}
                      isLast={index + 1 === attachmentsList.length}
                    />
                  ))}
                </div>
              ) : null}
              {props.expense.showNotAttachmentAlert ? (
                <div style={{ color: "#FF3B30" }}>
                  {t("request_detail.application_expenses_item.no_attachment")}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <If condition={!props.isCanShowAll || props.original}>
          <div className="flex-sm-row flex-sm-between">
            <div className="flex flex-column align-items-end">
              <If
                condition={
                  props.expense.paymentForm && props.expense.paymentForm.name
                }
              >
                <div className="conversion-rate text-gray w-100 mb-1">
                  {t("modals.create_order_modal.payment_form") +
                    ": " +
                    props.expense.paymentForm.name[i18n.language]}
                </div>
              </If>
              {fieldPermissions.amountRub && (
                <div className="request-list-item-cost mb-1">
                  <div
                    className="request-expenses-block"
                    style={{ display: "flex" }}
                  >
                    {fieldPermissions.status && (
                      <div
                        className={`request-list-item-status ${statusExpenseStylesMap[props.expense.status]}`}
                      >
                        {props.index !== 1 ? (
                          t(`status.exchanged`)
                        ) : (
                          <>
                            {t(
                              "request_detail.application_expenses_item.status"
                            )}
                            :{" "}
                            {t(
                              `status.${statusExpenseNameMap[props.expense.status]}`
                            )}
                            <span></span>
                          </>
                        )}
                      </div>
                    )}
                    {toLocaleNumberFormatter(props.expense.amountRub)}{" "}
                    {getCurrencieCode("rub")}
                  </div>
                </div>
              )}
              {expenseAmountFullCalculation ? (
                <FullCalculation expense={props.expense} />
              ) : null}
              <div className="request-expenses-cost-status">
                {props.expense.advanceCashRequired
                  ? `(${t("request_detail.application_expenses_item.advance")})`
                  : ""}
              </div>
            </div>
            <div className="request-expenses-block controls">
              <If
                condition={
                  props.expense.properties.includes(
                    EXPENSE_APPLICATION_PROPETIES.EXPENSE_UPDATE
                  ) ||
                  props.expense.properties.includes(
                    EXPENSE_APPLICATION_PROPETIES.EXPENSE_DELETE
                  )
                }
              >
                <If condition={isBookerOccupation()}>
                  <SvgIcon
                    className="icon icon-more pointer"
                    href="#svg_icon_more"
                    onClick={togglePopup}
                  />
                </If>
                <Else condition={isBookerOccupation()}>
                  <SvgIcon
                    className="icon icon-more pointer"
                    href="#svg_icon_more"
                    onClick={togglePopup}
                  />
                </Else>
              </If>
              {isOpenPopup ? (
                <div className="miss-click-handler" onClick={togglePopup} />
              ) : (
                ""
              )}
              <div
                className="request-files-item-dropdown"
                style={{ display: isOpenPopup ? "block" : "none", top: 40 }}
              >
                <a
                  className={`pointer${
                    props.expense.properties.includes(
                      EXPENSE_APPLICATION_PROPETIES.EXPENSE_UPDATE
                    )
                      ? ""
                      : " d-none"
                  }`}
                  onClick={onEdit}
                  style={{ display: "flex", marginBottom: "25px" }}
                >
                  <SvgIcon className="icon icon-edit" href="#svg_icon_edit" />
                  {t("report_detail.edit")}
                </a>
                <a
                  className={`pointer${
                    props.expense.properties.includes(
                      EXPENSE_APPLICATION_PROPETIES.EXPENSE_DELETE
                    )
                      ? ""
                      : " d-none"
                  }`}
                  onClick={onDelete}
                  style={{ display: "flex" }}
                >
                  <SvgIcon
                    className="icon icon-remove"
                    href="#svg_icon_remove"
                  />
                  {t("report_detail.delete")}
                </a>
              </div>
              <If condition={isBookerOccupation()}>
                {
                  <div className="controls-dropdown pointer">
                    <a
                      className={`${
                        props.expense.properties.includes(
                          EXPENSE_APPLICATION_PROPETIES.EXPENSE_UPDATE
                        )
                          ? ""
                          : "d-none"
                      }`}
                      onClick={openEditExpenseModalHandler}
                    >
                      <SvgIcon
                        className="icon icon-edit pointer"
                        href="#svg_icon_edit"
                      />
                    </a>
                    <a
                      className={`delete pointer${
                        props.expense.properties.includes(
                          EXPENSE_APPLICATION_PROPETIES.EXPENSE_DELETE
                        )
                          ? ""
                          : " d-none"
                      }`}
                      onClick={openDeleteExpenseModalHandler}
                    >
                      <SvgIcon
                        className="icon icon-remove pointer"
                        href="#svg_icon_remove"
                      />
                    </a>
                  </div>
                }
              </If>
              <Else condition={isBookerOccupation()}>
                {
                  <div className="controls-dropdown pointer">
                    <a
                      className={`${
                        props.expense.properties.includes(
                          EXPENSE_APPLICATION_PROPETIES.EXPENSE_UPDATE
                        )
                          ? ""
                          : "d-none"
                      }`}
                      onClick={openEditExpenseModalHandler}
                    >
                      <SvgIcon
                        className="icon icon-edit pointer"
                        href="#svg_icon_edit"
                      />
                    </a>
                    <a
                      className={`delete pointer${
                        props.expense.properties.includes(
                          EXPENSE_APPLICATION_PROPETIES.EXPENSE_DELETE
                        )
                          ? ""
                          : " d-none"
                      }`}
                      onClick={openDeleteExpenseModalHandler}
                    >
                      <SvgIcon
                        className="icon icon-remove pointer"
                        href="#svg_icon_remove"
                      />
                    </a>
                  </div>
                }
              </Else>
            </div>
          </div>
        </If>
      </>
    );
  };

  return applicationForExpenseDetail ? (
    props.isWidget ? (
      <Card style={{ border: "none", backgroundColor: "unset" }}>
        <Card.Body
          className={`request-expenses-item ${
            props.expense.external ? "gray-line" : ""
          } ${props.isCanShowAll && props.index === 1 ? "m-b-0" : ""} ${
            props.index !== 1 ? "gray-exchanged" : ""
          }`}
        >
          {renderExpenseItem()}
        </Card.Body>
      </Card>
    ) : (
      <div
        className={`request-expenses-item ${
          props.expense.external ? "gray-line" : ""
        } ${props.isCanShowAll && props.index === 1 ? "m-b-0" : ""} ${
          props.index !== 1 ? "gray-exchanged" : ""
        }`}
      >
        {renderExpenseItem()}
      </div>
    )
  ) : null;
};

export default ApplicationExpensesItem;
