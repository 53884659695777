import { BaseMapper } from "./base.mapper";
import { ChatMessage } from "infrastructure/interfaces";
import { get } from "lodash";

export class ChatMessageMapper extends BaseMapper<ChatMessage, any> {
  responseToEntity(response: any): ChatMessage {
    return {
      id: get(response, "id", 0),
      read: !!get(response, "read", false),
      author: {
        id: get(response, "sent.actor.id", 0),
        fullName: {
          ru: get(response, "sent.actor.fullName.ru", "fullName.ru[STUB]"),
          en: get(response, "sent.actor.fullName.en", "fullName.en[STUB]"),
        },
        login: get(response, "sent.actor.login", "login[STUB]"),
      },
      time: get(response, "sent.timestamp", "time[STUB]"),
      message: get(response, "content", "content[STUB]"),
    };
  }
}
