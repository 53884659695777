import ProfileUser from "app/component/profile-user/ProfileUser";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store/reducers";
import { MENU_FIELD_PERMISSIONS } from "../../../infrastructure/enum/field-permissions.enum";
import {
  getCommonCompaniesCatalog,
  getCommonUserDetail,
} from "store/selectors";
import Config, { ConfigTypes } from "services/ConfigService";

interface MenuHeaderProps {
  accessDenied: boolean;
}

const MenuHeader: React.FC<MenuHeaderProps> = (props) => {
  const { t } = useTranslation();

  const userDetail = useSelector(getCommonUserDetail);
  const companyDetails = useSelector(getCommonCompaniesCatalog);

  const [isPowerOfAttorneyEnabled, setIsPowerOfAttorneyEnabled] =
    useState(false);

  function isPowerOfAttorneyEnabledFunction() {
    for (let i = 0; i < userDetail.occupations.length; i++) {
      if (
        Config.getConfigToCompany(
          ConfigTypes.POWER_OF_ATTORNEY_ENABLED,
          userDetail.occupations[i].company.id
        ) &&
        companyDetails.find(
          (el) => el.manager && el.manager.employee.id === userDetail.id
        )
      ) {
        setIsPowerOfAttorneyEnabled(true);
      }
    }
  }

  useEffect(() => {
    isPowerOfAttorneyEnabledFunction();
  }, []);

  const [isMenuOpen, setMenuOpen] = useState(false);
  let menuFieldPermissions = useSelector(
    (state: ApplicationState) => state.common.menuFieldPermissions
  );

  const fieldPermissions = useMemo(() => {
    return {
      expenseApplication:
        menuFieldPermissions &&
        menuFieldPermissions.includes(
          MENU_FIELD_PERMISSIONS.EXPENSE_APPLICATION
        ),
      advanceReportApplication:
        menuFieldPermissions &&
        menuFieldPermissions.includes(
          MENU_FIELD_PERMISSIONS.ADVANCE_REPORT_APPLICATION
        ),
      agreement:
        menuFieldPermissions &&
        menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.AGREEMENT),
      journal:
        menuFieldPermissions &&
        menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.JOURNAL),
      registry:
        menuFieldPermissions &&
        menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.REPORTS),
      substitution:
        menuFieldPermissions &&
        menuFieldPermissions.includes(
          MENU_FIELD_PERMISSIONS.SUBSTITUTION_MANAGER
        ),
    };
  }, [menuFieldPermissions]);

  const toggleHandler = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <React.Fragment>
      {!props.accessDenied ? (
        <div onClick={toggleHandler} className="hamburger">
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
      ) : null}
      <div className={`mob-menu mob-menu-user ${isMenuOpen ? "active" : ""}`}>
        <div className="close" onClick={toggleHandler}></div>
        <ProfileUser
          accessDenied={props.accessDenied}
          isMobile={true}
          onCloseMobile={toggleHandler}
        />
        <ul className="mob-menu-wrap">
          {fieldPermissions.expenseApplication ? (
            <li>
              <Link
                className="header-controls__link"
                onClick={toggleHandler}
                to="/ExpenseApplication"
              >
                {t("menu.request")}
              </Link>
            </li>
          ) : null}
          {fieldPermissions.advanceReportApplication ? (
            <li>
              <Link
                className="header-controls__link"
                onClick={toggleHandler}
                to="/AdvanceReportApplication"
              >
                {t("menu.expenses")}
              </Link>
            </li>
          ) : null}
          {fieldPermissions.agreement ? (
            <li>
              <Link
                className="header-controls__link"
                onClick={toggleHandler}
                to="/Agreement"
              >
                {t("menu.analytics")}
              </Link>
            </li>
          ) : null}
          {fieldPermissions.journal ? (
            <li>
              <Link
                className="header-controls__link"
                onClick={toggleHandler}
                to="/Journal/AdvanceReportApplication"
              >
                {t("menu.journal")}
              </Link>
            </li>
          ) : null}
          {fieldPermissions.registry ? (
            <li>
              <Link
                className="header-controls__link"
                onClick={toggleHandler}
                to="/Reports"
              >
                {t("menu.reports_registry")}
              </Link>
            </li>
          ) : null}
          {isPowerOfAttorneyEnabled ||
          menuFieldPermissions.includes(
            MENU_FIELD_PERMISSIONS.POWER_OF_ATTORNEY_MANAGER
          ) ? (
            <li>
              <Link
                className="header-controls__link"
                onClick={toggleHandler}
                to="/PowersOfAttorney"
              >
                {t("menu.powers_of_attorney")}
              </Link>
            </li>
          ) : null}
          {fieldPermissions.substitution && (
            <li>
              <Link
                className="header-controls__link"
                onClick={toggleHandler}
                to="/Substitutions"
              >
                {t("menu.substitution")}
              </Link>
            </li>
          )}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default MenuHeader;
