import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { orderBy, filter, forEach } from "lodash";
//component
import Grid from "app/component/grid/Grid";
import CalculationsFilter from "app/pages/calculations/CalculationsFilter";
//common
import { UserDetail } from "infrastructure/interfaces";
import {
  NameFormats,
  nameFormatter,
  toLocaleNumberFormatter,
  getCurrencieCode,
} from "app/utils";
import moment from "moment";
import { get } from "lodash";
import { EmployeeAutocompleteOptionType } from "app/component/autocomplete/EmployeeAutocomplete";
import {
  getCommonClearingData,
  getCommonUserDetail,
} from "../../../store/selectors";

interface CalculationsProps {}
const Calculations: React.FC<CalculationsProps> = () => {
  const { t, i18n } = useTranslation();

  const user: UserDetail = useSelector(getCommonUserDetail);
  const clearingData = useSelector(getCommonClearingData);

  let [sortParameters, setSortParameters] = useState([] as any);
  let [clearing, setClearing] = useState([] as any[]);
  let [employeeFromFilter, setEmployeeFromFilter] = useState({
    value: 0,
    text: "",
  } as EmployeeAutocompleteOptionType);

  useEffect(() => {
    let sortKeyArray: string[] = [];
    let sortOrderArray: any[] = [];

    forEach(sortParameters, (item) => {
      sortKeyArray.push(item.key);
      sortOrderArray.push(item.sortOrder);
    });

    let clearingArr = orderBy(
      clearingData.clearings,
      sortKeyArray,
      sortOrderArray,
    );
    let clearingArr2 = [] as any[];
    clearingArr.forEach((item) => {
      let isBatchUpload = item.objectLogicalName == "BatchUpload";
      let isAdvance = item.objectLogicalName == "AdvanceReportApplication";
      if (isBatchUpload) {
        let isIncoming = item.amountRub > 0;
        clearingArr2.push({
          operationType: get(
            item,
            `operationType.${i18n.language as "en" | "ru"}`,
            "",
          ),
          incoming: isIncoming ? (
            <b>
              + {toLocaleNumberFormatter(item.amountRub)}{" "}
              <span className="roboto">{getCurrencieCode("rub")}</span>
            </b>
          ) : (
            <b>-</b>
          ),
          expense: isIncoming ? (
            <b>-</b>
          ) : (
            <b>
              {toLocaleNumberFormatter(item.amountRub)}{" "}
              <span className="roboto">{getCurrencieCode("rub")}</span>
            </b>
          ),
          date: moment(item.date).format("DD.MM.YYYY"),
        });
      } else {
        clearingArr2.push({
          // operationType: t('calculations.operation_type.'+item.objectLogicalName, {objectId: item.objectId}),
          operationType: get(
            item,
            `operationType.${i18n.language as "en" | "ru"}`,
            "",
          ),
          incoming: isAdvance ? (
            <b>-</b>
          ) : (
            <b>
              + {toLocaleNumberFormatter(item.amountRub)}{" "}
              <span className="roboto">{getCurrencieCode("rub")}</span>
            </b>
          ),
          expense: isAdvance ? (
            <b>
              - {toLocaleNumberFormatter(item.amountRub)}{" "}
              <span className="roboto">{getCurrencieCode("rub")}</span>
            </b>
          ) : (
            <b>-</b>
          ),
          date: moment(item.date).format("DD.MM.YYYY"),
        });
      }
    });

    setClearing(clearingArr2);
  }, [sortParameters, clearingData, i18n.language]);

  const downloadHeader = [
    {
      headerText: t("calculations.operation_type.title"),
      key: "operationType",
      sortable: true,
    },
    {
      headerText: t("calculations.incoming"),
      key: `incoming`,
      sortable: true,
    },
    {
      headerText: t("calculations.expense"),
      key: `expense`,
      sortable: true,
    },
    {
      headerText: t("calculations.date"),
      key: `date`,
      sortable: true,
    },
  ];

  const sortHandler = (key: string, sortOrder: string) => {
    let _sortParameters: any[] = filter(
      sortParameters,
      (item) => item.key !== key,
    );
    let sortParameter = [
      {
        key,
        sortOrder,
      },
    ];
    let sort = [...sortParameter, ..._sortParameters];
    setSortParameters(sort);
  };

  const getToDay = (date: Date) => moment(date).format("DD.MM.YYYY");

  const logEmployeeInfo = (employee: EmployeeAutocompleteOptionType) => {
    setEmployeeFromFilter(employee);
  };

  return (
    <div className="profile-calculation">
      <div className="profile-calculation-main">
        <div className="profile-calc-name">
          {employeeFromFilter.value
            ? employeeFromFilter.text
            : user.name
              ? nameFormatter(
                  user.name,
                  NameFormats.NAME_WITH_LASTNAME,
                  i18n.language as "en" | "ru",
                )
              : ""}
        </div>
        <div className="profile-calc-cost">
          {t("calculations.initial_balance")} {getToDay(clearingData.dateStart)}{" "}
          —{" "}
          <b>
            {toLocaleNumberFormatter(clearingData.balanceStart)}{" "}
            {getCurrencieCode("rub")}
          </b>
        </div>
        <div className="profile-calc-table-wrap">
          <Grid
            options={{
              headers: downloadHeader,
            }}
            data={clearing}
            onSort={sortHandler}
          />
        </div>
        <div className="profile-calc-cost">
          {t("calculations.final_balance")} {getToDay(clearingData.dateEnd)} —{" "}
          <b>
            {toLocaleNumberFormatter(clearingData.balanceEnd)}{" "}
            {getCurrencieCode("rub")}
          </b>
        </div>
      </div>
      <CalculationsFilter getEmployee={logEmployeeInfo} />
    </div>
  );
};

export default Calculations;
