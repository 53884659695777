import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RadioButton } from "ui/RadioButton";
import { Expire, ExpireDate, ExpireTerm } from "../Manager/ManagerListStyled";
import ModalContainer from "app/component/modal/ModalContainer";
import { calculateRemainingTime } from "app/pages/powers-of-attorney/helper";
import { getCommonUserDetail, getPowersOfAttorneyList } from "store/selectors";
import { setListOfPowerOfAttorneyForSigning } from "store/power/actions";

const Background = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  margin: 12px 24px;
  gap: 32px;
  background: #f5f5f5;
  cursor: pointer;
  &:hover {
    background: #d9e2e7;
    border-radius: 4px;
  }
`;

const Titles = styled.div`
  display: flex;
  padding: 12px;
  margin: 12px 24px;
  gap: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
`;

interface PowerOfAttorneyRequiredProps {
  signFinal: (getDocumentData: any[], getSignItemById: any) => void;
  powerOfAttorneyId: number;
  setPowerOfAttorneyId: (a: number) => void;
  setIsPowerOfAttorneyRequired: (
    modal: boolean,
    getDocumentData: any[],
    getSignItemById: number
  ) => void;
  isPowerOfAttorneyRequired: {
    modal: boolean;
    getDocumentData: any[];
    getSignItemById: number;
  };
  objectLogicalNames: string[];
}

const PowerOfAttorneyRequired: React.FC<PowerOfAttorneyRequiredProps> = ({
  signFinal,
  powerOfAttorneyId,
  setPowerOfAttorneyId,
  setIsPowerOfAttorneyRequired,
  isPowerOfAttorneyRequired,
  objectLogicalNames,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(getCommonUserDetail);
  const powerOfAttorneyList = useSelector(getPowersOfAttorneyList);

  useEffect(() => {
    if (objectLogicalNames.length) {
      dispatch(
        setListOfPowerOfAttorneyForSigning(user.name.ru, objectLogicalNames)
      );
    }
  }, [objectLogicalNames]);

  useEffect(() => {
    if (powerOfAttorneyList.length > 0 && isPowerOfAttorneyRequired.modal) {
      setPowerOfAttorneyId(powerOfAttorneyList[0].id);
    }
  }, [powerOfAttorneyList, isPowerOfAttorneyRequired.modal]);

  const optionHandler = (id: number) => {
    setPowerOfAttorneyId(id);
  };

  const redirectHandler = () => {
    history.push("/User/PowersOfAttorney");
  };

  const toggle = () => {
    setIsPowerOfAttorneyRequired(false, [], 0);
  };

  const signHandler = async () => {
    isPowerOfAttorneyRequired.getDocumentData.map((el) =>
      signFinal([el], isPowerOfAttorneyRequired.getSignItemById)
    );
    setPowerOfAttorneyId(0);
    setIsPowerOfAttorneyRequired(false, [], 0);
  };

  return (
    <ModalContainer isOpen={isPowerOfAttorneyRequired.modal} overlayClick={toggle}>
      <div className="box-modal" id="note-delete">
        <div
          className="box-modal_close arcticmodal-close"
          onClick={toggle}
        ></div>
        {!powerOfAttorneyList.length && (
          <div className="box-modal-title">
            <div style={{ lineHeight: 1.5 }} className="tx-center">
              {t("power.modal.failed_to_sign")}
            </div>
          </div>
        )}

        {powerOfAttorneyList.length === 1 && (
          <div className="box-modal-title">
            <div style={{ lineHeight: 1.5 }} className="tx-center">
              {t("power.modal.sign_one", {
                number: powerOfAttorneyList[0].id,
              })}
            </div>
          </div>
        )}

        {powerOfAttorneyList.length > 1 && (
          <>
            <div className="box-modal-title">
              <div style={{ lineHeight: 1.5 }} className="tx-center">
                {t("power.modal.sign_several", { number: "350" })}
              </div>
            </div>
            <Titles>
              <div style={{ width: "12%" }}>{t("power.number")}</div>
              <div style={{ width: "15%" }}>{t("power.modal.valid_until")}</div>
              <div style={{ width: "70%" }}>{t("power.authorities")}</div>
            </Titles>
            {powerOfAttorneyList &&
              powerOfAttorneyList.map((el) => (
                <Background key={el.id} onClick={() => optionHandler(el.id)}>
                  <RadioButton
                    style={{ width: "12%" }}
                    name={String(el.id)}
                    id={el.id}
                    label={String(el.id)}
                    checked={powerOfAttorneyId === el.id}
                    onClick={() => optionHandler(el.id)}
                  />
                  <Expire style={{ width: "15%" }}>
                    <ExpireDate expired={!moment(el.validTo).isAfter()}>
                      {moment(el.validTo).utc().format("DD.MM.YYYY")}
                    </ExpireDate>
                    <ExpireTerm expired={false}>
                      {el.revokeDate
                        ? calculateRemainingTime(el.revokeDate, false, t)
                        : calculateRemainingTime(el.validTo, false, t)}
                    </ExpireTerm>
                  </Expire>
                  <div style={{ width: "70%" }}>
                    {el.authorities.map(
                      (item) => ` ${item.name[i18n.language as "en" | "ru"]}; `
                    )}
                  </div>
                </Background>
              ))}
          </>
        )}

        <div className="box-modal-footer">
          {!powerOfAttorneyList.length ? (
            <button
              className="btn-expense btn_green pointer"
              onClick={redirectHandler}
            >
              {t("power.create_power_of_attorney")}
            </button>
          ) : (
            <>
              <button
                className="arcticmodal-close btn btn_black pointer"
                onClick={toggle}
              >
                {t("power.button.cancel")}
              </button>

              <button
                className={
                  !powerOfAttorneyId
                    ? "arcticmodal-close btn btn_black disabled"
                    : "btn-expense btn_green pointer"
                }
                onClick={signHandler}
                disabled={!powerOfAttorneyId}
              >
                {t("power.button.sign")}
              </button>
            </>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default PowerOfAttorneyRequired;
