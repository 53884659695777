import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CreateExpenseModal from "../modal/CreateExpenseModal";
import CreateAOModal from "../modal/CreateAOModal";
import CreateOrderModal from "../modal/CreateOrderModal";
import {
  getAdvanceReportApplication,
  getApplicationForExpenseDetail,
} from "../../../store/selectors";

interface CustomPropertyActivationProps {
  id: number;
  isAdvance?: boolean;
}

const CustomPropertyActivation: React.FC<CustomPropertyActivationProps> = (
  props
) => {
  let { t } = useTranslation();

  let [lastId, setLastId] = useState(0);
  let [steps, setSteps] = useState(
    [] as {
      type: string;
      id: number;
      documentId: number;
      isAdvance: boolean;
      isActive: boolean;
    }[]
  );
  let [currentStep, setCurrentStep] = useState(-1);

  const report = useSelector(getAdvanceReportApplication);
  const expenseApplication = useSelector(getApplicationForExpenseDetail);

  useEffect(() => {
    generateStep();
  }, [report, expenseApplication]);

  const generateStep = async () => {
    let newSteps = [];

    if (props.isAdvance) {
      if (!report.isActive) {
        newSteps.push({
          type: "AO",
          id: report.id,
          documentId: 0,
          isAdvance: true,
          isActive: false,
        });
      }

      for (
        let expenseIndex = 0;
        expenseIndex < report.expenses.length;
        expenseIndex++
      ) {
        const expense = report.expenses[expenseIndex];
        if (!expense.isActive) {
          newSteps.push({
            type: "Expense",
            id: expense.id,
            documentId: report.id,
            isAdvance: true,
            isActive: false,
          });
        }
      }
    } else {
      let expenseApplicationId = expenseApplication ? expenseApplication.id : 0;
      let expenseApplicationActive = expenseApplication
        ? expenseApplication.isActive
        : false;

      if (!expenseApplicationActive) {
        newSteps.push({
          type: "ZNR",
          id: expenseApplicationId,
          documentId: 0,
          documentType: "",
          isAdvance: false,
          isActive: false,
        });
      }

      let expenses = expenseApplication ? expenseApplication.expenses : [];

      for (
        let expenseIndex = 0;
        expenseIndex < expenses.length;
        expenseIndex++
      ) {
        const expense = expenses[expenseIndex];
        if (!expense.isActive) {
          newSteps.push({
            type: "Expense",
            id: expense.id,
            documentId: expenseApplicationId,
            isAdvance: false,
            isActive: false,
          });
        }
      }
    }

    if (
      lastId == props.id) {
      let resultSteps = [...newSteps];

      for (
        let newStepsIndex = 0;
        newStepsIndex < newSteps.length;
        newStepsIndex++
      ) {
        let newStep = newSteps[newStepsIndex];
        let needFill = true;
        for (
          let resultStepIndex = 0;
          resultStepIndex < steps.length;
          resultStepIndex++
        ) {
          let resultStep = steps[resultStepIndex];
          if (resultStep.id == newStep.id) {
            if (resultStep.isActive) {
              needFill = true;
            } else {
              needFill = false;
            }
          }
        }
        if (needFill) {
          resultSteps.push(newStep);
        }
      }

      if (resultSteps.length != steps.length) {
        setSteps(resultSteps);
      }
    } else {
      setCurrentStep(-1);
      setLastId(props.id);
      setSteps(newSteps);
    }
  };

  const startActivation = () => {
    let isActiveArray = steps.map((item) => item.isActive);
    let startIndex = isActiveArray.indexOf(false);
    setCurrentStep(startIndex);
  };

  const onCloseHandler = (isSimpleClose?: boolean) => {
    if (isSimpleClose) {
      setCurrentStep(-1);
    } else {
      steps[currentStep].isActive = true;
      let isActiveArray = steps.map((item) => item.isActive);
      let nextIndex = isActiveArray.indexOf(false, currentStep);
      setCurrentStep(nextIndex);
    }
  };

  const generateNodeArray = () => {
    let generatedNodeArray = [];

    for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
      const stepsElement = steps[stepIndex];
      let hasNext =
        steps.map((item) => item.isActive).indexOf(false, stepIndex + 1) >= 0;

      if (stepsElement.type == "ZNR") {
        generatedNodeArray.push(
          <div className="tx-left">
            <CreateExpenseModal
              editId={stepsElement.id}
              isOpen={stepIndex == currentStep}
              onClose={onCloseHandler}
              hasNext={hasNext}
            />
          </div>
        );
      }
      if (stepsElement.type == "AO") {
        generatedNodeArray.push(
          <div className="tx-left">
            <CreateAOModal
              editId={stepsElement.id}
              isOpen={stepIndex == currentStep}
              onClose={onCloseHandler}
              hasNext={hasNext}
            />
          </div>
        );
      }
      if (stepsElement.type == "Expense") {
        generatedNodeArray.push(
          <div className="tx-left">
            <CreateOrderModal
              id={stepsElement.documentId}
              editId={stepsElement.id}
              isAdvance={stepsElement.isAdvance}
              isOpen={stepIndex == currentStep}
              onClose={onCloseHandler}
              hasNext={hasNext}
            />
          </div>
        );
      }
    }
    return generatedNodeArray;
  };

  return (
    <>
      {steps.filter((item) => !item.isActive).length > 0 ? (
        <>
          <div className="btn btn_yellow pointer" onClick={startActivation}>
            {t("request_detail.request_button_controls.activation")}
          </div>
        </>
      ) : null}
      {generateNodeArray()}
    </>
  );
};

export default CustomPropertyActivation;
