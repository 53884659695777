import { Certificate } from "crypto";
import { Reducer } from "redux";
import { CERTIFICATES, CertificatesActions } from "./actionTypes";
export type Certificates = Certificate & {
  checked?: boolean;
  thumbprint: string;
  subjectKeyIdentifier?: string;
  authorityKeyIdentifier?: string;
};

export interface CertificatesState {
  clientMyCertificates: Certificates[];
  clientRootCertificates: Certificates[];
  clientCaCertificates: Certificates[];
  apiRootCertificates: Certificates[];
}

export const initialState: CertificatesState = {
  clientMyCertificates: [],
  clientRootCertificates: [],
  clientCaCertificates: [],
  apiRootCertificates: [],
};

export const reducer: Reducer<CertificatesState> = (
  state: CertificatesState = initialState,
  action,
) => {
  switch ((action as CertificatesActions).type) {
    case CERTIFICATES.SET_CLIENT_MY:
      return { ...state, clientMyCertificates: action.payload };
    case CERTIFICATES.SET_CLIENT_ROOT:
      return { ...state, clientRootCertificates: action.payload };
    case CERTIFICATES.SET_CLIENT_CA:
      return { ...state, clientCaCertificates: action.payload };
    case CERTIFICATES.SET_API_ROOT:
      return { ...state, apiRootCertificates: action.payload };
    default:
      return state;
  }
};

export default reducer;
