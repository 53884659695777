import React, { useEffect, useState } from "react";
import "./Loader.css";
import ModalContainer from "../modal/ModalContainer";

interface StatusLoaderProps {
  isOpen: boolean;
  status?: string;
  statuses?: string[]; // для отображения нескольких статусов по очереди, пока открыт компонент
}

const StatusLoader: React.FC<StatusLoaderProps> = (props) => {
  const [statusIndex, setStatusIndex] = useState<number>(0);
  const [currentStatus, setCurrentStatus] = useState<string>(
    props.statuses ? props.statuses[0] : "",
  );

  useEffect(() => {
    if (props.statuses && props.statuses.length && props.isOpen) {
      const interval = setInterval(() => {
        setStatusIndex((prevIndex) => {
          if (props.statuses && prevIndex < props.statuses.length - 1) {
            setCurrentStatus(props.statuses[prevIndex + 1]);
            return prevIndex + 1;
          } else {
            clearInterval(interval);
            return prevIndex;
          }
        });
      }, 700);

      return () => {
        clearInterval(interval);
      };
    }
  }, [statusIndex, props.statuses, props.isOpen]);

  return (
    <ModalContainer
      isOpen={props.isOpen}
      destroy={true}
      id={-1}
      style={true ? { zIndex: 100 } : undefined}
    >
      <div className="statusLoader_container">
        <div className="loader"></div>
        <div className="statusLoader_content">
          {props.statuses ? currentStatus : props.status}
        </div>
      </div>
    </ModalContainer>
  );
};

export default StatusLoader;
