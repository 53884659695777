/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PrintMenu from "app/component/menu/PrintMenu";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import If from "app/component/util/If";
import { useTranslation } from "react-i18next";
import { ReportMenuProps } from "./types";
import DeleteReportModal from "app/component/modal/DeleteReportModal";
import { updateAdvanceReportApplicationCancelById } from "services/ApiService";
import { useDispatch } from "react-redux";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { showErrors } from "store/exception/actions";
import CreateAOModal from "app/component/modal/CreateAOModal";

const ReportMenu: React.FC<ReportMenuProps> = (props) => {
  let [isMenuOpen, setMenuOpen] = useState(false);
  let [isOpenPrintModal, setOpenPrintModal] = useState(false);
  let [isOpenDeleteReportModal, setOpenDeleteReportModal] = useState(false);
  let [isOpenEditRequestModal, setOpenEditRequestModal] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { advanceReportApplication, fieldAccess, id } = props;

  const togglePopup = () => {
    setMenuOpen(!isMenuOpen);
  };

  const togglePrintModal = () => {
    setOpenPrintModal(!isOpenPrintModal);
    if (isMenuOpen) togglePopup();
  };

  const clickDeleteExpenseApplicationModalHandler = () => {
    if (isMenuOpen) togglePopup();
    setOpenDeleteReportModal(!isOpenDeleteReportModal);
  };

  const clickEditRequestModalHandler = () => {
    isMenuOpen && togglePopup();
    setOpenEditRequestModal(!isOpenEditRequestModal);
  };

  const deleteButtonSubmit = async () => {
    dispatch(loaderLock());
    let response = await updateAdvanceReportApplicationCancelById(id);
    if (response.headers.success) {
      setOpenDeleteReportModal(false);
      props.history.push("/AdvanceReportApplication");
    } else {
      dispatch(
        showErrors({
          code: "delete_report",
          message: t("modals.error.message.failed_to_delete_advance_report"),
        }),
      );
    }
    dispatch(loaderUnlock());
  };

  const isApproved =
    advanceReportApplication.status === "Approved" ||
    advanceReportApplication.status === "Posted";

  return (
    <>
      <a className="link-more-controls" onClick={togglePopup}>
        <div className="icon icon-more">
          <svg>
            <use x="0" y="0" href="#svg_icon_more" />
          </svg>
        </div>
      </a>
      <If condition={isMenuOpen}>
        <div className="miss-click-handler" onClick={togglePopup} />
      </If>
      <div
        className="request-item-controls"
        style={{
          display: isMenuOpen ? "block" : "",
          zIndex: 100,
          position: "absolute",
          right: "16px",
        }}
      >
        {/* {advanceReportApplication.paperWork.id ? <div className={`inHandWrapper`}>{t('request_detail.in_hand')}: {nameFormatter(advanceReportApplication.paperWork.employeeName, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)}</div> : null} */}
        {fieldAccess.printingForms ? (
          <a onClick={togglePrintModal} className={"pointer"}>
            <SvgIcon className="icon icon-download" href="#svg_icon_print" />
            <span> {t("report_detail.print")}</span>
          </a>
        ) : null}
        <If condition={fieldAccess.updateAO}>
          <a className={`pointer`} onClick={clickEditRequestModalHandler}>
            <SvgIcon className="icon icon-edit" href="#svg_icon_edit" />
            <span> {t("report_detail.edit")}</span>
          </a>
        </If>
        <If condition={fieldAccess.cancelAO}>
          <a
            className={`delete pointer`}
            onClick={clickDeleteExpenseApplicationModalHandler}
          >
            <SvgIcon className="icon icon-remove" href="#svg_icon_remove" />
            <span> {t("report_detail.delete")}</span>
          </a>
        </If>
      </div>
      <PrintMenu
        id={id}
        isOpen={isOpenPrintModal}
        isApproved={isApproved}
        onClose={togglePrintModal}
        expenseApplicationId={advanceReportApplication.expenseApplication.id}
        isAdvance={true}
        companyId={advanceReportApplication.company.id}
      />
      <DeleteReportModal
        id={id}
        isOpen={isOpenDeleteReportModal}
        onClose={() => setOpenDeleteReportModal(false)}
        onSubmit={deleteButtonSubmit}
      />
      <CreateAOModal
        editId={id}
        isOpen={isOpenEditRequestModal}
        onClose={() => setOpenEditRequestModal(false)}
      />
    </>
  );
};

export default ReportMenu;
