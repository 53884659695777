import styled, { keyframes } from "styled-components";

const ModalLoaderWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.011em;
  color: #0a0a0a;
  width: 640px;
  padding: 0 104px 56px 104px;
`;

const ModalLoaderHeader = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.021em;
  color: #0a0a0a;
  justify-content: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerAnimation = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
`;

export { ModalLoaderWrap, ModalLoaderHeader, SpinnerAnimation };
