import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Tooltip from "app/component/tooltip/Tooltip";
import { SvgIcon } from "ui/SvgIcon";
import {
  Array,
  Authorities,
  Button,
  Expire,
  ExpireWrapper,
  Info,
  InfoCode,
  Label,
  PowerContent,
  PowerInfo,
  PowerInfoName,
  PowerOfAttorney,
  PowerStatus,
  StatusContainer,
  Wrapper,
} from "./EmployeeListStyled";
import { PowersOfAttorneyListItemProps } from "../types";
import State from "../Status";

const PowersOfAttorneyListItem: React.FC<PowersOfAttorneyListItemProps> = ({
  POW,
  onClick,
}) => {
  const { t, i18n } = useTranslation();

  const isExpired = POW && !moment(POW.validTo).isAfter();

  const clickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick && POW && onClick(e, POW);
  };

  return (
    <>
      {POW && (
        <div>
          <Wrapper onClick={clickHandler}>
            <ExpireWrapper>
              <Expire expired={isExpired}>
                {t(isExpired ? "power.expired" : "power.expires", {
                  validTo: moment(POW.validTo).utc().format("DD.MM.YYYY"),
                })}
              </Expire>
            </ExpireWrapper>
            <PowerContent>
              <PowerOfAttorney>
                {t("power.number_from", {
                  number: POW.id,
                  CreatedAt: moment(POW.validFrom).utc().format("DD.MM.YYYY"),
                })}
              </PowerOfAttorney>

              <PowerInfo>
                <PowerInfoName>
                  <Label>{t("power.manager")}</Label>
                  <Info>
                    {
                      POW.owner.company.managerEmployee.fullName[
                        i18n.language as "en" | "ru"
                      ]
                    }
                  </Info>
                </PowerInfoName>
                <Authorities>
                  <Label>{t("power.authorities")}</Label>
                  <Info>
                    {POW &&
                      POW.authorities.map((el) => (
                        <span key={el.id + el.code}>
                          <InfoCode>{el.code}</InfoCode>
                          {` ${el.name[i18n.language as "en" | "ru"]}; `}
                        </span>
                      ))}
                  </Info>
                </Authorities>
              </PowerInfo>
            </PowerContent>

            <PowerStatus>
              <StatusContainer>
                {(POW.state === "Draft" ||
                  POW.state === "Declined" ||
                  POW.state === "Revoked") && (
                  <Tooltip
                    textArray={[POW.comment]}
                    fontSize={14}
                    width={"auto"}
                  >
                    <State state={POW.state} />
                  </Tooltip>
                )}

                {(POW.state === "SigningRequires" ||
                  POW.state === "Signed") && <State state={POW.state} />}
              </StatusContainer>
            </PowerStatus>
            {POW.state === "Draft" && (
              <Button id="item-button">{t("power.submit")}</Button>
            )}
            <Array>
              <SvgIcon id={"vectorRight"} size={18} color={"#0A0A0A"} />
            </Array>
          </Wrapper>
        </div>
      )}
    </>
  );
};

export default PowersOfAttorneyListItem;
