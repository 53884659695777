import React from "react";
import { Modal } from "ui/Modal";
import { SvgIcon } from "ui/SvgIcon";
import { NoticeProps } from "./types";
import { NoticeWrap, NoticeHeader } from "./StyledNotice";
import { Icon } from "ui/SvgIcon/types";

const Notice: React.FC<NoticeProps> = (props) => {
  const { header, text, type, isOpen, onClose, style } = props;

  const noticeType = {
    danger: "dangercircle",
    alert: "alertcircle",
    success: "checkcircle",
  };

  const noticeColor = {
    danger: "#E00000",
    alert: "#EA780E",
    success: "#4CD964",
  };

  return (
    <Modal style={style} isOpen={isOpen} onClose={onClose}>
      <NoticeWrap>
        <div>
          {type ? (
            <SvgIcon
              id={noticeType[type] as Icon}
              color={noticeColor[type]}
              size={32}
            />
          ) : (
            ""
          )}
        </div>
        <NoticeHeader>{header}</NoticeHeader>
        {text}
      </NoticeWrap>
    </Modal>
  );
};

export default Notice;
