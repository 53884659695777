import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import analitiks from "services/AnaliticsService";
import Downloads from "app/component/Downloads/Downloads";
import { getCommonUserDetail } from "../../../store/selectors";

const JournalDownloads: React.FC = () => {
  const user = useSelector(getCommonUserDetail);

  useEffect(() => {
    analitiks("JournalDownloadsWithPostings", {
      employeeId: user.id,
      occupationId: user.occupations[0].occupationId,
      companyId: user.occupations[0].company.id,
    });
  }, []);

  return <Downloads />;
};

export default JournalDownloads;
