import { Reducer } from "redux";
import { ScannedChequeInfoType } from "infrastructure/interfaces";
import { CHEQUE, ScannedChequeInfoActions } from "./actionsTypes";
import { NoticeItem } from "app/component/notice/NoticePanel2";

export interface QRScannerState {
  chequeInfoDetail: null | ScannedChequeInfoType;
  notion: { isEnabled: boolean; notice: NoticeItem | null };
  loader: boolean;
  sideModal: boolean;
  attachmentId: number;
}

export const initialState: QRScannerState = {
  chequeInfoDetail: null,
  notion: { isEnabled: false, notice: null },
  loader: false,
  sideModal: false,
  attachmentId: 0,
};

export const reducer: Reducer<QRScannerState> = (
  state: QRScannerState = initialState,
  action,
) => {
  switch ((action as ScannedChequeInfoActions).type) {
    case CHEQUE.GET_DETAIL:
      return { ...state, chequeInfoDetail: action.payload };
    case CHEQUE.NOTION:
      return {
        ...state,
        notion: {
          isEnabled: action.payload.isEnabled,
          notice: action.payload.notice,
        },
      };
    case CHEQUE.LOADER:
      return { ...state, loader: action.payload };
    case CHEQUE.SIDE_MODAL:
      return { ...state, sideModal: action.payload };
    case CHEQUE.ATTACHMENT_ID:
      return { ...state, attachmentId: action.payload };
    default:
      return state;
  }
};

export default reducer;
