import React from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "ui/SvgIcon";
import {
  PersonalData,
  PassportDataContainer,
  PassportData,
  BottomLabel,
  WarningIconInput,
  PassportIssuedByContainer,
  PassportIssuedBy,
  WarningIconTextarea,
} from "./TrustedPersonDetailsStyled";
import {
  DataContainer,
  Input,
  Textarea,
  UpperLabel,
} from "../PowerOfAttorneyCommonStyled";

interface TrustedPersonDetailsProps {
  name: string;
  inn: string;
  snils: string;
  passport: string;
  issuedOn: string;
  issuedByCode: string;
  citizenshipCountry: string;
  issuedBy: string;
  rows: number;
  isUserSelected: boolean;
}

const TrustedPersonDetails: React.FC<TrustedPersonDetailsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <DataContainer>
        <PersonalData>
          <UpperLabel>{t("power.INN")}</UpperLabel>

          <Input
            error={props.isUserSelected && !props.inn ? true : false}
            value={props.inn}
            placeholder={""}
            disabled={true}
          />
          <WarningIconInput
            error={props.isUserSelected && !props.inn ? true : false}
          >
            <SvgIcon id={"warningCircle"} />
          </WarningIconInput>

          {props.isUserSelected && !props.inn && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </PersonalData>
        <PersonalData>
          <UpperLabel>{t("power.SNILS")}</UpperLabel>

          <Input
            error={props.isUserSelected && !props.snils ? true : false}
            disabled={true}
            value={props.snils}
            placeholder={""}
          />
          <WarningIconInput
            error={props.isUserSelected && !props.snils ? true : false}
          >
            <SvgIcon id={"warningCircle"} />
          </WarningIconInput>

          {props.isUserSelected && !props.snils && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </PersonalData>
      </DataContainer>
      <PassportDataContainer>
        <PassportData>
          <UpperLabel>{t("power.passport")}</UpperLabel>
          <Input
            error={props.isUserSelected && !props.passport ? true : false}
            disabled={true}
            value={props.passport}
            placeholder={""}
          />
          <WarningIconInput
            error={props.isUserSelected && !props.passport ? true : false}
          >
            <SvgIcon id={"warningCircle"} />
          </WarningIconInput>

          {props.isUserSelected && !props.passport && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </PassportData>

        <PassportData>
          <UpperLabel>{t("power.date_of_issue")}</UpperLabel>
          <Input
            error={props.isUserSelected && !props.issuedOn ? true : false}
            disabled={true}
            value={props.issuedOn}
            placeholder={""}
          />
          <WarningIconInput
            error={props.isUserSelected && !props.issuedOn ? true : false}
          >
            <SvgIcon id={"warningCircle"} />
          </WarningIconInput>
          {props.isUserSelected && !props.issuedOn && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </PassportData>

        <PassportData>
          <UpperLabel>{t("power.department_code")}</UpperLabel>
          <Input disabled={true} value={props.issuedByCode} placeholder={""} />
        </PassportData>

        <PassportData>
          <UpperLabel>{t("power.citizenship")}</UpperLabel>
          <Input
            error={
              props.isUserSelected && !props.citizenshipCountry ? true : false
            }
            disabled={true}
            value={props.citizenshipCountry}
            placeholder={""}
          />
          <WarningIconInput
            error={
              props.isUserSelected && !props.citizenshipCountry ? true : false
            }
          >
            <SvgIcon id={"warningCircle"} />
          </WarningIconInput>
          {props.isUserSelected && !props.citizenshipCountry && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </PassportData>
      </PassportDataContainer>

      <PassportIssuedByContainer>
        <PassportIssuedBy>
          <UpperLabel>{t("power.issued_by")}</UpperLabel>
          <Textarea
            error={props.isUserSelected && !props.issuedBy ? true : false}
            rows={props.rows}
            disabled={true}
            value={props.issuedBy}
            placeholder={""}
          ></Textarea>
          <WarningIconTextarea
            error={props.isUserSelected && !props.issuedBy ? true : false}
          >
            <SvgIcon id={"warningCircle"} />
          </WarningIconTextarea>
          {props.isUserSelected && !props.issuedBy && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </PassportIssuedBy>
      </PassportIssuedByContainer>
    </div>
  );
};

export default TrustedPersonDetails;
