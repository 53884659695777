import { Action } from "redux";
import { Substitution } from "infrastructure/interfaces";

export const SUBSTITUTION = {
  SET_LIST: "SUBSTITUTION/SET_LIST",
};

export interface SetSubstitutionList extends Action {
  type: typeof SUBSTITUTION.SET_LIST;
  payload: { substitutionList: Substitution[] };
}

export type SubstitutionActions = SetSubstitutionList;
