import * as React from "react";
import "./style.css";

/**
 * interface to configure navigation var in date picker
 */
interface NavBarDatePickerProps {
  onPreviousClick: () => void;
  onNextClick: () => void;
  className: any;
  miniVector?: boolean | undefined;
  isDisableBeforeToday?: boolean;
  month: Date;
  onClose?: () => void;
}

/**
 * Custom navigation bar in date picker
 */
const NavBar: React.FC<NavBarDatePickerProps> = (prop) => {
  const isDisablePreviewMonth = () => {
    return (
      prop.isDisableBeforeToday &&
      prop.month.getMonth() === new Date().getMonth()
    );
  };

  return (
    <div className={prop.className}>
      {prop.miniVector && (
        <div className="miniVector" onClick={prop.onClose}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00012 4L20.0001 19.9999"
              stroke="#757575"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.00012 20L20.0001 4.00007"
              stroke="#757575"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          left: "20px",
          top: prop.miniVector ? "58px" : "29px",
          cursor: "pointer",
          pointerEvents: isDisablePreviewMonth() ? "none" : "auto",
          opacity: isDisablePreviewMonth() ? 0.5 : 1,
        }}
        onClick={() => prop.onPreviousClick()}
      >
        <div className="icon icon-arrow-left">
          {prop.miniVector ? (
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13L7 7L1 1"
                stroke="#0A0A0A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg>
              <use x="0" y="0" href="#svg_icon_arrow_right"></use>
            </svg>
          )}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: "20px",
          top: prop.miniVector ? "58px" : "29px",
          cursor: "pointer",
        }}
        onClick={() => prop.onNextClick()}
      >
        <div className="icon icon-arrow-right">
          {prop.miniVector ? (
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13L7 7L1 1"
                stroke="#0A0A0A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg>
              <use x="0" y="0" href="#svg_icon_arrow_right"></use>
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
