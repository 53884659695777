/* eslint-disable react-hooks/rules-of-hooks */
import moment from "moment";

export const calculateRemainingTime = (
  endDate: Date,
  detailed: boolean,
  t: any,
) => {
  const remainingTime = moment.duration(moment(endDate).diff(moment()));

  const years = remainingTime.get("year");
  const months = remainingTime.get("month");
  const days = remainingTime.get("day");
  let remaining = "";

  let yearString = "";
  const getYear = (year: number) => {
    switch (year) {
      case 1:
        return (yearString = t("power.time.year"));
      case 2:
      case 3:
      case 4:
        return (yearString = t("power.time.years"));
      default:
        break;
    }
  };

  let monthString = "";
  const getMonth = (month: number) => {
    switch (month) {
      case 1:
        return (monthString = t("power.time.month"));
      case 2:
      case 3:
      case 4:
        return (monthString = t("power.time.months"));
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
        return (monthString = t("power.time.months2"));
      default:
        break;
    }
  };

  let daysString = "";
  const getDay = (day: number, shortened = false) => {
    switch (day) {
      case 1:
      case 21:
      case 31:
        return shortened
          ? (daysString = t("power.time.day_short"))
          : (daysString = t("power.time.day"));
      case 2:
      case 3:
      case 4:
      case 22:
      case 23:
      case 24:
        return (daysString = t("power.time.days"));
      case 0:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
      case 25:
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
        return shortened
          ? (daysString = t("power.time.day_short"))
          : (daysString = t("power.time.days2"));
      default:
        break;
    }
  };

  if (detailed) {
    // для отображения списка доверенностей менеджера
    if (years > 0 && months === 0) {
      return (remaining += `${t("power.time.duration")} ${years} ${getYear(
        years,
      )}, ${t("power.time.expires")} ${moment(endDate)
        .utc()
        .format("DD.MM.YYYY")}`);
    }
    if (years > 0) {
      return (remaining += `${t("power.time.duration")} ${years} ${getYear(
        years,
      )} ${t("power.time.and")} ${months} ${getMonth(months)}, ${t(
        "power.time.expires",
      )} ${moment(endDate).utc().format("DD.MM.YYYY")}`);
    }
    if (years === 0 && months > 0) {
      return (remaining += `${t("power.time.duration")} ${months} ${getMonth(
        months,
      )} ${t("power.time.and")} ${days} ${getDay(days)}, ${t(
        "power.time.expires",
      )} ${moment(endDate).utc().format("DD.MM.YYYY")}`);
    }
    if (years === 0 && months === 0) {
      return (remaining += `${t("power.time.duration")} ${days} ${getDay(
        days,
      )}, ${t("power.time.expires")} ${moment(endDate)
        .utc()
        .format("DD.MM.YYYY")}`);
    }
    // для отображения детальной информации по доверенности
  } else {
    if (years > 0) {
      return (remaining += `${years} ${getYear(years)} ${months} ${t(
        "power.time.month_short",
      )}`);
    }
    if (years > 0 && months === 0) {
      return (remaining += `${years} ${getYear(years)}`);
    }
    if (years === 0 && months > 0) {
      return (remaining += `${months} ${getMonth(months)}`);
    }
    if (years === 0 && months === 0) {
      return (remaining += `${days} ${getDay(days)}`);
    }
  }
};
