import styled from "styled-components";

export const Title = styled.div`
  font-size: 18px;
  font-weight: 900;
  font-size: 21px;
  padding-bottom: 8px;
`;

export const AuthoritiesContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
  justify-content: space-between;
  margin-bottom: 10px;
  max-height: 100%;
  margin: 0;
  margin-bottom: 36px;
  @media screen and (max-width: 640px) {
    display: block;
  }
`;
export const ChoosenAuthorities = styled.div`
  min-height: "70px";
  max-height: "100%";
  font-size: 16px;
  width: 100%;
  font-family: "HarmoniaSansProCyr";
  max-height: 100%;
`;

export const ChoosenAuthority = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChoosenAuthorityInput = styled.label`
  width: 95%;
  padding: 13px 9px;
  min-height: 32px;
  border: 1px solid #98a2a8;
  font-size: 16px;
  font-family: "HarmoniaSansProCyr";
  white-space: pre-line;
`;

export const ChoosenAuthoritySpan = styled.span`
  display: inherit;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

export const AuthoritiesToChoose = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;

export const AuthoritiesToChooseOptions = styled.div`
  position: absolute;
  top: 20px;
  right: 38px;
  width: 400px;
  z-index: 99999999;
  @media screen and (max-width: 980px) {
    right: 11px;
    width: 387px;
  }
  @media screen and (max-width: 640px) {
    width: 361px;
  }
  @media screen and (max-width: 480px) {
    right: 3px;
    width: 300px;
  }
`;
export const Popup = styled.div`
  margin-top: 15px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
  overflow: hidden;
`;

export const PopupList = styled.ul`
  overflow: hidden;
`;

export const PopupListItem = styled.li`
  padding: 13px 9px;
  cursor: pointer;
  border-bottom: 1px solid gray;
  &:active {
    font-weight: bold;
    color: #f8f8f7;
  }
  &:hover {
    background: #f8f8f7;
  }
`;
