import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ModalFooter } from "ui/Modal";
import { BoxModalCert, ModalClose, ModalTitle } from "./StyledSignDocuments";
import { Button } from "ui/Button";
import { CertificatesModalProps } from "./types";
import { getCommon } from "store/selectors";
import ModalContainer from "ui/Modal/ModalContainer";
import CertificateContainer from "ui/Certificate/CertificateContainer";

const CertificatesModal: React.FC<CertificatesModalProps> = (props) => {
  const {
    certificates,
    isOpen,
    setAllDoc,
    onClick,
    onClose,
    setOpenSignsModal,
    allDoc,
    userDocuments,
    signAllDocuments,
    signOneDocument,
  } = props;
  const { certificateId } = useSelector(getCommon);
  const { t } = useTranslation();

  const onClickCert = async () => {
    if (!allDoc) {
      signOneDocument(certificateId);
    } else {
      signAllDocuments(userDocuments);
    }
    await setOpenSignsModal(false);
  };

  React.useEffect(() => {
    if (!isOpen) {
      setAllDoc(false);
    }
  }, [isOpen]);

  return (
    <ModalContainer isOpen={isOpen}>
      <BoxModalCert>
        <ModalTitle>{t("certificate.choose_certificate")}</ModalTitle>
        <ModalClose onClick={onClose}></ModalClose>
        <CertificateContainer certificates={certificates} onClick={onClick} />
        <ModalFooter>
          <Button
            big
            buttonColor={
              certificates.find((cert) => cert.checked) ? "gray" : "disabled"
            }
            onClick={onClickCert}
            iconId="fingerprint"
            buttonText={t(
              "request_detail.request_button_controls.complete_sign",
            )}
          />
        </ModalFooter>
      </BoxModalCert>
    </ModalContainer>
  );
};

export default CertificatesModal;
