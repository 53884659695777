import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { postTravelDocumentsSync } from "services/ApiService";
import { updateApplicationForExpenseDetail } from "store/applicationForExpense/actions";
import { updateDetailedAO } from "store/report/actions";
import { BoxBorder } from "ui/BoxBorder";
import { StyledBoxBorderText } from "ui/BoxBorder/StyledBoxBorder";
import { Button } from "ui/Button";
import { ModalLoader } from "ui/ModalLoader";
import SyncNotice from "./SyncNotice";
import { GetDocumentsFromTimeProps } from "./types";

const GetDocumentsFromTime: React.FC<GetDocumentsFromTimeProps> = ({
  id,
  isReport,
}) => {
  const [isOpenNotice, setOpenNotice] = useState(false);
  const [noticeType, setNoticeType] = useState("");
  const [isOpenLoader, setOpenLoader] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getDocuments = async () => {
    setOpenLoader(true);
    const objectLogicalName = isReport
      ? "AdvanceReportApplications"
      : "ExpenseApplications";
    await postTravelDocumentsSync(id, objectLogicalName).then((e) => {
      if (e.status === 200 || e.status === 201) {
        setNoticeType("success");
        setOpenNotice(true);
        isReport
          ? dispatch(updateDetailedAO(id))
          : dispatch(updateApplicationForExpenseDetail(id));
      } else {
        setNoticeType("alert");
        setOpenNotice(true);
      }
    });
    setOpenLoader(false);
  };

  const toggleNoticeModal = () => {
    setOpenNotice(!isOpenNotice);
  };

  return (
    <>
      <BoxBorder>
        <StyledBoxBorderText>
          {t("sync_documents.get_documents_box_title")}
        </StyledBoxBorderText>
        <Button
          onClick={getDocuments}
          buttonText={t("sync_documents.get_documents")}
          buttonWidth="120px"
          iconId="fileDownload"
          iconColor="#4D5A61"
        />
      </BoxBorder>
      <SyncNotice
        noticeType={noticeType}
        isOpen={isOpenNotice}
        onClose={toggleNoticeModal}
      />
      <ModalLoader
        header={t("sync_documents.uploading_files_header")}
        text={t("sync_documents.uploading_files_text")}
        isOpen={isOpenLoader}
      />
    </>
  );
};

export default GetDocumentsFromTime;
