import React from "react";
import { IconProps } from "../types";

const User: React.FC<IconProps> = (props) => {
  const { color, size } = props;

  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.5" cy="7.5" r="3" stroke={color} />
      <path
        d="M17 18C17 14.134 14.0899 11 10.5 11C6.91015 11 4 14.134 4 18"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10.5" cy="10.5" r="10" stroke={color} />
    </svg>
  );
};

export default User;
