import React from "react";
import { IconProps } from "../types";

const VectorDown: React.FC<IconProps> = (props) => {
  const { color, size, style } = props;
  return (
    <div style={style}>
      <svg
        width={size}
        height="6"
        viewBox="0 0 12 6"
        style={{ fill: "none" }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 0.5L6 5.5L11 0.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default VectorDown;
