import React from "react";
import { StyledBoxBorder } from "./StyledBoxBorder";
import { BoxBorderProps } from "./types";

const BoxBorder: React.FC<BoxBorderProps> = (props) => {
  const { border = "solid" } = props;

  return <StyledBoxBorder border={border}>{props.children}</StyledBoxBorder>;
};

export default BoxBorder;
