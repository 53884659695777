import React from "react";
import { useTranslation } from "react-i18next";
import { Notice } from "ui/Notice";
import { SyncNoticeProps } from "./types";

const SignNotice = (props: SyncNoticeProps) => {
  const { isOpen, onClose, noticeType } = props;

  const { t } = useTranslation();

  let notice;

  switch (noticeType) {
    case "success":
      notice = {
        header: t("sync_documents.success"),
        type: "success",
      };
      break;
    default:
      notice = {
        header: "Ошибка",
        text: "Не удалось загрузить файлы из TIME, попробуйте повторить загрузку позже",
        type: "danger",
      };
  }

  return (
    <>
      <Notice {...notice} isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default SignNotice;
