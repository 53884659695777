import React from "react";
import Agreement from "app/component/agreement/Agreement";

interface AOAgreementProps {
  id: number;
}

const AOAgreement: React.FC<AOAgreementProps> = (props) => {
  return <Agreement id={props.id} isAdvance={true} />;
};

export default AOAgreement;
