import React from "react";
import StyledButton from "./StyledButton";
import { buttonColorsStyles } from "./buttonColorsStyles";
import { ButtonProps, ButtonColor } from "./types";
import { SvgIcon } from "ui/SvgIcon";

const Button: React.FC<ButtonProps> = (props) => {
  const getColorsStyle = (sectedColor: ButtonColor) => {
    return buttonColorsStyles[sectedColor];
  };

  const {
    buttonText,
    disabled = false,
    buttonColor = "default",
    buttonWidth = "auto",
    big = false,
    iconId,
    iconColor = getColorsStyle(buttonColor).textColor,
    onClick,
  } = props;

  const isIconButton = buttonText ? false : true;

  return (
    <StyledButton
      disabled={disabled}
      isIconButton={isIconButton}
      buttonColor={buttonColor}
      buttonWidth={buttonWidth}
      big={big}
      onClick={onClick}
    >
      {iconId !== "vector" ? (
        <span>
          {iconId ? <SvgIcon id={iconId} color={iconColor} /> : null}{" "}
          {buttonText}
        </span>
      ) : (
        <span>
          {buttonText}
          {iconId ? <SvgIcon id={iconId} color={iconColor} /> : null}{" "}
        </span>
      )}
    </StyledButton>
  );
};

export default Button;
