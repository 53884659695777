import React from "react";
import { IconProps } from "../types";

const Image: React.FC<IconProps> = (props) => {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 0H3.5C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V17C0.5 17.7956 0.816071 18.5587 1.37868 19.1213C1.94129 19.6839 2.70435 20 3.5 20H17.5C17.6645 19.9977 17.8284 19.981 17.99 19.95L18.29 19.88H18.36H18.41L18.78 19.74L18.91 19.67C19.01 19.61 19.12 19.56 19.22 19.49C19.3535 19.3918 19.4805 19.2849 19.6 19.17L19.67 19.08C19.7682 18.9805 19.8585 18.8735 19.94 18.76L20.03 18.63C20.0998 18.5187 20.1601 18.4016 20.21 18.28C20.2374 18.232 20.2609 18.1818 20.28 18.13C20.33 18.01 20.36 17.88 20.4 17.75V17.6C20.4567 17.4046 20.4903 17.2032 20.5 17V3C20.5 2.20435 20.1839 1.44129 19.6213 0.87868C19.0587 0.316071 18.2956 0 17.5 0ZM3.5 18C3.23478 18 2.98043 17.8946 2.79289 17.7071C2.60536 17.5196 2.5 17.2652 2.5 17V12.69L5.79 9.39C5.88296 9.29627 5.99356 9.22188 6.11542 9.17111C6.23728 9.12034 6.36799 9.0942 6.5 9.0942C6.63201 9.0942 6.76272 9.12034 6.88458 9.17111C7.00644 9.22188 7.11704 9.29627 7.21 9.39L15.81 18H3.5ZM18.5 17C18.4991 17.1233 18.4753 17.2453 18.43 17.36C18.4071 17.4087 18.3804 17.4556 18.35 17.5C18.3232 17.5423 18.2931 17.5825 18.26 17.62L12.91 12.27L13.79 11.39C13.883 11.2963 13.9936 11.2219 14.1154 11.1711C14.2373 11.1203 14.368 11.0942 14.5 11.0942C14.632 11.0942 14.7627 11.1203 14.8846 11.1711C15.0064 11.2219 15.117 11.2963 15.21 11.39V11.39L18.5 14.69V17ZM18.5 11.86L16.62 10C16.0477 9.45699 15.2889 9.15428 14.5 9.15428C13.7111 9.15428 12.9523 9.45699 12.38 10L11.5 10.88L8.62 8C8.04772 7.45699 7.2889 7.15428 6.5 7.15428C5.7111 7.15428 4.95228 7.45699 4.38 8L2.5 9.86V3C2.5 2.73478 2.60536 2.48043 2.79289 2.29289C2.98043 2.10536 3.23478 2 3.5 2H17.5C17.7652 2 18.0196 2.10536 18.2071 2.29289C18.3946 2.48043 18.5 2.73478 18.5 3V11.86Z"
        fill={color}
      />
    </svg>
  );
};

export default Image;
