import React from "react";
import { useTranslation } from "react-i18next";

const AccessDenied: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="request-list empty access-denied-wrap">
      <div className="request-list-empty">
        <div className="image-wrap">
          <img src="/img/empty-img.png" />
        </div>
        {/*TODO: change message*/}
        <div style={{ width: "inherit" }}>
          {t("report_detail.access_denied")}
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
