import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  CheckInfoContainer,
  CheckInfoLine,
  CheckInfoLineInput,
  CheckInfoLineName,
  CheckInfoLineValue,
  CheckInfoLineValueInput,
} from "./AttachmentFilesQRScannerStyled";
import { getСhequeInfoDetail } from "store/selectors";
import { toLocaleNumberFormatter } from "app/utils";

interface Props {
  checkVatTypes: (array: Array<{ vatType: string }>) => boolean;
}

const AttachmentFilesChequeInfo: React.FC<Props> = ({ checkVatTypes }) => {
  const { t } = useTranslation();
  const chequeInfo = useSelector(getСhequeInfoDetail);

  return (
    <>
      {chequeInfo && (
        <CheckInfoContainer>
          <CheckInfoLine>
            <CheckInfoLineName>{t("QR.cheque_n")}</CheckInfoLineName>
            <CheckInfoLineValue>
              {chequeInfo.qrCheck.fiscalSign}
            </CheckInfoLineValue>
          </CheckInfoLine>
          <CheckInfoLine>
            <CheckInfoLineName>{t("QR.cheque_date")}</CheckInfoLineName>
            <CheckInfoLineValue>
              {moment(chequeInfo.qrCheck.operationDate)
                .utc()
                .format("DD.MM.YYYY")}
            </CheckInfoLineValue>
          </CheckInfoLine>
          <CheckInfoLine>
            <CheckInfoLineName>{t("QR.type")}</CheckInfoLineName>
            <CheckInfoLineValue>{t("QR.income")}</CheckInfoLineValue>
          </CheckInfoLine>

          <CheckInfoLineInput>
            <CheckInfoLineName>{t("QR.VAT_rate")}</CheckInfoLineName>
            <CheckInfoLineValueInput>
              {checkVatTypes(chequeInfo.qrCheck.qrCheckItems)
                ? t("QR.combined")
                : chequeInfo.qrCheck.qrCheckItems[0].vatType}
            </CheckInfoLineValueInput>
          </CheckInfoLineInput>

          <CheckInfoLineInput>
            <CheckInfoLineName>{t("QR.without_VAT")}</CheckInfoLineName>
            <CheckInfoLineValueInput>
              {toLocaleNumberFormatter(
                chequeInfo.qrCheck.amount - chequeInfo.qrCheck.vatAmount,
              )}
            </CheckInfoLineValueInput>
          </CheckInfoLineInput>

          <CheckInfoLineInput>
            <CheckInfoLineName>{t("QR.VAT")}</CheckInfoLineName>
            <CheckInfoLineValueInput>
              {toLocaleNumberFormatter(chequeInfo.qrCheck.vatAmount)}
            </CheckInfoLineValueInput>
          </CheckInfoLineInput>

          <CheckInfoLineInput>
            <CheckInfoLineName>{t("QR.with_VAT")}</CheckInfoLineName>
            <CheckInfoLineValueInput>
              {toLocaleNumberFormatter(chequeInfo.qrCheck.amount)}
            </CheckInfoLineValueInput>
          </CheckInfoLineInput>
        </CheckInfoContainer>
      )}
    </>
  );
};

export default AttachmentFilesChequeInfo;
