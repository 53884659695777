import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalContainer from "ui/Modal/ModalContainer";
import { decline } from "store/power/actions";

const Modal = styled.div`
  z-index: 10001;
  background: #ffffff;
  width: 424px;
  position: relative;
  width: 424px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 21px;
`;
const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.017em;
  color: #0a0a0a;
  margin-bottom: 8px;
`;
const Textarea = styled.textarea`
  display: flex;
  padding: 6px 12px 6px 16px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  margin-bottom: 24px;
  heigh: auto;
  width: 100%;
  resize: vertical;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonBack = styled.button`
  align-items: center;
  width: 102px;
  padding: 6px 16px;
  height: 32px;
  background: none;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #5d6c74;
  color: #5d6c74;
  font-family: "HarmoniaSansProCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-right: 4px;
  &:hover {
    background: rgb(223, 225, 227);
  }
`;
const ButtonDecline = styled.button`
  align-items: center;
  padding: 6px 16px;
  width: 102px;
  height: 32px;
  background: #4cd964;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-family: "HarmoniaSansProCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-left: 4px;
  &:hover {
    background: rgb(63, 181, 83);
  }
  &:disabled {
    background-color: #ededed;
    color: #9e9e9e;
    cursor: not-allowed;
  }
`;

interface DeclineToSignProps {
  setIsModalToDecline: (a: boolean) => void;
  isModalToDecline: boolean;
  powerOfAttorneyId: number;
  isInfoModal: (a: boolean) => void;
}

const DeclineToSign: React.FC<DeclineToSignProps> = ({
  setIsModalToDecline,
  isModalToDecline,
  powerOfAttorneyId,
  isInfoModal,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    if (message.length > 0) {
      setDisabled(false);
    }
  }, [message]);

  const declineToSignHandler = () => {
    const comment = {
      comment: message,
    };

    dispatch(
      decline(powerOfAttorneyId, comment, setIsModalToDecline, isInfoModal),
    );
  };

  const closeModalHandler = () => {
    if (isModalToDecline && setIsModalToDecline) {
      setIsModalToDecline(!isModalToDecline);
    }
  };

  return (
    <div>
      <ModalContainer style={{ zIndex: 101 }} isOpen={isModalToDecline}>
        <Modal>
          <Title>{t("power.reason_of_decline")}</Title>
          <Textarea
            value={message}
            onChange={handleMessageChange}
            placeholder={t("power.write_reason_of_decline")}
          />
          <ButtonContainer>
            <ButtonBack onClick={() => closeModalHandler()}>
              {t("power.button.cancel")}
            </ButtonBack>
            <ButtonDecline
              onClick={() => declineToSignHandler()}
              disabled={disabled}
            >
              {t("power.button.decline")}
            </ButtonDecline>
          </ButtonContainer>
        </Modal>
      </ModalContainer>
    </div>
  );
};

export default DeclineToSign;
