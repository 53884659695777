import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "ui/SvgIcon";
import "./InternalReport.css";
import {
  OptionContainer,
  TypeSelect,
  Option,
  ReportContainer,
  ReportType,
} from "./ReportFormStyled";
import { OptionProp } from "./types";

interface ReportSelectProps {
  reportOptions: OptionProp[];
  report: OptionProp;
  setReport: (report: OptionProp) => void;
}

const ReportSelect: React.FC<ReportSelectProps> = ({
  reportOptions,
  report,
  setReport,
}) => {
  const { i18n } = useTranslation();
  const [isOpenReportSelect, setIsOpenReportSelect] = useState<boolean>(false);

  const handleOptionClick = (value: OptionProp) => {
    setReport(value);
    setIsOpenReportSelect(false);
  };

  const handleInputClick = () => {
    setIsOpenReportSelect(true);
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpenReportSelect(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <ReportType onClick={handleInputClick}>
        <SvgIcon
          style={{
            position: "absolute",
            right: "8px",
            top: "14px",
            zIndex: 2,
          }}
          id={"vectorDown"}
          color={"#545B5E"}
          size={15}
        />
        <TypeSelect
          value={report ? report.name[i18n.language as "ru" | "en"] : ""}
          ref={ref}
          readOnly
        />

        {isOpenReportSelect ? (
          <OptionContainer>
            {reportOptions.map((el) => (
              <ReportContainer
                key={el.id}
                onClick={() => handleOptionClick(el)}
              >
                <Option>{el.name[i18n.language as "ru" | "en"]}</Option>
              </ReportContainer>
            ))}
          </OptionContainer>
        ) : null}
      </ReportType>
    </>
  );
};

export default ReportSelect;
