import React, { useState, Fragment, useEffect, useRef, useMemo } from "react";
import "./AOExpenses.css";
import CreateOrderModal from "app/component/modal/CreateOrderModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Config, { ConfigTypes } from "services/ConfigService";
import DeleteExpenseModal from "app/component/modal/DeleteExpenseModal";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { throwException } from "store/exception/actions";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { updateDetailedAO } from "store/report/actions";
import {
  IAO,
  AOExpense,
  UserDetail,
  IOccupations,
} from "infrastructure/interfaces";
import { Expense } from "infrastructure/interfaces/index";
import { statusReportNameMap } from "infrastructure/enum/status-report.enum";
import i18next from "i18next";
import { ApplicationState } from "store/reducers";
import { showErrors } from "store/exception/actions";
import { STATUS_TYPE_REPORT } from "infrastructure/enum/status-report.enum";
import {
  expenseStylesMap,
  expenseHrefMap,
  EXPENSE_TYPE,
} from "infrastructure/enum/expense-type.enum";
import {
  statusExpenseStylesMap,
  statusExpenseNameMap,
} from "infrastructure/enum/status-expense.enum";
import ExpenseAttachments from "app/component/expense-attachments/ExpenseAttachments";
import {
  toLocaleNumberFormatter,
  formatter,
  getCurrencieCode,
} from "app/utils";
import TextCutter from "app/component/text-cutter/TextCutter";
import { useWidth } from "app/hooks";
import { OCCUPATION_ROLE_TYPE } from "infrastructure/enum/user-role.enum";
import { isEmpty } from "lodash";
import If from "app/component/util/If";
import Else from "app/component/util/Else";
import { findIndex, pull } from "lodash";
import { NavLink } from "react-router-dom";
import { ADVANCE_APPLICATION_PROPETIES } from "../../../infrastructure/enum/object-properties.enum";
import { EXPENSE_PROPETIES } from "../../../infrastructure/enum/object-properties.enum";
import {
  getAdvanceReportApplication,
  getCommonAttachmentPermission,
  getCommonStateOptions,
  getCommonUserDetail,
} from "../../../store/selectors";
import {
  deleteExpensesById,
  updatePerDiemCalculations,
} from "../../../services/ApiService";

interface ReportExpensesProps {
  list: any[];
  id: number;
  applicationId: number;
}

const ReportExpenses: React.FC<ReportExpensesProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication
  );
  let stateOptions = useSelector(getCommonStateOptions);
  const user: UserDetail = useSelector(getCommonUserDetail);

  let [isOpenAddExpenseModal, setOpenAddExpenseModal] = useState(false);
  let [isOpenDeleteExpenseModal, setOpenDeleteExpenseModal] = useState(false);
  let [isOpenEditExpenseModal, setOpenEditExpenseModal] = useState(false);
  let [expenseId, setExpenseId] = useState(-1);

  const newList = [...props.list];

  newList.forEach((expense: any) => {
    if (expense && expense.relations.length > 1) {
      expense.relations.forEach((relation: any) => {
        let relatedId: number = relation.relatedExpenseId;
        let relatedIndex: number = findIndex(
          newList,
          (expense) => expense.id == relatedId
        );
        let relatedExpense: Expense = newList[relatedIndex];
        let relationItems = relatedExpense.relations;
        expense.relations = expense.relations.filter(
          (relation: any) =>
            !relationItems.some(
              (item: any) => relation.relatedExpenseId == item.relatedExpenseId
            )
        );
      });
    }
  });
  newList.forEach((expense: Expense) => {
    if (expense && expense.relations.length > 0) {
      let relatedIndex = findIndex(
        newList,
        (item) => item.id == expense.relations[0].relatedExpenseId
      );
      let expenseIndex = findIndex(newList, expense);
      let relatedExpense = newList[relatedIndex];
      pull(newList, relatedExpense);
      newList.splice(expenseIndex + 1, 0, relatedExpense);
    }
  });

  const getSortList = (): AOExpense[][] => {
    let externalArr: AOExpense[][] = [];
    let internalArr: AOExpense[] = [];
    newList.forEach((expense: AOExpense) => {
      if (expense.relations.length > 0) {
        internalArr.push(expense);
      } else {
        internalArr.push(expense);
        externalArr.push(internalArr.reverse());
        internalArr = [];
      }
    });
    return externalArr;
  };

  const setReportExpenseId = (id: number) => {
    setExpenseId(id);
  };

  const toggleEditExpenseModal = () => {
    setOpenEditExpenseModal(!isOpenEditExpenseModal);
  };

  const closeEditExpenseModal = () => {
    setOpenEditExpenseModal(false);
  };

  const toggleDeleteExpenseModal = () => {
    setOpenDeleteExpenseModal(!isOpenDeleteExpenseModal);
  };

  const hasState = (state: string) => {
    if (isEmpty(stateOptions)) {
      return false;
    }

    return stateOptions.indexOf(state) != -1;
  };

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  const isAssistantOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.ASSISTANT);
  };

  const isCoordinatorOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.COORDINATOR);
  };

  const toggleAddExpenseModal = () => {
    if (
      advanceReportApplication.properties.includes(
        ADVANCE_APPLICATION_PROPETIES.ADD_EXPENSE
      )
    ) {
      setOpenAddExpenseModal(!isOpenAddExpenseModal);
      return;
    }

    if (!advanceReportApplication) {
      return;
    }

    if (
      advanceReportApplication.status === STATUS_TYPE_REPORT.APPROVED ||
      advanceReportApplication.status === STATUS_TYPE_REPORT.ON_APPROVAL
    ) {
      dispatch(
        showErrors({
          code: "",
          message: t("status_error_message.status_expense_edit_RA", {
            status: t(
              `report_status.${statusReportNameMap[advanceReportApplication.status as STATUS_TYPE_REPORT]}`
            ),
          }),
        })
      );
      return;
    }

    if (advanceReportApplication.status === STATUS_TYPE_REPORT.POSTED) {
      dispatch(
        showErrors({
          code: "",
          message: t("status_error_message.status__expense_edit_RA", {
            status: t(
              `report_status.${statusReportNameMap[advanceReportApplication.status as STATUS_TYPE_REPORT]}`
            ),
          }),
        })
      );
      return;
    }

    setOpenAddExpenseModal(!isOpenAddExpenseModal);
  };

  const closeAddExpenseModal = () => {
    setOpenAddExpenseModal(false);
  };

  const expensesEmpty = () => (
    <div className="request-expenses-empty">
      <div className={"tx-center"}>
        <img src="/img/empty-img-2.png" />
      </div>
      {advanceReportApplication.properties.includes(
        ADVANCE_APPLICATION_PROPETIES.ADD_EXPENSE
      ) ? (
        <>
          {advanceReportApplication.properties.includes(
            ADVANCE_APPLICATION_PROPETIES.ADD_EXPENSE
          )
            ? `${t("request_detail.application_expenses.approveApplicationYouNeed")} `
            : null}
          {advanceReportApplication.properties.includes(
            ADVANCE_APPLICATION_PROPETIES.ADD_EXPENSE
          ) ? (
            <span
              onClick={toggleAddExpenseModal}
              className="pointer"
            >{`${t("request_detail.application_expenses.addExpenses")}`}</span>
          ) : null}
        </>
      ) : null}
    </div>
  );

  const handleDelete = async () => {
    dispatch(loaderLock());

    let a = advanceReportApplication.expenses
      .filter((item: any) => {
        return item.expenseType.expenseGroup.code == "Perdiem";
      })
      .map((item: any) => item.id);
    if (a.includes(expenseId)) {
      a.forEach((item) => {
        if (expenseId != item) {
          deleteExpensesById(item);
        }
      });
      let response = await deleteExpensesById(expenseId);
      if (response.headers.success) {
        let data = {
          applicationRef: {
            id: advanceReportApplication.id,
            logicalName: "AdvanceReportApplication",
            name: {
              ru: "AdvanceReportApplication",
              en: "AdvanceReportApplication",
            },
          },
          calculations: [],
        };
        await updatePerDiemCalculations(data);
        dispatch(updateDetailedAO(props.id as number));
      } else {
        dispatch(
          showErrors({
            code: "",
            message: response.data,
          })
        );
      }
      toggleDeleteExpenseModal();
      dispatch(loaderUnlock());
      return;
    }

    deleteExpensesById(expenseId)
      .then((response: any) => {
        toggleDeleteExpenseModal();
        dispatch(updateDetailedAO(props.id as number));
        if (!response.headers.success) {
          dispatch(
            showErrors({
              code: "",
              message: response.data,
            })
          );
        }
      })
      .catch((e: any) => {
        dispatch(
          throwException({
            code: "AdvanceReportApplication_cancel",
            message: `AdvanceReportApplication_cancel ${e.response.status}`,
          })
        );
        toggleDeleteExpenseModal();
        dispatch(updateDetailedAO(props.id as number));
      })
      .finally(() => {
        dispatch(loaderUnlock());
      });
  };

  return (
    <Fragment>
      <div className="request-expenses">
        <div className="request-expenses-title">
          <span style={{ fontWeight: 600 }}>
            {t("report_detail.report_expenses.reportExpenses")}
          </span>
          <If
            condition={advanceReportApplication.properties.includes(
              ADVANCE_APPLICATION_PROPETIES.ADD_EXPENSE
            )}
          >
            <If condition={isBookerOccupation()}>
              <a
                className="btn-expense btn_green js-open-modal add-note pointer"
                onClick={toggleAddExpenseModal}
              >
                {t("request_detail.application_expenses.addExpens")}
              </a>
            </If>
            <Else condition={isBookerOccupation()}>
              <a
                className="btn-expense btn_green js-open-modal add-note pointer"
                onClick={toggleAddExpenseModal}
              >
                {t("request_detail.application_expenses.addExpens")}
              </a>
            </Else>
          </If>
        </div>
        <div className="request-expenses-wrap">
          {props.list.length ? (
            <ApplicationExpensesList
              list={getSortList()}
              openDeleteExpenseModal={toggleDeleteExpenseModal}
              openEditExpenseModal={toggleEditExpenseModal}
              setExpenseId={setReportExpenseId}
            />
          ) : (
            expensesEmpty()
          )}
        </div>
      </div>
      <CreateOrderModal
        id={props.id}
        isOpen={isOpenAddExpenseModal}
        onClose={closeAddExpenseModal}
        isAdvance={true}
      />
      <CreateOrderModal
        id={props.id}
        editId={expenseId}
        isAdvance={true}
        isOpen={isOpenEditExpenseModal}
        onClose={closeEditExpenseModal}
      />
      <DeleteExpenseModal
        id={expenseId}
        isOpen={isOpenDeleteExpenseModal}
        onSubmit={handleDelete}
        onClose={toggleDeleteExpenseModal}
      />
    </Fragment>
  );
};

interface ApplicationExpensesList {
  list: AOExpense[][];
  setExpenseId: (id: number) => void;
  openDeleteExpenseModal: () => void;
  openEditExpenseModal: () => void;
}

const ApplicationExpensesList: React.FC<ApplicationExpensesList> = (props) => {
  return (
    <Fragment>
      {props.list.map((item) => (
        <WrapperApplicationExpensesItem //id={item.id}
          //key={item.id}
          setExpenseId={props.setExpenseId}
          expenses={item}
          openDeleteExpenseModal={props.openDeleteExpenseModal}
          openEditExpenseModal={props.openEditExpenseModal}
        />
      ))}
    </Fragment>
  );
};

interface WrapperApplicationExpensesItem {
  id?: number;
  expenses: any;
  setExpenseId: Function;
  openDeleteExpenseModal: () => void;
  openEditExpenseModal: () => void;
}

const WrapperApplicationExpensesItem: React.FC<
  WrapperApplicationExpensesItem
> = (props) => {
  let [isShowAll, setIsShowAll] = useState(false);
  const isCanShowAll =
    props.expenses[0].expenseType.groupCode == EXPENSE_TYPE.AVIA &&
    props.expenses.length > 1;
  const clickHandler = () => {
    setIsShowAll(!isShowAll);
  };
  if (isShowAll) {
    let index = 0;
    return props.expenses.map((expense: AOExpense) => {
      index++;
      return (
        <ApplicationExpensesItem
          key={expense.id}
          clickHandler={clickHandler}
          isShowAll={isShowAll}
          isCanShowAll={isCanShowAll}
          index={index}
          id={expense.id}
          setExpenseId={props.setExpenseId}
          openDeleteExpenseModal={props.openDeleteExpenseModal}
          openEditExpenseModal={props.openEditExpenseModal}
          expense={expense}
          original={index == props.expenses.length}
        />
      );
    });
  } else {
    return (
      <ApplicationExpensesItem
        key={props.expenses[0].id}
        clickHandler={clickHandler}
        isShowAll={isShowAll}
        isCanShowAll={isCanShowAll}
        index={1}
        id={props.expenses[0].id}
        setExpenseId={props.setExpenseId}
        openDeleteExpenseModal={props.openDeleteExpenseModal}
        openEditExpenseModal={props.openEditExpenseModal}
        expense={props.expenses[0]}
      />
    );
  }
};

interface ApplicationExpensesItem {
  id: number;
  isShowAll: boolean;
  isCanShowAll?: boolean;
  index?: number;
  original?: boolean;
  clickHandler: () => void;
  setExpenseId: Function;
  expense: any;
  openDeleteExpenseModal: () => void;
  openEditExpenseModal: () => void;
}

const ApplicationExpensesItem: React.FC<ApplicationExpensesItem> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let [isOpenPopup, setOpenPopup] = useState(false);

  const documentsTranslation = (documentNumber: string, type: string) => {
    switch (type) {
      case ADVANCE_APPLICATION_PROPETIES.SERVICE_NOTE:
        return (
          <span>
            {t("request_detail.application_expenses.attached_act", {
              documentNumber: documentNumber,
            })}
            &nbsp;
          </span>
        );
      case ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES:
        return (
          <span>
            {t("request_detail.application_expenses.attached_act", {
              documentNumber: documentNumber,
            })}
            &nbsp;
          </span>
        );
      case ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT:
        return (
          <span>
            {t("request_detail.application_expenses.attached_report", {
              documentNumber: documentNumber,
            })}
            &nbsp;
          </span>
        );
      default:
        return null;
    }
  };

  const { isAdvanceReportAttachmentPermission } = useSelector(
    getCommonAttachmentPermission
  );
  const user = useSelector(getCommonUserDetail);
  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication
  );
  let stateOptions = useSelector(getCommonStateOptions);
  const attachments = useSelector((state: ApplicationState) =>
    state.common.attachmentsExpense.find((attach) => attach.id === props.id)
  );

  const advanceReportAmountCalculationPermission = Config.getConfigToCompany(
    ConfigTypes.ADVANCE_REPORT_AMOUNT_CALCULATION,
    advanceReportApplication.company.id
  );
  const advanceReportAmountFullCalculation = Config.getConfigToCompany(
    ConfigTypes.ADVANCE_REPORT_AMOUNT_FULL_CALCULATION,
    advanceReportApplication.company.id
  );
  const attachmentsList = attachments ? attachments.attachments : [];

  let [actAccess, setActAccess] = useState(true);

  const fieldPermissions = useMemo(() => {
    return {
      type:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.TYPE}`),
      description:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.DESCRIPTION}`),
      amountRub:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.AMOUNT_RUB}`),
      isExternal:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.IS_EXTERNAL}`),
      status:
        props.expense &&
        props.expense.properties.includes(`${EXPENSE_PROPETIES.STATUS}`),
    };
  }, [advanceReportApplication]);

  let nameRef = useRef(null as any);
  let attachNameRef = useRef({} as any);
  let attachWidth = useWidth(attachNameRef);
  let width = useWidth(nameRef);

  useEffect(() => {
    if (advanceReportApplication && advanceReportApplication.properties) {
      setActAccess(
        advanceReportApplication.properties.includes(
          "ActOfEntertaimentExpenses"
        )
      );
    }
  }, [advanceReportApplication]);

  const hasState = (state: string) => {
    if (isEmpty(stateOptions)) {
      return false;
    }

    return stateOptions.indexOf(state) != -1;
  };

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  const openDeleteExpenseModalHandler = () => {
    if (
      isBookerOccupation() &&
      advanceReportApplication.status === STATUS_TYPE_REPORT.ON_APPROVAL &&
      hasState("Approve")
    ) {
      props.setExpenseId(props.id);
      props.openDeleteExpenseModal();
      return;
    }

    if (!advanceReportApplication) {
      return;
    }

    if (
      advanceReportApplication.status === STATUS_TYPE_REPORT.APPROVED ||
      advanceReportApplication.status === STATUS_TYPE_REPORT.ON_APPROVAL
    ) {
      dispatch(
        showErrors({
          code: "",
          message: t("status_error_message.status_expense_delete_RA", {
            status: t(
              `report_status.${statusReportNameMap[advanceReportApplication.status as STATUS_TYPE_REPORT]}`
            ),
          }),
        })
      );
      return;
    }

    if (advanceReportApplication.status === STATUS_TYPE_REPORT.POSTED) {
      dispatch(
        showErrors({
          code: "",
          message: t("status_error_message.status__expense_delete_RA", {
            status: t(
              `report_status.${statusReportNameMap[advanceReportApplication.status as STATUS_TYPE_REPORT]}`
            ),
          }),
        })
      );
      return;
    }

    props.setExpenseId(props.id);
    props.openDeleteExpenseModal();
  };

  const openEditExpenseModalHandler = () => {
    if (advanceReportApplication) {
      props.setExpenseId(props.id);
      props.openEditExpenseModal();
    }
  };

  const onDelete = () => {
    togglePopup();
    openDeleteExpenseModalHandler();
  };

  const onEdit = () => {
    togglePopup();
    openEditExpenseModalHandler();
  };

  const togglePopup = () => {
    setOpenPopup(!isOpenPopup);
  };

  const getCostStatus = (
    advanceCashRequired: boolean,
    billNumber: string,
    billDate: Date
  ) => {
    if (advanceCashRequired && billNumber && billDate) {
      return t("report_detail.application_expense_item.advanceBill", {
        billDate: formatter("DD.MM.YYYY", i18n.language, billDate),
      });
    }
    if (advanceCashRequired) {
      return t("report_detail.application_expense_item.advance");
    }
    if (!advanceCashRequired && billNumber && billDate) {
      return t("report_detail.application_expense_item.bill", {
        billDate: formatter("DD.MM.YYYY", i18n.language, billDate),
      });
    }
    return "";
  };

  return advanceReportApplication ? (
    <div
      className={`request-expenses-item ${props.expense.external && fieldPermissions.isExternal ? "gray-line" : ""} ${props.isCanShowAll && props.index == 1 ? "m-b-0" : ""} ${props.index != 1 ? "gray-exchanged" : ""}`}
      ref={attachNameRef}
      style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
    >
      {props.expense.copiedFromExpenseApplicationId ? (
        <div className={`gray-line-copyed`}>
          <span>
            {t("request_detail.application_expenses.by_application")}{" "}
            <NavLink
              tabIndex={-1}
              to={`/ExpenseApplication/Detail/${props.expense.copiedFromExpenseApplicationId}`}
            >{`№${props.expense.copiedFromExpenseApplicationId}`}</NavLink>
          </span>
        </div>
      ) : null}
      <div className="request-expenses-block pic">
        <SvgIcon
          className={
            expenseStylesMap[
              props.expense.expenseType.groupCode as EXPENSE_TYPE
            ]
          }
          href={
            expenseHrefMap[props.expense.expenseType.groupCode as EXPENSE_TYPE]
          }
        />
      </div>
      <div className="request-expenses-block info">
        <div className="request-expenses-item-text">
          {props.expense.expenseType.name && fieldPermissions.type
            ? props.expense.expenseType.name[i18next.language as "en" | "ru"]
            : ""}
          &nbsp;
          {actAccess
            ? advanceReportApplication.additionalDocuments
                .filter((document) =>
                  document.expenses
                    .map((expens: any) => expens.id)
                    .includes(props.expense.id)
                )
                .map((item) =>
                  documentsTranslation(item.documentNumber, item.documentType)
                )
            : null}
        </div>
        <If condition={props.isCanShowAll && props.index == 1}>
          <div className={"expense-exchanged"} onClick={props.clickHandler}>
            <div className={"transition " + (props.isShowAll ? "rotate" : "")}>
              <SvgIcon
                className="icon icon-table-arrow"
                href="#svg_icon_table-arrow"
              />
            </div>
          </div>
        </If>
        <div
          className="request-item-name"
          style={{ display: fieldPermissions.description ? undefined : "none" }}
          ref={nameRef}
        >
          <SvgIcon
            className={
              expenseStylesMap[
                props.expense.expenseType.groupCode as EXPENSE_TYPE
              ]
            }
            href={
              expenseHrefMap[
                props.expense.expenseType.groupCode as EXPENSE_TYPE
              ]
            }
          />
          <TextCutter
            text={props.expense.description}
            parentWidth={width}
            options={[
              {
                maxLength: 50,
                minWidth: 401,
                maxWidth: width > 700 ? width + 1 : 700,
                symbolSize: 12,
              },
              { maxLength: 20, minWidth: 0, maxWidth: 400, symbolSize: 14 },
            ]}
          />
        </div>
        {isAdvanceReportAttachmentPermission ? (
          <div className="request-expenses-item-file">
            {attachmentsList && attachmentsList.length != 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                }}
              >
                <SvgIcon
                  className={"icon icon-attach"}
                  href={"#svg_icon_attach"}
                />
                {attachmentsList.map((attachFile: any, index: number) => (
                  <ExpenseAttachments
                    width={attachWidth}
                    attachFile={attachFile}
                    isLast={index + 1 === attachmentsList.length}
                  />
                ))}
              </div>
            ) : null}
            {props.expense.showNotAttachmentAlert ? (
              <div style={{ color: "#FF3B30" }}>
                {t("request_detail.application_expenses_item.no_attachment")}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <If condition={!props.isCanShowAll || props.original}>
        <div className="flex-sm-row flex-sm-between">
          <div>
            <If condition={!advanceReportAmountCalculationPermission}>
              <div className="flex flex-column align-items-end">
                <If
                  condition={
                    props.expense.paymentFormId &&
                    props.expense.paymentFormId.name
                  }
                >
                  <div className="conversion-rate w-100 mb-1 text-gray">
                    {t("modals.create_order_modal.payment_form") +
                      ": " +
                      props.expense.paymentFormId.name[i18n.language]}
                  </div>
                </If>
                <If condition={props.expense.billDate}>
                  <div className="conversion-rate w-100 mb-1 text-gray">
                    {new Date(props.expense.billDate).toLocaleDateString(
                      "ru-RU"
                    )}
                  </div>
                </If>
                {fieldPermissions.amountRub && (
                  <div className="request-list-item-cost mb-1">
                    <div
                      className="request-expenses-block"
                      style={{ display: "flex" }}
                    >
                      {fieldPermissions.status && (
                        <div
                          className={`request-list-item-status ${statusExpenseStylesMap[props.expense.status]}`}
                        >
                          {props.index != 1 ? (
                            t(`status.exchanged`)
                          ) : (
                            <>
                              {t(
                                "request_detail.application_expenses_item.status"
                              )}
                              :{" "}
                              {t(
                                `status.${statusExpenseNameMap[props.expense.status]}`
                              )}
                              <span></span>
                            </>
                          )}
                        </div>
                      )}
                      {toLocaleNumberFormatter(props.expense.amountRub)}{" "}
                      {getCurrencieCode("rub")}
                    </div>
                  </div>
                )}
                <div className="conversion-rate text-gray">
                  <If
                    condition={
                      !!advanceReportAmountFullCalculation &&
                      props.expense.amountCurrency &&
                      props.expense.amountCurrency.alfaCode !== "RUB"
                    }
                  >
                    {t("report_detail.application_expense_item.currencyRate", {
                      amount: props.expense.amount,
                      currency: props.expense.amountCurrency.alfaCode,
                      currencyRate: props.expense.amountCurrencyRate,
                    })}
                    <br />
                  </If>
                  {props.expense.advanceCashRequired ||
                  (props.expense.billNumber && props.expense.billDate)
                    ? `(${getCostStatus(props.expense.advanceCashRequired, props.expense.billNumber, props.expense.billDate)})`
                    : ""}
                </div>
              </div>
            </If>
            <If condition={!!advanceReportAmountCalculationPermission}>
              <div className="flex flex-column align-items-end">
                <If
                  condition={
                    props.expense.paymentFormId &&
                    props.expense.paymentFormId.name
                  }
                >
                  <div className="conversion-rate w-100 mb-1 text-gray">
                    {t("modals.create_order_modal.payment_form") +
                      ": " +
                      props.expense.paymentFormId.name[i18n.language]}
                  </div>
                </If>
                <If condition={props.expense.billDate}>
                  <div className="conversion-rate w-100 mb-1 text-gray">
                    {new Date(props.expense.billDate).toLocaleDateString(
                      "ru-RU"
                    )}
                  </div>
                </If>
                {fieldPermissions.amountRub && (
                  <div className="request-list-item-cost mb-1">
                    <div
                      className="request-expenses-block"
                      style={{ display: "flex" }}
                    >
                      {fieldPermissions.status && (
                        <div
                          className={`request-list-item-status ${statusExpenseStylesMap[props.expense.status]}`}
                        >
                          {props.index != 1 ? (
                            t(`status.exchanged`)
                          ) : (
                            <>
                              {t(
                                "request_detail.application_expenses_item.status"
                              )}
                              :{" "}
                              {t(
                                `status.${statusExpenseNameMap[props.expense.status]}`
                              )}
                              <span></span>
                            </>
                          )}
                        </div>
                      )}
                      {toLocaleNumberFormatter(props.expense.approvedAmountRub)}{" "}
                      {getCurrencieCode("rub")}
                    </div>
                  </div>
                )}
                <If
                  condition={
                    !!advanceReportAmountFullCalculation &&
                    props.expense.approvedAmountCurrency &&
                    props.expense.approvedAmountCurrency.alfaCode !== "RUB"
                  }
                >
                  <div className="conversion-rate text-gray">
                    {t(
                      "report_detail.application_expense_item.currencyRate",
                      props.expense.amountCurrency.id ===
                        props.expense.approvedAmountCurrency.id
                        ? {
                            amount: props.expense.approvedAmount,
                            currency:
                              props.expense.approvedAmountCurrency.alfaCode,
                            currencyRate:
                              props.expense.approvedAmountCurrencyRate,
                          }
                        : {
                            amount: props.expense.amount,
                            currency: props.expense.amountCurrency.alfaCode,
                            currencyRate: props.expense.amountCurrencyRate,
                          }
                    )}
                  </div>
                </If>
              </div>
            </If>
          </div>
          <div className="request-expenses-block controls">
            <If
              condition={
                props.expense.properties.includes(
                  ADVANCE_APPLICATION_PROPETIES.EXPENSE_UPDATE
                ) ||
                props.expense.properties.includes(
                  ADVANCE_APPLICATION_PROPETIES.EXPENSE_DELETE
                )
              }
            >
              <If condition={isBookerOccupation()}>
                <SvgIcon
                  className="icon icon-more pointer"
                  href="#svg_icon_more"
                  onClick={togglePopup}
                />
              </If>
              <Else condition={isBookerOccupation()}>
                <SvgIcon
                  className="icon icon-more pointer"
                  href="#svg_icon_more"
                  onClick={togglePopup}
                />
              </Else>
              {isOpenPopup ? (
                <div className="miss-click-handler" onClick={togglePopup} />
              ) : (
                ""
              )}
              <div
                className="request-files-item-dropdown"
                style={{ display: isOpenPopup ? "block" : "none", top: 40 }}
              >
                <a
                  className={`pointer${props.expense.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSE_UPDATE) ? "" : " d-none"}`}
                  onClick={onEdit}
                  style={{ display: "flex", marginBottom: "25px" }}
                >
                  <SvgIcon className="icon icon-edit" href="#svg_icon_edit" />
                  {t("report_detail.edit")}
                </a>
                <a
                  className={`pointer${props.expense.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSE_DELETE) ? "" : " d-none"}`}
                  onClick={onDelete}
                  style={{ display: "flex" }}
                >
                  <SvgIcon
                    className="icon icon-remove"
                    href="#svg_icon_remove"
                  />
                  {t("report_detail.delete")}
                </a>
              </div>
              <If condition={isBookerOccupation()}>
                <div className="controls-dropdown">
                  <a
                    onClick={openEditExpenseModalHandler}
                    className={`pointer${props.expense.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSE_UPDATE) ? "" : " d-none"}`}
                  >
                    <SvgIcon className="icon icon-edit" href="#svg_icon_edit" />
                  </a>
                  <a
                    className={`delete pointer${props.expense.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSE_DELETE) ? "" : " d-none"}`}
                    onClick={openDeleteExpenseModalHandler}
                  >
                    <SvgIcon
                      className="icon icon-remove"
                      href="#svg_icon_remove"
                    />
                  </a>
                </div>
              </If>
              <Else condition={isBookerOccupation()}>
                <div className="controls-dropdown">
                  <a
                    onClick={openEditExpenseModalHandler}
                    className={`pointer${props.expense.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSE_UPDATE) ? "" : " d-none"}`}
                  >
                    <SvgIcon className="icon icon-edit" href="#svg_icon_edit" />
                  </a>
                  <a
                    className={`delete pointer${props.expense.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSE_DELETE) ? "" : " d-none"}`}
                    onClick={openDeleteExpenseModalHandler}
                  >
                    <SvgIcon
                      className="icon icon-remove"
                      href="#svg_icon_remove"
                    />
                  </a>
                </div>
              </Else>
            </If>
          </div>
        </div>
      </If>
    </div>
  ) : null;
};

export default ReportExpenses;
