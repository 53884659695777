import React, { useEffect, useState } from "react";

interface TextCutterProps {
  text: string;
  maxLength?: number;
  parentWidth?: number;
  options?: TextCutterOptions[];
  defaultLength?: number;
}

interface TextCutterOptions {
  minWidth: number;
  maxWidth: number;
  maxLength: number;
  symbolSize: number;
}

const TextCutter: React.FC<TextCutterProps> = (props) => {
  let [length, setLength] = useState(10);
  let defaultLength = props.defaultLength || 20;

  useEffect(() => {
    if (props.options) {
      props.options.forEach((option) => {
        if (
          props.parentWidth &&
          props.parentWidth >= option.minWidth &&
          props.parentWidth < option.maxWidth
        ) {
          setLength(
            Math.floor(
              option.maxLength -
                (option.maxWidth - props.parentWidth) / option.symbolSize,
            ),
          );
        }
      });
    }
  });

  useEffect(() => {
    if (props.maxLength) {
      setLength(props.maxLength);
    } else {
      setLength(defaultLength);
    }
  }, [props.maxLength]);

  const isTextLargerThanMaxLength = () => {
    if (!props.text) {
      return false;
    }
    return props.text.length > length;
  };

  return (
    <span title={isTextLargerThanMaxLength() ? props.text : undefined}>
      {`${isTextLargerThanMaxLength() ? props.text.slice(0, length) + "..." : props.text}`}
    </span>
  );
};

export default TextCutter;
