import React from "react";
import { IconProps } from "../types";

const FingerPrint: React.FC<IconProps> = (props) => {
  const { color, size } = props;

  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5004 1.50053C6.77709 1.50248 5.12493 2.18792 3.90636 3.40649C2.6878 4.62505 2.00235 6.27722 2.0004 8.00053C2.00121 8.625 1.8954 9.24503 1.68754 9.8339C1.66559 9.89583 1.65606 9.96147 1.6595 10.0271C1.66293 10.0927 1.67926 10.157 1.70756 10.2163C1.73585 10.2756 1.77556 10.3287 1.8244 10.3726C1.87324 10.4166 1.93026 10.4505 1.9922 10.4724C2.05415 10.4943 2.1198 10.5037 2.18541 10.5002C2.25101 10.4967 2.31529 10.4804 2.37456 10.452C2.43383 10.4237 2.48693 10.3839 2.53083 10.335C2.57473 10.2862 2.60857 10.2291 2.63041 10.1672C2.87614 9.47126 3.00127 8.73853 3.0004 8.00053C3.0004 6.54183 3.57987 5.14289 4.61132 4.11144C5.64277 3.07999 7.04171 2.50053 8.5004 2.50053C9.95909 2.50053 11.358 3.07999 12.3895 4.11144C13.4209 5.14289 14.0004 6.54183 14.0004 8.00053C14.0006 9.14299 13.8899 10.2828 13.6698 11.4038C13.657 11.4683 13.6571 11.5346 13.67 11.599C13.6829 11.6634 13.7084 11.7246 13.7449 11.7792C13.7815 11.8338 13.8285 11.8806 13.8831 11.917C13.9378 11.9534 13.9991 11.9787 14.0636 11.9914C14.128 12.0041 14.1943 12.004 14.2587 11.991C14.3231 11.9781 14.3844 11.9526 14.4389 11.916C14.4934 11.8794 14.5402 11.8324 14.5766 11.7777C14.613 11.723 14.6382 11.6617 14.6509 11.5972C14.8836 10.4125 15.0006 9.20791 15.0004 8.00053C14.9985 6.27722 14.313 4.62505 13.0944 3.40649C11.8759 2.18792 10.2237 1.50248 8.5004 1.50053Z"
        fill={color}
      />
      <path
        d="M6.31309 5.26804C6.41656 5.1851 6.48284 5.06445 6.49735 4.93263C6.51187 4.80081 6.47342 4.66863 6.39048 4.56516C6.34941 4.51393 6.29865 4.47128 6.2411 4.43967C6.18355 4.40805 6.12033 4.38808 6.05507 4.38089C5.92325 4.36638 5.79107 4.40482 5.6876 4.48777C5.16002 4.90848 4.73428 5.44291 4.44212 6.05116C4.14997 6.65942 3.99895 7.3258 4.00034 8.00059C4.00372 9.15767 3.73662 10.2995 3.22037 11.3351C3.19102 11.3939 3.17354 11.4579 3.16895 11.5235C3.16435 11.589 3.17273 11.6548 3.19361 11.7172C3.21448 11.7795 3.24744 11.8371 3.29059 11.8867C3.33375 11.9362 3.38625 11.9768 3.44511 12.0061C3.50396 12.0353 3.56801 12.0527 3.63358 12.0572C3.69915 12.0617 3.76497 12.0532 3.82726 12.0322C3.88955 12.0112 3.94709 11.9782 3.9966 11.935C4.0461 11.8917 4.08659 11.8391 4.11576 11.7802C4.70114 10.6065 5.00406 9.31219 5.00034 8.00059C4.99929 7.47567 5.1168 6.95729 5.34411 6.48414C5.57142 6.01098 5.90265 5.59528 6.31309 5.26804Z"
        fill={color}
      />
      <path
        d="M8.50034 7.50059C8.36773 7.50059 8.24056 7.55327 8.14679 7.64704C8.05302 7.74081 8.00034 7.86798 8.00034 8.00059C8.00076 9.94844 7.50676 11.8645 6.56461 13.5694C6.53271 13.6268 6.51244 13.6899 6.50496 13.7552C6.49748 13.8205 6.50293 13.8866 6.521 13.9497C6.53908 14.0129 6.56942 14.0718 6.61029 14.1233C6.65116 14.1747 6.70177 14.2176 6.75921 14.2494C6.81666 14.2813 6.87982 14.3015 6.94508 14.3089C7.01035 14.3164 7.07644 14.3109 7.13958 14.2928C7.20272 14.2746 7.26167 14.2443 7.31307 14.2034C7.36447 14.1625 7.4073 14.1118 7.43912 14.0544C8.46347 12.2011 9.00065 10.1181 9.00034 8.00059C9.00033 7.86798 8.94765 7.74081 8.85388 7.64704C8.76012 7.55327 8.63294 7.50059 8.50034 7.50059Z"
        fill={color}
      />
      <path
        d="M8.50037 5.50059C7.83756 5.50135 7.20211 5.76498 6.73344 6.23366C6.26476 6.70233 6.00112 7.33778 6.00037 8.00059C6.00037 8.1332 6.05304 8.26037 6.14681 8.35414C6.24058 8.44791 6.36776 8.50059 6.50037 8.50059C6.63297 8.50059 6.76015 8.44791 6.85392 8.35414C6.94769 8.26037 7.00037 8.1332 7.00037 8.00059C7.00037 7.60276 7.1584 7.22123 7.43971 6.93993C7.72101 6.65862 8.10254 6.50059 8.50037 6.50059C8.89819 6.50059 9.27972 6.65862 9.56103 6.93993C9.84233 7.22123 10.0004 7.60276 10.0004 8.00059C10.0055 9.98858 9.56756 11.9527 8.71844 13.7503C8.69045 13.8097 8.67442 13.874 8.67129 13.9396C8.66815 14.0052 8.67796 14.0707 8.70016 14.1325C8.72236 14.1943 8.75651 14.2511 8.80067 14.2997C8.84483 14.3483 8.89813 14.3878 8.95752 14.4157C9.01691 14.4437 9.08124 14.4598 9.14682 14.4629C9.21241 14.466 9.27797 14.4562 9.33977 14.434C9.40156 14.4118 9.45838 14.3777 9.50697 14.3335C9.55557 14.2894 9.59499 14.2361 9.62299 14.1767C10.5352 12.2458 11.0057 10.136 11.0004 8.00059C10.9996 7.33778 10.736 6.70233 10.2673 6.23366C9.79862 5.76498 9.16317 5.50135 8.50037 5.50059Z"
        fill={color}
      />
      <path
        d="M6.39982 9.51053C6.26988 9.48431 6.13485 9.5107 6.02434 9.58392C5.91384 9.65714 5.8369 9.77121 5.81041 9.9011C5.60785 10.8996 5.24472 11.8587 4.73515 12.7409C4.70229 12.7978 4.68096 12.8606 4.67238 12.9257C4.66379 12.9909 4.66813 13.057 4.68513 13.1205C4.70213 13.1839 4.73147 13.2434 4.77147 13.2955C4.81146 13.3476 4.86133 13.3914 4.91823 13.4242C4.97513 13.457 5.03794 13.4783 5.10307 13.4868C5.1682 13.4954 5.23437 13.491 5.29781 13.474C5.36125 13.4569 5.42071 13.4276 5.4728 13.3875C5.52488 13.3475 5.56857 13.2976 5.60136 13.2407C6.1649 12.2649 6.56646 11.2043 6.79039 10.0999C6.81676 9.96999 6.79042 9.83489 6.71718 9.72435C6.64393 9.61382 6.52978 9.5369 6.39982 9.51053Z"
        fill={color}
      />
      <path
        d="M8.50037 3.50059C8.31252 3.50059 8.12484 3.51221 7.93842 3.53538C7.87327 3.54351 7.81035 3.5644 7.75327 3.59685C7.69619 3.6293 7.64605 3.67268 7.60573 3.7245C7.56541 3.77633 7.5357 3.83559 7.51828 3.8989C7.50086 3.96221 7.49608 4.02833 7.50422 4.09348C7.52065 4.22507 7.58868 4.34474 7.69334 4.42617C7.74517 4.46649 7.80443 4.49621 7.86774 4.51363C7.93105 4.53105 7.99717 4.53582 8.06232 4.52769C8.20764 4.50961 8.35394 4.50056 8.50037 4.50059C9.42831 4.50163 10.3179 4.87071 10.9741 5.52686C11.6302 6.18302 11.9993 7.07265 12.0004 8.00059C12.0004 8.64846 11.9603 9.29571 11.8804 9.93864C11.864 10.0702 11.9006 10.2029 11.982 10.3076C12.0634 10.4123 12.1831 10.4803 12.3147 10.4967C12.4463 10.5131 12.579 10.4766 12.6837 10.3951C12.7883 10.3137 12.8563 10.194 12.8727 10.0624C12.9577 9.37843 13.0004 8.68984 13.0004 8.00059C12.999 6.80753 12.5245 5.66371 11.6809 4.82009C10.8372 3.97647 9.69344 3.50193 8.50037 3.50059Z"
        fill={color}
      />
      <path
        d="M12.121 11.5163C12.0574 11.4999 11.9912 11.4962 11.9262 11.5054C11.8611 11.5146 11.7986 11.5365 11.742 11.5699C11.6855 11.6033 11.6361 11.6475 11.5966 11.7C11.5572 11.7525 11.5285 11.8123 11.5121 11.8759C11.4206 12.2314 11.3151 12.5878 11.1985 12.9352C11.1563 13.0609 11.1657 13.1982 11.2248 13.3169C11.2838 13.4357 11.3876 13.5261 11.5134 13.5683C11.6391 13.6105 11.7764 13.601 11.8951 13.542C12.0139 13.4829 12.1043 13.3791 12.1465 13.2534C12.2707 12.8834 12.3831 12.5038 12.4805 12.1252C12.4969 12.0616 12.5006 11.9954 12.4914 11.9304C12.4822 11.8654 12.4602 11.8028 12.4269 11.7463C12.3935 11.6897 12.3493 11.6403 12.2968 11.6009C12.2443 11.5614 12.1846 11.5327 12.121 11.5163Z"
        fill={color}
      />
    </svg>
  );
};

export default FingerPrint;
