import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CompanyData,
  ManagerInfoContainer,
  ManagerName,
  ManagerSnils,
  ManagerSnilsInput,
  CompanyAdressTextarea,
} from "./CompanyDetailsStyled";
import {
  DataContainer,
  Input,
  Textarea,
  UpperLabel,
} from "../PowerOfAttorneyCommonStyled";
import { BottomLabel, WarningIconTextarea } from "./TrustedPersonDetailsStyled";
import { SvgIcon } from "ui/SvgIcon";

interface CompanyDetailsProps {
  addressValue: string;
  INN: string;
  OGRN: string;
  KPP: string;
  managersName: string;
  managersSnils: string;
  isCompanyDetails: boolean;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = (props) => {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [rowsName, setRowsName] = useState(1);
  const [rowsAddress, setRowsAddress] = useState(1);

  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (screenWidth >= 980 && props.managersName.length < 52) {
      setRowsName(1);
    }
    if (screenWidth > 980 && props.managersName.length > 52) {
      setRowsName(2);
    }
    if (
      screenWidth < 980 &&
      screenWidth > 640 &&
      props.managersName.length < 44
    ) {
      setRowsName(1);
    }
    if (screenWidth <= 640) {
      setRowsName(1);
    }
    if (screenWidth < 486 && screenWidth > 480) {
      setRowsName(2);
    }
    if (screenWidth < 440 && props.managersName.length > 44) {
      setRowsName(2);
    }
  }, [screenWidth, props.managersName]);

  useEffect(() => {
    if (screenWidth >= 450 && props.addressValue.length > 83) {
      setRowsAddress(2);
    }
    if (screenWidth >= 450 && props.addressValue.length < 83) {
      setRowsAddress(1);
    }
    if (screenWidth < 450 && props.addressValue.length > 83) {
      setRowsAddress(3);
    }
    if (screenWidth < 450 && props.addressValue.length < 83) {
      setRowsAddress(2);
    }
  }, [screenWidth, props.addressValue]);

  return (
    <div>
      <UpperLabel>{t("power.registered_address")}</UpperLabel>
      <div style={{ position: "relative", marginBottom: "-4px" }}>
        <CompanyAdressTextarea
          error={props.isCompanyDetails && !props.addressValue ? true : false}
          rows={rowsAddress}
          disabled={true}
          value={props.addressValue}
        ></CompanyAdressTextarea>
        <WarningIconTextarea
          style={{ top: "10px" }}
          error={props.isCompanyDetails && !props.addressValue ? true : false}
        >
          <SvgIcon id={"warningCircle"} />
        </WarningIconTextarea>
      </div>
      {props.isCompanyDetails && !props.addressValue && (
        <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
      )}
      <DataContainer>
        <CompanyData>
          <UpperLabel>{t("power.INN")}</UpperLabel>
          <div style={{ position: "relative" }}>
            <Input
              error={props.isCompanyDetails && !props.INN ? true : false}
              value={props.INN}
              placeholder={""}
              disabled={true}
            />
            <WarningIconTextarea
              style={{ top: "10px" }}
              error={props.isCompanyDetails && !props.INN ? true : false}
            >
              <SvgIcon id={"warningCircle"} />
            </WarningIconTextarea>
          </div>
          {props.isCompanyDetails && !props.INN && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </CompanyData>
        <CompanyData>
          <UpperLabel>{t("power.OGRN")}</UpperLabel>
          <div style={{ position: "relative" }}>
            <Input
              error={props.isCompanyDetails && !props.OGRN ? true : false}
              value={props.OGRN}
              placeholder={""}
              disabled={true}
            />
            <WarningIconTextarea
              style={{ top: "10px" }}
              error={props.isCompanyDetails && !props.OGRN ? true : false}
            >
              <SvgIcon id={"warningCircle"} />
            </WarningIconTextarea>
          </div>
          {props.isCompanyDetails && !props.OGRN && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </CompanyData>
        <CompanyData>
          <UpperLabel>{t("power.KPP")}</UpperLabel>
          <div style={{ position: "relative" }}>
            <Input
              disabled={true}
              error={props.isCompanyDetails && !props.KPP ? true : false}
              value={props.KPP}
              placeholder={""}
            />
            <WarningIconTextarea
              style={{ top: "10px" }}
              error={props.isCompanyDetails && !props.KPP ? true : false}
            >
              <SvgIcon id={"warningCircle"} />
            </WarningIconTextarea>
          </div>
          {props.isCompanyDetails && !props.KPP && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </CompanyData>
      </DataContainer>
      <ManagerInfoContainer>
        <ManagerName>
          <UpperLabel>{t("power.manager_full_name")}</UpperLabel>
          <div style={{ position: "relative", marginBottom: "-4px" }}>
            <Textarea
              style={{ marginBottom: "0" }}
              error={
                props.isCompanyDetails && !props.managersName ? true : false
              }
              rows={rowsName}
              disabled={true}
              value={props.managersName}
            ></Textarea>
            <WarningIconTextarea
              style={{ top: "10px" }}
              error={
                props.isCompanyDetails && !props.managersName ? true : false
              }
            >
              <SvgIcon id={"warningCircle"} />
            </WarningIconTextarea>
          </div>
          {props.isCompanyDetails && !props.managersName && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </ManagerName>
        <ManagerSnils>
          <UpperLabel>{t("power.manager_SNILS")}</UpperLabel>
          <div style={{ position: "relative" }}>
            <ManagerSnilsInput
              error={
                props.isCompanyDetails && !props.managersSnils ? true : false
              }
              disabled={true}
              value={props.managersSnils}
            />
            <WarningIconTextarea
              style={{ top: "10px" }}
              error={
                props.isCompanyDetails && !props.managersSnils ? true : false
              }
            >
              <SvgIcon id={"warningCircle"} />
            </WarningIconTextarea>
          </div>

          {props.isCompanyDetails && !props.managersSnils && (
            <BottomLabel>{t("power.must_be_filled")}</BottomLabel>
          )}
        </ManagerSnils>
      </ManagerInfoContainer>
    </div>
  );
};

export default CompanyDetails;
