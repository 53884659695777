import styled from "styled-components";

const ModalBox = styled.div`
  z-index: 1000;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: auto;
  position: relative;
`;

const ModalClose = styled.div`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 8px;
  display: block;
  width: 24px;
  height: 24px;
  background: url(img/icon-close.svg) no-repeat 0 0;
`;

const ModalTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  padding-top: 48px;
  padding-bottom: 24px;
  padding-left: 28px;
  padding-right: 28px;
`;

const ModalFooter = styled.div`
  padding: 21px 40px 19px;
  justify-content: center;
  display: flex;
`;

const ModalText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  padding-left: 28px;
`;

export { ModalBox, ModalClose, ModalTitle, ModalFooter, ModalText };
