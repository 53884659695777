import React, { useState, useRef, useEffect, Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { formatter } from "app/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setFilterParams, hideFilter, showFilter } from "store/common/actions";
import { FilterParams } from "infrastructure/interfaces";
import InputChoose, {
  InputChooseItem,
} from "app/component/inputchoose/InputChoose";
import EmployeeAutocomplete, {
  EmployeeAutocompleteOptionType,
} from "app/component/autocomplete/EmployeeAutocomplete";
import CityAutocomplete, {
  CityAutocompleteOptionType,
} from "app/component/autocomplete/CityAutocomplete";
import CompanyAutocomplete, {
  CompanyAutocompleteOptionType,
} from "app/component/autocomplete/CompanyAutocomplete";
import Dropdown from "app/component/dropdown/Dropdown";
import { Checkbox } from "ui/Checkbox";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import DatePickerCalendar from "app/component/date-picker/DatePickerCalendar";
import { updateAgreementList } from "store/agreement/actions";
import {
  getCommonFilterParams,
  getCommonFilterState,
} from "../../../store/selectors";

interface StatusFilter {
  text: {
    en: string;
    ru: string;
  };
  value: string;
  code: string;
}

interface AgreementFilterProps extends RouteComponentProps<any> {}

const AgreementFilter: React.FC<AgreementFilterProps> = (props) => {
  let { t, i18n } = useTranslation();

  let [isAdvance, setIsAdvance] = useState(true);
  let [isExpense, setIsExpense] = useState(true);
  let [statuses, setStatuses] = useState([0, 1] as number[]);
  let [numberTip, setNumberTip] = useState("");
  let [options, setOptions] = useState([] as InputChooseItem[]);
  let [startDate, setStartDate] = useState(null as any);
  let [endDate, setEndDate] = useState(null as any);
  let [isOpenToCalendar, setOpenToCalendar] = useState(false);
  let [isOpenBackCalendar, setOpenBackCalendar] = useState(false);
  let [employee, setEmployee] = useState({} as EmployeeAutocompleteOptionType);
  let [company, setCompany] = useState({} as CompanyAutocompleteOptionType);
  let [itemId, setItemId] = useState("");
  let [itemNumber, setItemNumber] = useState("");
  let [width, setWidth] = useState(0);

  let numberTipRef = useRef({} as HTMLInputElement);
  let itemIdRef = useRef({} as HTMLInputElement);
  let itemNumberRef = useRef({} as HTMLInputElement);
  let employeeAutocompleteRef = useRef({} as any);
  let cityRef = useRef({} as any);
  let companyAutocompleteRef = useRef({} as any);

  const dispatch = useDispatch();

  let filterState: boolean = useSelector(getCommonFilterState);
  let filterParams = useSelector(getCommonFilterParams);
  let [isFilterOpen, setFilterOpen] = useState(filterState);
  let [filterTimer, setFilterTimer] = useState(
    null as unknown as NodeJS.Timeout,
  );
  let [isClearingFilter, setClearingFilter] = useState(false);
  let [isInited, setInited] = useState(false);

  const statusFromNavigation = props.match.params.status;

  useEffect(() => {
    setInited(true);
    dispatch(updateAgreementList());
    window.scrollTo(0, 0);
  }, []);

  const update = (...args: any[]) => {
    dispatch(updateAgreementList(...args));
  };

  const resizeEventHander = (e: any) => {
    if (width !== window.innerWidth) {
      resizeHandler();
      setWidth(window.innerWidth);
    }
  };

  const resizeHandler = () => {
    if (window.innerWidth <= 959) {
      dispatch(hideFilter());
    } else {
      dispatch(showFilter());
    }
  };

  useEffect(() => {
    setFilterOpen(filterState);
    window.addEventListener("resize", resizeEventHander);
    return () => {
      window.removeEventListener("resize", resizeEventHander);
    };
  }, [filterState]);

  useEffect(() => {
    if (statusFromNavigation) {
      let status = inHandStatuses.find(
        (element) => element.code === statusFromNavigation,
      );
      status && setStatuses([status.value]);
      return;
    }
    if (props.location.pathname === "/Agreement" && statuses.length === 1) {
      let params: any = {};
      Object.keys(filterParams).forEach((key) => {
        if (key === "Status") {
          params[key] = [];
        } else {
          params[key] = (filterParams as any)[key];
        }
      });
      setStatuses([0, 1]);
    }
  }, [props.match.params]);

  useEffect(() => {
    if (isInited) {
      if (isClearingFilter) {
        dispatch(setFilterParams({ Status: ["2"] }));
        dispatch(updateAgreementList({}));
      } else {
        saveParameters();
      }
      setClearingFilter(false);
    }
  }, [
    options,
    startDate,
    endDate,
    employee,
    company,
    statuses,
    isAdvance,
    isExpense,
  ]);

  useEffect(() => {
    if (isInited && !isClearingFilter) {
      clearTimeout(filterTimer);
      setFilterTimer(
        setTimeout(() => {
          saveParameters();
        }, 2000),
      );
    }
  }, [numberTip, itemNumber]);

  const numberTipChangeHandler = () => {
    setNumberTip(numberTipRef.current.value);
  };
  const itemNumberChangeHandler = () => {
    setItemNumber(itemNumberRef.current.value);
  };

  const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
    if (option.value !== employee.value) {
      setEmployee(option);
    }
  };

  const companyChooseHandler = (option: CompanyAutocompleteOptionType) => {
    if (option.value !== company.value) {
      setCompany(option);
    }
  };

  const forceUpdateFilter = () => {
    clearTimeout(filterTimer);
    saveParameters();
  };

  const onEnterClickHandler = (event: any) => {
    if (event.key === "Enter") {
      forceUpdateFilter();
    }
  };

  const itemNumberBlurHandler = () => {
    const filterItemNumber = filterParams.Id ? filterParams.Id : "";
    if (filterItemNumber != itemNumber) {
      clearTimeout(filterTimer);
      forceUpdateFilter();
    }
  };

  const numberTipBlurHandler = () => {
    const filterNumberExpense = filterParams.Journey
      ? filterParams.Journey
      : "";
    if (filterNumberExpense != numberTip) {
      clearTimeout(filterTimer);
      forceUpdateFilter();
    }
  };

  const clearAllClickHandler = () => {
    setNumberTip("");
    setStartDate("");
    setOptions([]);
    setEndDate("");
    setItemId("");
    setItemNumber("");
    setStatuses([0, 1]);
    setIsAdvance(true);
    setIsExpense(true);
    setClearingFilter(true);
    employeeAutocompleteRef.current.clear();
    companyAutocompleteRef.current.clear();
    window.scrollTo(0, 0);
  };

  const onSelectedToDate = (value: any) => {
    setStartDate(value.startDate);
    setOpenToCalendar(false);
  };

  const onSelectedBackDate = (value: any) => {
    setEndDate(value.startDate);
    setOpenBackCalendar(false);
  };

  const onClickToCalendar = () => {
    setOpenToCalendar(!isOpenToCalendar);
  };

  const onClickBackCalendar = () => {
    setOpenBackCalendar(!isOpenBackCalendar);
  };

  const onCityChooseHandler = (option: CityAutocompleteOptionType) => {
    let cityOptions: InputChooseItem[] = [...options];
    let index = cityOptions.findIndex((city) => city.value === option.value);
    if (index === -1) {
      cityOptions.push(option);
      cityRef.current.clear();
      setOptions(cityOptions);
    }
  };

  const onCityDeleteHandler = (option: InputChooseItem) => {
    let cityOptions: InputChooseItem[] = [...options];
    cityOptions.splice(
      cityOptions.findIndex((city) => city.value === option.value),
      1,
    );
    setOptions(cityOptions);
  };

  const saveParameters = () => {
    let params: FilterParams = {
      Journey: numberTip,
      CityId: [...options.map((option) => option.value)],
      StartOn: startDate,
      EndOn: endDate,
      EmployeeId: employee.value,
      CompanyId: company.value,
      Id: itemNumber,
      WhoIsInPaperWork: statuses,
      // Number: itemNumber,
      Type:
        (isAdvance && isExpense) || (!isAdvance && !isExpense)
          ? ""
          : isAdvance
            ? "AdvanceReportApplication"
            : "ExpenseApplication",
    };
    dispatch(setFilterParams(params));
    update(params, 1, false, 10);
  };

  const onExpenseClickHandler = () => {
    setIsExpense(!isExpense);
  };

  const onAdvanceClickHandler = () => {
    setIsAdvance(!isAdvance);
  };

  const clearStart = () => {
    setStartDate(null);
  };

  const clearEnd = () => {
    setEndDate(null);
  };

  const onStatusClickHandler = (id: number) => {
    let statusesArray = [...statuses];
    let index = statusesArray.findIndex((item) => item === id);
    if (index === -1) {
      statusesArray.push(id);
    } else {
      statusesArray.splice(index, 1);
    }
    setStatuses(statusesArray);
  };

  const inHandStatuses = [
    {
      text: {
        ru: "Новые",
        en: "New",
      },
      value: 0,
      code: "new",
    },
    {
      text: {
        ru: "У меня в работе",
        en: "In my hand",
      },
      value: 1,
      code: "in_my_hand",
    },
    {
      text: {
        ru: "У других в работе",
        en: "In others hand",
      },
      value: 2,
      code: "in_others_hand",
    },
  ];

  return (
    <React.Fragment>
      <div
        className="filters"
        style={isFilterOpen ? { display: "block" } : { display: "none" }}
      >
        <div className="filters-wrap">
          <Dropdown label={t("filters.in_hand")}>
            <Fragment>
              <Checkbox
                key={0}
                name={"checkbox0"}
                id={0}
                label={t("filters.new")}
                checked={statuses.findIndex((status) => status === 0) !== -1}
                onClick={(id: number) => onStatusClickHandler(id)}
              />
              <Checkbox
                key={1}
                name={"checkbox1"}
                id={1}
                label={t("filters.in_my_hand")}
                checked={statuses.findIndex((status) => status === 1) !== -1}
                onClick={(id: number) => onStatusClickHandler(id)}
              />
              <Checkbox
                key={2}
                name={"checkbox2"}
                id={2}
                label={t("filters.in_others_hand")}
                checked={statuses.findIndex((status) => status === 2) !== -1}
                onClick={(id: number) => onStatusClickHandler(id)}
              />
            </Fragment>
          </Dropdown>
          <Dropdown label={t("filters.by_type")}>
            <Fragment>
              <Checkbox
                key={1}
                name={"checkbox1"}
                id={1}
                label={t("filters.expense")}
                checked={isExpense}
                onClick={onExpenseClickHandler}
              />
              <Checkbox
                key={2}
                name={"checkbox2"}
                id={2}
                label={t("filters.advance")}
                checked={isAdvance}
                onClick={onAdvanceClickHandler}
              />
            </Fragment>
          </Dropdown>
          <Dropdown label={t("filters.by_company")}>
            <div className="input-item">
              <div className="input-search">
                <CompanyAutocomplete
                  ref={companyAutocompleteRef}
                  onChoose={companyChooseHandler}
                  placeholder={t("filters.company")}
                />
                <SvgIcon
                  className={"icon icon-search"}
                  href={"#svg_icon-search"}
                />
              </div>
            </div>
          </Dropdown>
          <Dropdown label={t(`filters.by_number`)}>
            <div className="input-item">
              <input
                ref={itemNumberRef}
                className="input"
                type="text"
                value={itemNumber}
                onKeyPress={onEnterClickHandler}
                onBlur={itemNumberBlurHandler}
                onChange={itemNumberChangeHandler}
                placeholder={t(`filters.number`)}
              />
            </div>
          </Dropdown>
          <Dropdown label={t("filters.by_bysiness_trip_number")}>
            <div className="input-item">
              <input
                ref={numberTipRef}
                className="input"
                type="number"
                value={numberTip}
                onKeyPress={onEnterClickHandler}
                onBlur={numberTipBlurHandler}
                onChange={numberTipChangeHandler}
                placeholder={t("filters.business_trip_number")}
              />
            </div>
          </Dropdown>
          <Dropdown label={t("filters.by_city")}>
            <div className="input-item">
              <CityAutocomplete
                ref={cityRef}
                onChoose={onCityChooseHandler}
                placeholder={t("filters.city")}
              />
              <InputChoose options={options} onDelete={onCityDeleteHandler} />
            </div>
          </Dropdown>
          <Dropdown label={t("filters.by_employee")}>
            <div className="input-item">
              <div className="input-search">
                <EmployeeAutocomplete
                  ref={employeeAutocompleteRef}
                  onChoose={employeeChooseHandler}
                  placeholder={t("filters.enter_name")}
                />
                <SvgIcon
                  className={"icon icon-search"}
                  href={"#svg_icon-search"}
                />
              </div>
            </div>
          </Dropdown>
          <Dropdown label={t("filters.by_business_trip_date")}>
            <React.Fragment>
              <div className="input-item">
                {startDate ? (
                  <div className="input-picker-title">
                    {t("filters.one_way")}
                  </div>
                ) : null}
                <div
                  className={`input-picker-wrap ${startDate ? "active" : ""}`}
                >
                  <input
                    value={
                      startDate
                        ? formatter("D MMMM", i18n.language, startDate)
                        : ""
                    }
                    onClick={onClickToCalendar}
                    className="input datepicker pointer"
                    type="text"
                    id="datepicker"
                    placeholder={t("filters.one_way")}
                    readOnly={true}
                  />
                  {startDate ? (
                    <SvgIcon
                      className={"icon icon-close"}
                      href={"#svg_icon_close"}
                      onClick={clearStart}
                    />
                  ) : (
                    <SvgIcon
                      className={"icon icon-calendar"}
                      href={"#svg_icon_calendar"}
                      onClick={onClickToCalendar}
                    />
                  )}
                </div>
                <DatePickerCalendar
                  isShown={isOpenToCalendar}
                  startDate={startDate}
                  onSelected={onSelectedToDate}
                  isCleansed={false}
                  isMultiChoice={false}
                  onClose={() => setOpenToCalendar(false)}
                />
              </div>
              <div className="input-item">
                {endDate ? (
                  <div className="input-picker-title">{t("filters.back")}</div>
                ) : null}
                <div className={`input-picker-wrap ${endDate ? "active" : ""}`}>
                  <input
                    value={
                      endDate ? formatter("D MMMM", i18n.language, endDate) : ""
                    }
                    onClick={onClickBackCalendar}
                    className="input datepicker pointer"
                    type="text"
                    id="datepicker_2"
                    placeholder={t("filters.back")}
                    readOnly={true}
                  />
                  {endDate ? (
                    <SvgIcon
                      className={"icon icon-close"}
                      href={"#svg_icon_close"}
                      onClick={clearEnd}
                    />
                  ) : (
                    <SvgIcon
                      className={"icon icon-calendar"}
                      href={"#svg_icon_calendar"}
                      onClick={onClickBackCalendar}
                    />
                  )}
                </div>
                <DatePickerCalendar
                  isShown={isOpenBackCalendar}
                  startDate={endDate}
                  onSelected={onSelectedBackDate}
                  isCleansed={false}
                  isMultiChoice={false}
                  onClose={() => setOpenBackCalendar(false)}
                />
              </div>
            </React.Fragment>
          </Dropdown>
          <Dropdown>
            <div className={"filter-btns-two m-t-15"}>
              <a
                className="btn btn_gray width-100 pointer"
                onClick={() => clearAllClickHandler()}
              >
                {t("filters.reset")}
              </a>
            </div>
          </Dropdown>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AgreementFilter);
