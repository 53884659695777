export const defaultPercentOptions = [
  { text: "100", value: 100 },
  { text: "90", value: 90 },
  { text: "80", value: 80 },
  { text: "70", value: 70 },
  { text: "60", value: 60 },
  { text: "50", value: 50 },
  { text: "40", value: 40 },
  { text: "30", value: 30 },
  { text: "20", value: 20 },
  { text: "10", value: 10 },
];

export const widgetPercentOptions = [
  { text: "100", value: 100 },
  { text: "99", value: 99 },
  { text: "98", value: 98 },
  { text: "97", value: 97 },
  { text: "96", value: 96 },
  { text: "95", value: 95 },
  { text: "94", value: 94 },
  { text: "93", value: 93 },
  { text: "92", value: 92 },
  { text: "91", value: 91 },
  { text: "90", value: 90 },
  { text: "89", value: 89 },
  { text: "88", value: 88 },
  { text: "87", value: 87 },
  { text: "86", value: 86 },
  { text: "85", value: 85 },
  { text: "84", value: 84 },
  { text: "83", value: 83 },
  { text: "82", value: 82 },
  { text: "81", value: 81 },
  { text: "80", value: 80 },
  { text: "79", value: 79 },
  { text: "78", value: 78 },
  { text: "77", value: 77 },
  { text: "76", value: 76 },
  { text: "75", value: 75 },
  { text: "74", value: 74 },
  { text: "73", value: 73 },
  { text: "72", value: 72 },
  { text: "71", value: 71 },
  { text: "70", value: 70 },
  { text: "69", value: 69 },
  { text: "68", value: 68 },
  { text: "67", value: 67 },
  { text: "66", value: 66 },
  { text: "65", value: 65 },
  { text: "64", value: 64 },
  { text: "63", value: 63 },
  { text: "62", value: 62 },
  { text: "61", value: 61 },
  { text: "60", value: 60 },
  { text: "59", value: 59 },
  { text: "58", value: 58 },
  { text: "57", value: 57 },
  { text: "56", value: 56 },
  { text: "55", value: 55 },
  { text: "54", value: 54 },
  { text: "53", value: 53 },
  { text: "52", value: 52 },
  { text: "51", value: 51 },
  { text: "50", value: 50 },
  { text: "49", value: 49 },
  { text: "48", value: 48 },
  { text: "47", value: 47 },
  { text: "46", value: 46 },
  { text: "45", value: 45 },
  { text: "44", value: 44 },
  { text: "43", value: 43 },
  { text: "42", value: 42 },
  { text: "41", value: 41 },
  { text: "40", value: 40 },
  { text: "39", value: 39 },
  { text: "38", value: 38 },
  { text: "37", value: 37 },
  { text: "36", value: 36 },
  { text: "35", value: 35 },
  { text: "34", value: 34 },
  { text: "33", value: 33 },
  { text: "32", value: 32 },
  { text: "31", value: 31 },
  { text: "30", value: 30 },
  { text: "29", value: 29 },
  { text: "28", value: 28 },
  { text: "27", value: 27 },
  { text: "26", value: 26 },
  { text: "25", value: 25 },
  { text: "24", value: 24 },
  { text: "23", value: 23 },
  { text: "22", value: 22 },
  { text: "21", value: 21 },
  { text: "20", value: 20 },
  { text: "19", value: 19 },
  { text: "18", value: 18 },
  { text: "17", value: 17 },
  { text: "16", value: 16 },
  { text: "15", value: 15 },
  { text: "14", value: 14 },
  { text: "13", value: 13 },
  { text: "12", value: 12 },
  { text: "11", value: 11 },
  { text: "10", value: 10 },
  { text: "9", value: 9 },
  { text: "8", value: 8 },
  { text: "7", value: 7 },
  { text: "6", value: 6 },
  { text: "5", value: 5 },
  { text: "4", value: 4 },
  { text: "3", value: 3 },
  { text: "2", value: 2 },
  { text: "1", value: 1 },
];
