import { BaseMapper } from "./base.mapper";
import { UserDetail } from "infrastructure/interfaces";
import { get } from "lodash";
import { getWithoutNull } from "app/utils";

export class UserDetailMapper extends BaseMapper<UserDetail, any> {
  responseToEntity(response: any): UserDetail {
    return {
      id: get(response, "id", 0),
      name: {
        ru: get(response, "name.ru", ""),
        en: get(response, "name.en", ""),
      },
      email: get(response, "email", ""),
      phoneNumber: get(response, "phone", ""),
      roleId: get(response, "role.id", 0),
      roleName: {
        ru: get(response, "role.name.ru", "0"),
        en: get(response, "role.name.en", "0"),
      },
      roleCode: get(response, "role.code", "0"),
      defaultCostCenter: {
        id: get(response, "costCenter.id", -1),
        name: {
          ru: get(response, "costCenter.name.ru", ""),
          en: get(response, "costCenter.name.en", ""),
        },
      },
      language: {
        id: get(response, "language.id", ""),
        name: get(response, "language.name", ""),
        code: get(response, "language.code", ""),
      },
      employeeViewOptions: get(response, "employeeViewOptions", []).map(
        (item: any) => {
          return {
            id: get(item, "id", 0),
            paramName: get(item, "paramName", ""),
            value: get(item, "value", ""),
          };
        },
      ),
      company: {
        id: get(response, "company.id", 0),
        name: {
          ru: get(response, "company.name.ru", ""),
          en: get(response, "company.name.en", ""),
        },
        alternateKey: get(response, "company.alternateKey", ""),
      },
      occupations: get(response, "occupations", []).map((item: any) => {
        return {
          occupationId: get(item, "id", 0),
          code: get(item, "code", ""),
          name: {
            ru: get(item, "name.ru", ""),
            en: get(item, "name.en", ""),
          },
          company: {
            id: get(item, "company.id", 0),
            name: {
              ru: get(item, "company.name.ru", ""),
              en: get(item, "company.name.en", ""),
            },
          },
          employee: {
            id: get(item, "employee.id", 0),
            name: {
              ru: get(item, "employee.name.ru", ""),
              en: get(item, "employee.name.en", ""),
            },
          },
          roles: getWithoutNull(item, "roles", []).map((role: any) => {
            return {
              id: get(role, "id", 0),
              code: get(role, "code", ""),
              name: {
                ru: get(role, "name.ru"),
                en: get(role, "name.en"),
              },
            };
          }),
          isHierarchy: get(item, "isHierarchy", false),
        };
      }),
    };
  }
}
