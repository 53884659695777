import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PowerOfAttorneyDialog } from "app/pages/powers-of-attorney/dialogs";
import { SvgIcon } from "ui/SvgIcon";
import PowersOfAttorneyListItem from "./EmployeeListItem";
import {
  AddButton,
  ButtomContainer,
  Header,
  ListContainer,
  PersonalAccount,
} from "./EmployeeListStyled";
import { PowerOfAttorneyType } from "infrastructure/interfaces";
import PowerOfAttorneyItem from "../PowerOfAttorneyItem";
import {
  getCommonUserDetail,
  getPowersOfAttorneyList,
  isPowerOfAttorneyEnabled,
} from "store/selectors";
import NoPowersOfAttorneyPage from "../Manager/NoPowersOfAttorneyPage";
import {
  changeStatusToSigning,
  getPowerOfAttorneyDetailed,
  IsPowerOfAttorneyEnabled,
  setListOfPowerOfAttorney,
} from "store/power/actions";

function PowersOfAttorneyList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userDetail = useSelector(getCommonUserDetail);
  const powerOfAttorneyList = useSelector(getPowersOfAttorneyList);
  const isEnabled = useSelector(isPowerOfAttorneyEnabled);

  const [isDetailedModal, setIsDetailedModal] = useState(false);
  const [isModalToCreate, setIsModalToCreate] = useState(false);
  const [isModalToEdit, setIsModalToEdit] = useState(false);

  useEffect(() => {
    dispatch(IsPowerOfAttorneyEnabled(userDetail));
  }, []);

  useEffect(() => {
    dispatch(
      setListOfPowerOfAttorney({
        pageSize: 1000,
        queryParameter: "representativeFullName",
        queryValue: userDetail.name.ru,
      })
    );
  }, [isModalToCreate]);

  const createPowerOfAttorneyHandler = () => {
    setIsModalToCreate(!isModalToCreate);
  };

  const onClickHandler = (
    e: React.ChangeEvent<HTMLDivElement> | React.ChangeEvent<HTMLButtonElement>,
    POW: PowerOfAttorneyType
  ): void => {
    if (e.target.id === "item-button") {
      dispatch(changeStatusToSigning(POW.id));
    } else {
      if (POW.state === "Draft" || POW.state === "Declined") {
        dispatch(getPowerOfAttorneyDetailed(POW.id, setIsModalToEdit));
      } else {
        dispatch(getPowerOfAttorneyDetailed(POW.id, setIsDetailedModal));
      }
    }
  };

  return isEnabled ? (
    <>
      {isDetailedModal && (
        <PowerOfAttorneyItem
          setPowerAttorney={setIsDetailedModal}
          isPowerAttorney={isDetailedModal}
        />
      )}
      {isModalToEdit && (
        <PowerOfAttorneyDialog
          setIsModalToEdit={setIsModalToEdit}
          isModalToEdit={Boolean(isModalToEdit)}
        />
      )}
      <ListContainer>
        <Header>{t("power.my_powers_of_attorney")}</Header>
        <PersonalAccount>
          {t("power.personal_account")}
          {"  "}
          <SvgIcon id={"vectorRight"} size={13.5} color={"#9E9E9E"} />
          {"  "}
          {t("power.my_powers_of_attorney")}
        </PersonalAccount>
        <ButtomContainer>
          <AddButton onClick={createPowerOfAttorneyHandler}>
            <SvgIcon id={"browser"} size={13.3} color={"#FFFFFF"} />
            <div>{t("power.create_power_of_attorney")}</div>
          </AddButton>
        </ButtomContainer>
        {powerOfAttorneyList.length ? (
          powerOfAttorneyList.map((el, index) => (
            <PowersOfAttorneyListItem
              key={index}
              POW={el}
              onClick={onClickHandler}
              isManager={
                el.owner.company.managerEmployee.id &&
                el.owner.company.managerEmployee.id === userDetail.id
              }
            />
          ))
        ) : (
          <NoPowersOfAttorneyPage />
        )}
        {isModalToCreate && (
          <PowerOfAttorneyDialog
            setIsModalToCreate={setIsModalToCreate}
            isModalToCreate={isModalToCreate}
          />
        )}
      </ListContainer>
    </>
  ) : null;
}

export default PowersOfAttorneyList;
