import React from "react";
import { PictureContainer, SubTitle, Title } from "./ManagerListStyled";

function NoPowersOfAttorneyPage() {
  return (
    <div>
      <PictureContainer>
        <img src="/img/no-powers.svg" alt="no powers" />
        <Title>Пока нет ни одной доверенности</Title>
        <SubTitle>Будьте первым — нажмите «Создать доверенность»</SubTitle>
      </PictureContainer>
    </div>
  );
}

export default NoPowersOfAttorneyPage;
