import React from "react";
import { IconProps } from "../types";

const Vector: React.FC<IconProps> = (props) => {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L5 5L9 1"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Vector;
