import React from "react";
import { Route, Switch, withRouter, RouteComponentProps } from "react-router";
//component
import Home from "app/pages/home/Home";
import ZNRList from "app/pages/ZNR/ZNRList";
import ZNRDetail from "app/pages/ZNR/ZNR-Detail/ZNRDetail";
import AODetail from "app/pages/report/AODetail";
import AOList from "app/pages/report/AOList";
import OpenZNR from "app/pages/open/OpenZNR";
import User from "app/component/user/User";
import Journal from "app/pages/journal/Journal";
import AgreementList from "app/pages/agreement/AgreementList";
import { ReportsAll, ReportsRegistries } from "app/pages/reports";
import ManagerList from "app/pages/powers-of-attorney/Manager/ManagerList";
import { PowerOfAttorneyDialog } from "app/pages/powers-of-attorney/dialogs";
import Substitution from "app/pages/substitution/Substitution";

interface BodyProps extends RouteComponentProps<any> {}

const Body: React.FC<BodyProps> = (props) => {
  const mainProfileClass =
    props.location.pathname.startsWith("/User") ||
    props.location.pathname.startsWith("/PowersOfAttorney") ||
    props.location.pathname.startsWith("/Reports") ||
    props.location.pathname.startsWith("/Substitutions");

  return (
    <main className={`main ${mainProfileClass ? "main-profile" : ""}`}>
      <Switch>
        <Route
          path="/ExpenseApplication/open/:tripNumber/:journeyNumber"
          component={OpenZNR}
        />
        <Route path="/ExpenseApplication/Detail/:id" component={ZNRDetail} />
        <Route
          path="/AdvanceReportApplication/Detail/:id"
          component={AODetail}
        />
        <Route path="/ExpenseApplication/:status" component={ZNRList} />
        <Route path="/AdvanceReportApplication/:status" component={AOList} />
        <Route path="/AdvanceReportApplication" component={AOList} />
        <Route path="/ExpenseApplication" component={ZNRList} />
        <Route path="/Agreement/:status" component={AgreementList} />
        <Route path="/Agreement" component={AgreementList} />
        <Route path="/Journal" component={Journal} />
        <Route path="/User" component={User} />
        <Route path="/PowersOfAttorney" component={ManagerList} />
        <Route path="/Substitutions" component={Substitution} />

        <Route
          path="/dialogPowerOfAttorney"
          component={PowerOfAttorneyDialog}
        />
        <Route path="/Reports" component={ReportsRegistries} />
        <Route path="/Reports" component={ReportsAll} />
        <Route path="/" exact component={Home} />
      </Switch>
    </main>
  );
};

export default withRouter(Body);
