import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//component
import Grid from "app/component/grid/Grid";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store/reducers";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { showErrors } from "store/exception/actions";
import { withRouter, RouteComponentProps } from "react-router";
import { nameFormatter, NameFormats, getCurrencieCode } from "app/utils";
import { getCommonPostings } from "../../../store/selectors";
import { deleteBatchesById } from "../../../services/ApiService";
import { DeleteDownloadModal } from "../../component/Downloads/Downloads";

interface JournalDetailDownloadsProps
  extends RouteComponentProps<{ id: string }> {}

const JournalDetailDownloads: React.FC<JournalDetailDownloadsProps> = (
  props,
) => {
  const { t, i18n } = useTranslation();
  let [downloads, setDownloads] = useState([] as any[]);
  const postings = useSelector(getCommonPostings);
  let [deleteDownloadModalOpen, setDeleteDownloadModalOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteHandlerModal = () => {
    setDeleteDownloadModalOpen(true);
  };

  const closeHandler = () => {
    setDeleteDownloadModalOpen(false);
  };

  const downloadsStub = {
    costCenter: "RU8889478",
    employeeName: "Константинопольский К.К",
    businessTargetName: "Сервисное обслуживание клиентов",
    textOperation:
      "Request for Travel / Заявка на командировку, 04.10.2018 - 06.10.2018, Almaty / Алматы",
    ledgerAccount: "000430434",
    typeOperation: "Дебет",
    amountMST: "11 558,00",
  };

  useEffect(() => {
    setDownloads(
      postings.transactions.map((item) => {
        return {
          costCenter: item.costCenterName[i18n.language as "ru"],
          employeeName: nameFormatter(
            item.employeeName,
            NameFormats.LASTNAME_WITH_FULL_INITIALS,
            i18n.language,
          ), // item.employeeName[i18n.language as 'ru'],
          businessTargetName: item.businessTargetName[i18n.language as "ru"],
          textOperation: item.textOperation,
          ledgerAccount: item.ledgerAccount,
          typeOperation: `${t("transaction.postingKey." + item.postingKey)}(${item.postingKey})`,
          amountMST: item.amountRub,
        };
      }),
    );
  }, [postings, i18n.language]);

  const downloadHeader = [
    {
      headerText: t("downloads.cost_center"),
      key: "costCenter",
    },
    {
      headerText: t("downloads.employee"),
      key: `employeeName`,
    },
    {
      headerText: t("downloads.business_target"),
      key: `businessTargetName`,
    },
    {
      headerText: t("downloads.text_operation"),
      key: `textOperation`,
    },
    {
      headerText: t("downloads.ledger_account"),
      key: `ledgerAccount`,
    },
    {
      headerText: t("downloads.type_transaction"),
      key: `typeOperation`,
    },
    {
      headerText: t("downloads.amount_rub", {
        currency: getCurrencieCode("rub"),
      }),
      key: `amountMST`,
    },
  ];

  const deleteHandler = async () => {
    dispatch(loaderLock());
    let response = await deleteBatchesById(props.match.params.id);
    if (response.headers.success) {
      props.history.push("/Journal/Downloads");
    } else {
      dispatch(
        showErrors({ code: "delete_downland", message: "Что-то пошло не так" }),
      );
    }
    dispatch(loaderUnlock());
  };

  return (
    <div className="request">
      <div className="request-expenses request-expenses-bookkeeper">
        <div className="request-expenses-wrap">
          <div className="request-bookkeeper-table-wrap">
            <Grid
              options={{
                downloadsTable: true,
                headers: downloadHeader,
              }}
              data={downloads}
            />
          </div>
        </div>
      </div>
      <div className="request-bottom-controls">
        <div className="tx-center">
          <a className="btn btn_red" onClick={deleteHandlerModal}>
            {t("downloads.filter.deleteDownload")}
          </a>
        </div>
      </div>
      <DeleteDownloadModal
        isOpen={deleteDownloadModalOpen}
        onClose={closeHandler}
        onSubmit={deleteHandler}
      />
    </div>
  );
};

export default withRouter(JournalDetailDownloads);
