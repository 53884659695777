import React from "react";
import ModalContainer from "ui/Modal/ModalContainer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  saveAndSendForSigning,
  saveDraft,
  updateAndChangeStatus,
  updateAndSave,
} from "store/power/actions";
import { getPowerOfAttorneyDetail } from "store/selectors";

interface DuplicateProps {
  setModalDuplicate?: (a: {
    status: boolean;
    action: string;
    id: number;
  }) => void;
  modaldDuplicate: { status: boolean; action: string; id: number };
  data: {};
  setIsModalToCreate?: (a: boolean) => void;
  isManager: boolean;
  setCert: (a: boolean) => void;
  setPowerOfAttorneyId: (a: number) => void;
  setSigningBlobFileId: (a: number) => void;
  setIsModalToEdit?: (a: boolean) => void;
}

const Modal = styled.div`
  z-index: 10001;
  background: #ffffff;
  width: 424px;
  position: relative;
  width: 424px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 21px;
`;
const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.017em;
  color: #0a0a0a;
  margin: 24px 21px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonBack = styled.button`
  align-items: center;
  width: 102px;
  padding: 6px 16px;
  height: 32px;
  background: none;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #5d6c74;
  color: #5d6c74;
  font-family: "HarmoniaSansProCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-right: 4px;
  &:hover {
    background: rgb(223, 225, 227);
  }
`;
const ButtonDecline = styled.button`
  align-items: center;
  padding: 6px 16px;
  width: 102px;
  height: 32px;
  background: #4cd964;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-family: "HarmoniaSansProCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-left: 4px;
  &:hover {
    background: rgb(63, 181, 83);
  }
  &:disabled {
    background-color: #ededed;
    color: #9e9e9e;
    cursor: not-allowed;
  }
`;

const Duplicate: React.FC<DuplicateProps> = ({
  setModalDuplicate,
  modaldDuplicate,
  data,
  setIsModalToCreate,
  isManager,
  setCert,
  setPowerOfAttorneyId,
  setSigningBlobFileId,
  setIsModalToEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const POW = useSelector(getPowerOfAttorneyDetail);

  const closeHandler = () => {
    if (setModalDuplicate) {
      setModalDuplicate({ status: false, action: "", id: 0 });
    }
  };

  const confirmHandler = () => {
    // DUPLICATE & DRAFT
    if (
      modaldDuplicate.action === "Draft" &&
      setModalDuplicate &&
      setIsModalToCreate
    ) {
      dispatch(saveDraft(data, isManager, setIsModalToCreate));
      setModalDuplicate({ status: false, action: "", id: 0 });
    }
    // DUPLICATE & DRAFT & SIGNING REQUIRES
    if (
      modaldDuplicate.action === "SigningRequires" &&
      setModalDuplicate &&
      setIsModalToCreate
    ) {
      dispatch(
        saveAndSendForSigning(
          data,
          setPowerOfAttorneyId,
          setSigningBlobFileId,
          isManager,
          setIsModalToCreate,
          setCert,
        ),
      );
      setModalDuplicate({ status: false, action: "", id: 0 });
    }
    // UPDATE & DUPLICATE & DRAFT
    if (
      modaldDuplicate.action === "Draft" &&
      setModalDuplicate &&
      setIsModalToEdit
    ) {
      dispatch(updateAndSave(POW, data, isManager, setIsModalToEdit));
      setModalDuplicate({ status: false, action: "", id: 0 });
    }
    // UPDATE & CHECK & DRAFT & SIGNING REQUIRES
    if (
      modaldDuplicate.action === "SigningRequires" &&
      setModalDuplicate &&
      setIsModalToEdit
    ) {
      dispatch(updateAndChangeStatus(POW, data, setIsModalToEdit));
      setModalDuplicate({ status: false, action: "", id: 0 });
    }
  };

  return (
    <div>
      <ModalContainer style={{ zIndex: 1001 }} isOpen={modaldDuplicate.status}>
        <Modal>
          <Title>
            {t("power.message.duplicate", { number: modaldDuplicate.id })}
          </Title>
          <ButtonContainer>
            <ButtonBack onClick={() => closeHandler()}>
              {t("power.message.no")}
            </ButtonBack>
            <ButtonDecline onClick={() => confirmHandler()}>
              {t("power.message.yes")}
            </ButtonDecline>
          </ButtonContainer>
        </Modal>
      </ModalContainer>
    </div>
  );
};

export default Duplicate;
