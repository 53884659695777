import { Certificate } from "crypto";
import { Action } from "redux";

export type Certificates = Certificate & {
  checked?: boolean;
  thumbprint: string;
  subjectKeyIdentifier?: string;
  authorityKeyIdentifier?: string;
};

export const CERTIFICATES = {
  SET_CLIENT_MY: "CERTIFICATES/SET_CLIENT_MY", // пользовательские
  SET_CLIENT_ROOT: "CERTIFICATES/SET_CLIENT_ROOT", // корневые
  SET_CLIENT_CA: "CERTIFICATES/SET_CLIENT_CA", // промежуточные
  SET_API_ROOT: "CERTIFICATES/SET_API_ROOT", // корневые из API
};

export interface SetClientMyCertificates extends Action {
  type: typeof CERTIFICATES.SET_CLIENT_MY;
  payload: { ClientMyCertificates: Certificates[] };
}

export interface SetClientRootCertificates extends Action {
  type: typeof CERTIFICATES.SET_CLIENT_ROOT;
  payload: { ClientRootCertificates: Certificates[] };
}

export interface SetClientCaCertificates extends Action {
  type: typeof CERTIFICATES.SET_CLIENT_CA;
  payload: { ClientCaCertificates: Certificates[] };
}

export interface SetApiRootCertificates extends Action {
  type: typeof CERTIFICATES.SET_API_ROOT;
  payload: { ApiRootCertificates: Certificates[] };
}

export type CertificatesActions =
  | SetClientMyCertificates
  | SetClientRootCertificates
  | SetClientCaCertificates
  | SetApiRootCertificates;
