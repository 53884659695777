import styled from "styled-components";

const BoxModalCert = styled.div`
  background: #fff;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  width: 720px;
  position: relative;
  z-index: 1000;
  min-height: 10%;
  @media screen and (min-width: 0px) and (max-width: 760px) {
    width: 80vw;
    min-height: 10%;
    margin: 0 auto;
  }
`;
const ModalTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  padding-top: 48px;
  padding-bottom: 24px;
  padding-left: 28px;
  padding-right: 28px;
  letter-spacing: -0.021em;
  text-align: left;
  @media screen and (min-width: 0px) and (max-width: 760px) {
    text-align: center;
  }
`;

const ModalClose = styled.div`
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 28px;
  display: block;
  width: 24px;
  height: 24px;
  background: url(img/icon-close.svg) no-repeat 0 0;
`;

const StyledDocument = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: 266px;
  text-align: left;
`;

const TextBold = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 266px;
  text-align: left;
`;

const Cost = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CostWarp = styled.div`
  display: flex;
`;

export {
  Text,
  TextBold,
  Cost,
  StyledDocument,
  CostWarp,
  ButtonWrapper,
  BoxModalCert,
  ModalTitle,
  ModalClose,
};
