import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportHeaderProps } from "./types";
import {
  StyledReportHeaderContainer,
  StyledReportHeaderRow,
  StyledReportHeaderInfo,
  StyledReportUser,
  StyledReportText,
  StyledLine,
} from "./SyledReportHeader";
import { formatter, NameFormats, nameFormatter } from "app/utils";
import { ReportMenu } from "ui/ReportMenu";
import {
  statusReportNameMap,
  statusReportStylesMap,
} from "infrastructure/enum/status-report.enum";
import { ReportStatus } from "ui/ReportStatus";
import { SvgIcon } from "ui/SvgIcon";
import EmployeeInformationModal from "app/component/modal/EmployeeInformationModal";

const ReportHeader: React.FC<ReportHeaderProps> = (props) => {
  let [isOpenEmployeeInfo, setOpenEmployeeInfo] = useState(false);
  let [isMenuOpen, setMenuOpen] = useState(false);
  const {
    id,
    advanceReportApplication,
    fieldAccess,
    linkClickHandler,
    history,
  } = props;
  const { t, i18n } = useTranslation();

  const toggleEmployeeInfo = () => {
    setOpenEmployeeInfo(!isOpenEmployeeInfo);
    if (isMenuOpen) togglePopup();
  };

  const togglePopup = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <StyledReportHeaderContainer>
        <StyledReportHeaderRow>
          <StyledReportHeaderInfo>
            <div>
              <span className={`${fieldAccess.type ? "" : "d-none"}`}>
                {t("report_detail.report_type")}:&nbsp;
                {
                  advanceReportApplication.applicationType.name[
                    i18n.language as "en" | "ru"
                  ]
                }
              </span>
              &nbsp;({t("report_detail.created")}&nbsp;
              {advanceReportApplication.expenseApplication.id ? (
                <b
                  className={"pointer"}
                  onClick={() =>
                    linkClickHandler(
                      advanceReportApplication.expenseApplication.id,
                    )
                  }
                >
                  &nbsp;
                  {t("report_detail.by_request")}{" "}
                  {advanceReportApplication.expenseApplication.id}
                </b>
              ) : (
                ""
              )}
              :&nbsp;
              {nameFormatter(
                advanceReportApplication.createdByEmployee.name,
                NameFormats.LASTNAME_WITH_FULL_INITIALS,
                i18n.language,
              )}
              &nbsp;
              {formatter(
                "DD.MM.YYYY",
                i18n.language,
                advanceReportApplication.createdAt,
              )}
              {advanceReportApplication.postingDate
                ? `, 
                                \n${t("report_detail.posted")}: ${formatter("DD.MM.YYYY", i18n.language, advanceReportApplication.postingDate)}`
                : ""}
              )
            </div>
          </StyledReportHeaderInfo>
          <ReportMenu
            id={id}
            history={history}
            advanceReportApplication={advanceReportApplication}
            fieldAccess={fieldAccess}
          />
        </StyledReportHeaderRow>
        <StyledReportHeaderRow>
          <StyledReportUser>
            <div className="pointer" onClick={toggleEmployeeInfo}>
              <SvgIcon id={"infoCircle"} color={"#9E9E9E"} size={20} />
            </div>
            {nameFormatter(
              advanceReportApplication.assigneeEmployee.name,
              NameFormats.NAME_WITH_LASTNAME,
              i18n.language,
            )}
            <StyledReportText>
              {advanceReportApplication.assigneeEmployee.phoneNumber} |{" "}
              <a
                href={`mailto:${advanceReportApplication.assigneeEmployee.email}`}
              >
                {" "}
                {advanceReportApplication.assigneeEmployee.email}
              </a>
            </StyledReportText>
          </StyledReportUser>
          <ReportStatus
            advanceReportApplication={advanceReportApplication}
            text={t(
              `report_status.${statusReportNameMap[advanceReportApplication.status]}`,
            )}
            color={statusReportStylesMap[advanceReportApplication.status]}
          />
        </StyledReportHeaderRow>
        <StyledLine />
      </StyledReportHeaderContainer>
      <EmployeeInformationModal
        isOpen={isOpenEmployeeInfo}
        onClose={toggleEmployeeInfo}
        id={id}
        isAdvance={true}
        isZNR={false}
      />
    </>
  );
};

export default ReportHeader;
