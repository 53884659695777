import React from "react";
import Agreement from "app/component/agreement/Agreement";

interface ZNRAgreementProps {
  id: number;
}

const ZNRAgreement: React.FC<ZNRAgreementProps> = (props) => {
  return <Agreement id={props.id} />;
};

export default ZNRAgreement;
