import React from "react";
import { IconProps } from "../types";

const Fragment: React.FC<IconProps> = (props) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
    >
      <rect width="40" height="40" rx="4" fill="#5D6C74" />
      <path
        d="M15.7099 14.2899C15.617 14.1962 15.5064 14.1218 15.3845 14.071C15.2627 14.0203 15.132 13.9941 14.9999 13.9941C14.8679 13.9941 14.7372 14.0203 14.6154 14.071C14.4935 14.1218 14.3829 14.1962 14.2899 14.2899L9.28994 19.2899C9.19621 19.3829 9.12182 19.4935 9.07105 19.6154C9.02028 19.7372 8.99414 19.8679 8.99414 19.9999C8.99414 20.132 9.02028 20.2627 9.07105 20.3845C9.12182 20.5064 9.19621 20.617 9.28994 20.7099L14.2899 25.7099C14.3829 25.8037 14.4935 25.8781 14.6154 25.9288C14.7372 25.9796 14.8679 26.0057 14.9999 26.0057C15.132 26.0057 15.2627 25.9796 15.3845 25.9288C15.5064 25.8781 15.617 25.8037 15.7099 25.7099C15.8037 25.617 15.8781 25.5064 15.9288 25.3845C15.9796 25.2627 16.0057 25.132 16.0057 24.9999C16.0057 24.8679 15.9796 24.7372 15.9288 24.6154C15.8781 24.4935 15.8037 24.3829 15.7099 24.2899L11.4099 19.9999L15.7099 15.7099C15.8037 15.617 15.8781 15.5064 15.9288 15.3845C15.9796 15.2627 16.0057 15.132 16.0057 14.9999C16.0057 14.8679 15.9796 14.7372 15.9288 14.6154C15.8781 14.4935 15.8037 14.3829 15.7099 14.2899ZM30.7099 19.2899L25.7099 14.2899C25.6167 14.1967 25.506 14.1227 25.3842 14.0723C25.2624 14.0218 25.1318 13.9958 24.9999 13.9958C24.7336 13.9958 24.4782 14.1016 24.2899 14.2899C24.1967 14.3832 24.1227 14.4939 24.0723 14.6157C24.0218 14.7375 23.9958 14.8681 23.9958 14.9999C23.9958 15.2662 24.1016 15.5216 24.2899 15.7099L28.5899 19.9999L24.2899 24.2899C24.1962 24.3829 24.1218 24.4935 24.071 24.6154C24.0203 24.7372 23.9941 24.8679 23.9941 24.9999C23.9941 25.132 24.0203 25.2627 24.071 25.3845C24.1218 25.5064 24.1962 25.617 24.2899 25.7099C24.3829 25.8037 24.4935 25.8781 24.6154 25.9288C24.7372 25.9796 24.8679 26.0057 24.9999 26.0057C25.132 26.0057 25.2627 25.9796 25.3845 25.9288C25.5064 25.8781 25.617 25.8037 25.7099 25.7099L30.7099 20.7099C30.8037 20.617 30.8781 20.5064 30.9288 20.3845C30.9796 20.2627 31.0057 20.132 31.0057 19.9999C31.0057 19.8679 30.9796 19.7372 30.9288 19.6154C30.8781 19.4935 30.8037 19.3829 30.7099 19.2899Z"
        fill="white"
      />
      <line
        x1="18.4019"
        y1="24.7185"
        x2="21.7185"
        y2="15.5981"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Fragment;
