import React from "react";
import { useTranslation } from "react-i18next";
import SvgIcons from "app/component/svg-icon/SvgIcon";
import { Button } from "ui/Button";
import {
  Title,
  AuthoritiesContainer,
  ChoosenAuthorities,
  ChoosenAuthority,
  ChoosenAuthorityInput,
  ChoosenAuthoritySpan,
  AuthoritiesToChoose,
  AuthoritiesToChooseOptions,
  Popup,
  PopupList,
  PopupListItem,
} from "./AuthoritiesStyled";
import { UpperLabel } from "../PowerOfAttorneyCommonStyled";
import { PowerOfAttorneyAuthorityItem } from "infrastructure/interfaces";

interface AuthoritiesProps {
  authorityChosen: PowerOfAttorneyAuthorityItem[];
  setAuthorityChosen: (
    prev:
      | PowerOfAttorneyAuthorityItem[]
      | ((
          prev: PowerOfAttorneyAuthorityItem[],
        ) => PowerOfAttorneyAuthorityItem[]),
  ) => void;
  setAuthorities: (
    prev:
      | PowerOfAttorneyAuthorityItem[]
      | ((
          prev: PowerOfAttorneyAuthorityItem[],
        ) => PowerOfAttorneyAuthorityItem[]),
  ) => void;
  setOpenDropDown: (a: boolean) => void;
  isOpenDropDown: boolean;
  authorities: PowerOfAttorneyAuthorityItem[];
}

const Authorities: React.FC<AuthoritiesProps> = (props) => {
  const { t, i18n } = useTranslation();

  const handleClick = (e: PowerOfAttorneyAuthorityItem) => {
    props.setAuthorityChosen((prev) => [...prev, e]);
    props.setAuthorities((prev) => prev.filter((item) => item !== e));
    props.setOpenDropDown(!props.isOpenDropDown);
  };

  const handleDelete = (e: PowerOfAttorneyAuthorityItem) => {
    props.setAuthorityChosen((prev) => prev.filter((item) => item !== e));
    props.setAuthorities((prev) => [...prev, e]);
  };

  const handleOpen = () => {
    props.setOpenDropDown(!props.isOpenDropDown);
  };

  return (
    <div>
      {props.authorityChosen.length ? (
        <>
          <Title>{t("power.authorities")}</Title>
          <AuthoritiesContainer>
            <ChoosenAuthorities>
              {props.authorityChosen.map((item, i) => (
                <>
                  <UpperLabel>
                    {t("power.authority")} {i + 1}
                  </UpperLabel>
                  <ChoosenAuthority key={i + item.code}>
                    <ChoosenAuthorityInput>
                      {`${item.code}   ${
                        item.name[i18n.language as "en" | "ru"]
                      }`}
                    </ChoosenAuthorityInput>
                    <ChoosenAuthoritySpan>
                      <SvgIcons
                        type="delete"
                        className={"icon icon-remove"}
                        href={"#svg_icon_remove"}
                        onClick={() => handleDelete(item)}
                      />
                    </ChoosenAuthoritySpan>
                  </ChoosenAuthority>
                </>
              ))}
            </ChoosenAuthorities>
          </AuthoritiesContainer>
        </>
      ) : null}
      <div style={{ marginBottom: "34px" }}>
        <AuthoritiesToChoose>
          <Button
            buttonColor="gray"
            buttonText={`+ ${t("power.add_аuthorities")}`}
            buttonWidth="205px"
            onClick={handleOpen}
          />
          <AuthoritiesToChooseOptions>
            {props.isOpenDropDown && (
              <Popup>
                <PopupList>
                  {props.authorities.map((item, i) => {
                    return (
                      <PopupListItem
                        key={i + item.code}
                        onClick={() => handleClick(item)}
                      >
                        {`${item.code}   ${
                          item.name[i18n.language as "en" | "ru"]
                        }`}
                      </PopupListItem>
                    );
                  })}
                </PopupList>
              </Popup>
            )}
          </AuthoritiesToChooseOptions>
        </AuthoritiesToChoose>
      </div>
    </div>
  );
};

export default Authorities;
