import React from "react";
import { Modal } from "ui/Modal";
import { SvgIcon } from "ui/SvgIcon";
import { ModalLoaderProps } from "./types";
import {
  ModalLoaderWrap,
  ModalLoaderHeader,
  SpinnerAnimation,
} from "./StyledModalLoader";

const Notice: React.FC<ModalLoaderProps> = (props) => {
  const { header, text, isOpen } = props;

  return (
    <Modal isOpen={isOpen}>
      <ModalLoaderWrap>
        <SpinnerAnimation>
          <SvgIcon id={"spinner"} />
        </SpinnerAnimation>
        <ModalLoaderHeader>{header}</ModalLoaderHeader>
        {text}
      </ModalLoaderWrap>
    </Modal>
  );
};

export default Notice;
