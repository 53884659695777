import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "ui/Button";
import { Checkbox } from "ui/Checkbox";

type Props = {
  isBreakfastAccess: boolean;
  isLunchAccess: boolean;
  isDinnerAccess: boolean;
  isBreakfast: boolean;
  setBreakfast: (value: boolean) => void;
  isLunch: boolean;
  setLunch: (value: boolean) => void;
  isDinner: boolean;
  setDinner: (value: boolean) => void;
  onChangeForm: (newDateArray: Date[], isDelete: boolean) => void;
  selectedDays?: Date[];
  toggleDatePicker: () => void;
  setSelectedDays: (value: Date[]) => void;
  breakfastLabel: string | null;
  dinnerLabel: string | null;
  lunchLabel: string | null;
};

const RepresentationDayMeals: FC<Props> = ({
  breakfastLabel,
  dinnerLabel,
  lunchLabel,
  isBreakfastAccess,
  isLunchAccess,
  isDinnerAccess,
  isBreakfast,
  setBreakfast,
  isLunch,
  setLunch,
  isDinner,
  setDinner,
  setSelectedDays,
  onChangeForm,
  selectedDays,
  toggleDatePicker,
}) => {
  const { t } = useTranslation();
  const allowMeals = isBreakfastAccess || isLunchAccess || isDinnerAccess;
  const resetForm = () => {
    setBreakfast(false);
    setLunch(false);
    setDinner(false);
    setSelectedDays([]);
  };
  return (
    <div>
      <div
        className="d-flex justify-content-end position-absolute"
        style={{
          zIndex: 1000,
          height: "366px",
          width: "680px",
          padding: "20px",
          top: "7px",
          left: "-3px",
          background: "#E0E0E0",
        }}
      >
        <div className="d-flex flex-column ">
          {isBreakfastAccess && (
            <Checkbox
              name={"isBreakfast"}
              id={0}
              label={
                breakfastLabel ||
                t("create_expense.daily_allowance.representation_day_breakfast")
              }
              checked={isBreakfast}
              onClick={() => setBreakfast(!isBreakfast)}
            />
          )}
          {isLunchAccess && (
            <Checkbox
              name={"isLunch"}
              id={1}
              label={
                lunchLabel ||
                t("create_expense.daily_allowance.representation_day_lunch")
              }
              checked={isLunch}
              onClick={() => setLunch(!isLunch)}
            />
          )}
          {isDinnerAccess && (
            <Checkbox
              name={"isDinner"}
              id={2}
              label={
                dinnerLabel ||
                t("create_expense.daily_allowance.representation_day_dinner")
              }
              checked={isDinner}
              onClick={() => setDinner(!isDinner)}
            />
          )}
        </div>
      </div>
      {allowMeals && (
        <div
          className="position-absolute d-flex justify-content-between"
          style={{
            top: "300px",
            zIndex: 10000,
            padding: "15px 0",
            width: "460px",
            background: "white",
          }}
        >
          <Button
            big
            buttonWidth="220px"
            onClick={resetForm}
            buttonText={t("calculations.reset_all").toLowerCase()}
          ></Button>
          <Button
            big
            buttonWidth="220px"
            buttonColor="green"
            buttonText={t("request_detail.personal_travel.add")}
            onClick={() => {
              selectedDays && onChangeForm(selectedDays, false);
              toggleDatePicker();
            }}
            disabled={
              (!isBreakfast && !isLunch && !isDinner) ||
              (selectedDays && !selectedDays.length)
            }
          ></Button>
        </div>
      )}
    </div>
  );
};

export default RepresentationDayMeals;
