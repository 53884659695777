import { ADVANCE_APPLICATION_PROPETIES } from "infrastructure/enum/object-properties.enum";
import { IAO } from "infrastructure/interfaces";

export const accessInitialValues = {
  number: false,
  associatedExpenseApplication_Number: false,
  expensesAccounting: false,
  reportExpenses: false,
  type: false,
  orderNumber: false,
  journeyNumber: false,
  businessTripNumber: false,
  businessTarget: false,
  description: false,
  vbsElement: false,
  departureFromCity: false,
  arrivalToCity: false,
  startOn: false,
  endOn: false,
  personalDaysNumber: false,
  advanceCashAmount: false,
  advanceCashAmountReceived: false,
  advanceCashAmountRemainder: false,
  advanceCashAmountOverspend: false,
  totalAmount: false,
  flowHistory: false,
  status: false,
};


/**
 * Checks if the given field has access in the advanceReportApplication properties.
 *
 * @param {keyof typeof ADVANCE_APPLICATION_PROPETIES} field - The field to check for access.
 * @return {boolean} Returns true if the field has access, false otherwise.
 */
export const generateFieldsAccess = (
  advanceReportApplication: IAO,
  setFieldAccess: React.Dispatch<
    React.SetStateAction<typeof accessInitialValues>
  >
) => {
  const hasAccess = (field: keyof typeof ADVANCE_APPLICATION_PROPETIES) => {
    return advanceReportApplication.properties.includes(
      ADVANCE_APPLICATION_PROPETIES[field]
    );
  };

  if (advanceReportApplication && advanceReportApplication.properties) {
    const fieldAccess = {
      number: hasAccess("NUMBER"), 
      associatedExpenseApplication_Number: hasAccess(
        "EXPENSE_APPLICATION_NUMBER"
      ), 
      expensesAccounting: hasAccess("EXPENSES_ACCOUNTING"),
      reportExpenses: hasAccess("REPORT_EXPENSES"),
      type: hasAccess("APPLICATION_TYPE"),
      orderNumber: hasAccess("ORDER_NUMBER"),
      journeyNumber: hasAccess("JOURNEY_NUMBER"), 
      businessTripNumber: hasAccess("BUSINESS_TRIP_NUMBER"), 
      businessTarget: hasAccess("BUSINESS_TARGET"), 
      description: hasAccess("DESCRIPTION"),
      vbsElement: hasAccess("VBS_ELEMENT"), 
      departureFromCity: hasAccess("DEPARTURE_FROM_CITY"),
      arrivalToCity: hasAccess("ARRIVAL_TO_CITY"),
      startOn: hasAccess("START_ON"), 
      endOn: hasAccess("END_ON"), 
      personalDaysNumber: hasAccess("PERSONAL_DAYS_NUMBER"), 
      advanceCashAmount: hasAccess("ADVANCE_CASH_AMOUNT"),
      advanceCashAmountReceived: hasAccess("ADVANCE_CASH_AMOUNT_RECEIVED"),
      advanceCashAmountRemainder: hasAccess("ADVANCE_CASH_AMOUNT_REMAINDER"),
      advanceCashAmountOverspend: hasAccess("ADVANCE_CASH_AMOUNT_OVERSPEND"),
      totalAmount: hasAccess("TOTAL_AMOUNT"), 
      flowHistory: hasAccess("FLOW_HISTORY"), 
      status: true, 
    };
    setFieldAccess(fieldAccess);
    return;
  }
};
