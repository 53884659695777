import React, { Fragment } from "react";

interface ElseProps {
  condition?: boolean;
}

const If: React.FC<ElseProps> = (props) => {
  if (props.condition) {
    return null;
  } else {
    return <Fragment>{props.children}</Fragment>;
  }
};

export default If;
