import React from "react";
import { IconProps } from "../types";

const Browser: React.FC<IconProps> = (props) => {
  const { color, size } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M11.6668 0.333008H5.00016C4.46973 0.333008 3.96102 0.543722 3.58595 0.918794C3.21088 1.29387 3.00016 1.80257 3.00016 2.33301V2.99967H2.3335C1.80306 2.99967 1.29436 3.21039 0.919283 3.58546C0.54421 3.96053 0.333496 4.46924 0.333496 4.99967V11.6663C0.333496 12.1968 0.54421 12.7055 0.919283 13.0806C1.29436 13.4556 1.80306 13.6663 2.3335 13.6663H9.00016C9.5306 13.6663 10.0393 13.4556 10.4144 13.0806C10.7894 12.7055 11.0002 12.1968 11.0002 11.6663V10.9997H11.6668C12.1973 10.9997 12.706 10.789 13.081 10.4139C13.4561 10.0388 13.6668 9.53011 13.6668 8.99967V2.33301C13.6668 1.80257 13.4561 1.29387 13.081 0.918794C12.706 0.543722 12.1973 0.333008 11.6668 0.333008ZM9.66683 11.6663C9.66683 11.8432 9.59659 12.0127 9.47157 12.1377C9.34654 12.2628 9.17697 12.333 9.00016 12.333H2.3335C2.15669 12.333 1.98712 12.2628 1.86209 12.1377C1.73707 12.0127 1.66683 11.8432 1.66683 11.6663V6.99967H9.66683V11.6663ZM9.66683 5.66634H1.66683V4.99967C1.66683 4.82286 1.73707 4.65329 1.86209 4.52827C1.98712 4.40325 2.15669 4.33301 2.3335 4.33301H9.00016C9.17697 4.33301 9.34654 4.40325 9.47157 4.52827C9.59659 4.65329 9.66683 4.82286 9.66683 4.99967V5.66634ZM12.3335 8.99967C12.3335 9.17649 12.2633 9.34606 12.1382 9.47108C12.0132 9.5961 11.8436 9.66634 11.6668 9.66634H11.0002V4.99967C10.9984 4.77225 10.9578 4.54679 10.8802 4.33301H12.3335V8.99967ZM12.3335 2.99967H4.3335V2.33301C4.3335 2.1562 4.40373 1.98663 4.52876 1.8616C4.65378 1.73658 4.82335 1.66634 5.00016 1.66634H11.6668C11.8436 1.66634 12.0132 1.73658 12.1382 1.8616C12.2633 1.98663 12.3335 2.1562 12.3335 2.33301V2.99967Z"
        fill={color}
      />
    </svg>
  );
};

export default Browser;
