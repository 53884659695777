import ModalContainer from "app/component/modal/ModalContainer";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

interface WizardProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  onNext: () => void;
  currentStep: number;
  stepsCount: number;
  title: string;
}

const Wizard: React.FC<WizardProps> = (props) => {
  const { t } = useTranslation();

  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  const nextClickHandler = () => {
    props.onNext();
  };

  const closeHandler = () => {
    props.onClose();
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return (
    <ModalContainer
      onEnter={nextClickHandler}
      isOpen={props.isOpen}
      destroy={true}
      highestModal={isHighestModal}
      overlayClick={closeHandler}
      id={-2}
    >
      <div
        className="box-modal modal-new"
        id={props.currentStep === props.stepsCount ? "modal-new-5" : undefined}
      >
        <div
          className="box-modal_close arcticmodal-close"
          onClick={closeHandler}
        />
        <div className="box-modal-title">{props.title}</div>
        {props.children}
        <div className="box-modal-footer">
          <div className="tx-center">
            <a
              className="arcticmodal-close link-skip pointer"
              onClick={closeHandler}
            >
              {t("on_board.skip")}
            </a>
            <div className="modal-new-counter">{`${props.currentStep + 1} / ${props.stepsCount + 1}`}</div>
            {props.currentStep === props.stepsCount ? (
              <a
                className="btn btn_black pointer"
                onClick={closeHandler}
                tabIndex={isTabIndexDisabled ? -1 : 0}
              >
                Ясно
              </a>
            ) : (
              <a
                className={"pointer"}
                onClick={nextClickHandler}
                tabIndex={isTabIndexDisabled ? -1 : 0}
              >
                {t("on_board.still")}
                <SvgIcon
                  className={"icon icon-arrow-right"}
                  href={"#svg_icon_arrow_right"}
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default Wizard;
