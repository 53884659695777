import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import FileSaver from "file-saver";
import { isEmpty } from "lodash";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { useTranslation } from "react-i18next";
import {
  STATUS_TYPE,
  statusNameMap,
  statusStylesMap,
} from "infrastructure/enum/status-type.enum";
import { Checkbox } from "ui/Checkbox";
import {
  statusReportNameMap,
  statusReportStylesMap,
} from "infrastructure/enum/status-report.enum";
import { JournalItem } from "infrastructure/interfaces";
import { Link } from "react-router-dom";
import { loaderLock, loaderUnlock } from "store/common/actions";
import If from "../util/If";
import Else from "../util/Else";
import { updateJournalList } from "store/journal/actions";
import moment from "moment";
import DownloadPostingsModal from "../modal/DownloadPostingsModal";
import AssistanceWarningModal from "../modal/AssistanceWarningModal";
import {
  getJournalIsArchive,
  getJournalFilter,
  getJournalList,
} from "../../../store/selectors";
import {
  postTransactionsDownload,
  postTransactionsDownloadWithParams,
  updateBatchesArchiveBulk,
} from "../../../services/ApiService";

interface JournalPageProps {
  isAdvance?: boolean;
  isArchive?: boolean;
}

const JournalPage: React.FC<JournalPageProps> = (props) => {
  const { t } = useTranslation();

  let [chosenItems, setChosenItems] = useState([] as number[]);
  let journalList = useSelector(getJournalList);
  let isArchive = useSelector(getJournalIsArchive);
  let journalFilter = useSelector(getJournalFilter);
  let [
    isOpenReturnAllFromArchiveWarningModal,
    setOpenReturnAllFromArchiveWarningModal,
  ] = useState(false);
  let [
    isOpenReturnSelectedFromArchiveWarningModal,
    setOpenReturnSelectedFromArchiveWarningModal,
  ] = useState(false);
  let [isOpenDownloadPostingsModal, setOpenDownloadPostingsModal] =
    useState(false);
  const [isDownloadAllChosen, setDownloadAllChosen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setChosenItems([]);
    };
  }, []);

  const checkChosenItem = (id: number): boolean => {
    return chosenItems.includes(id);
  };

  const clearAllItem = () => {
    setChosenItems([]);
  };

  const toggleClickItem = (id: number) => {
    const copy = chosenItems.slice();
    if (checkChosenItem(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      copy.push(id);
    }
    setChosenItems(copy);
  };

  const getObjectIdsAsParams = (objectIds: number[]): string => {
    let result = "?";
    let params: string[] = [];
    objectIds.map((item) => {
      params.push(`ObjectIds=${item}`);
    });
    if (params.length) {
      result += params.join("&");
    }
    return result;
  };

  const getCityIdsAndObjectIdsAsParams = (): string => {
    let result = "";
    let params: string[] = [];
    const cities = journalFilter.cityId;
    if (cities && cities.length > 0) {
      cities.map((item: any) => {
        params.push(`CityId=${item}`);
      });
    }
    let itemNumbers =
      journalFilter.itemNumber && journalFilter.itemNumber.split(",");
    if (itemNumbers && itemNumbers.length) {
      itemNumbers.map((itemNumber: string) => {
        params.push(`ObjectIds=${itemNumber.trim()}`);
      });
    }
    if (params.length > 0) {
      result += "?";
      result += params.join("&");
    }
    return result;
  };

  const prepareFilterRequestParams = (): any => {
    return {
      CreateDate: journalFilter.dateCreate,
      loadDateStart: journalFilter.downloadDateStart,
      loadDateEnd: journalFilter.downloadDateEnd,
      LastLoadDateStart: journalFilter.downloadLastDateStart,
      LastLoadDateEnd: journalFilter.downloadLastDateEnd,
      JourneyNum: journalFilter.tripNumber,
      JourneyStartDate: journalFilter.tripDateStart,
      JourneyEndDate: journalFilter.tripDateEnd,
      EmployeeId: journalFilter.employee,
      batchID: journalFilter.uploadNumber,
    };
  };

  const downloadHandler = async (date?: string) => {
    dispatch(loaderLock());
    let response = await postTransactionsDownloadWithParams(
      isDownloadAllChosen
        ? getCityIdsAndObjectIdsAsParams()
        : !isEmpty(chosenItems)
          ? getObjectIdsAsParams(chosenItems)
          : "",
      {},
      {
        responseType: "blob",
        params: isDownloadAllChosen
          ? {
              ...prepareFilterRequestParams(),
              PostingDate: date ? date : null,
              ObjectType: props.isAdvance
                ? "AdvanceReportApplication"
                : "ExpenseApplication",
              isArchive: false,
            }
          : {
              PostingDate: date ? date : null,
              ObjectType: props.isAdvance
                ? "AdvanceReportApplication"
                : "ExpenseApplication",
              isArchive: false,
            },
      },
    );
    if (response.headers.success) {
      let contentDisposition = response.headers["content-disposition"];
      let fileName = contentDisposition.slice(
        contentDisposition.lastIndexOf("'") + 1,
      );
      FileSaver.saveAs(response.data, decodeURI(fileName));
      dispatch(
        updateJournalList(
          props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
          false,
        ),
      );
      setChosenItems([]);
    } else {
      //dispatch(showErrors({ code: 'approve_postings', message: 'Не удалось согласовать проводки' }));
    }
    dispatch(loaderUnlock());
  };

  const returnFromArchiveHandler = async (byFilter: boolean = false) => {
    dispatch(loaderLock());
    let response = await updateBatchesArchiveBulk(
      byFilter
        ? getCityIdsAndObjectIdsAsParams()
        : !isEmpty(chosenItems)
          ? getObjectIdsAsParams(chosenItems)
          : "",
      { isArchive: false },
      {
        params: byFilter ? prepareFilterRequestParams() : null,
      },
    );
    if (response.headers.success) {
      dispatch(
        updateJournalList(
          props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
          false,
        ),
      );
      setChosenItems([]);
    }
    dispatch(loaderUnlock());
  };

  const deleteHandler = async (event: any) => {
    event.preventDefault();
  };

  const toggleDownloadPostingsModal = () => {
    setOpenDownloadPostingsModal(!isOpenDownloadPostingsModal);
  };

  const downloadAllPostings = () => {
    if (journalList && !!journalList.length && !isArchive) {
      setDownloadAllChosen(true);
      toggleDownloadPostingsModal();
    }
  };

  const downloadSelectedPostings = () => {
    setDownloadAllChosen(false);
    toggleDownloadPostingsModal();
  };

  const returnAllFromArchive = () => {
    returnFromArchiveHandler(true);
  };

  const returnFromArchiveSelected = async () => {
    returnFromArchiveHandler(false);
  };

  const toggleReturnAllFromArchiveWarningModal = () => {
    setOpenReturnAllFromArchiveWarningModal(
      !isOpenReturnAllFromArchiveWarningModal,
    );
  };

  const toggleReturnSelectedFromArchiveWarningModal = () => {
    setOpenReturnSelectedFromArchiveWarningModal(
      !isOpenReturnSelectedFromArchiveWarningModal,
    );
  };

  return (
    <Fragment>
      <div className="journal-swap-controls">
        <If condition={isEmpty(chosenItems)}>
          <If condition={!isArchive}>
            <a
              className={`flex-center control pointer ${journalList && !!journalList.length ? "" : "disabled"}`}
              onClick={downloadAllPostings}
            >
              <SvgIcon
                className={"icon icon-download"}
                href={"#svg_icon_download"}
              />
              <span>{t("journal.unload_all")}</span>
            </a>
          </If>
          <If condition={isArchive}>
            <a
              className={`flex-center control pointer ${journalList && !!journalList.length ? "" : "disabled"}`}
              onClick={toggleReturnAllFromArchiveWarningModal}
            >
              <span>{t("journal.return_from_archive_all")}</span>
            </a>
          </If>
        </If>
        <If condition={!isEmpty(chosenItems)}>
          <div className="journal-swap-controls-counter">
            <span>
              {t("journal.selected", { count: chosenItems.length })} |
            </span>
            <a className={"pointer"} onClick={clearAllItem}>
              {t("journal.cancel_selection")}
            </a>
          </div>
          <If condition={!isArchive}>
            <a
              className="flex-center control pointer"
              onClick={downloadSelectedPostings}
            >
              <SvgIcon
                className={"icon icon-download"}
                href={"#svg_icon_download"}
              />
              <span>{t("journal.unload_selected")}</span>
            </a>
          </If>
          <If condition={isArchive}>
            <a
              className="flex-center control pointer"
              onClick={toggleReturnSelectedFromArchiveWarningModal}
            >
              <span>{t("journal.return_from_archive_selected")}</span>
            </a>
          </If>
        </If>
      </div>
      <div className="request-expenses-wrap journal-swap">
        <JournalList
          journalList={journalList}
          isAdvance={props.isAdvance ? true : false}
          isArchive={isArchive}
          toggleClickItem={toggleClickItem}
          checkChosenItem={checkChosenItem}
        />
      </div>
      <AssistanceWarningModal
        isOpen={isOpenReturnAllFromArchiveWarningModal}
        title={t("journal.return_from_archive_warning_title")}
        info={t("journal.return_from_archive_warning_info")}
        sub_info={t("journal.return_from_archive_warning_sub_info")}
        todo={t("journal.return_from_archive_warning_todo")}
        onClose={toggleReturnAllFromArchiveWarningModal}
        onSubmit={() => {
          toggleReturnAllFromArchiveWarningModal();
          returnAllFromArchive();
        }}
      />
      <AssistanceWarningModal
        isOpen={isOpenReturnSelectedFromArchiveWarningModal}
        title={t("journal.return_from_archive_warning_title")}
        info={t("journal.return_from_archive_warning_info")}
        sub_info={t("journal.return_from_archive_warning_sub_info")}
        todo={t("journal.return_from_archive_warning_todo")}
        onClose={toggleReturnSelectedFromArchiveWarningModal}
        onSubmit={() => {
          toggleReturnSelectedFromArchiveWarningModal();
          returnFromArchiveSelected();
        }}
      />
      <DownloadPostingsModal
        isOpen={isOpenDownloadPostingsModal}
        isList={true}
        onClose={toggleDownloadPostingsModal}
        onSubmit={(date) => {
          toggleDownloadPostingsModal();
          downloadHandler(date);
        }}
      />
    </Fragment>
  );
};

interface JournalListProps {
  isAdvance?: boolean;
  isArchive?: boolean;
  journalList: JournalItem[];
  toggleClickItem: (id: number) => void;
  checkChosenItem: (id: number) => boolean;
}

const JournalList: React.FC<JournalListProps> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let [journalList, setJournalList] = useState([] as JournalItem[]);

  const clickHandler = (e: any, id: number) => {
    const type: any = (e.target as HTMLElement).getAttribute("data-type");
    if (type) {
      e.preventDefault();
    }
    switch (type) {
      case "delete":
        return;
      case "checkbox":
        props.toggleClickItem(id);
        return;
    }
  };

  useEffect(() => {
    if (props.journalList) {
      setJournalList(props.journalList);
    } else {
      setJournalList([]);
    }
  }, [props.journalList]);

  const observer = useRef();

  const lastElement = useCallback(
    (node: any) => {
      if (observer && observer.current) {
        // @ts-ignore
        observer.current.disconnect();
      }
      // @ts-ignore
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          dispatch(
            updateJournalList(
              props.isAdvance
                ? "AdvanceReportApplication"
                : "ExpenseApplication",
              true,
              () => {
                window.scrollTo(0, document.documentElement.scrollTop - 500);
              },
            ),
          );
        }
      });
      if (node && observer && observer.current) {
        // @ts-ignore
        observer.current.observe(node);
      }
    },
    [props.journalList],
  );

  const toggle = (item: JournalItem) => (e: any) => {
    e.preventDefault();
    props.toggleClickItem(item.referenceId);
  };
  return (
    <Fragment>
      {journalList.map((item, index) => {
        let loadedAt = moment
          .utc(item.loadedAt, "YYYY-MM-DDThh:mm:ss")
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        let lastLoadedAt = moment
          .utc(item.lastLoadedAt, "YYYY-MM-DDThh:mm:ss")
          .local()
          .format("YYYY-MM-DD HH:mm:ss");

        if (journalList && journalList.length === index + 1) {
          return (
            <Link
              ref={lastElement}
              key={index}
              to={`/Journal/${item.referenceType}/${item.referenceId}`}
              onClick={(e) => clickHandler(e, item.referenceId)}
            >
              <div className="request-expenses-item">
                <div
                  style={{ display: "flex" }}
                  className="request-expenses-block"
                >
                  <label
                    onClick={toggle(item)}
                    style={{
                      flex: 1,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    htmlFor={`check${index}`}
                  >
                    <Checkbox
                      id={index}
                      name={`check${index}`}
                      label={""}
                      onClick={() => {}}
                      type={"checkbox"}
                      checked={props.checkChosenItem(item.referenceId)}
                    />
                  </label>
                </div>
                <div className="request-expenses-block info">
                  <div className="request-expenses-item-text">№{item.id}</div>
                  <div className="request-item-name">{item.name}</div>
                  <div className="request-item-user">
                    {item.employee.firstName[i18n.language as "ru"]}{" "}
                    {item.employee.lastName[i18n.language as "ru"]}
                  </div>
                </div>
                <div className="request-expenses-block-wrap">
                  <div className="flex-sm-row flex-sm-between">
                    <div>
                      {/* <div className="request-list-item-cost">{toLocaleNumberFormatter(item.totalAmount)}{getCurrencieCode('rub')}</div> */}
                    </div>
                  </div>
                  <If condition={props.isAdvance}>
                    <div className="request-expenses-block">
                      <div
                        className={`request-list-item-status ${statusReportStylesMap[item.referenceState]}`}
                      >
                        {t("filters.status")}:{" "}
                        {t(
                          `statusAO.${statusReportNameMap[item.referenceState]}`,
                        )}
                        <span></span>
                      </div>
                    </div>
                  </If>
                  <Else condition={props.isAdvance}>
                    <div className="request-expenses-block">
                      <div
                        className={`request-list-item-status ${statusStylesMap[item.referenceState as STATUS_TYPE]}`}
                      >
                        {t("filters.status")}:{" "}
                        {t(
                          `status.${statusNameMap[item.referenceState as STATUS_TYPE]}`,
                        )}
                        <span></span>
                      </div>
                    </div>
                  </Else>
                  <span>
                    {item.loadedAt != null
                      ? ` ${t("status.unload_first_date")}: ${loadedAt}`
                      : null}
                  </span>
                  {item.lastLoadedAt && (
                    <span>{` ${t("status.unload_last_date")}: ${lastLoadedAt}`}</span>
                  )}
                </div>
              </div>
            </Link>
          );
        } else
          return (
            <Link
              key={index}
              to={`/Journal/${item.referenceType}/${item.referenceId}`}
              onClick={(e) => clickHandler(e, item.referenceId)}
            >
              <div className="request-expenses-item">
                <div className="request-expenses-block">
                  <label
                    onClick={toggle(item)}
                    style={{
                      flex: 1,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    htmlFor={`check${index}`}
                  >
                    <Checkbox
                      id={index}
                      name={`check${index}`}
                      label={""}
                      onClick={() => {}}
                      type={"checkbox"}
                      checked={props.checkChosenItem(item.referenceId)}
                    />
                  </label>
                </div>
                <div className="request-expenses-block info">
                  <div className="request-expenses-item-text">№{item.id}</div>
                  <div className="request-item-name">{item.name}</div>
                  <div className="request-item-user">
                    {item.employee.firstName[i18n.language as "ru"]}{" "}
                    {item.employee.lastName[i18n.language as "ru"]}
                  </div>
                </div>
                <div className="request-expenses-block-wrap">
                  <div className="flex-sm-row flex-sm-between">
                    <div></div>
                  </div>
                  <If condition={props.isAdvance}>
                    <div className="request-expenses-block">
                      <div
                        className={`request-list-item-status ${statusReportStylesMap[item.referenceState]}`}
                      >
                        {t("filters.status")}:{" "}
                        {t(
                          `statusAO.${statusReportNameMap[item.referenceState]}`,
                        )}
                        <span></span>
                      </div>
                    </div>
                  </If>
                  <Else condition={props.isAdvance}>
                    <div className="request-expenses-block">
                      <div
                        className={`request-list-item-status ${statusStylesMap[item.referenceState as STATUS_TYPE]}`}
                      >
                        {t("filters.status")}:{" "}
                        {t(
                          `status.${statusNameMap[item.referenceState as STATUS_TYPE]}`,
                        )}
                        <span></span>
                      </div>
                    </div>
                  </Else>
                  <span>
                    {item.loadedAt != null
                      ? ` ${t("status.unload_first_date")}: ${loadedAt}`
                      : null}
                  </span>
                  {item.lastLoadedAt && (
                    <span>{` ${t("status.unload_last_date")}: ${lastLoadedAt}`}</span>
                  )}
                </div>
              </div>
            </Link>
          );
      })}
    </Fragment>
  );
};

export default JournalPage;
