import React from "react";
import DayPicker, { DayPickerProps } from "react-day-picker";
import { TimePicker } from "./TimePicker";
import classnames from "classnames";

export interface DayPickerWithChildrenProps extends DayPickerProps {
  onChangeTime?: () => void;
  isStartDate?: boolean;
}

export class DayPickerWithChildren extends DayPicker {
  constructor(props: DayPickerWithChildrenProps) {
    super(props);

    this.state = {
      ...this.state,
      hours: "",
      minutes: "",
    };
  }

  getTimeWithZeros(value: string, isHours: boolean) {
    switch (value.length) {
      case 0: {
        if (isHours) {
          return this.props.isStartDate ? "00" : "23";
        }
        return this.props.isStartDate ? "00" : "59";
      }
      case 1:
        return `0${value}`;
      case 2:
        return value;
    }
  }

  onChangeHours(hours: string) {
    this.setState({ hours });

    const time = `${this.getTimeWithZeros(hours, true)}:${this.getTimeWithZeros(this.state.minutes, false)}`;
    this.props.onChangeTime(time);
  }

  onChangeMinutes(minutes: string) {
    this.setState({ minutes });

    const time = `${this.getTimeWithZeros(this.state.hours, true)}:${this.getTimeWithZeros(minutes, false)}`;
    this.props.onChangeTime(time);
  }

  renderFooter() {
    const classname = this.props.classNames;
    return (
      <div className={classname && classname.footer}>
        <TimePicker
          onChangeHours={this.onChangeHours.bind(this)}
          onChangeMinutes={this.onChangeMinutes.bind(this)}
        />
      </div>
    );
  }
}
