import React, { useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
//component
import History from "app/component/history/History";
import { useSelector } from "react-redux";
import { EXPENSE_APPLICATION_PROPETIES } from "infrastructure/enum/object-properties.enum";
import { getApplicationForExpenseDetail } from "../../../store/selectors";

const ZNRHistory: React.FC<RouteComponentProps<any>> = (props) => {
  const applicationForExpenseDetail = useSelector(
    getApplicationForExpenseDetail,
  );
  let [historyPermission, setHistoryPermission] = useState(false);

  useEffect(() => {
    if (applicationForExpenseDetail) {
      setHistoryPermission(
        applicationForExpenseDetail.properties.includes(
          EXPENSE_APPLICATION_PROPETIES.FLOW_HISTORY,
        ),
      );
    }
  }, [applicationForExpenseDetail]);

  return applicationForExpenseDetail ? (
    <div className={`${historyPermission ? "" : "d-none"}`}>
      <History
        objectId={applicationForExpenseDetail.id}
        logicalRout={"ExpenseApplications"}
      />
    </div>
  ) : null;
};

export default withRouter(ZNRHistory);
