import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getBlobFileContentById,
  getListOfBlobFiles,
} from "services/ApiService";
import {
  Body,
  Download,
  DownloadContainer,
  DownloadSuffix,
  SubTitle,
} from "./PowerOfAttorneyItemStyled";
import { DownloadFileType, PowersOfAttorneyListFilesProps } from "./types";
import { SvgIcon } from "ui/SvgIcon";

const PowerOfAttorneyItemFiles: React.FC<PowersOfAttorneyListFilesProps> = ({
  POW,
}) => {
  const { t } = useTranslation();
  const [downloadfiles, setDownloadfiles] = useState([]);

  const getBlobFiles = async () => {
    try {
      let result = await getListOfBlobFiles({
        params: {
          "ObjectId.Id": POW.id,
          "ObjectId.LogicalName": "PowerOfAttorney",
        },
      });

      if (result.headers.success) {
        const uniqueTypes = result.data.data.reduce((acc, obj) => {
          if (
            obj.type === "SignedPowerOfAttorneyXml" ||
            obj.type === "PrintingPowerOfAttorney"
          ) {
            if (!acc.some((item) => item.type === obj.type)) {
              acc.push(obj);
            }
          }
          return acc;
        }, []);

        setDownloadfiles(uniqueTypes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (downloadfiles.length === 0) {
      getBlobFiles();
    }
  }, []);

  const downloadConentHandler = async (file: DownloadFileType) => {
    try {
      const result = await getBlobFileContentById(file.id, {
        responseType: "blob",
      });
      if (result.headers.success) {
        let a = document.createElement("a");
        document.body.appendChild(a);

        let blob = new Blob([result.data], {
            type: result.headers["content-type"],
          }),
          url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {}
  };

  return (
    <div>
      <SubTitle>{t("power.available_files")}</SubTitle>
      <Body>
        {downloadfiles.map((el: DownloadFileType) => {
          if (el.name.includes("pdf")) {
            return (
              <DownloadContainer
                key={el.id}
                onClick={() => downloadConentHandler(el)}
              >
                <Download>
                  <SvgIcon id={"pdfIcon"} size={40} />
                  <DownloadSuffix>{el.name}</DownloadSuffix>
                </Download>
                <SvgIcon id={"download"} size={24} />
              </DownloadContainer>
            );
          }
          if (el.name.includes("xml")) {
            return (
              <DownloadContainer
                key={el.id}
                onClick={() => downloadConentHandler(el)}
              >
                <Download>
                  <SvgIcon id={"fragment"} size={40} />
                  <DownloadSuffix>{el.name}</DownloadSuffix>
                </Download>
                <SvgIcon id={"download"} size={24} />
              </DownloadContainer>
            );
          }
        })}
      </Body>
    </div>
  );
};

export default PowerOfAttorneyItemFiles;
