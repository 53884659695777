import DatePickerCalendar from "app/component/date-picker/DatePickerCalendar";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { formatter } from "app/utils";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DateInput.css";

interface DateInputProps {
  isCleansed?: boolean;
  isMultiChoice?: boolean;
  onSelect: (date: Date) => void;
  onChangeTime?: (time: string) => void;
  format: string;
  placeholder?: string;
  ref?: any;
  isStartDate?: boolean;
  value?: Date;
  newStyle?: boolean;
}

const DateInput: React.FC<DateInputProps> = React.forwardRef(
  (prop: DateInputProps, ref: any) => {
    let { i18n } = useTranslation();
    let [isOpenDatePicker, setOpenDatePicker] = useState(false);
    let [selectedDate, setSelectedDate] = useState(null as any);

    const toggleDate = () => {
      setOpenDatePicker(!isOpenDatePicker);
    };

    const onSelect = (value: any) => {
      setSelectedDate(value.startDate);
      !prop.onChangeTime && toggleDate();
      prop.onSelect(value.startDate);
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        setSelectedDate("");
      },
    }));

    return (
      <React.Fragment>
        <input
          className={
            prop.newStyle ? "calendar-input" : "input datepicker pointer"
          }
          type="text"
          value={
            prop.value
              ? formatter(prop.format, i18n.language, prop.value, "", {
                  withMonth: true,
                  withYear: true,
                  withTime: true,
                })
              : selectedDate
                ? formatter(prop.format, i18n.language, selectedDate)
                : ""
          }
          placeholder={prop.placeholder}
          onClick={toggleDate}
          readOnly
        />
        <SvgIcon
          className={"icon icon-calendar pointer"}
          style={
            prop.newStyle
              ? { position: "absolute", top: "20px", right: "2px" }
              : null
          }
          href={"#svg_icon_calendar"}
        />
        <DatePickerCalendar
          isCleansed={prop.isCleansed || false}
          isMultiChoice={prop.isMultiChoice || false}
          isShown={isOpenDatePicker}
          onSelected={onSelect}
          onClose={toggleDate}
          onChangeTime={prop.onChangeTime}
          isStartDate={prop.isStartDate}
        />
      </React.Fragment>
    );
  },
);

export default DateInput;
