import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "ui/SvgIcon";
import {
  ArrayBtn,
  Authorities,
  Authority,
  Button,
  Expire,
  ExpireDate,
  ExpireTerm,
  Number,
  PowerStatus,
  Representative,
  RepresentativeName,
  RepresentativeOccupation,
  SignDate,
  SigningContainer,
  Wrapper,
} from "./ManagerListStyled";
import { ManagerListItemProps } from "../types";
import { calculateRemainingTime } from "../helper";
import State from "../Status";

const ManagerListItem: React.FC<ManagerListItemProps> = ({ POW, onClick, isManager }) => {
  const { t, i18n } = useTranslation();

  const isExpired = !moment(POW.validTo).isAfter();
  const remainingTime = calculateRemainingTime(POW.validTo, false, t);

  const clickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick && onClick(e, POW);
  };

  return (
    <>
      <Wrapper onClick={clickHandler}>
        <Number>{POW.id}</Number>
        <Expire>
          <ExpireDate expired={isExpired}>
            {moment(POW.validTo).utc().format("DD.MM.YYYY")}
          </ExpireDate>
          <ExpireTerm expired={isExpired}>
            {isExpired ? t("power.expired_") : remainingTime}
          </ExpireTerm>
        </Expire>
        <Representative>
          <RepresentativeName>
            {POW.representative.employee.fullName[i18n.language as "en" | "ru"]}
          </RepresentativeName>
          <RepresentativeOccupation>
            {
              POW.representative.employee.occupation.name[
                i18n.language as "en" | "ru"
              ]
            }
          </RepresentativeOccupation>
        </Representative>
        <Authorities>
          {POW &&
            POW.authorities.map((el) => (
              <Authority key={el.id}>
                <strong>{el.code}</strong>
                {` ${el.name[i18n.language as "en" | "ru"]}; `}
              </Authority>
            ))}
        </Authorities>
        <PowerStatus>
          <State state={POW.state} />
        </PowerStatus>
        <SigningContainer>
          {POW.state === "SigningRequires" && isManager &&  (
            <Button id="item-button">{t("power.button.sign")}</Button>
          )}
          {(POW.state === "Signed" || POW.state === "Revoked") && (
            <SignDate>
              {moment(POW.signedAt).utc().format("DD.MM.YYYY")}
            </SignDate>
          )}
        </SigningContainer>
        <ArrayBtn>
          <SvgIcon id={"vectorRight"} size={18} color={"#0A0A0A"} />
        </ArrayBtn>
      </Wrapper>
    </>
  );
};

export default ManagerListItem;
