import styled from "styled-components";

export const DropdownButtonContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: 44px;
  right: 0;
  box-shadow: 0px 10px 15px -3px #29294014;
  box-shadow: 0px 4px 6px -2px #2929400a;
  border: 1px solid #e9eff2;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  padding: 5px;
`;

export const DropdownButtonItem = styled.a`
  min-width: 233px;
  height: 40px;
  gap: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  padding-left: 10px;
  &:hover {
    color: #98a2a8;
  }
`;
