/**
 * @typedef {Object} STATUS_TYPE_EXPENSE
 * @property {string} DRAFT - Черновик
 * @property {string} APPROVAL_REQUIRES - Требует согласования
 * @property {string} ON_APPROVAL - На согласовании
 * @property {string} APPROVED - Согласован
 * @property {string} NOT_APPROVED - Не согласован
 * @property {string} REVOKED - Отозван (на схеме Удален)
 * @property {string} POSTED - Проведен
 * @property {string} ON_REVISION - На доработке
 */

export enum STATUS_TYPE_EXPENSE {
  DRAFT = "Draft",
  APPROVAL_REQUIRES = "Approval Requires",
  ON_APPROVAL = "On Approval",
  APPROVED = "Approved",
  NOT_APPROVED = "Not Approved",
  REVOKED = "Revoked",
  POSTED = "Posted",
  ON_REVISION = "OnRevision",
  CANCELLED = "Cancelled",
}

export const statusExpenseStylesMap: any = {
  [STATUS_TYPE_EXPENSE.DRAFT]: "gray",
  [STATUS_TYPE_EXPENSE.APPROVAL_REQUIRES]: "yellow",
  [STATUS_TYPE_EXPENSE.ON_APPROVAL]: "orange",
  [STATUS_TYPE_EXPENSE.APPROVED]: "green",
  [STATUS_TYPE_EXPENSE.NOT_APPROVED]: "red",
  [STATUS_TYPE_EXPENSE.REVOKED]: "gray",
  [STATUS_TYPE_EXPENSE.POSTED]: "blue",
  [STATUS_TYPE_EXPENSE.ON_REVISION]: "yellow",
  [STATUS_TYPE_EXPENSE.CANCELLED]: "black",
};

export const statusExpenseNameMap: any = {
  [STATUS_TYPE_EXPENSE.DRAFT]: "draft",
  [STATUS_TYPE_EXPENSE.APPROVAL_REQUIRES]: "approval_requires",
  [STATUS_TYPE_EXPENSE.ON_APPROVAL]: "on_approval",
  [STATUS_TYPE_EXPENSE.APPROVED]: "approved",
  [STATUS_TYPE_EXPENSE.NOT_APPROVED]: "not_approved",
  [STATUS_TYPE_EXPENSE.REVOKED]: "revoked",
  [STATUS_TYPE_EXPENSE.POSTED]: "posted",
  [STATUS_TYPE_EXPENSE.ON_REVISION]: "оn_revision",
  [STATUS_TYPE_EXPENSE.CANCELLED]: "cancelled",
};

export const getExpenseEnumByName = (
  stringStatus: string,
): STATUS_TYPE_EXPENSE => {
  switch (stringStatus) {
    case "Draft":
      return STATUS_TYPE_EXPENSE.DRAFT;
    case "ApprovalRequires":
      return STATUS_TYPE_EXPENSE.APPROVAL_REQUIRES;
    case "OnApproval":
      return STATUS_TYPE_EXPENSE.ON_APPROVAL;
    case "Approved":
      return STATUS_TYPE_EXPENSE.APPROVED;
    case "NotApproved":
      return STATUS_TYPE_EXPENSE.NOT_APPROVED;
    case "Revoked":
      return STATUS_TYPE_EXPENSE.REVOKED;
    case "Posted":
      return STATUS_TYPE_EXPENSE.POSTED;
    case "OnRevision":
      return STATUS_TYPE_EXPENSE.ON_REVISION;
    case "Cancelled":
      return STATUS_TYPE_EXPENSE.CANCELLED;
    default:
      return STATUS_TYPE_EXPENSE.DRAFT;
  }
};
