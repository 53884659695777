import React from "react";
//component
import Postings from "app/component/postings/Postings";

interface ZNRPostings {
  id: number;
}

const ZNRPostings: React.FC<ZNRPostings> = (props) => {
  return <Postings id={props.id} />;
};

export default ZNRPostings;
