/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { showErrors } from "store/exception/actions";
import CreateOrderModal from "app/component/modal/CreateOrderModal";
import { Expense, IOccupations } from "infrastructure/interfaces/index";
import {
  statusNameMap,
  STATUS_TYPE,
} from "infrastructure/enum/status-type.enum";
import { UserDetail } from "infrastructure/interfaces";
import { OCCUPATION_ROLE_TYPE } from "infrastructure/enum/user-role.enum";
import If from "app/component/util/If";
import Else from "app/component/util/Else";
import {
  getApplicationForExpenseDetail,
  getCommonUserDetail,
} from "../../../../store/selectors";
import { EXPENSE_APPLICATION_PROPETIES } from "../../../../infrastructure/enum/object-properties.enum";
import { Button } from "react-bootstrap";
import ApplicationExpensesEmpty from "./ApplicationExpensesEmpty";
import ApplicationExpensesList from "./ApplicationExpensesList";

interface ZNRExpensesPanelProps {
  id: number;
  isWidget?: boolean;
  list: Expense[];
}

const ZNRExpensesPanel: React.FC<ZNRExpensesPanelProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const applicationForExpenseDetail = useSelector(
    getApplicationForExpenseDetail,
  );
  const user: UserDetail = useSelector(getCommonUserDetail);

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  let [isOpenAddExpenseModal, setOpenAddExpenseModal] = useState(false); //открытие формы добавление расходов
  const clickAddExpenseModalHandler = () => {
    if (applicationForExpenseDetail) {
      if (
        applicationForExpenseDetail.properties.includes(
          EXPENSE_APPLICATION_PROPETIES.ADD_EXPENSE,
        )
      ) {
        setOpenAddExpenseModal(!isOpenAddExpenseModal);
        return;
      }
      if (
        applicationForExpenseDetail.status === STATUS_TYPE.APPROVED ||
        applicationForExpenseDetail.status === STATUS_TYPE.ON_APPROVAL
      ) {
        dispatch(
          showErrors({
            code: "",
            message: t("status_error_message.status_expense_edit_EA", {
              status: t(
                `status.${
                  statusNameMap[
                    applicationForExpenseDetail.status as STATUS_TYPE
                  ]
                }`,
              ),
            }),
          }),
        );
      } else {
        if (
          applicationForExpenseDetail.status === STATUS_TYPE.POSTED ||
          applicationForExpenseDetail.status === STATUS_TYPE.REPORT_PREPARED ||
          applicationForExpenseDetail.status === STATUS_TYPE.EXECUTED
        ) {
          dispatch(
            showErrors({
              code: "",
              message: t("status_error_message.status__expense_edit_EA", {
                status: t(
                  `status.${
                    statusNameMap[
                      applicationForExpenseDetail.status as STATUS_TYPE
                    ]
                  }`,
                ),
              }),
            }),
          );
        } else {
          setOpenAddExpenseModal(!isOpenAddExpenseModal);
        }
      }
    }
  };

  return applicationForExpenseDetail ? (
    <Fragment>
      <div className="request-expenses">
        <div className="request-expenses-title">
          <span>
            {t("request_detail.application_expenses.applicationExpenses")}
          </span>
          <If
            condition={applicationForExpenseDetail.properties.includes(
              EXPENSE_APPLICATION_PROPETIES.ADD_EXPENSE,
            )}
          >
            <If condition={isBookerOccupation()}>
              {applicationForExpenseDetail.assigneeEmployee.id === user.id ? ( // || (applicationForExpenseDetail.status == STATUS_TYPE.ON_APPROVAL )  ?
                <Fragment>
                  <If condition={props.isWidget}>
                    <Button
                      variant="success"
                      data-modal-id="add-expenses"
                      onClick={clickAddExpenseModalHandler}
                    >
                      {t("request_detail.application_expenses.addExpens")}
                    </Button>
                  </If>
                  <Else condition={props.isWidget}>
                    <a
                      className="btn-expense btn_green js-open-modal add-note pointer"
                      data-modal-id="add-expenses"
                      onClick={clickAddExpenseModalHandler}
                    >
                      {t("request_detail.application_expenses.addExpens")}
                    </a>
                  </Else>
                </Fragment>
              ) : null}
            </If>
            <Else condition={isBookerOccupation()}>
              {applicationForExpenseDetail.status !== STATUS_TYPE.CANCELLED ? (
                <Fragment>
                  <If condition={props.isWidget}>
                    <Button
                      variant="success"
                      data-modal-id="add-expenses"
                      onClick={clickAddExpenseModalHandler}
                    >
                      {t("request_detail.application_expenses.addExpens")}
                    </Button>
                  </If>
                  <Else condition={props.isWidget}>
                    <a
                      className="btn-expense btn_green js-open-modal add-note pointer"
                      data-modal-id="add-expenses"
                      onClick={clickAddExpenseModalHandler}
                    >
                      {t("request_detail.application_expenses.addExpens")}
                    </a>
                  </Else>
                </Fragment>
              ) : null}
            </Else>
          </If>
        </div>
        <div className="request-expenses-wrap">
          {props.list.length ? (
            <ApplicationExpensesList
              id={props.id}
              list={props.list}
              isWidget={props.isWidget}
            />
          ) : (
            <ApplicationExpensesEmpty id={props.id} isWidget={props.isWidget} />
          )}
        </div>
      </div>
      <CreateOrderModal
        id={props.id}
        isOpen={isOpenAddExpenseModal}
        isWidget={props.isWidget}
        onClose={() => setOpenAddExpenseModal(false)}
      />
    </Fragment>
  ) : null;
};

export default ZNRExpensesPanel;
