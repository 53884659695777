import moment from "moment";
import * as React from "react";
import { CaptionElementProps } from "react-day-picker";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

const DatePickerCaption: React.FC<CaptionElementProps> = (prop) => {
  let { i18n } = useTranslation();
  return (
    <div className="lightpick__month-title-bar DayPicker-Caption">
      <div className="lightpick__month-title pointer" onClick={prop.onClick}>
        <div className="lightpick-months">
          {startCase(
            moment(prop.date).locale(i18n.language).format("MMMM YYYY"),
          )}
        </div>
      </div>
    </div>
  );
};

export default DatePickerCaption;
