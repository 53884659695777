import React from "react";
import { IconProps } from "../types";

const PDFicon: React.FC<IconProps> = (props) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
    >
      <rect width="40" height="40" rx="4" fill="#5D6C74" />
      <path
        d="M17.07 25.28H19.85L21.6 28.72H24.14L20 17.87L17.07 25.28ZM10 11V29L17.42 11H10ZM22.48 11L30 28.81V11H22.48Z"
        fill="white"
      />
    </svg>
  );
};

export default PDFicon;
