import React, { useEffect, useRef, useState } from "react";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { useTranslation } from "react-i18next";
import {
  Option,
  OptionsContainer,
  Section,
  SelectButton,
  SelectContainer,
  SelectedOption,
  Text,
} from "./SelectWithSection2Styled";

export interface SelectWithSectionProps {
  options: SelectWithSectionOptionType[];
  selectedOption: SelectWithSectionOptionType | null;
  setSelectedOption: (a: SelectWithSectionOptionType) => void;
  error?: boolean;
}

export interface SelectWithSectionOptionType {
  expenseTypeID?: number;
  value: number;
  text: string;
  section?: string;
}

const SelectWithSection2: React.FC<SelectWithSectionProps> = ({
  options,
  selectedOption,
  setSelectedOption,
  error,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectOption = (option: SelectWithSectionOptionType) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef}>
      <SelectContainer
        onClick={toggleOptions}
        error={error}
        className={`select ${isOpen ? "active" : ""} `}
      >
        <SelectButton>
          <SelectedOption>
            {selectedOption && selectedOption.text.length
              ? selectedOption.text
              : t("QR.select_option")}
          </SelectedOption>
        </SelectButton>
        <div
          style={{ border: "none", justifyContent: "space-between" }}
          className={`select-title`}
        >
          <SvgIcon className="icon icon-arrow-top" href="#svg_icon_arrow_top" />
        </div>
        {isOpen && options && (
          <OptionsContainer>
            {options.map((option) => (
              <Option key={option.value} onClick={() => selectOption(option)}>
                <Text>{option.text}</Text>
                <Section>{option.section}</Section>
              </Option>
            ))}
          </OptionsContainer>
        )}
      </SelectContainer>
    </div>
  );
};

export default SelectWithSection2;
