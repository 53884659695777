import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showErrors } from "store/exception/actions";
import { RouteComponentProps, withRouter } from "react-router";
import { getExpenseApplicationOpen } from "../../../services/ApiService";

interface OpenProps extends RouteComponentProps<any> {
  tripNumber: string;
  journeyNumber: string;
  logicalName: string;
}

const Open: React.FC<OpenProps> = (props) => {
  const dispatch = useDispatch();

  const redirect = async () => {
    let result = await getExpenseApplicationOpen({
      params: {
        tripNumber: props.tripNumber,
        journeyNumber: props.journeyNumber,
      },
    });
    if (result.headers.success) {
      props.history.push(`/ExpenseApplication/Detail/${result.data.id}`);
    } else {
      dispatch(
        showErrors({
          code: "open_redirect",
          message: `The request '/${props.logicalName}/open' is dropped with the error ${result.status}`,
        }),
      );
      props.history.push(`/ExpenseApplication`);
    }
  };

  useEffect(() => {
    redirect();
  });

  return <div></div>;
};

export default withRouter(Open);
