import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store/reducers";
import { useTranslation } from "react-i18next";
import { ApprovalTier } from "infrastructure/interfaces";
import { loaderLock, loaderUnlock } from "store/common/actions";
import AgreementItem from "./AgreementItem";
import analitiks from "services/AnaliticsService";
import agreementService from "services/entity/agreement.service";
import { getCommonUserDetail } from "../../../store/selectors";

interface AgreementProps {
  id: number;
  isAdvance?: boolean;
}

const Agreement: React.FC<AgreementProps> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(getCommonUserDetail);

  let [levelsUser, setLevelsUser] = useState([] as ApprovalTier[]);
  let [currentTierId, setCurrentTierId] = useState(0);

  const getApprovalsDetails = async () => {
    dispatch(loaderLock());
    agreementService.setId(props.id);
    agreementService.setIsAdvance(!!props.isAdvance);
    let list = await agreementService.getList();
    setCurrentTierId(
      list && list.currentApprovalTierId ? list.currentApprovalTierId : 0,
    );
    setLevelsUser(list && list.approvalTiers ? list.approvalTiers : []);

    dispatch(loaderUnlock());
  };

  useEffect(() => {
    getApprovalsDetails();
    analitiks(
      props.isAdvance ? "ViewAOAgreement" : "ViewZNRAgreement",
      props.isAdvance
        ? {
            aoId: +props.id,
            employeeId: user.id,
            occupationId: user.occupations[0].occupationId,
            companyId: user.occupations[0].company.id,
          }
        : {
            znrId: +props.id,
            employeeId: user.id,
            occupationId: user.occupations[0].occupationId,
            companyId: user.occupations[0].company.id,
          },
    );
  }, []);

  const offsetForLevel = (level: number, levelArray: ApprovalTier[]) => {
    //TODO: Maybe you don't need to
    let offset = 0;
    for (let index = 0; index < levelArray.length; index++) {
      if (index < level) {
        const element = levelArray[index];
        offset += element.employees.length > 0 ? 0 : 1;
      }
    }

    return offset;
  };

  return (
    <Fragment>
      <div className="request-expenses-wrap">
        <div className="request-expenses-item" style={{ marginTop: "0px" }}>
          {levelsUser.length != 0 && (
            <>
              <div className="list-agreement">
                {levelsUser.map((level, index) => {
                  return (
                    <AgreementItem
                      id={level.ordinal + 1}
                      toggleFullList={level.id === currentTierId}
                      title={level.description[i18n.language as "ru"]}
                      users={level.employees}
                      offset={offsetForLevel(index, levelsUser)}
                      isAdvance={props.isAdvance}
                      approvalTierDocuments={level.approvalTierDocuments}
                      approvalItems={level.approvalItems}
                    />
                  );
                })}
              </div>
            </>
          )}
          {levelsUser.length == 0 && (
            <>
              <div
                className="request-bookkeeper-table-wrap"
                style={{ margin: "auto" }}
              >
                <div
                  className="request-expenses-empty"
                  style={{ marginTop: "30px" }}
                >
                  <div className="tx-center">
                    <img src="/img/empty-img-2.png" />
                  </div>
                  {t("agreement.list.not_found", {
                    user: user.name[i18n.language as "en" | "ru"].split(" ")[0],
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Agreement;
