/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import qs from "qs";
import { ApplicationState } from "../../../store/reducers";
import { updateApplicationForExpenseDetail } from "../../../store/applicationForExpense/actions";
import ZNRExpensesPanel from "../../pages/ZNR/ZNR-Detail/ZNRExpensesPanel";

interface ZNRExpensesWidgetProps extends RouteComponentProps<any> {}

const ZNRExpensesWidget: React.FC<ZNRExpensesWidgetProps> = (props) => {
  const applicationForExpenseDetail = useSelector(
    (state: ApplicationState) =>
      state.applicationForExpense.applicationForExpenseDetail,
  );
  const user = useSelector(
    (state: ApplicationState) => state.common.userDetail,
  );
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
    dispatch(updateApplicationForExpenseDetail(id ? +id : 0));
  }, []);

  return applicationForExpenseDetail ? (
    <ZNRExpensesPanel
      isWidget={true}
      id={applicationForExpenseDetail ? applicationForExpenseDetail.id : 0}
      list={
        applicationForExpenseDetail ? applicationForExpenseDetail.expenses : []
      }
    />
  ) : (
    <div className="request-list empty">
      <div className="request-list-empty">
        <img src="/img/empty-img.png" alt="" />
        <div style={{ width: "inherit" }}>
          {t(
            user.name[i18n.language as "en" | "ru"] &&
              user.name[i18n.language as "en" | "ru"].length
              ? "request_detail.not_found"
              : "request_detail.not_found_anonymous",
            { user: user.name[i18n.language as "en" | "ru"].split(" ")[0] },
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ZNRExpensesWidget);
