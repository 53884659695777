import { MENU_FIELD_PERMISSIONS } from "infrastructure/enum/field-permissions.enum";
import React, { useLayoutEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { getFieldPermissionsReadMainMenu } from "services/ApiService";

const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [route, setRoute] = useState("");

  const handleRedirect = async () => {
    setIsLoading(true);
    const response = await getFieldPermissionsReadMainMenu();
    if (response.headers.success && response.data) {
      if (response.data.includes(MENU_FIELD_PERMISSIONS.EXPENSE_APPLICATION)) {
        setRoute("/ExpenseApplication");
      } else {
        setRoute("/AdvanceReportApplication");
      }
    }
    setIsLoading(false);
  };

  useLayoutEffect(() => {
    handleRedirect();
  }, []);

  return <div>{!isLoading && <Redirect to={route} />}</div>;
};

export default Home;
