import styled from "styled-components";

const StyledFullCalculation = styled.div`
  float: right;
  margin-right: 14px;
  text-align: right;
  color: #5D6C74;
`;

export { StyledFullCalculation };
