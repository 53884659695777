import { BaseMapper } from "./base.mapper";
import { get, isArray } from "lodash";
import { Substitution } from "../interfaces";
import moment from "moment";

export class SubstitutionMapper extends BaseMapper<Substitution, any> {
  responseToEntity(response: any): Substitution {
    return {
      id: get(response, "id", 0),
      employee: {
        id: get(response, "employeeOccupation.id", 0),
        name: {
          ru: get(response, "employeeOccupation.employee.name.ru", ""),
          en: get(response, "employeeOccupation.employee.name.en", ""),
        },
      },
      deputyEmployee: {
        id: get(response, "deputyOccupation.id", 0),
        name: {
          ru: get(response, "deputyOccupation.employee.name.ru", ""),
          en: get(response, "deputyOccupation.employee.name.en", ""),
        },
      },
      company: {
        id: get(response, "company.id", 0),
        name: {
          ru: get(response, "company.name.ru", ""),
          en: get(response, "company.name.en", ""),
        },
      },
      startOn: moment(
        get(response, "startOn", ""),
        "YYYY-MM-DDThh:mm:ss",
      ).toDate(),
      endOn: moment(get(response, "endOn", ""), "YYYY-MM-DDThh:mm:ss").toDate(),
    };
  }

  responsesToEntitys(response: any[]): Substitution[] {
    return [
      ...(isArray(response)
        ? response.map((item) => this.responseToEntity(item))
        : []),
    ];
  }
}
