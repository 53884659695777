/**
 * @typedef {Object} ROLE_TYPE
 * @property {string} BOOKER - Бухгалтер
 * @property {string} USER - Пользователь
 * @property {string} CFO - Финансовый директор
 * @property {string} ASSISTANT - Ассистент
 * @property {string} SERVICE_COORDINATOR - Сервисный координатор
 * @property {string} LINE_MANAGER - Линейный менеджер
 * @property {string} MAIN_BOOKER - Главный бухгалтер
 * @property {string} AUDITOR - Аудитор
 */

export enum ROLE_TYPE {
  BOOKER = "Accountant",
  USER = "User",
  CFO = "FinancialDirector",
  ASSISTANT = "Assistant",
  ASSISTANT_MANAGER = "AssistantManager",
  SERVICE_COORDINATOR = "ServiceCoordinator",
  COORDINATOR = "Coordinator",
  LINE_MANAGER = "LineManager",
  MAIN_BOOKER = "ChiefAccountant",
  AUDITOR = "Auditor",
  SERVICE_ENGINEER = "ServiceEngineer",
}

export enum OCCUPATION_ROLE_TYPE {
  BOOKER = "Accountant",
  USER = "User",
  CFO = "FinancialDirector",
  ASSISTANT = "Assistant",
  ASSISTANT_MANAGER = "AssistantManager",
  SERVICE_COORDINATOR = "ServiceCoordinator",
  COORDINATOR = "Coordinator",
  LINE_MANAGER = "LineManager",
  MAIN_BOOKER = "ChiefAccountant",
  AUDITOR = "Auditor",
  SERVICE_ENGINEER = "ServiceEngineer",
}
