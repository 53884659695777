import styled from "styled-components";

const StyledBox = styled.div`
  background: ${(props) => (props.color ? props.color : "#F0F0EF")};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  justify-content: space-evenly;
  margin: 8px 28px;
`;

export { StyledBox };
