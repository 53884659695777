/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loaderLock, loaderUnlock } from "../../../store/common/actions";
import { forEach, isEmpty } from "lodash";
import { showErrors } from "store/exception/actions";
import SvgIcon from "app/component/svg-icon/SvgIcon";

import TextCutter from "../text-cutter/TextCutter";
import { getAttachments } from "../../../services/ApiService";
import { Form } from "react-bootstrap";

interface FileAttachProps {
  isOpen: boolean;
  isEdit: boolean;
  editId?: number | null;
  isWidget?: boolean;
  getAttachFiles: (attachFiles: any) => void;
  getDeletedIds: (deletedIds: any) => void;
}

const FileAttach: React.FC<FileAttachProps> = (props) => {
  const { t } = useTranslation();
  const dropZone = useRef({} as any);
  const dispatch = useDispatch();

  let [attachFiles, setAttachFiles] = useState([] as any);
  let [isDragOver, setIsDragOver] = useState(false);
  let [filesName, setFilesName] = useState([] as any);
  let [attachId, setAttachId] = useState([] as any);
  let [deletedIds, setDeletedIds] = useState([] as any);
  let [width, setWidth] = useState(0);

  let nameRef = useRef({} as any);

  const resizeHandler = () => {
    if (!isEmpty(filesName)) {
      setWidth(nameRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    resizeHandler();
  }, [filesName]);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  });

  useEffect(() => {
    props.getAttachFiles(attachFiles);
  }, [attachFiles]);

  useEffect(() => {
    props.getDeletedIds(deletedIds);
  }, [deletedIds]);

  useEffect(() => {
    if (props.editId) {
      let params = {
        "ObjectId.Id": props.editId,
        "ObjectId.LogicalName": "Expense",
      };
      dispatch(loaderLock());
      getAttachments({ params })
        .then((response: any) => {
          if (response.status === 200) {
            let filesName = response.data.data.map((item: any) => item.name);
            let filesId = response.data.data.map((item: any) => item.id);
            setFilesName(filesName);
            setAttachId(filesId);
          }
        })
        .finally(() => {
          dispatch(loaderUnlock());
        });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("dragover", (e) => {
      e.preventDefault();
    });
    window.addEventListener("drop", (e) => {
      e.preventDefault();
    });
    return () => {
      window.removeEventListener("drop", (e) => {
        e.preventDefault();
      });
      window.removeEventListener("dragover", (e) => {
        e.preventDefault();
      });
    };
  }, []);

  const dropZoneChangeHandler = () => {
    let file = [...dropZone.current.files];
    checkSizeAndFormat(file);
    dropZone.current.value = "";
  };

  const dropZoneDropHander = (e: any) => {
    setIsDragOver(false);
    let files = [...e.dataTransfer.files];
    checkSizeAndFormat(files);
    dropZone.current.value = "";
  };

  const checkSizeAndFormat = (files: any) => {
    if (files && files.length === 0) {
      return;
    }

    let maxFileSize = 104857600 as number;
    const checkSizeAndFormatFiles: boolean[] = [];
    forEach(files, (item: any, index: any) => {
      if (
        !(
          item.size <= maxFileSize &&
          (item.type === "image/png" ||
            item.type === "image/jpeg" ||
            item.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            item.type === "application/msword" ||
            item.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            item.type === "application/vnd.ms-excel" ||
            item.type === "application/pdf")
        )
      ) {
        checkSizeAndFormatFiles.push(false);
      }
    });

    if (isEmpty(checkSizeAndFormatFiles)) {
      let filesArray = [...attachFiles];
      let filesNameArray = [...filesName];
      forEach(files, (item: any, index: any) => {
        filesArray.push(item);
        filesNameArray.push(item.name);
      });
      setAttachFiles(filesArray);
      setFilesName(filesNameArray);
    } else {
      dispatch(
        showErrors({
          code: "",
          message: t("attach_file_error.message"),
        }),
      );
    }
  };

  const deleteAttachHandler = (index: number) => {
    if (!props.editId) {
      let filesArray = [...attachFiles];
      filesArray.splice(Number(index), 1);
      setAttachFiles(filesArray);

      let filesNameArray = [...filesName];
      filesNameArray.splice(Number(index), 1);
      setFilesName(filesNameArray);
    }
    if (props.editId) {
      let deletedIdsArray = [...deletedIds];
      if (
        !deletedIdsArray.filter((item) => item === attachId[index]).length &&
        attachId.length
      ) {
        deletedIdsArray.push(attachId[index]);
        setDeletedIds(deletedIdsArray);

        let attachIdArray = [...attachId];
        attachIdArray.splice(index, 1);
        setAttachId(attachIdArray);
      }

      if (!isEmpty(attachFiles)) {
        let filesArray = [...attachFiles];
        filesArray.splice(Number(index), 1);
        setAttachFiles(filesArray);
      }

      let filesNameArray = [...filesName];
      filesNameArray.splice(index, 1);
      setFilesName(filesNameArray);
    }
  };

  return (
    <Fragment>
      <div className="request-files">
        {!props.isWidget ? (
          <div
            className="request-files-dropzone"
            onDragOver={() => {
              setIsDragOver(true);
            }}
            onDragLeave={() => {
              setIsDragOver(false);
            }}
            onDrop={dropZoneDropHander}
            style={
              !isDragOver
                ? {
                    position: "relative",
                    zIndex: 10,
                    background: "url(img/icon-dropzone.svg) no-repeat 50% 40%",
                  }
                : {
                    position: "relative",
                    zIndex: 10,
                    background: "url(img/icon-dropzone.svg) no-repeat 50% 40%",
                    backgroundColor: "rgba(229, 230, 234, 1)",
                  }
            }
          >
            <input
              ref={dropZone}
              onChange={dropZoneChangeHandler}
              id="file-input"
              type="file"
              name="file"
              multiple
              title=""
              tabIndex={-1}
              style={{
                width: "100%",
                height: "100%",
                opacity: 0,
                position: "absolute",
                zIndex: -11,
                cursor: "pointer",
              }}
            />
            <label
              htmlFor="file-input"
              style={{
                position: "absolute",
                left: "10px",
                right: "10px",
                textAlign: "center",
                top: "70%",
              }}
            >
              {t("attach_file_message.massage")}
            </label>
          </div>
        ) : (
          <Form.Group
            className="request-files-dropzone"
            onDragOver={() => {
              setIsDragOver(true);
            }}
            onDragLeave={() => {
              setIsDragOver(false);
            }}
            onDrop={dropZoneDropHander}
            style={
              !isDragOver
                ? {
                    position: "relative",
                    zIndex: 10,
                    background: "url(img/icon-dropzone.svg) no-repeat 50% 40%",
                  }
                : {
                    position: "relative",
                    zIndex: 10,
                    background: "url(img/icon-dropzone.svg) no-repeat 50% 40%",
                    backgroundColor: "rgba(229, 230, 234, 1)",
                  }
            }
          >
            <Form.Control
              ref={dropZone}
              onChange={dropZoneChangeHandler}
              id="file-input"
              type="file"
              name="file"
              multiple
              title=""
              tabIndex={-1}
              style={{
                width: "100%",
                height: "100%",
                opacity: 0,
                position: "absolute",
                zIndex: -11,
                cursor: "pointer",
              }}
            />
            <Form.Label
              htmlFor="file-input"
              style={{
                position: "absolute",
                left: "10px",
                right: "10px",
                textAlign: "center",
                top: "70%",
              }}
            >
              {t("attach_file_message.massage")}
            </Form.Label>
          </Form.Group>
        )}
      </div>
      <div></div>
      {!isEmpty(filesName) ? (
        <div className={"input-wrap"}>
          <div
            className="request-expenses-item-file "
            ref={nameRef}
            style={{ marginBottom: "20px" }}
          >
            <div>
              <SvgIcon
                className={"icon icon-attach"}
                href={"#svg_icon_attach"}
              />
              {t("modals.create_order_modal.attached")} ({filesName.length}
              ):&nbsp;
            </div>
            {filesName.map((name: any, index: any) => (
              <Fragment>
                <p className="flex-center" style={{ marginTop: "10px" }}>
                  <b key={index}>
                    <TextCutter
                      text={name}
                      parentWidth={width}
                      options={[
                        {
                          maxLength: 90,
                          maxWidth: 645,
                          minWidth: 266,
                          symbolSize: 6,
                        },
                        {
                          maxLength: 40,
                          maxWidth: 265,
                          minWidth: 0,
                          symbolSize: 6,
                        },
                      ]}
                    />
                  </b>

                  <div className="icon icon-close file pointer">
                    <SvgIcon
                      href={"#svg_icon_close"}
                      index={index}
                      onClick={() => deleteAttachHandler(index)}
                      style={{ width: "17px" }}
                    />
                  </div>
                </p>
              </Fragment>
            ))}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default FileAttach;
