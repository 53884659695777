import "./Tooltip.css";
import React, { useState, useEffect } from "react";
import { getNextKey } from "app/utils";

interface TooltipProps {
  textArray?: (string | undefined | null)[];
  fontSize?: number;
  width?: number | string;
  active?: boolean;
  block?: boolean;
  notHovered?: boolean;
  textAlign?: "" | "center" | "left" | "right";
}

const Tooltip: React.FC<TooltipProps> = (props) => {
  let [tooltipProps, setTooltipProps] = useState({
    fontSize: "16px",
    width: "120px",
    marginLeft: "-60px",
  });
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    let newProps = { ...tooltipProps };

    if (props.fontSize) {
      newProps.fontSize = props.fontSize + "px";
    }

    if (props.width) {
      newProps.width = props.width + "px";
      newProps.marginLeft = "-" + props.width / 2 + "px";
    }

    setTooltipProps(newProps);
  }, [props.fontSize, props.width]);

  const visibility =
    props.textArray !== undefined && props.textArray[0] !== null
      ? "visible"
      : "hidden";

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={"tooltip-expense" + (props.block ? " block" : "")}
    >
      {props.children}

      {showTooltip && (
        <span
          className={
            "tooltiptext" +
            (props.active ? " active" : "") +
            (props.notHovered ? "" : " hovered")
          }
          style={{
            fontSize: tooltipProps.fontSize,
            width: tooltipProps.width,
            marginLeft: tooltipProps.marginLeft,
            textAlign: props.textAlign || "center",
            visibility,
          }}
        >
          {props.textArray
            ? props.textArray.map((item) => (
                <div key={getNextKey("Tooltip")}>
                  {item} <br />
                </div>
              ))
            : ""}
        </span>
      )}
    </div>
  );
};

export default Tooltip;
