import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ManagerListItem from "./ManagerListItem";
import PowerOfAttorneyAutocomplete from "../../../component/autocomplete/PowerOfAttorneyAutocomplete";
import { SvgIcon } from "ui/SvgIcon";
import {
  Authorities,
  Autocomplete,
  Body,
  ButtomContainer,
  Expire,
  Footer,
  Number,
  PowerStatus,
  Quantity,
  Representative,
  SigningContainer,
  SortTitleContainer,
  StatusFilterContainer,
  Tab,
} from "./ManagerListStyled";
import {
  AddButton,
  Header,
  PersonalAccount,
} from "../Employee/EmployeeListStyled";
import { PowerOfAttorneyDialog } from "app/pages/powers-of-attorney/dialogs";
import NoPowersOfAttorneyPage from "./NoPowersOfAttorneyPage";
import Pagination from "app/component/pagination/Pagination";
import DropDown from "./DropDown";
import NoFilteredPowersOfAttorneyPage from "./NoFilteredPowersOfAttorneyPage";
import { PowerOfAttorneyType } from "infrastructure/interfaces";
import PowerOfAttorneyItem from "../PowerOfAttorneyItem";
import PowerOfAttorneyCertificate from "../dialogs/PowerOfAttorneyDialogStep3";
import {
  getPowerOfAttorneyDetailed,
  IsPowerOfAttorneyEnabled,
  setListOfPowerOfAttorney,
} from "./../../../../store/power/actions";
import {
  getCommonCompaniesCatalog,
  getCommonUserDetail,
  isPowerOfAttorneyEnabled,
  getPowersOfAttorneyList,
} from "store/selectors";
import { ApplicationState } from "store/reducers";
import { MENU_FIELD_PERMISSIONS } from "infrastructure/enum/field-permissions.enum";

const ManagerList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const powerOfAttorneyList = useSelector(getPowersOfAttorneyList);
  const isEnabled = useSelector(isPowerOfAttorneyEnabled);
  const userDetail = useSelector(getCommonUserDetail);
  const companyDetails = useSelector(getCommonCompaniesCatalog);
  const menuFieldPermissions = useSelector(
    (state: ApplicationState) => state.common.menuFieldPermissions
  );

  const [searched, setSearched] = useState(true);
  const [isModalToCreate, setIsModalToCreate] = useState(false);
  const [itemsCount, setItemsCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [noPowers, setNoPowers] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("SigningRequires");
  const [isModalToSign, setIsModalToSign] = useState<
    PowerOfAttorneyType | boolean
  >(false);
  const [isModalToEdit, setIsModalToEdit] = useState(false);
  const [isDetailedModal, setIsDetailedModal] = useState(false);
  const [isManagerSection, setIsManagerSection] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const tabs = [
    { id: "All", name: "all" },
    { id: "Signed", name: "signed" },
    { id: "SigningRequires", name: "signing_requires" },
    { id: "Draft", name: "draft" },
    { id: "Revoked", name: "revoked" },
    { id: "Declined", name: "declined" },
  ];

  function isManagerSectionEnabled() {
    if (
      companyDetails.find(
        (el) => el.manager && el.manager.employee.id === userDetail.id
      ) ||
      menuFieldPermissions.includes(
        MENU_FIELD_PERMISSIONS.POWER_OF_ATTORNEY_MANAGER
      )
    ) {
      setIsManagerSection(true);
    }
  }

  useEffect(() => {
    isManagerSectionEnabled();
  }, [userDetail, powerOfAttorneyList]);

  useEffect(() => {
    dispatch(IsPowerOfAttorneyEnabled(userDetail));
    dispatch(
      setListOfPowerOfAttorney({ pageSize: 10 }, setItemsCount, setIsLoading)
    );
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (itemsCount > 0) {
        setNoPowers(false);
      }
      if (itemsCount <= 0) {
        setNoPowers(true);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      getListOfPowersofAttorney({ state: "SigningRequires", pageSize: 1000 });
    }
  }, [isLoading]);

  const getListOfPowersofAttorney = (params: {}) => {
    dispatch(setListOfPowerOfAttorney(params, setItemsCount));
    setCurrentPage(1);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return powerOfAttorneyList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, powerOfAttorneyList, itemsPerPage]);

  const onTabClick = (div: any) => {
    let id = div.target.id;
    setActiveTab(id);
    if (id === "All") {
      getListOfPowersofAttorney({ pageSize: 1000 });
    } else {
      getListOfPowersofAttorney({ state: id, pageSize: 1000 });
    }
  };

  const createPowerOfAttorneyHandler = () => {
    setIsModalToCreate(!isModalToCreate);
  };

  const onClickHandler = (
    e: React.ChangeEvent<HTMLDivElement> | React.ChangeEvent<HTMLButtonElement>,
    POW: PowerOfAttorneyType
  ): void => {
    if (e.target.id === "item-button") {
      setIsModalToSign(POW);
    } else {
      if (POW.state === "Draft" || POW.state === "Declined") {
        dispatch(getPowerOfAttorneyDetailed(POW.id, setIsModalToEdit));
      } else {
        dispatch(getPowerOfAttorneyDetailed(POW.id, setIsDetailedModal));
      }
    }
  };

  return isEnabled && isManagerSection ? (
    <>
      {isModalToEdit && (
        <PowerOfAttorneyDialog
          setIsModalToEdit={setIsModalToEdit}
          isModalToEdit={isModalToEdit}
        />
      )}
      {isModalToSign && (
        <PowerOfAttorneyCertificate
          signingBlobFileId={
            (isModalToSign as PowerOfAttorneyType).signingBlobFileId
          }
          powerOfAttorneyId={(isModalToSign as PowerOfAttorneyType).id}
          setIsModalToSign={setIsModalToSign}
          isModalToSign={Boolean(isModalToSign)}
        />
      )}
      <PowerOfAttorneyItem
        setPowerAttorney={setIsDetailedModal}
        isPowerAttorney={isDetailedModal}
        isManager={
          !!companyDetails.find(
            (el) => el.manager && el.manager.employee.id === userDetail.id
          )
        }
      />
      <Header>{t("menu.powers_of_attorney")}</Header>
      <PersonalAccount>
        <span style={{ color: "#0A0A0A" }}> {t("menu.main")}</span>
        {"  "}
        <SvgIcon id={"vectorRight"} size={13.5} color={"#0A0A0A"} />
        {"  "}
        {t("menu.powers_of_attorney")}
      </PersonalAccount>
      <Autocomplete>
        <PowerOfAttorneyAutocomplete
          placeholder={t("power.autocomplete")}
          setSearched={setSearched}
          setItemsCount={setItemsCount}
          setActive={setActiveTab}
          setCurrentPage={setCurrentPage}
        />
        <ButtomContainer>
          <AddButton onClick={createPowerOfAttorneyHandler}>
            <SvgIcon id={"browser"} size={13.3} color={"#FFFFFF"} />
            <div>{t("power.create_power_of_attorney")}</div>
          </AddButton>
        </ButtomContainer>
      </Autocomplete>
      {noPowers ? (
        <NoPowersOfAttorneyPage />
      ) : (
        <Body>
          <Quantity>
            {t("power.of_attorney")}: {itemsCount}
          </Quantity>
          <StatusFilterContainer>
            {tabs.map((el) => (
              <Tab
                key={el.id}
                id={el.id}
                active={activeTab === el.id}
                onClick={onTabClick}
              >
                {t(`power.tab.${el.name}`)}
              </Tab>
            ))}
          </StatusFilterContainer>
          <SortTitleContainer>
            <Number style={{ whiteSpace: "nowrap" }}>
              {t("power.number")}{" "}
            </Number>
            <Expire style={{ whiteSpace: "nowrap" }}>
              {t("power.expire")}{" "}
            </Expire>
            <Representative style={{ whiteSpace: "nowrap" }}>
              {t("power.representative")}{" "}
            </Representative>
            <Authorities>{t("power.authorities")}</Authorities>
            <PowerStatus>{t("power.state")}</PowerStatus>
            <SigningContainer>{t("power.tab.signed")} </SigningContainer>
          </SortTitleContainer>
          {!searched ? (
            <NoFilteredPowersOfAttorneyPage />
          ) : (
            <>
              {powerOfAttorneyList &&
                currentTableData.map((el) => (
                  <ManagerListItem
                    onClick={onClickHandler}
                    key={el.id}
                    POW={el}
                    isManager={
                          el.owner.company.managerEmployee.id && el.owner.company.managerEmployee.id === userDetail.id
                    }
                  />
                ))}
              <Footer>
                <DropDown
                  lable={`Отображать по ${itemsPerPage}`}
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={setItemsPerPage}
                  setCurrentPage={setCurrentPage}
                />
                <Pagination
                  totalCount={powerOfAttorneyList.length}
                  pageSize={itemsPerPage}
                  onPageChange={(page) => setCurrentPage(page)}
                  currentPage={currentPage}
                />
              </Footer>
            </>
          )}
        </Body>
      )}
      {isModalToCreate && (
        <PowerOfAttorneyDialog
          setIsModalToCreate={setIsModalToCreate}
          isModalToCreate={isModalToCreate}
        />
      )}
    </>
  ) : null;
};

export default ManagerList;
