import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./CurrencyInput.module.css";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  currency?: string;
  field: string;
  label?: string;
}

const CurrencyInput: FC<Props> = ({ ...props }) => {
  const { label, currency, field, className, ...rest } = props;
  const [width, setWidth] = useState("auto");
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (input.current && input.current.value.length > 0) {
      setWidth(input.current.value.length + 0.65 + "ch");
    }
  }, [input, props]);

  const validateFormat = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "e") {
      e.preventDefault();
    }
  };

  return (
    <div className={`${className} d-flex flex-column`}>
      <label htmlFor={field} className="mb-2 text-nowrap">
        {label}
      </label>
      <span
        className={styles.currencyInputContainer}
        onClick={() => input.current && input.current.focus()}
      >
        <input
          onKeyDown={validateFormat}
          style={{ width }}
          ref={input}
          className={styles.currencyInput}
          id={field}
		  type="number"
          inputMode="decimal"
          {...rest}
        />
        {currency}
      </span>
    </div>
  );
};

export default CurrencyInput;
