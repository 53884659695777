/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect, CSSProperties } from "react";
import "./CityAutocomplete.css";
import { useTranslation } from "react-i18next";
import SvgIcon from "../svg-icon/SvgIcon";
import Slidebar from "../slidebar/Slidebar";
import { SelectOptionType } from "../select/Select";
import { Dropdown, Form } from "react-bootstrap";

export interface SelectAutocompleteProps {
  name: string;
  options: SelectOptionType[];
  onChoose?: (option: SelectOptionType) => void;
  defaultText?: string;
  defaultValue?: SelectOptionType | null;
  readOnly?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  tabIndex?: -1 | 0;
  isWidget?: boolean;
}

const SelectAutocomplete: React.FC<SelectAutocompleteProps> = (props) => {
  let { t, i18n } = useTranslation();

  let [isOpen, setOpen] = useState(false);
  let [isLoading, setLoading] = useState(false);
  let [inputValue, setInputValue] = useState(
    props.defaultText ? props.defaultText : "",
  );
  let [filteredOption, setFilteredOption] = useState([] as SelectOptionType[]);
  let [error, setError] = useState(false);

  let inputRef = useRef({} as HTMLInputElement);

  useEffect(() => {
    setInputValue(props.defaultText ? props.defaultText : "");
  }, [props.defaultText]);

  useEffect(() => {
    setInputValue(props.defaultValue ? props.defaultValue.text : "");
  }, [props.defaultValue]);

  const optionClickHandler = (option: SelectOptionType) => {
    setInputValue(option.text);
    setOpen(false);
    if (props.onChoose) props.onChoose(option);
  };

  const changeInput = () => {
    if (props.onChoose)
      props.onChoose({ value: 0, text: inputRef.current.value });
    setInputValue(inputRef.current.value);
    if (inputRef.current.value.length > 0) {
      getFilteredOption();
    } else {
      setFilteredOption([] as SelectOptionType[]);
      setOpen(false);
    }
  };

  const missClickHandler = () => {
    if (isOpen) {
      setOpen(false);
    }
  };

  const getFilteredOption = async () => {
    setFilteredOption([] as SelectOptionType[]);
    setOpen(true);
    setLoading(true);
    setError(false);

    let newOption = props.options.filter((item) =>
      item.text
        .toLocaleLowerCase()
        .includes(inputRef.current.value.toLocaleLowerCase()),
    );
    setFilteredOption(newOption);

    setLoading(false);
  };

  return props.isWidget ? (
    <Dropdown className={"input-search" + (props.disabled ? " disabled" : "")}>
      <div className={"select " + (isOpen ? "active" : "")}>
        {isOpen ? (
          <div className="miss-click-handler" onClick={missClickHandler} />
        ) : (
          ""
        )}
        <SvgIcon className="icon icon-search" href="#svg_icon_search" />
        <Form.Control
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={changeInput}
          readOnly={props.readOnly}
          disabled={props.disabled}
          style={{ fontSize: "16px" }}
          tabIndex={props.tabIndex}
        />
        <Slidebar
          className="select-content"
          style={{ zIndex: 20, maxHeight: "186px" }}
        >
          {filteredOption.length > 0
            ? filteredOption.map((option, index) => {
                return (
                  <div
                    className="select-content-item"
                    key={index}
                    onClick={() => optionClickHandler(option)}
                  >
                    {option.text}
                  </div>
                );
              })
            : null}
          {!error && !isLoading && filteredOption.length === 0 ? (
            <div className="select-content-item">
              {t("custom_property.no_select_found", { name: props.name })}
            </div>
          ) : null}
          {error ? (
            <div className="select-content-item">
              {t("create_expense.search_error")}
            </div>
          ) : null}
          {isLoading ? (
            <div className="select-content-item">
              <div
                className="lds-ellipsis"
                style={{ marginLeft: "calc(50% - 30px)" }}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : null}
        </Slidebar>
      </div>
    </Dropdown>
  ) : (
    <div className={"input-search" + (props.disabled ? " disabled" : "")}>
      <div className={"select " + (isOpen ? "active" : "")}>
        {isOpen ? (
          <div className="miss-click-handler" onClick={missClickHandler} />
        ) : (
          ""
        )}
        <SvgIcon className="icon icon-search" href="#svg_icon_search" />
        <input
          className="input"
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={changeInput}
          readOnly={props.readOnly}
          disabled={props.disabled}
          style={{ fontSize: "16px" }}
          tabIndex={props.tabIndex}
        />
        <Slidebar
          className="select-content"
          style={{ zIndex: 20, maxHeight: "186px" }}
        >
          {filteredOption.length > 0
            ? filteredOption.map((option, index) => {
                return (
                  <div
                    className="select-content-item"
                    key={index}
                    onClick={() => optionClickHandler(option)}
                  >
                    {option.text}
                  </div>
                );
              })
            : null}
          {!error && !isLoading && filteredOption.length === 0 ? (
            <div className="select-content-item">
              {t("custom_property.no_select_found", { name: props.name })}
            </div>
          ) : null}
          {error ? (
            <div className="select-content-item">
              {t("create_expense.search_error")}
            </div>
          ) : null}
          {isLoading ? (
            <div className="select-content-item">
              <div
                className="lds-ellipsis"
                style={{ marginLeft: "calc(50% - 30px)" }}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : null}
        </Slidebar>
      </div>
    </div>
  );
};

export default SelectAutocomplete;
