import React, { useEffect, useState } from "react";
import ModalContainer from "ui/Modal/ModalContainer";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PowerOfAttorneyType } from "infrastructure/interfaces";
import DatePickerCalendar from "app/component/date-picker/DatePickerCalendar";
import { formatter } from "app/utils";
import SvgIconS from "app/component/svg-icon/SvgIcon";
import { revoke } from "store/power/actions";

const Modal = styled.div`
  z-index: 10001;
  background: #ffffff;
  width: 424px;
  position: relative;
  width: 424px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 21px;
`;
const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.017em;
  color: #0a0a0a;
  margin-bottom: 8px;
`;
const Textarea = styled.textarea`
  display: flex;
  padding: 6px 12px 6px 16px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  margin-bottom: 20px;
  heigh: auto;
  width: 100%;
  resize: vertical;
`;

const CalendarContainer = styled.div`
  display: inline-flex;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  position: relative;
  width: 60%;
  height: 40px;
  align-self: center;
`;
const Calendar = styled.input`
  padding: 6px 12px 6px 16px;
  background: #ffffff;
  border: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
const ButtonBack = styled.button`
  align-items: center;
  width: 102px;
  padding: 6px 16px;
  height: 32px;
  background: none;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #5d6c74;
  color: #5d6c74;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-right: 4px;
  &:hover {
    background: rgb(223, 225, 227);
  }
`;
const ButtonDecline = styled.button`
  align-items: center;
  padding: 6px 16px;
  width: 102px;
  height: 32px;
  background: #4cd964;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-left: 4px;
  &:hover {
    background: rgb(63, 181, 83);
  }
  &:disabled {
    background-color: #ededed;
    color: #9e9e9e;
    cursor: not-allowed;
  }
`;

interface RevokeProps {
  powerOfAttorney: PowerOfAttorneyType;
  isModalToRevoke: boolean;
  setIsModalToRevoke: (a: boolean) => void;
  isInfoModal: (a: boolean) => void;
}

const Revoke: React.FC<RevokeProps> = ({
  powerOfAttorney,
  isModalToRevoke,
  setIsModalToRevoke,
  isInfoModal,
}) => {
  const dispatch = useDispatch();
  let { t, i18n } = useTranslation();

  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [isOpenToCalendar, setOpenToCalendar] = useState(false);
  const [isOpenToCalendarHelper, setOpenToCalendarHelper] = useState(false);
  const [startDate, setStartDate] = useState(null as any);
  const [disableBeforeDate, setDisableBeforeDate] = useState(new Date());
  const [disableAfterDate, setDisableAfterDate] = useState(new Date());

  const endDate = moment(powerOfAttorney.validTo)
    .add("days", -1)
    .startOf("day");
  const minimumDate = moment().add("days", 1).startOf("day");

  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    setDisableBeforeDate(moment(minimumDate, "YYYY-MM-DDThh:mm:ss").toDate());
    setDisableAfterDate(moment(endDate, "YYYY-MM-DDThh:mm:ss").toDate());
  }, []);

  useEffect(() => {
    if (message.length > 0 && startDate !== null) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [message, startDate]);

  const revokeHandler = () => {
    const revokeData = {
      comment: message,
      revokeDate: new Date(startDate).toISOString().split("T")[0],
      utcOffset: moment().utcOffset(),
    };
    dispatch(
      revoke(powerOfAttorney.id, revokeData, setIsModalToRevoke, isInfoModal),
    );
  };

  const closeModalHandler = () => {
    if (isModalToRevoke && setIsModalToRevoke) {
      setIsModalToRevoke(!isModalToRevoke);
    }
  };
  const onSelectedToDate = (value: any) => {
    setStartDate(value.startDate);
    setOpenToCalendar(false);
  };

  const onClickToCalendarToggle = () => {
    setOpenToCalendar(false);
    setOpenToCalendarHelper(false);
  };

  const onClickToCalendar = () => {
    setOpenToCalendar(!isOpenToCalendar);
    setOpenToCalendarHelper(!isOpenToCalendarHelper);
  };

  return (
    <div>
      <ModalContainer style={{ zIndex: 101 }} isOpen={isModalToRevoke}>
        <Modal>
          <Title>{t("power.revocation_reason")}</Title>
          <Textarea
            value={message}
            onChange={handleMessageChange}
            placeholder={t("power.write_reason_of_revocation_reason")}
          />
          <Title>{t("power.revocation_date")}</Title>
          <CalendarContainer onClick={onClickToCalendar}>
            <Calendar
              value={
                startDate &&
                formatter("D MMMM YYYY", i18n.language, startDate || "")
              }
              type="text"
              placeholder={t("ДД.ММ.ГГ")}
              readOnly={true}
              tabIndex={-1}
            />
            <SvgIconS
              className={"icon icon-calendar"}
              href={"#svg_icon_calendar"}
            />
          </CalendarContainer>
          <DatePickerCalendar
            startDate={startDate}
            isShown={isOpenToCalendar}
            month={disableBeforeDate}
            onSelected={onSelectedToDate}
            isCleansed={false}
            isMultiChoice={false}
            onClose={() => onClickToCalendarToggle()}
            disableBeforeDate={disableBeforeDate}
            disableAfterDate={disableAfterDate}
          />
          <ButtonContainer>
            <ButtonBack onClick={() => closeModalHandler()}>
              {t("power.button.cancel")}
            </ButtonBack>
            <ButtonDecline onClick={() => revokeHandler()} disabled={disabled}>
              {t("power.button.revoke")}
            </ButtonDecline>
          </ButtonContainer>
        </Modal>
      </ModalContainer>
    </div>
  );
};

export default Revoke;
