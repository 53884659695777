/**
 * @typedef {Object} EXPENSE_TYPE
 * @property {string} BOX - коробка
 * @property {string} PERDIEM - коробка
 * @property {string} AVIA - самолет
 * @property {string} BUILDING - здание(гостиница)
 * @property {string} TRANSFER - трансфер (такси, авто на заказ)
 * @property {string} TRAIN - трансфер (жд)
 * @property {string} VISA - visa
 */

export enum EXPENSE_TYPE {
  BOX = "Other",
  PERDIEM = "Perdiem",
  AVIA = "Avia",
  BUILDING = "Hotel",
  TRANSFER = "Transfer",
  TRAIN = "Train",
  VISA = "Visa",
}

export const expenseStylesMap = {
  [EXPENSE_TYPE.BOX]: "icon icon-box",
  [EXPENSE_TYPE.PERDIEM]: "icon icon-box",
  [EXPENSE_TYPE.AVIA]: "icon icon-plane",
  [EXPENSE_TYPE.BUILDING]: "icon icon-building",
  [EXPENSE_TYPE.TRANSFER]: "icon icon-car",
  [EXPENSE_TYPE.TRAIN]: "icon icon-tram",
  [EXPENSE_TYPE.VISA]: "icon icon-box",
};

export const expenseHrefMap = {
  [EXPENSE_TYPE.BOX]: "#svg_icon_box",
  [EXPENSE_TYPE.PERDIEM]: "#svg_icon_box",
  [EXPENSE_TYPE.AVIA]: "#svg_icon_plane",
  [EXPENSE_TYPE.BUILDING]: "#svg_icon_building",
  [EXPENSE_TYPE.TRANSFER]: "#svg_icon_car",
  [EXPENSE_TYPE.TRAIN]: "#svg_icon_tram",
  [EXPENSE_TYPE.VISA]: "#svg_icon_box",
};
