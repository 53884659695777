import React from "react";
import { useSelector } from "react-redux";
import ModalContainer from "../modal/ModalContainer";
import { SvgIcon } from "ui/SvgIcon";
import {
  Button,
  NoticePanelButtonGroup,
  NoticePanelContainer,
  NoticePanelInfo,
  NoticePanelInfoText,
  NoticePanelInfoTitle,
} from "./NoticePanel2Styled";
import { chequeNotice } from "store/selectors";

export interface NoticeItem {
  noticeIconID: string; // "alertcircle" | "alertTriangle"
  color: string; // '#228BE6' | "#FA5252" | "#FAB005" | "#5D6C74"
  noticeTitle: string;
  noticeText?: string;
  actionButton: { text: string; color?: string; func: () => void };
  actionButton2?: { text: string; color?: string; func: () => void };
  cancelButton?: { text: string; color?: string; func: () => void };
}

const NoticePanel2: React.FC = () => {
  const notion = useSelector(chequeNotice);

  const { isEnabled, notice } = notion;

  const actionHandler = () => {
    if (notice !== null) {
      notice.actionButton.func();
    }
  };
  const actionHandler2 = () => {
    if (notice !== null && notice.actionButton2) {
      notice.actionButton2.func();
    }
  };
  const cancelHandler = () => {
    if (notice !== null && notice.cancelButton) {
      notice.cancelButton.func();
    }
  };
  return (
    <>
      {notice !== null && (
        <ModalContainer
          isOpen={isEnabled}
          destroy={true}
          id={-1}
          style={true ? { zIndex: 1002 } : undefined} // todo
        >
          <NoticePanelContainer>
            <NoticePanelInfo>
              <div style={{ marginRight: "16px" }}>
                <SvgIcon
                  id={notice.noticeIconID}
                  color={notice.color}
                  size={30}
                />
              </div>
              <div>
                <NoticePanelInfoTitle>
                  {notice.noticeTitle}
                </NoticePanelInfoTitle>
                <NoticePanelInfoText>{notice.noticeText}</NoticePanelInfoText>
              </div>
            </NoticePanelInfo>
            <NoticePanelButtonGroup
              style={
                notice.cancelButton
                  ? { justifyContent: "space-between" }
                  : { justifyContent: "flex-end" }
              }
            >
              {notice.cancelButton && notice.cancelButton.text && (
                <Button
                  onClick={cancelHandler}
                  backgroundColor={
                    notice.actionButton.color ? notice.cancelButton.color : ""
                  }
                >
                  {notice.cancelButton.text}
                </Button>
              )}
              <div>
                {notice.actionButton2 && notice.actionButton2.text && (
                  <Button
                    onClick={actionHandler2}
                    backgroundColor={
                      notice.actionButton.color
                        ? notice.actionButton2.color
                        : ""
                    }
                  >
                    {notice.actionButton2.text}
                  </Button>
                )}
                <Button
                  onClick={actionHandler}
                  backgroundColor={notice.actionButton.color}
                >
                  {notice.actionButton.text}
                </Button>
              </div>
            </NoticePanelButtonGroup>
          </NoticePanelContainer>
        </ModalContainer>
      )}
    </>
  );
};

export default NoticePanel2;
