import styled from "styled-components";

const StyledReportHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`;

const StyledReportHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 315px;
`;

const StyledReportHeaderInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #000000;
`;

const StyledReportUser = styled.div`
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.017em;
  color: #000000;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledReportText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #5d6c74;
  gap: 4px;
`;

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #98a2a8;
  background-color: #98a2a8;
`;

export {
  StyledReportHeaderContainer,
  StyledReportHeaderRow,
  StyledReportHeaderInfo,
  StyledReportUser,
  StyledReportText,
  StyledLine,
};
