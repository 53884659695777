import React from "react";
import { IconProps } from "../types";

const WarningCircle: React.FC<IconProps> = (props) => {
  return (
    <div style={props.style}>
      <svg
        className="icon icon-warning"
        style={{ right: "6px" }}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.48599 3.24431C4.14984 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71761 10.009 2.03112 11.5851C2.34462 13.1612 3.11845 14.6089 4.25476 15.7452C5.39106 16.8815 6.83879 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1226 7.84586 17.2658 5.78065 15.7426 4.25744C14.2193 2.73424 12.1541 1.87743 10 1.875ZM9.375 6.25C9.375 6.08424 9.44085 5.92527 9.55806 5.80806C9.67527 5.69085 9.83424 5.625 10 5.625C10.1658 5.625 10.3247 5.69085 10.4419 5.80806C10.5592 5.92527 10.625 6.08424 10.625 6.25V10.625C10.625 10.7908 10.5592 10.9497 10.4419 11.0669C10.3247 11.1842 10.1658 11.25 10 11.25C9.83424 11.25 9.67527 11.1842 9.55806 11.0669C9.44085 10.9497 9.375 10.7908 9.375 10.625V6.25ZM10 14.375C9.81458 14.375 9.63332 14.32 9.47915 14.217C9.32498 14.114 9.20482 13.9676 9.13386 13.7963C9.0629 13.625 9.04434 13.4365 9.08051 13.2546C9.11669 13.0727 9.20597 12.9057 9.33709 12.7746C9.4682 12.6435 9.63524 12.5542 9.8171 12.518C9.99896 12.4818 10.1875 12.5004 10.3588 12.5714C10.5301 12.6423 10.6765 12.7625 10.7795 12.9167C10.8825 13.0708 10.9375 13.2521 10.9375 13.4375C10.9375 13.6861 10.8387 13.9246 10.6629 14.1004C10.4871 14.2762 10.2486 14.375 10 14.375Z"
          fill="#E00000"
        />
      </svg>
    </div>
  );
};

export default WarningCircle;
