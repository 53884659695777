import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import SvgIconS from "app/component/svg-icon/SvgIcon";
import { SvgIcon } from "ui/SvgIcon";
import DatePickerCalendar from "app/component/date-picker/DatePickerCalendar";
import { formatter } from "app/utils";
import { UpperLabel } from "../PowerOfAttorneyCommonStyled";

const Warning = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding-top: 4px;
  color: #616161;

  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
`;
const DatePickerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 0%;
  @media screen and (max-width: 980px) {
    top: 100px;
    right: 240px;
  }
  @media screen and (max-width: 640px) {
    right: 230px;
  }
`;

interface CalendarProps {
  setStartDate: (a: boolean) => void;
  startDate: any;
  selectedDate: string | Date;
  warningDate: string | Date;
  setSelectedDate: (a: string | Date) => void;
  POWDate?: string | Date | undefined;
}

const Calendar: React.FC<CalendarProps> = (props) => {
  let { t, i18n } = useTranslation();

  const [screenWidth] = useState(window.innerWidth);
  const [isOpenToCalendar, setOpenToCalendar] = useState(false);
  const [isOpenToCalendarHelper, setOpenToCalendarHelper] = useState(false);

  let width980 = useMemo(() => {
    return screenWidth < 981;
  }, [screenWidth]);

  const onClickToCalendar = () => {
    setOpenToCalendar(!isOpenToCalendar);
    setOpenToCalendarHelper(!isOpenToCalendarHelper);
  };

  const onClickToCalendarToggle = () => {
    setOpenToCalendar(false);
    setOpenToCalendarHelper(false);
  };
  const resizeMax = () => {
    setOpenToCalendarHelper(false);

    if (window.innerWidth < 983 && isOpenToCalendarHelper) {
      if (isOpenToCalendarHelper) {
        setOpenToCalendar(false);
        setOpenToCalendarHelper(false);
      }
      window.removeEventListener("resize", resizeMax);
      window.addEventListener("resize", resizeMin);
    }
  };

  const resizeMin = () => {
    if (window.innerWidth < 980 && isOpenToCalendarHelper) {
      if (isOpenToCalendarHelper) {
        setOpenToCalendar(false);
        setOpenToCalendarHelper(false);
      }
      window.removeEventListener("resize", resizeMin);
      window.addEventListener("resize", resizeMax);
    }
  };

  useEffect(() => {
    if (isOpenToCalendarHelper) {
      window.addEventListener("resize", resizeMin);
    } else if (isOpenToCalendarHelper) {
      window.addEventListener("resize", resizeMax);
    }
    if (!isOpenToCalendarHelper) {
      window.removeEventListener("resize", resizeMin);
      window.removeEventListener("resize", resizeMax);
    }
  }, [isOpenToCalendarHelper]);

  const onSelectedToDate = (value: any) => {
    props.setStartDate(value.startDate);
    setOpenToCalendar(false);
  };

  useEffect(() => {
    if (props.POWDate) {
      props.setSelectedDate(props.POWDate);
    } else {
      props.setSelectedDate(props.startDate);
    }
  }, [props.startDate]);

  const [warningMIN, setWarningMIN] = useState(false);
  const [warningMAX, setWarningMAX] = useState(false);

  useEffect(() => {
    if (props.warningDate < props.startDate) {
      setWarningMAX(true);
      setWarningMIN(false);
    } else if (today > props.startDate && props.startDate !== null) {
      setWarningMAX(false);
      setWarningMIN(true);
    } else {
      setWarningMAX(false);
      setWarningMIN(false);
    }
  }, [props.startDate, props.selectedDate]);

  let today = new Date();

  let minimumDay = moment().add(1, "days");
  let maximumDate = moment().add(3, "years");

  return (
    <div
      className="input-item"
      style={{ alignSelf: "baseline", position: "relative" }}
    >
      <UpperLabel>{t("power.expiration_date")}</UpperLabel>
      <div
        className={`input-picker-wrap input-date ${
          props.startDate ? "active" : ""
        }`}
      >
        <input
          style={{
            border: warningMIN || warningMAX ? "2px solid #E00000" : "",
            color: props.startDate ? "" : "#9E9E9E",
          }}
          value={
            props.startDate
              ? formatter("D MMMM YYYY", i18n.language, props.startDate)
              : props.POWDate
                ? formatter("D MMMM YYYY", i18n.language, props.POWDate)
                : t("power.calendar_mask")
          }
          onClick={onClickToCalendar}
          className="input datepicker pointer"
          type="text"
          id="datepicker"
          placeholder={t("ДД.ММ.ГГ")}
          readOnly={true}
          tabIndex={-1}
        />
        {warningMIN || warningMAX ? (
          <SvgIcon id={"warningCircle"} />
        ) : (
          <SvgIconS
            className={"icon icon-calendar"}
            href={"#svg_icon_calendar"}
            onClick={onClickToCalendar}
          />
        )}
      </div>
      {warningMAX && (
        <Warning>
          {t("power.maximum_until")}{" "}
          {moment(maximumDate).locale(i18n.language).format("DD.MM.YYYY")}{" "}
        </Warning>
      )}
      {warningMIN && (
        <Warning>
          {t("power.not_earlier")}
          {moment(minimumDay).locale(i18n.language).format("DD.MM.YYYY")}
        </Warning>
      )}
      <DatePickerContainer>
        <DatePickerCalendar
          isDisableBeforeToday={false}
          isDisableToday={false}
          startDate={props.startDate}
          isShown={isOpenToCalendar}
          month={props.startDate ? props.startDate : today}
          onSelected={onSelectedToDate}
          isCleansed={false}
          isMultiChoice={false}
          onClose={() => onClickToCalendarToggle()}
          miniVector={width980}
          disableBeforeDate={moment(
            moment().add(1, "days").startOf("day"),
            "YYYY-MM-DDThh:mm:ss",
          ).toDate()}
        />
      </DatePickerContainer>
    </div>
  );
};

export default Calendar;
