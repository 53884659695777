import React, { useState, Fragment, useEffect, useMemo } from "react";
import SvgIcon from "../svg-icon/SvgIcon";
import { Switch, Route } from "react-router";
import Profile from "app/pages/profile/Profile";
import Substitution from "app/pages/substitution/Substitution";
import Assistance from "app/pages/assistance/Assistance";
import Calculations from "app/pages/calculations/Calculations";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ApplicationState } from "store/reducers";
import If from "../util/If";
import { useSelector } from "react-redux";
import {
  ROLE_TYPE,
  OCCUPATION_ROLE_TYPE,
} from "infrastructure/enum/user-role.enum";
import { IOccupations } from "infrastructure/interfaces";
import { getCommonUserDetail } from "../../../store/selectors";
import { PROFILE_FIELD_PERMISSIONS } from "../../../infrastructure/enum/field-permissions.enum";
import PowersOfAttorneyList from "app/pages/powers-of-attorney/Employee/EmployeeList";
import ManagerList from "app/pages/powers-of-attorney/Manager/ManagerList";

const User: React.FC = () => {
  let { t } = useTranslation();

  const userDetail = useSelector(getCommonUserDetail);
  const profileFieldPermissions = useSelector(
    (state: ApplicationState) => state.common.profileFieldPermissions,
  );

  let [isOpenProfile, setOpenProfile] = useState(false);
  let [isOpenSubstitution, setOpenSubstituation] = useState(false);
  let [isOpenSettlement, setOpenSettlement] = useState(false);
  let [isOpenAssistance, setOpenAssistance] = useState(false);

  const showProfile: boolean = useMemo(() => {
    return (
      profileFieldPermissions &&
      profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.PROFILE)
    );
  }, [profileFieldPermissions]);
  const showSubstitution: boolean = useMemo(() => {
    return (
      profileFieldPermissions &&
      profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.SUBSTITUTION)
    );
  }, [profileFieldPermissions]);
  const showSettlement: boolean = useMemo(() => {
    return (
      profileFieldPermissions &&
      profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.SETTLEMENT)
    );
  }, [profileFieldPermissions]);
  const showAssistance: boolean = useMemo(() => {
    return (
      profileFieldPermissions &&
      profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.ASSISTANCE)
    );
  }, [profileFieldPermissions]);

  const checkOpenProfile = (match = {}) => {
    setOpenProfile(!!match);
    return !!match;
  };

  const checkOpenSubstituation = (match = {}) => {
    setOpenSubstituation(!!match);
    return !!match;
  };

  const checkOpenAssistance = (match = {}) => {
    setOpenAssistance(!!match);
    return !!match;
  };

  const checkOpenSettlement = (match = {}) => {
    setOpenSettlement(!!match);
    return !!match;
  };

  const visibleEmployeePowerOfAttorney = window.location.href.includes(
    "/User/PowersOfAttorney",
  );

  return (
    <Fragment>
      {visibleEmployeePowerOfAttorney && profileFieldPermissions ? (
        <Route path="/User/PowersOfAttorney" component={PowersOfAttorneyList} />
      ) : (
        <>
          <div className="profile-title">{t("user.profile")}</div>
          <div className="profile-menu">
            {showProfile ? (
              <NavLink
                className="profile-menu-item"
                to="/User/Profile"
                isActive={checkOpenProfile}
                activeClassName="active"
              >
                <div className="profile-menu-title">
                  <SvgIcon
                    href="#svg_icon_profile-1"
                    className="icon icon-profile-1"
                  />
                  <span>{t("user.profile")}</span>
                </div>
                <div className="profile-menu-text">
                  {t("user.profile_header")}
                </div>
              </NavLink>
            ) : null}
            {showSubstitution ? (
              <NavLink
                className="profile-menu-item"
                to="/User/Substitution"
                isActive={checkOpenSubstituation}
                activeClassName="active"
              >
                <div className="profile-menu-title">
                  <SvgIcon
                    href="#svg_icon_profile-2"
                    className="icon icon-profile-1"
                  />
                  <span>{t("user.substitution")}</span>
                </div>
                <div className="profile-menu-text">
                  {t("user.substitution_header")}
                </div>
              </NavLink>
            ) : null}
            {showAssistance ? (
              <NavLink
                className="profile-menu-item"
                to="/User/Assistance"
                isActive={checkOpenAssistance}
                activeClassName="active"
              >
                <div className="profile-menu-title">
                  <SvgIcon
                    href="#svg_icon_profile-2"
                    className="icon icon-profile-1"
                  />
                  <span>{t("user.assistance")}</span>
                </div>
                <div className="profile-menu-text">
                  {t("user.assistance_header")}
                </div>
              </NavLink>
            ) : null}
            {showSettlement ? (
              <NavLink
                className="profile-menu-item"
                to="/User/Settlement"
                isActive={checkOpenSettlement}
                activeClassName="active"
              >
                <div className="profile-menu-title">
                  <SvgIcon
                    href="#svg_icon_profile-3"
                    className="icon icon-profile-1"
                  />
                  <span>{t("user.settlement")}</span>
                </div>
                <div className="profile-menu-text">
                  {t("user.settlement_header")}
                </div>
              </NavLink>
            ) : null}
          </div>
          <Switch>
            {showProfile ? (
              <Route path="/User/Profile" component={Profile} />
            ) : null}
            {showSubstitution ? (
              <Route path="/User/Substitution" component={Substitution} />
            ) : null}
            {showAssistance ? (
              <Route path="/User/Assistance" component={Assistance} />
            ) : null}
            {showSettlement ? (
              <Route path="/User/Settlement" component={Calculations} />
            ) : null}
          </Switch>
        </>
      )}
    </Fragment>
  );
};

export default User;
