import styled from "styled-components";
import { InputProps } from "../../types";

export const CompanyAdressTextarea = styled.textarea<InputProps>`
  min-height: 40px;
  border: 1px solid ${(props) => (props.error ? "#E00000" : "#c2c2c2")};
  border-radius: 2px;
  font-size: 16px;
  padding: 12px 8px;
  width: 100%;
  font-family: "HarmoniaSansProCyr";
  resize: none;
  @media screen and (max-width: 480px) {
    white-space: normal;
    padding: 11px 9px;
  }
`;

export const CompanyData = styled.div`
  width: 32.6%;
  @media screen and (max-width: 980px) {
    width: 33%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    line-height: 30px;
  }
`;

export const ManagerInfoContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
  justify-content: space-between;
  max-height: 100%;
  margin: 0 0 36px;
  @media screen and (max-width: 640px) {
    display: block;
    margin-bottom: 60px;
  }
`;

export const ManagerName = styled.div`
  min-height: 40px;
  font-size: 16px;
  width: 66.3%;
  font-family: "HarmoniaSansProCyr";
  max-height: 100%;
  @media screen and (max-width: 640px) {
    font-size: 14px;
    width: 100%;
  }
`;

export const ManagerSnils = styled.div`
  height: 40px;
  font-size: 16px;
  width: 32.5%;
  font-family: "HarmoniaSansProCyr";

  @media screen and (max-width: 980px) {
    width: 33%;
  }
  @media screen and (max-width: 640px) {
    font-size: 14px;
    width: 50%;
  }

  @media screen and (max-width: 480px) {
    width: 70%;
  }
`;

export const ManagerSnilsInput = styled.input<InputProps>`
  height: 40px;
  border: 1px solid ${(props) => (props.error ? "#E00000" : "#c2c2c2")};
  border-radius: 2px;
  font-size: 16px;
  padding: 0 8px;
  width: 100%;
  font-family: "HarmoniaSansProCyr";
`;
