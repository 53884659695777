import React from "react";
import Open from "app/pages/open/Open";
import { RouteComponentProps, withRouter } from "react-router";

interface OpenZNRProps extends RouteComponentProps<any> {}

const OpenZNR: React.FC<OpenZNRProps> = (props) => {
  return (
    <div>
      {props.match.params.tripNumber && props.match.params.journeyNumber ? (
        <Open
          logicalName={"ExpenseApplications"}
          tripNumber={props.match.params.tripNumber}
          journeyNumber={props.match.params.journeyNumber}
        />
      ) : null}
    </div>
  );
};

export default withRouter(OpenZNR);
