import React, { useState, Fragment, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import FileSaver from "file-saver";
import SvgIconS from "app/component/svg-icon/SvgIcon";
import AttachedImagePreview from "app/component/modal/AttachedImagePreview";
import { loaderLock, loaderUnlock } from "store/common/actions";
import AttachFileModal from "app/component/modal/AttachFileModal";
import DeleteAttachmentModal from "app/component/modal/DeleteAttachmentModal";
import { AttachmentItem } from "infrastructure/interfaces";
import { updateDetailedAO } from "../../../store/report/actions";
import { updateApplicationForExpenseDetail } from "store/applicationForExpense/actions";
import TextCutter from "../text-cutter/TextCutter";
import {
  getAdvanceReportApplication,
  getCommonAttachmentList,
  isQRLoader,
} from "../../../store/selectors";
import {
  deleteAttachmentsById,
  getAttachmentsContent,
  getAttachmentsPreview,
  putAttachmentsById,
} from "../../../services/ApiService";
import StatusLoader from "../loader/StatusLoader";
import NoticePanel2 from "../notice/NoticePanel2";
import AttachmentFilesQRCanner from "./AttachmentFilesQRScanner";
import { getScannedChequeInfo, setAttacmentId } from "store/qrScanner/actions";

interface AttachmentFilesListProps {
  isReport?: boolean;
  id: number;
}

const AttachmentFilesList: React.FC<AttachmentFilesListProps> = (props) => {
  const attachmentList = useSelector(getCommonAttachmentList);
  return !isEmpty(attachmentList) ? (
    <div className="request-files-wrap">
      {attachmentList.map((item: AttachmentItem) => {
        return (
          <AttachmentFileItem
            key={item.id}
            item={item}
            id={props.id}
            isReport={props.isReport}
          />
        );
      })}
    </div>
  ) : null;
};

interface AttachmentFileItemProps {
  item: AttachmentItem;
  isReport?: boolean;
  id: number;
}

const AttachmentFileItem: React.FC<AttachmentFileItemProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector(isQRLoader);
  const report = useSelector(getAdvanceReportApplication);

  let [isOpenPopup, setOpenPopup] = useState(false);
  let [isOpenAttachFile, setOpenAttachFile] = useState(false);
  let [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  let [imgData, setImgData] = useState("");
  let [isOpenImagePreview, setOpenImagePreview] = useState(false);
  let [width, setWidth] = useState(0);

  let nameRef = useRef(null as any);

  const toggleSubmitEditAttachFile = (expense: any) => {
    let formData = new FormData();
    formData.append("Content", "");
    formData.append("ObjectId.Id", expense.id);
    formData.append("ObjectId.LogicalName", expense.expenseType.logicalName);

    dispatch(loaderLock());
    putAttachmentsById(props.item.id, formData)
      .then((response) => {
        props.isReport
          ? dispatch(updateDetailedAO(props.id))
          : dispatch(updateApplicationForExpenseDetail(props.id));
      })
      .finally(() => {
        dispatch(loaderUnlock());
      });
    setOpenAttachFile(!isOpenAttachFile);
  };

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!isOpenDeleteModal);
  };

  const toggleImagePreviewModal = () => {
    setOpenImagePreview(!isOpenImagePreview);
  };

  const toggleSubmitDeleteFile = () => {
    dispatch(loaderLock());
    deleteAttachmentsById(props.item.id)
      .then((response) => {
        setOpenDeleteModal(!isOpenDeleteModal);
        props.isReport
          ? dispatch(updateDetailedAO(props.id))
          : dispatch(updateApplicationForExpenseDetail(props.id));
      })
      .finally(() => {
        dispatch(loaderUnlock());
      });
  };

  const getImage = () => {
    dispatch(loaderLock());
    getAttachmentsPreview(props.item.id, {
      responseType: "arraybuffer",
    })
      .then((response) => {
        let buffer = Buffer.from(response.data, "binary").toString("base64");
        setImgData(`data:${response.headers["content-type"]};base64,${buffer}`);
      })
      .finally(() => {
        dispatch(loaderUnlock());
      });
  };

  useEffect(() => {
    resizeEventHander();
    getImage();
  }, []);

  const resizeEventHander = () => {
    setWidth(nameRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeEventHander);
    return () => {
      window.removeEventListener("resize", resizeEventHander);
    };
  });

  const clickDownloadHandler = async (id: number, e?: any) => {
    if (e) e.stopPropagation();
    let downland = await getAttachmentsContent(id, { responseType: "blob" });
    let contentDisposition = downland.headers["content-disposition"];
    let fileName = contentDisposition.slice(
      contentDisposition.lastIndexOf("'") + 1,
    );
    FileSaver.saveAs(downland.data, decodeURI(fileName));
  };

  const isFormatForDownload = () => {
    const format: any = props.item.name.match(/[0-9a-z]+$/i);
    switch (format[0].toLowerCase()) {
      case "docx":
        return t("title.download");
      case "xlsx":
        return t("title.download");
      case "doc":
        return t("title.download");
      case "xls":
        return t("title.download");
      case "pdf":
        return t("title.download");
      case "msg":
        return t("title.download");
      case "eml":
        return t("title.download");
      default:
        return t("title.open");
    }
  };

  const clickHandlerPreviewOrDownload = () => {
    const format: any = props.item.name.match(/[0-9a-z]+$/i);
    switch (format[0].toLowerCase()) {
      case "docx":
        return (e: any) => clickDownloadHandler(props.item.id);
      case "xlsx":
        return (e: any) => clickDownloadHandler(props.item.id);
      case "doc":
        return (e: any) => clickDownloadHandler(props.item.id);
      case "xls":
        return (e: any) => clickDownloadHandler(props.item.id);
      case "pdf":
        return (e: any) => clickDownloadHandler(props.item.id);
      case "msg":
        return (e: any) => clickDownloadHandler(props.item.id);
      case "eml":
        return (e: any) => clickDownloadHandler(props.item.id);
      default:
        return toggleImagePreviewModal;
    }
  };

  const getAttachmentClassByFormat = () => {
    const format: any = props.item.name.match(/[0-9a-z]+$/i);
    switch (format[0].toLowerCase()) {
      case "docx":
        return "doc";
      case "doc":
        return "doc";
      case "xlsx":
        return "doc";
      case "xls":
        return "doc";
      case "pdf":
        return "doc";
      case "msg":
        return "doc";
      case "eml":
        return "doc";
      case "bmp":
        return "img";
      case "gif":
        return "img";
      case "jpeg":
        return "img";
      case "jpg":
        return "img";
      case "png":
        return "img";
      case "tiff":
        return "img";
      case "heic":
        return "img";
      default:
        return "img";
    }
  };

  const togglePopup = (event?: any) => {
    if (event) event.stopPropagation();
    setOpenPopup(!isOpenPopup);
  };

  const toggleAttachFile = (event?: any) => {
    if (event) event.stopPropagation();
    setOpenAttachFile(!isOpenAttachFile);
    if (isOpenPopup) {
      togglePopup();
    }
  };

  const onDelete = (event?: any) => {
    if (event) event.stopPropagation();
    togglePopup();
    toggleDeleteModal();
  };

  const QRScan = async (event?: any) => {
    if (event) event.stopPropagation();
    setOpenPopup(!isOpenPopup);
    const currentExpensesId = report.expenses.map((el) => el.id);
    dispatch(getScannedChequeInfo(props.item.id, t, currentExpensesId));
    dispatch(setAttacmentId(props.item.id));
  };

  return (
    <Fragment>
      <div
        className={`request-files-item ${getAttachmentClassByFormat()} pointer`}
        onClick={clickHandlerPreviewOrDownload()}
        title={isFormatForDownload()}
      >
        {isOpenPopup ? (
          <div className="miss-click-handler" onClick={togglePopup} />
        ) : (
          ""
        )}
        <div
          className="request-files-item-dropdown"
          style={{ display: isOpenPopup ? "block" : "none" }}
        >
          {props.isReport ? (
            <a className="pointer" onClick={QRScan}>
              <SvgIconS className="icon icon-scan" href="#svg_icon_scan" />
              {t("report_detail.scan_qr")}
            </a>
          ) : null}
          <a className="pointer" onClick={toggleAttachFile}>
            <SvgIconS className="icon icon-edit" href="#svg_icon_edit" />
            {t("report_detail.edit")}
          </a>
          <a className="pointer" onClick={onDelete}>
            <SvgIconS className="icon icon-remove" href="#svg_icon_remove" />
            {t("report_detail.delete")}
          </a>
        </div>
        <div onClick={togglePopup}>
          <SvgIconS className="icon icon-more" href="#svg_icon_more" />
        </div>
        <div className="request-files-item-pic ">
          <img src={imgData} />
        </div>
        <div
          className="request-files-item-info pointer"
          title={t("title.download")}
          onClick={(e) => clickDownloadHandler(props.item.id, e)}
        >
          <div>{moment(props.item.createdAt).format("DD.MM.YYYY")}</div>
          <div ref={nameRef}>
            <TextCutter
              text={props.item.name}
              parentWidth={width}
              options={[
                {
                  maxLength: 33,
                  maxWidth: 274,
                  minWidth: 48,
                  symbolSize: 12,
                },
              ]}
            />
          </div>
          <div>({props.item.size})</div>
        </div>
      </div>
      <AttachFileModal
        isOpen={isOpenAttachFile}
        onClose={toggleAttachFile}
        onSubmit={toggleSubmitEditAttachFile}
        objectId={props.item}
        isReport={props.isReport}
      />
      <DeleteAttachmentModal
        isOpen={isOpenDeleteModal}
        onClose={toggleDeleteModal}
        onSubmit={toggleSubmitDeleteFile}
      />
      <AttachedImagePreview
        size={props.item.size}
        isOpen={isOpenImagePreview}
        onClose={toggleImagePreviewModal}
        itemId={props.item.id}
      />
      <StatusLoader
        isOpen={loader}
        statuses={[t("QR.loading"), t("QR.scanning"), t("QR.reading")]}
      />
      <NoticePanel2 />
      {props.isReport ? (
        <AttachmentFilesQRCanner attachmentItem={props.item} />
      ) : null}
    </Fragment>
  );
};

export default AttachmentFilesList;
