import React from "react";
import { CheckboxProps } from "./types";
import {
  StyledCheckbox,
  Container,
  Checkmark,
  CheckboxLabelText,
} from "./StyledCheckbox";

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { id, name, checked, disabled, type, label, onClick } = props;

  const clickHandler = () => {
    !disabled && onClick(id);
  };

  return (
    <Container onClick={clickHandler} data-type={type} htmlFor={id.toString()}>
      <StyledCheckbox
        type="checkbox"
        name={name}
        id={name + "-" + id}
        checked={checked}
        disabled={disabled}
      />
      <Checkmark />
      <CheckboxLabelText>{label}</CheckboxLabelText>
    </Container>
  );
};

export default Checkbox;
