import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import moment from "moment";
//store
import { updateApplicationForExpenseDetail } from "store/applicationForExpense/actions";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { showErrors } from "store/exception/actions";
//component
import SvgIcon from "app/component/svg-icon/SvgIcon";
import DatePickerCalendar from "app/component/date-picker/DatePickerCalendar";
//common
import { formatter, betweenTwoDates } from "app/utils";
import {
  TravelDates,
  Expense,
  IOccupations,
} from "infrastructure/interfaces/index";
import If from "app/component/util/If";
import { STATUS_TYPE_EXPENSE } from "infrastructure/enum/status-expense.enum";
import { OCCUPATION_ROLE_TYPE } from "infrastructure/enum/user-role.enum";
import Config, { ConfigTypes } from "services/ConfigService";
import CreateOrderModal from "app/component/modal/CreateOrderModal";
import {
  getApplicationForExpenseDetail,
  getCommonUserDetail,
} from "../../../../store/selectors";
import {
  deleteExpensesById,
  updateExpensesById,
  updatePerDiemCalculations,
} from "../../../../services/ApiService";

interface ZNRTravel {
  dates: TravelDates[];
  personalDaysNumber: number;
  isVisible?: boolean;
  canEdit?: boolean;
}

const ZNRTravel: React.FC<ZNRTravel> = (props) => {
  const { t, i18n } = useTranslation();

  let listDate = null;
  let [isShowDatePicker, setShowDatePicker] = useState(false);
  let [disableBeforeDate, setDisableBeforeDate] = useState(new Date());
  let [disableAfterDate, setDisableAfterDate] = useState(new Date());
  let [disabledDates, setDisabledDates] = useState(
    [] as { from: Date; to: Date }[],
  );
  let [external, setExternal] = useState(false);
  let [canEdit, setCanEdit] = useState(true);
  let [isShowDailyRenew, setShowDailyRenew] = useState(false);

  const applicationForExpenseDetail = useSelector(
    getApplicationForExpenseDetail,
  );
  const user = useSelector(getCommonUserDetail);
  const id = get(applicationForExpenseDetail, "id");
  const dispatch = useDispatch();

  const saveSubmit = async (data: any) => {
    dispatch(loaderLock());
    let companyId = applicationForExpenseDetail
      ? applicationForExpenseDetail.company.id
      : 0;
    let perDiemCalculationType = Config.getConfigToCompany(
      ConfigTypes.PERDIEM_CALCULATION_TYPE,
      companyId,
    );

    try {
      let needRenew = false;
      let dateDiffer = (dateA: any, dateB: any) => {
        return (
          +moment(dateA).format("YYYYMMDD") == +moment(dateB).format("YYYYMMDD")
        );
      };

      if (
        perDiemCalculationType != 0 &&
        applicationForExpenseDetail &&
        applicationForExpenseDetail.expenses.filter(
          (item) => item.expenseType.expenseGroup.code == "Perdiem",
        ).length > 0
      ) {
        if (
          data.businessTripOption &&
          !dateDiffer(
            data.businessTripOption.startOn,
            applicationForExpenseDetail.startOn,
          )
        ) {
          needRenew = true;
        }
        if (
          data.businessTripOption &&
          !dateDiffer(
            data.businessTripOption.endOn,
            applicationForExpenseDetail.endOn,
          )
        ) {
          needRenew = true;
        }

        if (
          data.businessTripOption &&
          data.businessTripOption.departureFromCityId !=
            applicationForExpenseDetail.departureFromCity.id
        ) {
          needRenew = true;
        }
        if (
          data.businessTripOption &&
          data.businessTripOption.arrivalToCityId !=
            applicationForExpenseDetail.arrivalToCity.id
        ) {
          needRenew = true;
        }

        let oldTrip = applicationForExpenseDetail
          ? applicationForExpenseDetail.businessTripDestinations
          : [];
        let newTrip = data.businessTripOption
          ? data.businessTripOption.businessTripDestination
            ? data.businessTripOption.businessTripDestination
            : []
          : [];
        if (oldTrip.length == newTrip.length) {
          oldTrip.sort((a, b) => a.ordinal - b.ordinal);
          newTrip.sort((a: any, b: any) => a.ordinal - b.ordinal);

          for (let index = 0; index < oldTrip.length; index++) {
            const oldElement = oldTrip[index];
            const newElement = newTrip[index];
            if (oldElement.city.id != newElement.cityId) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.leaveOn, newElement.leaveOn)) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.arrivalOn, newElement.arrivalOn)) {
              needRenew = true;
            }
          }
        } else {
          needRenew = true;
        }
      }

      //let response = await Api.put('/ExpenseApplications/' + id, applicationForExpenseDetail);
      //id && await dispatch(updateApplicationForExpenseDetail(id));
      if (needRenew) {
        dailyRenew();
      }
      //props.onClose();
      //analitiks('EditingZNR', { id: applicationForExpenseDetail ? applicationForExpenseDetail.id : null, employeeId: userDetail.id, companyId: userDetail.company.id });
    } catch (e) {
      dispatch(
        showErrors({
          code: "expense_applications_error",
          message: `Create expense applications failure. ${e}`,
        }),
      );
    }
    if (id) {
      let response = await updateExpensesById(id, data);
      if (response.headers.success && id) {
        dispatch(updateApplicationForExpenseDetail(id));
      } else {
        dispatch(
          showErrors({
            code: "save_application_for_expense",
            message: "Не удалось сохранить данные",
          }),
        );
      }
    }
    dispatch(loaderUnlock());
  };

  const dailyRenew = async () => {
    dispatch(loaderLock());
    let dailyExpensesId = applicationForExpenseDetail
      ? applicationForExpenseDetail.expenses
          .filter((item: any) => {
            return item.expenseType.expenseGroup.code == "Perdiem";
          })
          .map((item: any) => item.id)
      : [];
    for (let index = 0; index < dailyExpensesId.length; index++) {
      const element = dailyExpensesId[index];
      await deleteExpensesById(element);
    }

    let data = {
      applicationRef: {
        id: applicationForExpenseDetail ? applicationForExpenseDetail.id : 0,
        logicalName: "ExpenseApplication",
        name: {
          ru: "ExpenseApplication",
          en: "ExpenseApplication",
        },
      },
      calculations: [],
    };
    await updatePerDiemCalculations(data);
    id && (await dispatch(updateApplicationForExpenseDetail(id)));
    dispatch(loaderUnlock());
    openDailyRenew();
  };

  const openDailyRenew = () => {
    setShowDailyRenew(true);
  };

  const closeDailyRenew = () => {
    setShowDailyRenew(false);
  };

  const daysCount = (applicationTravelDates: TravelDates[]) => {
    let daysCount = 0;
    applicationTravelDates.forEach((dates: TravelDates) => {
      daysCount = daysCount + betweenTwoDates(dates.startDate, dates.endDate);
    });
    return daysCount;
  };

  const savePersonalTravel = (dates: TravelDates[]) => {
    if (applicationForExpenseDetail) {
      let data: any = {
        applicationTypeId: applicationForExpenseDetail.applicationType.id,
        businessTargetId: applicationForExpenseDetail.businessTarget.id,
        description: applicationForExpenseDetail.description,
        //costCentersOption: [...applicationForExpenseDetail.costCenters.map(costCenter => ({ costCenterId: costCenter.id, percent: costCenter.percent }))],
        // costCentersOption: [{costCenterId: 6, percent: 100}],
        businessTripOption: {
          departureFromCityId: applicationForExpenseDetail.departureFromCity.id,
          startOn: applicationForExpenseDetail.startOn,
          endOn: applicationForExpenseDetail.endOn,
          arrivalToCityId: applicationForExpenseDetail.arrivalToCity.id,
          //personalDaysNumber: applicationForExpenseDetail.personalDaysNumber,
          personalDaysNumber: daysCount(dates),
          applicationTravelDates: dates,
        },
      };
      saveSubmit(data);
    }
  };

  if (props.dates.length) {
    listDate = props.dates.map((date, index) => {
      return (
        <div className="input-choose-item HarmoniaSansProCyr">
          {formatter("D MMMM", i18n.language, date.startDate, date.endDate, {
            withMonth: false,
            withYear: false,
          })}
          <If condition={props.canEdit}>
            <div onClick={() => deleteHandler(index)}>
              <SvgIcon
                className="icon icon-close"
                href="#svg_icon_close"
                index={index}
              />
            </div>
          </If>
        </div>
      );
    });
  }

  let deleteHandler = (index: number) => {
    let result = [...props.dates];
    result.splice(index, 1);
    savePersonalTravel(result);
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!isShowDatePicker);
  };

  const onSelected = (value: any) => {
    let result = [...props.dates];
    result.push({ startDate: value.startDate, endDate: value.endDate });
    savePersonalTravel(result);
    toggleDatePicker();
  };

  const checkExternal = (expenses: Expense[]) => {
    let result = false;
    for (let index = 0; index < expenses.length; index++) {
      if (expenses[index].external) {
        result = true;
        break;
      }
    }
    setExternal(result);
  };

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  const checkCanEdit = (status: string) => {
    if (applicationForExpenseDetail && isBookerOccupation()) {
      setCanEdit(
        !(
          status == STATUS_TYPE_EXPENSE.APPROVED ||
          status == STATUS_TYPE_EXPENSE.ON_APPROVAL ||
          status == STATUS_TYPE_EXPENSE.POSTED ||
          user.id != applicationForExpenseDetail.assigneeEmployee.id
        ),
      );
      return;
    }
    setCanEdit(
      !(
        status == STATUS_TYPE_EXPENSE.APPROVED ||
        status == STATUS_TYPE_EXPENSE.ON_APPROVAL ||
        status == STATUS_TYPE_EXPENSE.POSTED ||
        status == "Cancelled"
      ),
    );
  };

  useEffect(() => {
    if (applicationForExpenseDetail) {
      setDisableBeforeDate(
        moment(
          applicationForExpenseDetail.startOn,
          "YYYY-MM-DDThh:mm:ss",
        ).toDate(),
      );
      setDisableAfterDate(
        moment(
          applicationForExpenseDetail.endOn,
          "YYYY-MM-DDThh:mm:ss",
        ).toDate(),
      );
      checkExternal(applicationForExpenseDetail.expenses);
      checkCanEdit(applicationForExpenseDetail.status);
    }
  }, [applicationForExpenseDetail]);

  useEffect(() => {
    if (props.dates) {
      let newDisabledDates = props.dates.map((date) => {
        return {
          from: moment(date.startDate, "YYYY-MM-DDThh:mm:ss").toDate(),
          to: moment(date.endDate, "YYYY-MM-DDThh:mm:ss").toDate(),
        };
      });
      setDisabledDates(newDisabledDates);
    }
  }, [props.dates]);

  return applicationForExpenseDetail ? (
    <div
      className={`request-item-block ${props.isVisible ? "" : "d-none"}`}
      style={{ width: "100%" }}
    >
      <div className="request-item-label add-trip-label">
        {t("request_detail.personal_travel.personalTravel")} (
        {props.personalDaysNumber}д.)
        <If condition={props.canEdit}>
          <div
            className="btn btn_gray add-trip"
            style={{ marginLeft: "5px" }}
            id="datepicker"
            onClick={toggleDatePicker}
          >
            + {t("request_detail.personal_travel.add")}
          </div>
        </If>
      </div>
      <DatePickerCalendar
        isShown={isShowDatePicker}
        isMultiChoice={true}
        isCleansed={true}
        onSelected={onSelected}
        onClose={toggleDatePicker}
        disableBeforeDate={disableBeforeDate}
        disableAfterDate={disableAfterDate}
        disabledDates={disabledDates}
        month={disableBeforeDate}
      />
      <div className="request-item-content">
        <div className="input-choose">{listDate}</div>
      </div>
      <div className="request-item-content"></div>
      <CreateOrderModal
        id={applicationForExpenseDetail.id}
        isOpen={isShowDailyRenew}
        onClose={closeDailyRenew}
        dailyRenew={true}
      />
    </div>
  ) : null;
};

export default ZNRTravel;
