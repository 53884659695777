import React from "react";
import SvgIcon from "app/component/svg-icon/SvgIcon";

export interface InputChooseItem {
  value: number;
  text: string;
}

interface InputChooseProps {
  options: InputChooseItem[];
  onDelete?: (option: InputChooseItem) => void;
}

const InputChoose: React.FC<InputChooseProps> = (props) => {
  return (
    <div className="input-choose">
      {props.options.map((child) => (
        <div className="input-choose-item">
          {child.text}
          <SvgIcon
            className={"icon icon-close pointer"}
            onClick={() => (props.onDelete ? props.onDelete(child) : null)}
            href={"#svg_icon_close"}
          />
        </div>
      ))}
    </div>
  );
};

export default InputChoose;
