import React, { useState } from "react";
import ModalContainer from "ui/Modal/ModalContainer";
import PowerOfAttorneyItemInfo from "./PowerOfAttorneyItemInfo";
import { SvgIcon } from "ui/SvgIcon";
import moment from "moment";
import {
  BoxModal,
  BoxModalHeader,
  Button,
  ButtonContainer,
  Container,
  DeclineBox,
  DropDownOption,
  DropDownOptions,
  Expire,
  ReasonText,
  ReasonTitle,
  StatusBox,
  Tab,
  TabContainer,
  Title,
} from "./PowerOfAttorneyItemStyled";
import { PowerOfAttorneyItemProps } from "./types";
import { useTranslation } from "react-i18next";
import { calculateRemainingTime } from "./helper";
import PowerOfAttorneyItemFiles from "./PowerOfAttorneyItemFiles";
import State from "./Status";
import PowerOfAttorneyCertificate from "./dialogs/PowerOfAttorneyDialogStep3";
import DeclineToSign from "./Manager/DeclineToSign";
import Revoke from "./Manager/Revoke";
import { useSelector } from "react-redux";
import { getCommonUserDetail, getPowerOfAttorneyDetail } from "store/selectors";

const PowerOfAttorneyItem: React.FC<PowerOfAttorneyItemProps> = ({
  setPowerAttorney,
  isPowerAttorney,
}) => {
  const { t } = useTranslation();
  const POW = useSelector(getPowerOfAttorneyDetail);
  const userDetail = useSelector(getCommonUserDetail);
  const [PowerOfAttorneyInfo, setPowerOfAttorneyInfo] = useState(true);
  const [PowerOfAttorneyFiles, setPowerOfAttorneyFiles] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalToSign, setIsModalToSign] = useState(false);
  const [isModalToDecline, setIsModalToDecline] = useState(false);
  const [isModalToRevoke, setIsModalToRevoke] = useState(false);

  const remainingTime = calculateRemainingTime(POW.validTo, true, t);

  const changeToInfoHandler = () => {
    setPowerOfAttorneyInfo(true);
    setPowerOfAttorneyFiles(false);
  };

  const changeToFilesHandler = () => {
    setPowerOfAttorneyFiles(true);
    setPowerOfAttorneyInfo(false);
  };

  const toggle = () => {
    setPowerAttorney(!isPowerAttorney);
    setPowerOfAttorneyInfo(true);
    setPowerOfAttorneyFiles(false);
    setDropdownOpen(false);
  };

  function toggleDropdown() {
    setDropdownOpen(!dropdownOpen);
  }

  function signHandler() {
    setIsModalToSign(!isModalToSign);
    setDropdownOpen(!dropdownOpen);
    setPowerOfAttorneyInfo(true);
    setPowerOfAttorneyFiles(false);
  }

  function declineHandler() {
    setIsModalToDecline(!isModalToDecline);
    setDropdownOpen(!dropdownOpen);
    setPowerOfAttorneyInfo(true);
    setPowerOfAttorneyFiles(false);
  }

  function revokeHandler() {
    setIsModalToRevoke(!isModalToRevoke);
    setPowerOfAttorneyInfo(true);
    setPowerOfAttorneyFiles(false);
  }

  const isExpired = !moment(POW.validTo).isAfter();
  const expiringTomorrow = moment(POW.validTo).isAfter(moment().add(1, "d"));

  return (
    <div style={{ width: "100%" }}>
      {isModalToSign && POW && (
        <PowerOfAttorneyCertificate
          signingBlobFileId={POW.signingBlobFileId}
          powerOfAttorneyId={POW.id}
          setIsModalToSign={setIsModalToSign}
          isModalToSign={isModalToSign}
          isInfoModal={setPowerAttorney}
        />
      )}
      {isModalToDecline && POW && (
        <DeclineToSign
          powerOfAttorneyId={POW.id}
          setIsModalToDecline={setIsModalToDecline}
          isModalToDecline={isModalToDecline}
          isInfoModal={setPowerAttorney}
        />
      )}
      {isModalToRevoke && POW && (
        <Revoke
          powerOfAttorney={POW}
          setIsModalToRevoke={setIsModalToRevoke}
          isModalToRevoke={isModalToRevoke}
          isInfoModal={setPowerAttorney}
        />
      )}
      <ModalContainer
        isOpen={isPowerAttorney}
        style={{ alignItems: "center", zIndex: 100 }}
      >
        <BoxModal>
          <BoxModalHeader>
            <Title>{t("power.POW_number", { number: POW.id })}</Title>
            <StatusBox>
              <State state={POW.state} svgKey={true} />
            </StatusBox>
          </BoxModalHeader>
          <div className="box-modal_close" onClick={toggle}></div>
          <Container>
            <Expire expired={isExpired}>
              {isExpired ? t("power.expired_") : remainingTime}
            </Expire>

            {POW.owner && POW.owner.company.managerEmployee.id === userDetail.id &&
              POW.state !== "Revoked" &&
              (POW.state === "Signed" ? (
                <>
                  {expiringTomorrow && (
                    <ButtonContainer>
                      <Button onClick={revokeHandler}>
                        {t("power.button.revoke")}
                      </Button>
                    </ButtonContainer>
                  )}
                </>
              ) : (
                <ButtonContainer>
                  <Button onClick={toggleDropdown}>
                    {t("power.button.sign")}
                    <SvgIcon id={"vectorDown"} color={"#FFFFFF"} size={22} />
                  </Button>
                  {dropdownOpen && (
                    <DropDownOptions>
                      <DropDownOption onClick={signHandler}>
                        {t("power.button.sign_power_of_attorney")}
                      </DropDownOption>
                      <DropDownOption onClick={declineHandler}>
                        {t("power.button.decline_to_sign")}
                      </DropDownOption>
                    </DropDownOptions>
                  )}
                </ButtonContainer>
              ))}
          </Container>

          {(POW.state === "Declined" || POW.state === "Revoked") && (
            <DeclineBox>
              <SvgIcon id={"warningTriangle"} color={"#A20000"} size={22} />
              <div>
                <ReasonTitle>
                  {POW.state === "Declined"
                    ? t("power.reason_of_decline")
                    : t("power.revocation_reason")}
                </ReasonTitle>
                <ReasonText>{POW.comment}</ReasonText>
              </div>
            </DeclineBox>
          )}

          <TabContainer>
            <Tab active={PowerOfAttorneyInfo} onClick={changeToInfoHandler}>
              {t("power.general_information")}
            </Tab>
            <Tab active={PowerOfAttorneyFiles} onClick={changeToFilesHandler}>
              {t("power.files")}
            </Tab>
          </TabContainer>
          {PowerOfAttorneyInfo && <PowerOfAttorneyItemInfo POW={POW} />}
          {PowerOfAttorneyFiles && <PowerOfAttorneyItemFiles POW={POW} />}
        </BoxModal>
      </ModalContainer>
    </div>
  );
};

export default PowerOfAttorneyItem;
