import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SvgIcon } from "ui/SvgIcon";
import "./InternalReport.css";
import {
  ButtonContainer,
  ButtonCreate,
  ButtonReset,
  ContentReportTitle,
  ContentWrap,
  ReportHeader,
} from "./ReportFormStyled";
import ReportSelect from "./ReportSelect";
import {
  ReportOptions,
  getArrayOfInt,
  getExternalReport,
  getInternalReport,
  getStatusesOptions,
  typeOptions,
  validateInternalReport,
} from "./utils";
import { getCommonCompaniesCatalog } from "store/selectors";
import {
  getAvailableReportTypes,
  getEmployeeByParams,
} from "services/ApiService";
import { Employee } from "infrastructure/interfaces";
import { OptionProp, Period } from "./types";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { CompanyAutocompleteOptionType } from "../autocomplete/CompanyAutocomplete";
import InternalReport from "./InternalReport";
import { ApplicationState } from "store/reducers";
import { MENU_FIELD_PERMISSIONS } from "infrastructure/enum/field-permissions.enum";
import ExternalReport from "./ExternalReport";

const ReportForm: React.FC = () => {
  const { t } = useTranslation();
  const companyDetails = useSelector(getCommonCompaniesCatalog);
  const dispatch = useDispatch();

  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [report, setReport] = useState<OptionProp>({
    id: 0,
    name: {
      en: "",
      ru: "",
    },
    label: "",
  });

  let internalReport = report.label === "AdvanceReportsRegistry";
  const [companies, setCompanies] = useState<OptionProp[]>([]);
  const [statuses, setStatuses] = useState<OptionProp[]>([]);
  const [types, setTypes] = useState<OptionProp[]>([]);
  const [creationPeriod, setCreationPeriod] = useState<Period>({
    startDate: firstDayOfMonth,
    endDate: lastDayOfMonth,
  });
  const [approvalPeriod, setApprovalPeriod] = useState<Period>({
    startDate: "",
    endDate: "",
  });
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] =
    useState<Employee[]>(employeesOptions);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [filteredReportOptions, setFilteredReportOptions] = useState<
    OptionProp[]
  >([]);

  const [reset, setReset] = useState(false);

  //internalReport
  let [downloadDateStart, setDownloadDateStart] = useState(null as any);
  let [downloadDateEnd, setDownloadDateEnd] = useState(null as any);
  let [company, setCompany] = useState({} as CompanyAutocompleteOptionType);
  let [fieldsWithError, setFieldsWithError] = useState([] as string[]);

  let menuFieldPermissions = useSelector(
    (state: ApplicationState) => state.common.menuFieldPermissions,
  );

  const fieldPermissions = useMemo(() => {
    return {
      reports:
        menuFieldPermissions &&
        menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.REPORTS),
    };
  }, [menuFieldPermissions]);

  useEffect(() => {
    getReportTypes();
  }, []);

  const getReportTypes = async () => {
    let result = await getAvailableReportTypes();
    if (result.headers.success) {
      let reportData = result.data.data.map((el: any) => el.valueString);

      let filteredOptions = ReportOptions.filter((option) =>
        reportData.includes(option.label),
      );

      setFilteredReportOptions(filteredOptions);
      setReport(filteredOptions[0]);
    }
  };

  let statusOptions = getStatusesOptions(report);
  useEffect(() => {
    statusOptions = getStatusesOptions(report);
  }, [report]);

  useEffect(() => {
    setCompanies(companyDetails);
    setStatuses(statusOptions);
    setTypes(typeOptions);
    setCreationPeriod({
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
    });
    setApprovalPeriod({
      startDate: "",
      endDate: "",
    });
  }, [report]);

  const getEmployee = async () => {
    let employees: any = [];
    for (let i = 0; i < companies.length; i++) {
      const result = await getEmployeeByParams({
        params: { OccupationCompanyId: companies[i].id, PageSize: 1000 },
      });
      if (result.headers.success) {
        employees.push(
          ...result.data.data.map((employee: Employee) => ({
            ...employee,
            companyID: companies[i].id,
          })),
        );
      }
    }
    setEmployeesOptions(employees);
  };

  useEffect(() => {
    getEmployee();
  }, [companies.length, report]);

  const resetHandler = () => {
    setCompanies(companyDetails);
    setStatuses(statusOptions);
    setTypes(typeOptions);
    getEmployee();
    setCreationPeriod({
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
    });
    setApprovalPeriod({
      startDate: "",
      endDate: "",
    });
    setReset(true);
  };

  const validateExternalReport = (): boolean => {
    return (
      !!companies.length &&
      !!statuses.length &&
      !!types.length &&
      (!!creationPeriod.startDate || !!approvalPeriod.startDate)
    );
  };

  const handleSubmit = async () => {
    if (internalReport) {
      if (
        !validateInternalReport(
          downloadDateStart,
          downloadDateEnd,
          company,
          setFieldsWithError,
        )
      ) {
        return;
      }

      dispatch(loaderLock());
      getInternalReport(
        downloadDateStart,
        downloadDateEnd,
        company,
        t,
        dispatch,
      );
      dispatch(loaderUnlock());
    } else {
      if (!validateExternalReport()) {
        setButtonClicked(true);
      } else {
        dispatch(loaderLock());
        let companyIds = getArrayOfInt(companies);
        let employeeIds = getArrayOfInt(selectedEmployees, employeesOptions);
        let statusesIds = getArrayOfInt(statuses, statusOptions);

        let typesIds = types.map((el) => {
          return el.label;
        });

        let request = {
          CompanyIds: companyIds,
          EmployeeIds: employeeIds,
          StatusIds: statusesIds,
          ApplicationTypeCodes: typesIds,
          CreationDateFrom: creationPeriod.startDate
            ? new Date(
                new Date(creationPeriod.startDate).setHours(0),
              ).toISOString()
            : "",
          CreationDateTo: creationPeriod.endDate
            ? new Date(
                new Date(creationPeriod.endDate).setHours(24),
              ).toISOString()
            : "",
          ApprovalDateFrom: approvalPeriod.startDate
            ? new Date(
                new Date(approvalPeriod.startDate).setHours(0),
              ).toISOString()
            : "",
          ApprovalDateTo: approvalPeriod.endDate
            ? new Date(
                new Date(approvalPeriod.endDate).setHours(24),
              ).toISOString()
            : "",
        };

        getExternalReport(report, request, t, dispatch);

        dispatch(loaderUnlock());
      }
    }
  };

  return (
    <>
      {fieldPermissions.reports && (
        <>
          <ReportHeader>{t("report.reports")}</ReportHeader>
          <ContentWrap>
            <ContentReportTitle>{t("report.report_type")}</ContentReportTitle>
            <ReportSelect
              reportOptions={filteredReportOptions}
              report={report}
              setReport={setReport}
            />
          </ContentWrap>
          {internalReport ? (
            <ContentWrap>
              <InternalReport
                setDownloadDateStart={setDownloadDateStart}
                setDownloadDateEnd={setDownloadDateEnd}
                setCompany={setCompany}
                fieldsWithError={fieldsWithError}
              />
            </ContentWrap>
          ) : (
            <ContentWrap>
              <ExternalReport
                report={report}
                statusOptions={statusOptions}
                employeesOptions={employeesOptions}
                companies={companies}
                setCompanies={setCompanies}
                selectedEmployees={selectedEmployees}
                setSelectedEmployees={setSelectedEmployees}
                statuses={statuses}
                setStatuses={setStatuses}
                types={types}
                setTypes={setTypes}
                creationPeriod={creationPeriod}
                setCreationPeriod={setCreationPeriod}
                approvalPeriod={approvalPeriod}
                setApprovalPeriod={setApprovalPeriod}
                buttonClicked={buttonClicked}
                reset={reset}
                setReset={setReset}
              />
            </ContentWrap>
          )}
          <ContentWrap style={{ width: "100%" }}>
            <ButtonContainer>
              {!internalReport && (
                <ButtonReset onClick={resetHandler}>
                  <SvgIcon
                    id={"close"}
                    color={"#545B5E"}
                    size={15}
                    style={{ marginRight: "5px" }}
                  />
                  {t("report.reset_settings")}
                </ButtonReset>
              )}

              <ButtonCreate onClick={handleSubmit}>
                {" "}
                {t("report.generate")}
              </ButtonCreate>
            </ButtonContainer>
          </ContentWrap>
        </>
      )}
    </>
  );
};

export default ReportForm;
