import React, { Fragment, useState, useRef, useEffect, useMemo } from "react";
import Dropdown from "../dropdown/Dropdown";
import { RadioButton } from "ui/RadioButton";
import { useSelector, useDispatch } from "react-redux";
import EmployeeAutocomplete, {
  EmployeeAutocompleteOptionType,
} from "../autocomplete/EmployeeAutocomplete";
import { useTranslation } from "react-i18next";
import { ApplicationState } from "store/reducers";
import {
  updatePostings,
  hideFilter,
  showFilter,
  UpdatePostingsFilters,
} from "store/common/actions";
import { getCommonFilterState } from "../../../store/selectors";
import { TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS } from "../../../infrastructure/enum/field-permissions.enum";

interface JournalDetailFilterProps {
  id: number;
  isAdvance?: boolean;
}

enum TRANSACTION_TYPE {
  ALL = "all",
  DEBIT = 40,
  CREDIT = 50,
  NDS = 31,
}

const JournalDetailFilter: React.FC<JournalDetailFilterProps> = (props) => {
  const { t, i18n } = useTranslation();

  let [transactionType, setTransactionType] = useState(TRANSACTION_TYPE.ALL);
  let [employee, setEmployee] = useState({} as EmployeeAutocompleteOptionType);
  let [numberAccount, setNumberAccount] = useState("");
  let [width, setWidth] = useState(0);

  let numberAccountRef = useRef({} as HTMLInputElement);
  let employeeRef = useRef({} as any);

  const dispatch = useDispatch();
  let filterState: boolean = useSelector(getCommonFilterState);
  const transactionsJournalFieldPermissions = useSelector(
    (state: ApplicationState) =>
      state.common.transactionsJournalFieldPermissions,
  );

  const filterPermissions = useMemo(() => {
    return {
      postKey: transactionsJournalFieldPermissions.includes(
        TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS.POST_KEY,
      ),
      lagerAccount: transactionsJournalFieldPermissions.includes(
        TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS.LAGER_ACCOUNT,
      ),
      employee: transactionsJournalFieldPermissions.includes(
        TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS.EMPLOYEE,
      ),
    };
  }, [transactionsJournalFieldPermissions]);

  let [isFilterOpen, setFilterOpen] = useState(filterState);
  let [filterTimer, setFilterTimer] = useState(
    null as unknown as NodeJS.Timeout,
  );
  let [isClearingFilter, setClearingFilter] = useState(false);
  let [isInited, setInited] = useState(false);

  const resizeEventHander = (e: any) => {
    if (width !== window.innerWidth) {
      resizeHandler();
      setWidth(window.innerWidth);
    }
  };

  const resizeHandler = () => {
    if (window.innerWidth <= 959) {
      dispatch(hideFilter());
    } else {
      dispatch(showFilter());
    }
  };

  useEffect(() => {
    setFilterOpen(filterState);
    window.addEventListener("resize", resizeEventHander);
    return () => {
      window.removeEventListener("resize", resizeEventHander);
    };
  }, [filterState]);

  useEffect(() => {
    resizeHandler();
  }, []);

  useEffect(() => {
    dispatch(
      updatePostings(
        props.id,
        props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
        {},
      ),
    );
    setInited(true);
  }, []);

  useEffect(() => {
    if (isInited) {
      filter();
      setClearingFilter(false);
    }
  }, [employee, transactionType]);

  useEffect(() => {
    if (isInited && !isClearingFilter) {
      clearTimeout(filterTimer);
      setFilterTimer(
        setTimeout(() => {
          filter();
        }, 2000),
      );
    }
  }, [numberAccount]);

  const numberAccountChangeHandler = () => {
    setNumberAccount(numberAccountRef.current.value);
  };

  const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
    if (option.value !== employee.value) {
      setEmployee(option);
    }
  };

  const transactionTypeChangeHandler = (transaction: TRANSACTION_TYPE) => {
    setTransactionType(transaction);
  };

  const clearAllClickHandler = () => {
    setEmployee({} as EmployeeAutocompleteOptionType);
    employeeRef.current.clear();
    setNumberAccount("");
    setTransactionType(TRANSACTION_TYPE.ALL);
    setClearingFilter(true);
  };

  const filter = () => {
    let filter = {} as UpdatePostingsFilters;

    if (transactionType != TRANSACTION_TYPE.ALL) {
      filter.postingKey = transactionType;
    }

    if (numberAccount.trim() != "") {
      filter.lager = numberAccount;
    }

    if (employee) {
      filter.employee = employee.value;
    }

    dispatch(
      updatePostings(
        props.id,
        props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
        filter,
      ),
    );
  };

  const forceUpdateFilter = () => {
    clearTimeout(filterTimer);
    filter();
  };

  const onEnterClickHandler = (event: any) => {
    if (event.key === "Enter") {
      forceUpdateFilter();
    }
  };

  return (
    <Fragment>
      <div
        className="filters"
        style={isFilterOpen ? { display: "block" } : { display: "none" }}
      >
        <div className="filters-wrap">
          <Dropdown
            label={t("journal.filters.by_type")}
            displayStyle={filterPermissions.postKey ? undefined : "none"}
          >
            <RadioButton
              id={1}
              label={t("journal.filters.all")}
              name={"radio1"}
              onClick={() => transactionTypeChangeHandler(TRANSACTION_TYPE.ALL)}
              checked={transactionType === TRANSACTION_TYPE.ALL}
            />
            <RadioButton
              id={2}
              label={t("journal.filters.debit")}
              name={"radio2"}
              onClick={() =>
                transactionTypeChangeHandler(TRANSACTION_TYPE.DEBIT)
              }
              checked={transactionType === TRANSACTION_TYPE.DEBIT}
            />
            <RadioButton
              id={3}
              label={t("journal.filters.credit")}
              name={"radio3"}
              onClick={() =>
                transactionTypeChangeHandler(TRANSACTION_TYPE.CREDIT)
              }
              checked={transactionType === TRANSACTION_TYPE.CREDIT}
            />
            <RadioButton
              id={4}
              label={t("journal.filters.without_tax")}
              name={"radio4"}
              onClick={() => transactionTypeChangeHandler(TRANSACTION_TYPE.NDS)}
              checked={transactionType === TRANSACTION_TYPE.NDS}
            />
          </Dropdown>
          <Dropdown
            label={t("journal.filters.by_account")}
            displayStyle={filterPermissions.lagerAccount ? undefined : "none"}
          >
            <div className="input-item">
              <input
                ref={numberAccountRef}
                type="text"
                placeholder={t("journal.filters.account_number")}
                onKeyPress={onEnterClickHandler}
                onChange={numberAccountChangeHandler}
                value={numberAccount}
              />
            </div>
          </Dropdown>
          <Dropdown
            label={t("journal.filters.by_employee")}
            displayStyle={filterPermissions.employee ? undefined : "none"}
          >
            <div className="input-item">
              <div className="input-search">
                <EmployeeAutocomplete
                  ref={employeeRef}
                  onChoose={employeeChooseHandler}
                  placeholder={t("journal.filters.enter_name")}
                />
              </div>
            </div>
          </Dropdown>
          <Dropdown>
            <div className={"filter-btns-two m-t-15"}>
              <a
                className="btn btn_gray width-100 pointer"
                onClick={clearAllClickHandler}
              >
                {t("filters.reset")}
              </a>
            </div>
          </Dropdown>
        </div>
      </div>
    </Fragment>
  );
};

export default JournalDetailFilter;
