import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Validator from "app/component/validator/Validator";
import Config, { ConfigTypes } from "services/ConfigService";
import ModalContainer from "ui/Modal/ModalContainer";
import DialogContine from "./PowerOfAttorneyDialogStep2";
import { SvgIcon } from "ui/SvgIcon";
import CompanyAutocomplete, {
  CompanyAutocompleteOptionType,
} from "app/component/autocomplete/CompanyAutocomplete";
import {
  BackButton,
  BoxModal,
  BoxModalContent,
  BoxModalContentInfo,
  BoxModalFooter,
  BoxModalHeader,
  DeclineBox,
  NextButton,
  NumberOfAttorney,
  NumberOfAttorneyInput,
  Status,
  SubTitle,
  UpperLabel,
} from "./PowerOfAttorneyCommonStyled";
import {
  getCommonCompaniesCatalog,
  getPowerOfAttorneyDetail,
} from "store/selectors";
import { getCompaniesWithParams, getEmployeesById } from "services/ApiService";
import { showErrors } from "store/exception/actions";
import CompanyDetails from "./ui/CompanyDetails";
import Calendar from "./ui/Calendar";
import { getCommonUserDetail } from "../../../../store/selectors";
import { ReasonText, ReasonTitle } from "../PowerOfAttorneyItemStyled";
import { DialogStep1Props } from "../types";
import moment from "moment";

const PowerOfAttorneyDialog: React.FC<DialogStep1Props> = ({
  isModalToCreate,
  setIsModalToCreate,
  isModalToEdit,
  setIsModalToEdit,
}) => {
  let { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const userDetail = useSelector(getCommonUserDetail);
  const POW = useSelector(getPowerOfAttorneyDetail);
  const companiesCatalog = useSelector(getCommonCompaniesCatalog);

  const [isCompanyDetails, setIsCompanyDetails] = useState(false);
  const [managersName, setManagersName] = useState("");
  const [managersSnils, setManagersSnils] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [OGRN, setOGRN] = useState("");
  const [INN, setINN] = useState("");
  const [KPP, setKPP] = useState("");
  const [managerId, setManagerId] = useState(0);
  const [company, setCompany] = useState({} as CompanyAutocompleteOptionType);
  const [disabledToContinue, setDisabledToContinue] = useState(true);
  const [startDate, setStartDate] = useState(null as any);
  const [selectedDate, setSelectedDate] = useState(null as any);
  const [contine, setContine] = useState(false);

  const companyAutocompleteRef = useRef({} as HTMLInputElement);

  useEffect(() => {
    if (!isModalToCreate) {
      setStartDate(new Date(POW.validTo));
      setCompany({
        value: POW.owner.company.id,
        text: POW.owner.company.name.ru,
      });
    } else {
      getListOfCompanies();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(company).length !== 0) {
      getCompanyDetails();
    }
  }, [company]);

  useEffect(() => {
    if (managerId > 0) {
      getManagerById(managerId);
    }
  }, [managerId]);

  const getListOfCompanies = async () => {
    try {
      let result = await getCompaniesWithParams({
        params: { OccupationEmployeeId: userDetail.id },
      });
      if (result.headers.success) {
        let companies = result.data.data;
        let enabledCompany = companies.filter((el: any) => {
          return Config.getConfigToCompany(
            ConfigTypes.POWER_OF_ATTORNEY_ENABLED,
            el.id,
          );
        });
        setCompany({
          value: enabledCompany[0].id,
          text: enabledCompany[0].name[i18n.language as "ru"],
        });
      }
    } catch (er) {
      dispatch(
        showErrors({ code: "", message: "Не удалось загрузить компанию" }),
      );
    }
  };

  const getManagerById = async (id: number) => {
    try {
      const result = await getEmployeesById(id);
      if (result.headers.success) {
        setManagersName(result.data.name[i18n.language as "ru" | "en"]);
        setManagersSnils(result.data.snils);
        setIsCompanyDetails(true);
      }
    } catch (error) {
      dispatch(
        showErrors({
          code: "",
          message: "Не удалось загрузить информацию о руководителе компании",
        }),
      );
    }
  };

  const getCompanyDetails = () => {
    let selectedCompany = companiesCatalog.filter(
      (el) => el.id === company.value,
    );
    let managerId = selectedCompany[0].manager
      ? selectedCompany[0].manager.employee.id
      : 0;
    setManagerId(managerId);
    let address = selectedCompany[0].legal
      ? selectedCompany[0].legal.address
      : "";
    setAddressValue(address);
    let KPP = selectedCompany[0].legal ? selectedCompany[0].legal.kpp : "";
    setKPP(KPP);
    let INN = selectedCompany[0].legal ? selectedCompany[0].legal.inn : "";
    setINN(INN);
    let OGRN = selectedCompany[0].legal ? selectedCompany[0].legal.ogrn : "";
    setOGRN(OGRN);
  };

  const companyChooseHandler = (option: CompanyAutocompleteOptionType) => {
    if (option.value !== company.value) {
      setCompany(option);
    }
  };

  const toggle = () => {
    if (setIsModalToCreate) {
      setIsModalToCreate(!isModalToCreate);
    }
    if (setIsModalToEdit) {
      setIsModalToEdit(!isModalToEdit);
    }
  };

  let warningDate = moment().add(3, "years");

  useEffect(() => {
    if (
      Object.keys(company).length !== 0 &&
      selectedDate !== null &&
      (warningDate > selectedDate || warningDate > startDate) &&
      new Date() < startDate &&
      addressValue &&
      INN &&
      OGRN &&
      KPP &&
      managersName &&
      managersSnils
    ) {
      setDisabledToContinue(false);
    } else {
      setDisabledToContinue(true);
    }
  }, [
    selectedDate,
    company,
    warningDate,
    startDate,
    addressValue,
    INN,
    OGRN,
    KPP,
    managersName,
    managersSnils,
  ]);

  return (
    <div style={{ width: "100%" }}>
      {!contine ? (
        <>
          <ModalContainer
            style={{ alignItems: "center" }}
            styleForDialog={true}
            isOpen={isModalToCreate || isModalToEdit}
          >
            <BoxModal>
              <BoxModalHeader>
                <div>{t("power.of_attorney")}</div>
                <div style={{ marginBottom: "6px" }}>
                  {POW ? (
                    <Status
                      draft={
                        POW.state === "Declined" && !isModalToCreate
                          ? false
                          : true
                      }
                    >
                      {POW.state === "Declined" && !isModalToCreate
                        ? t("power.declined").toLocaleUpperCase()
                        : t("power.draft")}
                    </Status>
                  ) : (
                    <Status draft={true}>{t("power.draft")}</Status>
                  )}
                </div>
                <div className="box-modal_close" onClick={toggle}></div>
              </BoxModalHeader>
              {isModalToEdit && POW.state === "Declined" && (
                <DeclineBox>
                  <SvgIcon id={"warningTriangle"} color={"#A20000"} size={22} />
                  <div>
                    <ReasonTitle>{t("power.reason_of_decline")}</ReasonTitle>
                    <ReasonText>{POW && POW.comment}</ReasonText>
                  </div>
                </DeclineBox>
              )}
              <BoxModalContent>
                <div
                  className="box-modal-form-block"
                  style={{ marginBottom: "4px" }}
                >
                  <BoxModalContentInfo>
                    <div
                      className="input-item-row"
                      style={{ flexDirection: "column" }}
                    >
                      <UpperLabel>{t("power.number")}</UpperLabel>
                      <NumberOfAttorney>
                        <NumberOfAttorneyInput
                          value={isModalToEdit ? POW.id : ""}
                          disabled={true}
                        />
                      </NumberOfAttorney>
                    </div>

                    <Calendar
                      startDate={startDate}
                      warningDate={warningDate}
                      setStartDate={setStartDate}
                      POWDate={
                        isModalToEdit
                          ? new Date(POW.validTo).toISOString()
                          : undefined
                      }
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                    />
                  </BoxModalContentInfo>
                  <SubTitle>{t("power.issuing_authority")}</SubTitle>
                  <div
                    className="input-item-row"
                    style={{ marginBottom: "5px" }}
                  >
                    <Validator
                      type={"text"}
                      className={"fl-grow"}
                      style={{ marginBottom: "1px" }}
                    >
                      <>
                        <UpperLabel>
                          {t("power.name_of_the_organization")}
                          <i className="input-required"></i>
                        </UpperLabel>
                        <div className="input-search">
                          <CompanyAutocomplete
                            ref={companyAutocompleteRef}
                            onChoose={companyChooseHandler}
                            placeholder={company.text}
                            miniVector="vectorDown"
                            comboBox={true}
                            powerOfAttorney={true}
                          />
                        </div>
                      </>
                    </Validator>
                  </div>
                </div>
                <CompanyDetails
                  addressValue={addressValue}
                  INN={INN}
                  OGRN={OGRN}
                  KPP={KPP}
                  managersName={managersName}
                  managersSnils={managersSnils}
                  isCompanyDetails={isCompanyDetails}
                />
              </BoxModalContent>

              <BoxModalFooter>
                <BackButton onClick={toggle}>
                  {t("power.button.cancel")}
                </BackButton>
                <NextButton
                  onClick={() => setContine(!contine)}
                  disabled={disabledToContinue}
                >
                  {t("power.button.continue")}
                  {
                    <SvgIcon
                      id={"vectorRight"}
                      color={disabledToContinue ? "#9E9E9E" : "white"}
                    />
                  }
                </NextButton>
              </BoxModalFooter>
            </BoxModal>
          </ModalContainer>
        </>
      ) : (
        <DialogContine
          setContine={setContine}
          companyId={company.value}
          ownerCompanyManager={managerId}
          validTo={startDate}
          setIsModalToCreate={setIsModalToCreate}
          isModalToCreate={isModalToCreate}
          isModalToEdit={isModalToEdit}
          setIsModalToEdit={setIsModalToEdit}
        />
      )}
    </div>
  );
};

export default PowerOfAttorneyDialog;
