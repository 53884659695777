import React from "react";
import { IconProps } from "../types";

const Sorter: React.FC<IconProps> = (props) => {
  const { color, size } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height={size}
      viewBox="0 0 10 14"
      fill="none"
    >
      <path
        d="M7.8598 8.52663L4.9998 11.3933L2.1398 8.52663C2.01426 8.40109 1.844 8.33056 1.66646 8.33056C1.48893 8.33056 1.31867 8.40109 1.19313 8.52663C1.0676 8.65216 0.99707 8.82242 0.99707 8.99996C0.99707 9.17749 1.0676 9.34776 1.19313 9.47329L4.52646 12.8066C4.58844 12.8691 4.66217 12.9187 4.74341 12.9526C4.82465 12.9864 4.91179 13.0038 4.9998 13.0038C5.08781 13.0038 5.17494 12.9864 5.25618 12.9526C5.33742 12.9187 5.41116 12.8691 5.47313 12.8066L8.80646 9.47329C8.86862 9.41113 8.91793 9.33734 8.95157 9.25613C8.98521 9.17491 9.00252 9.08786 9.00252 8.99996C9.00252 8.91205 8.98521 8.82501 8.95157 8.74379C8.91793 8.66258 8.86862 8.58878 8.80646 8.52663C8.7443 8.46447 8.67051 8.41516 8.5893 8.38152C8.50808 8.34788 8.42104 8.33056 8.33313 8.33056C8.24522 8.33056 8.15818 8.34788 8.07696 8.38152C7.99575 8.41516 7.92196 8.46447 7.8598 8.52663ZM2.1398 5.47329L4.9998 2.60663L7.8598 5.47329C7.92177 5.53578 7.99551 5.58537 8.07675 5.61922C8.15799 5.65307 8.24512 5.67049 8.33313 5.67049C8.42114 5.67049 8.50828 5.65307 8.58952 5.61922C8.67075 5.58537 8.74449 5.53578 8.80646 5.47329C8.86895 5.41132 8.91855 5.33758 8.95239 5.25634C8.98624 5.1751 9.00366 5.08797 9.00366 4.99996C9.00366 4.91195 8.98624 4.82481 8.95239 4.74357C8.91855 4.66234 8.86895 4.5886 8.80646 4.52663L5.47313 1.19329C5.41116 1.13081 5.33742 1.08121 5.25618 1.04737C5.17494 1.01352 5.08781 0.996094 4.9998 0.996094C4.91179 0.996094 4.82465 1.01352 4.74341 1.04737C4.66217 1.08121 4.58844 1.13081 4.52646 1.19329L1.19313 4.52663C1.0676 4.65216 0.99707 4.82242 0.99707 4.99996C0.99707 5.17749 1.0676 5.34776 1.19313 5.47329C1.31867 5.59883 1.48893 5.66935 1.66646 5.66935C1.844 5.66935 2.01426 5.59883 2.1398 5.47329Z"
        fill={color}
      />
    </svg>
  );
};

export default Sorter;
