import React from "react";

const Spinner: React.FC = () => {
  return (
    <svg
      width="92"
      height="92"
      viewBox="0 0 92 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="37" cy="77.5" r="3.5" fill="#FF5248" />
      <circle cx="23" cy="68.5" r="3.5" fill="#FF5248" />
      <circle cx="15" cy="52.5" r="3.5" fill="#FF5248" />
      <circle cx="17" cy="35.5" r="3.5" fill="#FF5248" />
      <circle cx="28" cy="20.5" r="3.5" fill="#FF5248" />
      <circle cx="47" cy="14.5" r="3.5" fill="#FF5248" />
      <circle cx="66" cy="21.5" r="3.5" fill="#FF5248" />
      <circle cx="77" cy="38.5" r="3.5" fill="#FF5248" />
    </svg>
  );
};

export default Spinner;
