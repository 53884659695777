import React from "react";
import HistoryBox from "app/component/history/HistoryBox";
import Filter from "app/component/filter/Filter";
import ChatBox from "app/component/chat/ChatBox";

const RightSide: React.FC = () => {
  return (
    <aside className="sidebar-right sidebar-2">
      <Filter />
      <HistoryBox />
      <ChatBox />
    </aside>
  );
};

export default RightSide;
