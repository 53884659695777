import styled from "styled-components";

const StyledReportActStatus = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.006em;
  color: #008243;
`;

export { StyledReportActStatus };
