import styled from "styled-components";
import { InputProps } from "../types";

interface StatusProps {
  draft?: boolean;
}

interface NextButtonProps {
  longWidth?: boolean;
}

export const BoxModal = styled.div`
  z-index: 1000;
  background: #fff;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  width: 720px;
  position: relative;
  min-height: 10%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 980px) {
    width: 640px;
  }

  @media screen and (max-width: 640px) {
    min-width: 320px;
    width: 100%;
    position: relative;
  }
`;

export const BoxModalHeader = styled.div`
  padding: 27px 50px 14px 26px;
  border: 1px solid #d9e2e7;
  margin-bottom: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media screen and (max-width: 480px) {
    padding: 27px 45px 14px 13px;
    display: block;
  }
`;

export const Status = styled.div<StatusProps>`
  background-color: ${(props) => (props.draft ? "#ededed" : "#FFECEC")};
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  height: 24px;
  margin-right: 10px;
  text-align: center;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: ${(props) => (props.draft ? "#616161" : "#E00000")};
  border-radius: 3px;
`;

export const Requires = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 2px 8px;
  gap: 4px;
  height: 24px;
  width: 100%;
  background: #fff2e7;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #ea780e;
  @media screen and (max-width: 395px) {
    text-align: center;
    width: 74%;
    display: inline-block;
    max-width: 100%;
  }
`;

export const BoxModalContent = styled.div`
  padding: 0 24px;
  @media screen and (max-width: 980px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 480px) {
    padding: 0px 12px;
  }
`;

export const DeclineBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: #ffecec;
  border: 1px solid #ffd7d7;
  border-radius: 4px;
  margin: 20px 24px 5px;
`;

export const BoxModalContentInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const UpperLabel = styled.label`
  margin: 8px 0 4px;
  min-height: 14px;
  font-size: 14px;
  line-height: 24px;
  color: #424242;
`;

export const DataContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 640px) {
    display: block;
  }
`;

export const NumberOfAttorney = styled.div`
  height: 40px;
  font-size: 16px;
  width: 95%;
  font-family: "HarmoniaSansProCyr";

  @media screen and (max-width: 640px) {
    font-size: 14px;
    width: 97%;
  }
  @media screen and (max-width: 480px) {
    height: 32px;
    width: 100%;
  }
`;

export const NumberOfAttorneyInput = styled.input`
  height: 40px;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  font-size: 16px;
  padding: 0 8px;
  width: 100%;
  font-family: "HarmoniaSansProCyr";

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 900;
  padding: 0 28px 22px;
`;

export const SubTitle = styled.div`
  height: 28px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.011em;
  margin-bottom: 8px;
  letter-spacing: -0.011em;
  color: #0a0a0a;

  @media screen and (max-width: 640px) {
    padding: 0 2px;
  }
`;

export const Textarea = styled.textarea<InputProps>`
  min-height: 40px;
  width: 100%;
  border: 1px solid ${(props) => (props.error ? "#E00000" : "#c2c2c2")};
  border-radius: 2px;
  font-size: 16px;
  padding: 11px 8px;
  font-family: "HarmoniaSansProCyr";
  resize: none;
  @media screen and (max-width: 980px) {
    white-space: pre-wrap;
  }
  @media screen and (max-width: 480px) {
    min-height: 32px;
    width: 100%;
    max-height: 100%;
    position: relative;
  }
`;

export const Input = styled.input<InputProps>`
  font-family: "HarmoniaSansProCyr";
  height: 40px;
  border-radius: 2px;
  border: 1px solid ${(props) => (props.error ? "#E00000" : "#c2c2c2")};
  font-size: 16px;
  padding: 0 8px;
  width: 100%;
`;

export const BoxModalFooter = styled.div`
  border-top: 1px solid #d9e2e7;
  padding: 21px 24px 19px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 980px) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    margin-top: 50px;
    font-size: 14px;
  }
  @media screen and (max-width: 480px) {
    padding: 21px 7px 19px;
  }
`;

export const BackButton = styled.button`
  align-items: center;
  width: 104px;
  padding: 6px 16px;
  height: 32px;
  background: none;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #5d6c74;
  color: #5d6c74;
  font-family: "HarmoniaSansProCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: rgb(223, 225, 227);
  }
`;

export const NextButton = styled.button<NextButtonProps>`
  align-items: center;
  padding: 6px 16px;
  width: ${(props) => (props.longWidth ? "242px" : "153px")};
  height: 32px;
  background: #4cd964;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-family: "HarmoniaSansProCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: rgb(63, 181, 83);
  }
  &:disabled {
    background-color: #ededed;
    color: #9e9e9e;
    cursor: not-allowed;
  }
`;

export const ButtonSaveContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  width: 203px;
`;

export const PopupButtonContainer = styled.div`
  position: absolute;
  top: 40px;
  right: -64px;
  border-radius: 5px;
`;
export const PopupBackground = styled.div`
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
  overflow: hidden;
  border-radius: 10px;
`;

export const PopupButtons = styled.ul`
  overflow: hidden;
`;

export const PopupButton = styled.li`
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  font-family: "HarmoniaSansProCyr";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  &:hover {
    background: #f8f8f7;
    width: 100%;
    border-radius: 5px;
  }
  &:active {
    font-weight: bold;
    color: #f8f8f7;
  }
`;
