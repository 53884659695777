import React, { useState } from "react";
import { SvgIcon } from "ui/SvgIcon";
import { StyledReportActStatus } from "./StyledReportActStatus";
import agreementService from "services/entity/agreement.service";
import Config, { ConfigTypes } from "services/ConfigService";
import { useSelector } from "react-redux";
import { getAdvanceReportApplication } from "store/selectors";

interface ReportActStatusProps {
  id: number;
}

const ReportActStatus: React.FC<ReportActStatusProps> = (props) => {
  const { id } = props;
  const report = useSelector(getAdvanceReportApplication);
  const companyId = report && report.company ? report.company.id : 0;
  const isSigningEnabled = Config.getConfigToCompany(
    ConfigTypes.ADVANCE_REPORT_APPLICATION_SIGNING_ENABLED,
    companyId,
  );

  const [isSign, setIsSign] = useState<boolean | null>(false);

  const isSigned = async (id: number) => {
    const agreementList = await agreementService.getList();
    const result =
      agreementList &&
      agreementList.approvalTiers
        .flatMap((item) => item.employees)
        .flatMap((item) => item.approvalItemSignatures)
        .filter((item) => item.objectId === id && item.signedAt !== "").length >
        0;
    setIsSign(result);
  };
  isSigned(id);

  if (isSigningEnabled && isSign) {
    return (
      <StyledReportActStatus>
        Подписано с ЭЦП{" "}
        <SvgIcon id={"fingerprint"} color={"#008243"} size={24} />
      </StyledReportActStatus>
    );
  } else {
    return null;
  }
};

export default ReportActStatus;
