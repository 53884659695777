import DatePickerCaption from "app/component/date-picker/DatePickerCaption";
import i18next from "i18next";
import "moment/locale/ru";
import * as React from "react";
import { useEffect, useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import NavBar from "./DatePickerNavBar";
import "./style.css";
import { isIE } from "react-device-detect";
import { DayPickerWithChildren } from "./DayPickerWithChildren";

/**
 * Configure property date picker
 */
interface DatePickerCalendarProps {
  isShown: boolean;
  onSelected: (value: any) => void;
  onChangeTime?: (value: any) => void;
  isCleansed: boolean;
  isMultiChoice: boolean;
  isDisableBeforeToday?: boolean;
  isDisableAfterToday?: boolean;
  isDisableToday?: boolean;
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
  disabledDates?: { from: Date; to: Date }[];
  onDisabledDatesClick?: (value: any) => void;
  startDate?: Date;
  endDate?: Date;
  onClose?: () => void;
  month?: Date;
  isStartDate?: boolean;
  miniVector?: boolean;
  reset?: boolean;
  setReset?: (a: boolean) => void;
  removedPeriod?: boolean;
  setRemovedPeriod?: (a: boolean) => void;
}

/**
 * interface dates in date format
 */
interface Dates {
  startDate: Date;
  endDate?: Date;
}

const modifiersStyles = {
  startDate: {
    background:
      "linear-gradient(to right, rgba(223, 33, 27, 0) 50%, rgb(223, 33, 27) 0%, rgba(249, 210, 211, 0.4) 0%, rgba(249, 210, 211, 0.4) 0%)",
  },
  endDate: {
    background:
      "linear-gradient(to left, rgba(223, 33, 27, 0) 50%, rgb(223, 33, 27) 0%, rgba(249, 210, 211, 0.4) 0%, rgba(249, 210, 211, 0.4) 0%)",
  },
  captionLayout: {
    button: "button",
  },
  toDisable: {
    color: "rgb(217, 226, 231)",
    pointerEvents: "none",
  },
  outside: {
    backgroundColor: "white",
  },
};

/**
 *
 * Generate modifiers to calendar. To update specific dates using the method
 * @link modifiersStyles
 *
 * @param props Startup property
 * @param selectedDays State
 */
let generateModifiers = (
  props: DatePickerCalendarProps,
  selectedDays: Dates
) => {
  let modifier: any = {};
  if (selectedDays !== undefined && selectedDays.endDate !== undefined) {
    let from = new Date(selectedDays.startDate.valueOf());
    let to = new Date(selectedDays.endDate.valueOf());

    if (from.valueOf() === to.valueOf()) {
      modifiersStyles.endDate.background = "white";
    } else {
      modifiersStyles.endDate.background =
        "linear-gradient(to left, rgba(223, 33, 27, 0) 50%, rgb(223, 33, 27) 0%, rgba(249, 210, 211, 0.4) 0%, rgba(249, 210, 211, 0.4) 0%)";
    }

    modifier = {
      period: {
        from: new Date(from),
        to: new Date(to),
      },
      startDate: new Date(selectedDays.startDate.valueOf()),
      endDate: new Date(selectedDays.endDate.valueOf()),
      today: new Date(),
    };
  }
  modifier.toDisable = {};
  if (props.isDisableToday) {
    modifier.toDisable = {
      ...modifier.toDisable,
      from: new Date(1900, 10, 0),
      to: new Date(),
    };
  }
  if (props.isDisableBeforeToday) {
    modifier.toDisable = { ...modifier.toDisable, before: new Date() };
  }

  if (props.isDisableAfterToday) {
    modifier.toDisable = { ...modifier.toDisable, after: new Date() };
  }
  if (props.disableBeforeDate) {
    modifier.toDisable = {
      ...modifier.toDisable,
      before: props.disableBeforeDate,
    };
    modifier.initialMonth = props.disableBeforeDate;
  }
  if (props.disableAfterDate) {
    modifier.toDisable = {
      ...modifier.toDisable,
      after: props.disableAfterDate,
    };
  }
  return modifier;
};

/**
 *
 *The main method returns a data picker with a specific behavior.
 *
 * @param props Startup properties
 */
const DatePickerCalendar: React.FC<DatePickerCalendarProps> = (props) => {
  const getLanguage = () => i18next.language;
  const [language, setLanguage] = useState("en");

  let [selectedDays, setSelectedDays] = useState({
    startDate: props.startDate,
    endDate: props.endDate,
  } as Dates);

  useEffect(() => {
    setLanguage(getLanguage());
  }, []);

  useEffect(() => {
    if (props.reset || props.removedPeriod) {
      setSelectedDays({
        startDate: undefined,
        endDate: undefined,
      });
    }
  }, [props.reset, props.removedPeriod]);

  useEffect(() => {
    setSelectedDays({
      startDate: props.startDate,
      endDate: props.endDate,
    } as Dates);
    if (props.setReset) {
      props.setReset(false);
    }
    if (props.setRemovedPeriod) {
      props.setRemovedPeriod(false);
    }
  }, [props.startDate, props.endDate, props.reset, props.removedPeriod]);

  let isFirstChoice = true;
  let selectedMonth: Date = new Date();
  let modifier = generateModifiers(props, selectedDays);

  let handleClick = (day: Date, modifier: any) => {
    if (modifier.disabled || (isIE && modifier.toDisable)) {
      props.onDisabledDatesClick && props.onDisabledDatesClick(day);
      return;
    }

    let arrayAfterAction: Dates = actionsWithChosenDays(
      day,
      props,
      selectedDays
    );
    setSelectedDays(arrayAfterAction);
    checkAction(arrayAfterAction);
  };

  let getSelectedDay = (selectedDays: Dates) => {
    return selectedDays
      ? [selectedDays.startDate, selectedDays.endDate]
      : undefined;
  };

  let onSelected = (selectedDays: Dates) => {
    if (selectedDays.startDate && selectedDays.endDate) {
      props.onSelected(selectedDays);
    } else if (selectedDays.startDate && selectedDays.startDate) {
      props.onSelected(selectedDays);
    }
  };

  /**
   *
   * A method that will determine actions under certain conditions for each date
   *
   * @param day Date was be clicked
   * @param props Startup property
   * @param selectedDays State with chosen date
   * @return Array to update state and doing next stage
   */
  let actionsWithChosenDays = (
    day: Date,
    props: DatePickerCalendarProps,
    selectedDays: Dates
  ): Dates => {
    if (props.isMultiChoice) {
      switch (true) {
        case selectedDays.startDate === undefined: {
          isFirstChoice = false;
          return {
            startDate: day,
          };
        }
        case selectedDays.endDate === undefined &&
          selectedDays.startDate < day: {
          isFirstChoice = false;
          return {
            startDate: selectedDays.startDate,
            endDate: day,
          };
        }
        case selectedDays.startDate > day: {
          isFirstChoice = false;
          return {
            startDate: day,
            endDate: selectedDays.endDate,
          };
        }
        case selectedDays.startDate.valueOf() === day.valueOf() &&
          !selectedDays.endDate: {
          isFirstChoice = true;
          return {
            startDate: day,
            endDate: day,
          };
        }
        case selectedDays.endDate !== undefined && selectedDays.endDate < day: {
          isFirstChoice = false;
          return {
            startDate: selectedDays.startDate,
            endDate: day,
          };
        }
        case selectedDays.endDate !== undefined &&
          day < selectedDays.endDate &&
          day > selectedDays.startDate: {
          isFirstChoice = true;
          return {
            startDate: day,
          };
        }
        default: {
          return {} as Dates;
        }
      }
    } else {
      return {
        startDate: day,
      };
    }
  };

  /**
   *
   * Function checks what a properties have been using and call appropriate method
   *
   * @param selectedDays Chosen dates
   */
  let checkAction = (selectedDays: Dates) => {
    if (props.isMultiChoice && !props.isCleansed) {
      // is multi choice and not clear field
      if (selectedDays.endDate) {
        onSelected(selectedDays);
        isFirstChoice = true;
      } else {
        if (isFirstChoice) {
          onSelected(selectedDays);
          isFirstChoice = true;
        }
      }
    } else if (props.isMultiChoice && props.isCleansed) {
      // is multi choice and clear field
      if (selectedDays.endDate) {
        onSelected(selectedDays);
        isFirstChoice = true;
        setSelectedDays({} as Dates);
      } else {
        if (isFirstChoice) {
          onSelected(selectedDays);
          isFirstChoice = false;
          // setSelectedDays({} as Dates);
        }
      }
    } else if (!props.isMultiChoice && !props.isCleansed) {
      // is single choice and is not clear field
      onSelected(selectedDays);
      isFirstChoice = true;
    } else if (!props.isMultiChoice && props.isCleansed) {
      // is single choice and is clear field
      onSelected(selectedDays);
      isFirstChoice = true;
      setSelectedDays({} as Dates);
    }
  };

  let missClickCalendar = () => {
    if (props.onClose !== undefined) {
      props.onClose();
    }
  };

  let onCaptionClick = () => {
    if (props.isMultiChoice) {
      /*setSelectedDays({
        startDate: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), selectedMonth.getMonth() === new Date().getMonth() ? new Date().getDate() : 1),
        endDate: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0)
      });*/
      onSelected({
        startDate: new Date(
          selectedMonth.getFullYear(),
          selectedMonth.getMonth(),
          selectedMonth.getMonth() === new Date().getMonth()
            ? new Date().getDate()
            : 1
        ),
        endDate: new Date(
          selectedMonth.getFullYear(),
          selectedMonth.getMonth() + 1,
          0
        ),
      });
    }
  };

  let monthChangeHandler = (month: Date) => {
    selectedMonth = month;
  };

  function renderDay(day: Date) {
    return (
      <div className={"date-item-container"}>
        <span className={"date-item-text"}>{day.getDate()}</span>
      </div>
    );
  }

  return (
    <div>
      {props.isShown ? (
        <div style={{ position: "absolute", width: "100%" }}>
          <div className="miss-click-handler" onClick={missClickCalendar}></div>
          {props.children}
          {props.onChangeTime ? (
            <DayPickerWithChildren
              month={props.month ? props.month : new Date()}
              navbarElement={({
                className,
                onNextClick,
                onPreviousClick,
                month,
              }) => (
                <NavBar
                  className={className}
                  onNextClick={onNextClick}
                  onPreviousClick={onPreviousClick}
                  isDisableBeforeToday={props.isDisableBeforeToday}
                  month={month}
                  miniVector={props.miniVector}
                />
              )}
              showOutsideDays
              className="style"
              onMonthChange={monthChangeHandler}
              modifiers={modifier}
              modifiersStyles={modifiersStyles}
              onDayClick={handleClick}
              selectedDays={getSelectedDay(selectedDays)}
              captionElement={DatePickerCaption}
              localeUtils={MomentLocaleUtils}
              locale={language}
              renderDay={renderDay}
              initialMonth={modifier.initialMonth}
              disabledDays={props.disabledDates}
              onCaptionClick={onCaptionClick}
              onChangeTime={props.onChangeTime}
              isStartDate={props.isStartDate}
            />
          ) : (
            <DayPicker
              month={props.month ? props.month : new Date()}
              navbarElement={({
                className,
                onNextClick,
                onPreviousClick,
                month,
              }) => (
                <NavBar
                  className={className}
                  onNextClick={onNextClick}
                  onPreviousClick={onPreviousClick}
                  isDisableBeforeToday={props.isDisableBeforeToday}
                  month={month}
                  miniVector={props.miniVector}
                  onClose={props.onClose}
                />
              )}
              showOutsideDays
              className="style"
              onMonthChange={monthChangeHandler}
              modifiers={modifier}
              modifiersStyles={modifiersStyles}
              onDayClick={handleClick}
              selectedDays={getSelectedDay(selectedDays)}
              captionElement={DatePickerCaption}
              localeUtils={MomentLocaleUtils}
              locale={language}
              renderDay={renderDay}
              initialMonth={modifier.initialMonth}
              disabledDays={props.disabledDates}
              onCaptionClick={onCaptionClick}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DatePickerCalendar;
