import React, { Fragment, useRef } from "react";
import DateInput from "../date-input/DateInput";
import { useTranslation } from "react-i18next";
import CompanyAutocomplete, {
  CompanyAutocompleteOptionType,
} from "../autocomplete/CompanyAutocomplete";
import SvgIcon from "../svg-icon/SvgIcon";
import "./InternalReport.css";
import { ContentReportTitle, Label } from "./ReportFormStyled";

export interface DownloadParameters {
  downloadDateStart?: Date;
  downloadDateEnd?: Date;
}

interface InternalReportProps {
  setDownloadDateStart: (a: null | Date) => void;
  setDownloadDateEnd: (a: null | Date) => void;
  setCompany: (a: CompanyAutocompleteOptionType) => void;
  fieldsWithError: string[];
}

const InternalReport: React.FC<InternalReportProps> = ({
  setDownloadDateStart,
  setDownloadDateEnd,
  setCompany,
  fieldsWithError,
}) => {
  const { t } = useTranslation();

  let dowloadDateStartRef = useRef({} as any);
  let downloadDateEndRef = useRef({} as any);
  let companyAutocompleteRef = useRef({} as any);

  const validStateField = (fieldName: string) => {
    return fieldsWithError.includes(fieldName) ? "error" : "";
  };

  const downaloadDateStartSelectHandler = (value: any) => {
    setDownloadDateStart(value);
  };

  const downloadDateEndSelectHandler = (value: any) => {
    setDownloadDateEnd(value);
  };

  const companyChooseHandler = (option: CompanyAutocompleteOptionType) => {
    setCompany(option);
  };

  return (
    <Fragment>
      <ContentReportTitle>
        {t("reports.registry_form.header")}
      </ContentReportTitle>

      <div className="content-wrap_internal_report">
        <div className="content-wrap_internal_report_dates">
          <div
            className={`input-item quoter internal_report_date ${validStateField("date_from")}`}
          >
            <Label>
              {t("reports.registry_form.date_from")}
              <i className="input-required">*</i>
            </Label>
            <div className="input-date">
              <DateInput
                ref={dowloadDateStartRef}
                placeholder={t("reports.registry_form.choose_date")}
                onSelect={downaloadDateStartSelectHandler}
                format={"D MMMM"}
                newStyle={true}
              />
            </div>
          </div>

          <div
            className={`input-item quoter internal_report_date ${validStateField("date_to")}`}
          >
            <Label>
              {t("reports.registry_form.date_to")}
              <i className="input-required">*</i>
            </Label>
            <div className="input-date">
              <DateInput
                ref={downloadDateEndRef}
                placeholder={t("reports.registry_form.choose_date")}
                onSelect={downloadDateEndSelectHandler}
                format={"D MMMM"}
                newStyle={true}
              />
            </div>
          </div>
        </div>
        <div className="break"></div>

        <div className={`input-item half ${validStateField("company")}`}>
          <Label>
            {t("reports.registry_form.company")}
            <i className="input-required">*</i>
          </Label>
          <div className="input-date">
            <CompanyAutocomplete
              ref={companyAutocompleteRef}
              onChoose={companyChooseHandler}
              placeholder={t("reports.registry_form.company")}
              style={{ borderRadius: "8px" }}
            />
            <SvgIcon className={"icon icon-search"} href={"#svg_icon-search"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InternalReport;
