import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  getUserCertificates,
  getSystemInfo,
  isValidSystemSetup,
  SystemInfo,
} from "crypto-pro";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "ui/Modal/ModalContainer";
import { SvgIcon } from "ui/SvgIcon";
import {
  getBlobFileContentById,
  signPowerOfAttorney,
} from "services/ApiService";
import CertificateContainer from "ui/Certificate/CertificateContainer";
import {
  BackButton,
  BoxModal,
  BoxModalFooter,
  BoxModalHeader,
  ButtonSaveContainer,
  NextButton,
  Requires,
  Title,
} from "./PowerOfAttorneyCommonStyled";
import { loaderLock, loaderUnlock } from "store/common/actions";
import {
  getApiRootCertificates,
  getClientCaCertificates,
  getClientRootCertificates,
  getCommonUserDetail,
} from "store/selectors";
import { showErrors, showInfo } from "store/exception/actions";
import { getPowerOfAttorneyDetailed } from "store/power/actions";
import { Certificates, DialogStep3Props } from "../types";
import SignNotice from "app/component/sign-document/SignNotice";
import {
  setListOfApiRootCertificates,
  setListOfClientCertificates,
} from "store/certificates/actions";
import {
  checkRootCertificate,
  getAllCertificates,
  getCertificateWithSKIAndAKI,
} from "app/component/util/CertificatesHelper";

const PowerOfAttorneyCertificate: React.FC<DialogStep3Props> = ({
  signingBlobFileId,
  powerOfAttorneyId,
  isModalToCreate,
  setIsModalToCreate,
  setIsModalToSign,
  isModalToSign,
  setIsModalToEdit,
  isInfoModal,
}) => {
  const { t } = useTranslation();
  const user = useSelector(getCommonUserDetail);
  const apiRootCertificates = useSelector(getApiRootCertificates);
  const clientRootCertificates = useSelector(getClientRootCertificates);
  const clientCaCertificates = useSelector(getClientCaCertificates);

  const dispatch = useDispatch();

  const [thumbprint, setThumbprint] = useState("");
  const [certificates, setCertificates] = useState([] as Certificates[]);
  const [systemInfo, setSystemInfo] = useState(
    {} as SystemInfo & { isValidSystemSetup: boolean },
  );
  const [systemInfoError, setSystemInfoError] = useState(null);
  const [utcOffset, setUtcOffset] = useState(0);
  const [isOpenNotice, setOpenNotice] = useState(false);
  const [noticeType, setNoticeType] = useState("");
  const [noticeText, setNoticeText] = useState("");
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);

  const pluginHandler = useCallback(async (e) => {
    if (e.data === "cadesplugin_loaded") {
      dispatch(setListOfApiRootCertificates());
      dispatch(setListOfClientCertificates("ROOT"));
      dispatch(setListOfClientCertificates("CA"));
      dispatch(setListOfClientCertificates("MY"));
    } else if (e.data === "cadesplugin_load_error") {
      console.log("cadesplugin_load_error");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", pluginHandler);
    return () => {
      window.removeEventListener("message", pluginHandler);
    };
  }, [pluginHandler]);

  const getType = async (certificates: any) => {
    let allInternalCertificates = [];
    let typedCertificate = [];

    const caCertificates = await getAllCertificates(clientCaCertificates, "CA");

    const rootCertificates = await getAllCertificates(
      clientRootCertificates,
      "ROOT",
    );

    allInternalCertificates = caCertificates.concat(rootCertificates).flat();

    for (let i = 0; i < certificates.length; i++) {
      const clientCertificateWithSKIAndAKI = await getCertificateWithSKIAndAKI(
        certificates[i],
      );

      if (clientCertificateWithSKIAndAKI.authorityKeyIdentifier) {
        const isAQES = checkRootCertificate(
          allInternalCertificates,
          clientCertificateWithSKIAndAKI.authorityKeyIdentifier,
          apiRootCertificates,
        );

        certificates[i].type = isAQES ? "AQES" : "AES";
      } else {
        certificates[i].type = "AES";
      }
      typedCertificate.push(certificates[i]);
    }
    return typedCertificate;
  };

  const getSign = () => {
    dispatch(loaderLock());
    (async () => {
      try {
        const certificates: Certificates[] = await getUserCertificates();
        const typedCertificate = await getType(certificates);
        setCertificates(typedCertificate);
      } catch (e) {}
    })();
    dispatch(loaderUnlock());
    if (systemInfoError) {
      setNoticeType("notice_browser_plugin");
      setOpenNotice(true);
    }
  };
  useEffect(() => {
    setUtcOffset(moment().utcOffset());
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setSystemInfo({
          ...(await getSystemInfo()),
          isValidSystemSetup: await isValidSystemSetup(),
        });
      } catch (error: any) {
        setSystemInfoError(error.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (!thumbprint && clientCaCertificates && clientRootCertificates) {
      getSign();
    }
  }, [thumbprint, clientCaCertificates, clientRootCertificates]);

  const changeXML = async (id: any, signatureBase64: any) => {
    try {
      const XMLTemplate = await getBlobFileContentById(id, {
        responseType: "text/xml",
      });
      const parser = new DOMParser();
      const file = parser.parseFromString(XMLTemplate.data, "text/xml");
      const body = file.getElementsByTagName("soapenv:Body");
      let binarySecurityToken = file.getElementsByTagName(
        "wsse:BinarySecurityToken",
      );
      const bodyContent = body[0].innerHTML;

      if (bodyContent) {
        // Заполнить открытым ключом сертификата тег <wsse:BinarySecurityToken>
        binarySecurityToken[0].innerHTML = signatureBase64;
        const fileToSign = file.documentElement.outerHTML;

        return fileToSign;
      }
    } catch (error) {}
  };

  function signXML() {
    var CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE = 2;
    var CADESCOM_ENCODE_BASE64 = 0;

    const selectedSertificate = certificates.filter(
      (sert) => sert.thumbprint === thumbprint,
    );

    const result = cadesplugin.async_spawn(function* (): any {
      let oCertificateBase64 =
        yield selectedSertificate[0]._cadesCertificate.Export(
          CADESCOM_ENCODE_BASE64,
        );

      let sContent = yield changeXML(signingBlobFileId, oCertificateBase64);

      // Создаем объект CAdESCOM.CPSigner
      var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
      yield oSigner.propset_Certificate(
        selectedSertificate[0]._cadesCertificate,
      );
      yield oSigner.propset_CheckCertificate(true);

      // Создаем объект CAdESCOM.SignedXML
      var oSignedXML =
        yield cadesplugin.CreateObjectAsync("CAdESCOM.SignedXML");
      yield oSignedXML.propset_Content(sContent);

      // Указываем тип подписи - в данном случае по шаблону
      yield oSignedXML.propset_SignatureType(
        CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE,
      );

      var sSignedMessage = "";
      try {
        sSignedMessage = yield oSignedXML.Sign(oSigner);
      } catch (err) {
        setNoticeType("alert");
        setOpenNotice(true);
        console.log(
          "Failed to create signature. Error: " + cadesplugin.getLastError(err),
        );
        return;
      }
      // Создаем объект CAdESCOM.SignedXML
      var oSignedXML2 =
        yield cadesplugin.CreateObjectAsync("CAdESCOM.SignedXML");

      try {
        yield oSignedXML2.Verify(sSignedMessage);
      } catch (err) {
        setNoticeType("alert");
        setOpenNotice(true);
        console.log(
          "Failed to verify signature. Error: " + cadesplugin.getLastError(err),
        );
        return;
      }

      const parser = yield new DOMParser();
      const file = parser.parseFromString(sSignedMessage, "text/xml");
      const body = file.getElementsByTagName("soapenv:Envelope");
      const bodyContent = body[0].outerHTML;
      return bodyContent;
    });
    return result;
  }

  const postSignPowerOfAttorney = async (fileToUpload: any) => {
    const selectedSertificate = certificates.filter(
      (sert) => sert.thumbprint === thumbprint,
    );
    const uploadSignatureFileName = `AeroclubPowerOfAttorney.xml`;

    const uploadedFile = new File([fileToUpload], uploadSignatureFileName, {
      type: "text/xml",
    });

    let formData = new FormData();
    formData.append("signatureType", "AQES");
    formData.append(
      "principalOccupationId",
      user.occupations[0].occupationId.toString(),
    );
    formData.append("signingBlobFileId", signingBlobFileId.toString());

    formData.append("signedBlobFileContent", uploadedFile);
    formData.append("signedAt", new Date().toISOString());
    formData.append("utcOffset", utcOffset.toString());
    formData.append(
      "certificate.serialNumber",
      selectedSertificate[0].thumbprint,
    );
    formData.append(
      "certificate.issuerName",
      selectedSertificate[0].issuerName,
    );
    formData.append(
      "certificate.subjectName",
      selectedSertificate[0].subjectName,
    );
    formData.append(
      "certificate.validFromDate",
      selectedSertificate[0].validFrom,
    );
    formData.append("certificate.validToDate", selectedSertificate[0].validTo);
    formData.append(
      "certificate.thumbprint",
      selectedSertificate[0].thumbprint,
    );

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const result = await signPowerOfAttorney(
      powerOfAttorneyId,
      formData,
      config,
    );
    return result;
  };

  const toggle = () => {
    setIsModalToCreate && setIsModalToCreate(false);
    setIsModalToSign && setIsModalToSign(false);
    setIsModalToEdit && setIsModalToEdit(false);
  };
  const click = () => {
    setIsModalToCreate && setIsModalToCreate(false);
    setIsModalToSign && setIsModalToSign(false);
    setIsModalToEdit && setIsModalToEdit(false);
  };

  const onClick = (thumbprint: string) => {
    const newCertificatesData = certificates.map((certificate) =>
      certificate.thumbprint === thumbprint
        ? { ...certificate, checked: true }
        : { ...certificate, checked: false },
    );
    setCertificates(newCertificatesData as Certificates[]);
    setThumbprint(thumbprint);
  };

  const signPowerofAttorneyHandleClick = async () => {
    if (isRequestProcessing) {
      return;
    }

    setIsRequestProcessing(true);
    const fileToUpload = await signXML();

    if (fileToUpload) {
      const result = await postSignPowerOfAttorney(fileToUpload);
      if (result && result.status >= 200) {
        dispatch(getPowerOfAttorneyDetailed(powerOfAttorneyId));
        dispatch(
          showInfo({
            code: "",
            message: t("power.message.signed"),
          }),
        );
      } else {
        dispatch(
          showErrors({
            code: "",
            message: t("power.error.sign"),
          }),
        );
      }
      isInfoModal && isInfoModal(false);
      setIsModalToCreate && setIsModalToCreate(false);
      setIsModalToSign && setIsModalToSign(false);
      setIsModalToEdit && setIsModalToEdit(false);
    }
  };

  const toggleNoticeModal = () => {
    setOpenNotice(!isOpenNotice);
  };

  return (
    <div>
      <SignNotice
        style={{ zIndex: 1002 }}
        text={noticeText}
        noticeType={noticeType}
        isOpen={isOpenNotice}
        onClose={toggleNoticeModal}
      />
      <Fragment>
        <ModalContainer
          style={{ zIndex: 1001 }}
          isOpen={isModalToCreate || isModalToSign}
        >
          <BoxModal>
            <div className="box-modal_close" onClick={toggle}></div>
            <BoxModalHeader>
              <div>{t("power.of_attorney")}</div>
              <div style={{ marginBottom: "6px" }}>
                <Requires>{t("power.signing_requires")}</Requires>
              </div>
            </BoxModalHeader>
            <div>
              <div>
                <Title>{`Сертификат руководителя`}</Title>
                <CertificateContainer
                  certificates={certificates}
                  onClick={onClick}
                />
              </div>
            </div>
            <BoxModalFooter>
              <BackButton onClick={click}>
                {<SvgIcon id={"vectorLeft"} />} {t("power.button.back")}
              </BackButton>
              <ButtonSaveContainer>
                <NextButton
                  disabled={!thumbprint.length}
                  onClick={signPowerofAttorneyHandleClick}
                  style={{ width: "143px" }}
                >
                  {t("power.button.sign")}
                </NextButton>
              </ButtonSaveContainer>
            </BoxModalFooter>
          </BoxModal>
        </ModalContainer>
      </Fragment>
    </div>
  );
};

export default PowerOfAttorneyCertificate;
