import moment from "moment";
import { get, max, uniqBy } from "lodash";
import { getCurrenciesConversion } from "../services/ApiService";
import { RepresentationDayValue } from "infrastructure/interfaces/Daily.interface";

var keys = new Map();

export const debounce = (func: () => void, wait = 50) => {
  let h: any;
  return () => {
    clearTimeout(h);
    h = setTimeout(() => func(), wait);
  };
};

interface FormatterOptions {
  withMonth: boolean;
  withYear: boolean;
  withTime?: boolean;
}

export const formatter = (
  format: string,
  locale: string,
  startDate: Date | string,
  endDate?: Date | string,
  options?: FormatterOptions
): string => {
  // TODO внимание кастыль так как не понятно как будут прилетать даты, после выяснения удалить
  let result: string = "";
  if (options && options.withTime) {
    result = moment(startDate).locale(locale).format(format);
  } else {
    startDate = typeof startDate === "string" ? toDate(startDate) : startDate; //2019-10-24T21:00:00
    endDate = typeof endDate === "string" ? toDate(endDate) : endDate;
    if (endDate && endDate.valueOf() !== startDate.valueOf()) {
      if (options) {
        if (startDate.getMonth() === endDate.getMonth() && !options.withMonth) {
          result = `${moment(startDate).locale(locale).format("D")} — ${moment(endDate).locale(locale).format(format)}`;
        } else {
          result = `${moment(startDate).locale(locale).format(format)} — ${moment(endDate).locale(locale).format(format)}`;
        }
      }
    } else {
      result = `${moment(startDate).locale(locale).format(format)}`;
    }
    if (options && options.withYear)
      result += ` ${moment(endDate).format("YYYY")}`;
  }
  return result;
};

export enum NameFormats {
  FULL,
  NAME,
  NAME_WITH_MIDDLENAME,
  NAME_WITH_LASTNAME,
  LASTNAME_WITH_NAME,
  LASTNAME_WITH_FULL_INITIALS,
  LASTNAME_WITH_INITIALS_WITHOUT_MIDDLENAME,
}

export const nameFormatter = (
  name: any,
  format: NameFormats,
  locale: string
) => {
  if (!name || !name[locale]) {
    return "";
  }

  const fullName: string[] = name[locale].split(" ");
  const firstName = fullName[0];
  const middleName = fullName[1];
  const lastName = fullName[2];

  switch (true) {
    case format === NameFormats.FULL: {
      return `${firstName} ${middleName} ${lastName}`;
    }
    case format === NameFormats.NAME: {
      return `${firstName}`;
    }
    case format === NameFormats.NAME_WITH_MIDDLENAME: {
      return `${firstName} ${middleName}`;
    }
    case format === NameFormats.NAME_WITH_LASTNAME: {
      return `${firstName} ${lastName}`;
    }
    case format === NameFormats.LASTNAME_WITH_NAME: {
      return `${lastName} ${firstName}`;
    }
    case format === NameFormats.LASTNAME_WITH_FULL_INITIALS: {
      return `${lastName} ${firstName[0]}. ${middleName ? middleName[0] + "." : ""}`;
    }
    case format === NameFormats.LASTNAME_WITH_INITIALS_WITHOUT_MIDDLENAME: {
      return `${lastName} ${firstName[0]}.`;
    }
  }
  return "";
};

export const dateFormatter = (
  date: Date | null | undefined,
  format: string,
  locale: string
) => {
  return date ? moment(date).locale(locale).local().format(format) : "";
};

export const parseTime = (time: string): number[] => {
  return time.split(":").map(Number);
};

const startCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
};

export const numberFormatter = (number: string, toPoint?: boolean) => {
  if (toPoint) {
    return number.split(",").join(".");
  } else {
    return number.split(".").join(",");
  }
};

export const toLocaleNumberFormatter = (number: number) => {
  return new Intl.NumberFormat("ru-RU").format(number);
};

export const isSentenceContainsMaxLengthWord = (sentence: string) => {
  if (!sentence) {
    return false;
  }

  let result: boolean = false;
  sentence.split(" ").forEach((item: string) => {
    if (item.length > 30) {
      result = true;
    }
  });
  return result;
};

export const returnOnlyDate = (date: Date) => {
  let result = date;
  result && result.setHours(0, 0, 0, 0);
  return result;
};

export const returnDateWithoutUTC = (date: any) => {
  if (date.toLowerCase) {
    date = moment(String(date), "YYYY-MM-DDThh:mm:ss").toDate();
  }
  let dateWithoutTime = date;
  dateWithoutTime && dateWithoutTime.setHours(0, 0, 0, 0);
  return moment
    .utc(moment(dateWithoutTime).format("YYYY-MM-DD"), "YYYY-MM-DD")
    .toDate();
};

export const addUtcOffset = (date: string | Date) => {
  return moment.utc(date, "YYYY-MM-DDThh:mm:ss").toDate();
};

export const toDate = (
  date: string | Date,
  format: string = "YYYY-MM-DDThh:mm:ss"
) => {
  return moment(date, format).toDate();
};

export const optional = <T>(object: T, defaultValue: T): T => {
  return object ? object : defaultValue;
};

export const getNextKey = (key: string): number => {
  if (keys.has(key)) {
    return ++keys.get(key).key;
  } else {
    keys.set(key, { key: 0 });
    return ++keys.get(key).key;
  }
};

export const roundNumberToAccuracy = (
  number: number,
  accuracy: number
): number => {
  return (
    Math.round(number * Math.pow(10, accuracy) + Math.pow(0.1, accuracy)) /
    Math.pow(10, accuracy)
  );
};

export const betweenTwoDates = (startOn: Date, endOn: Date) => {
  if (startOn === endOn) {
    return 1;
  }
  let start = moment(startOn);
  let end = moment(endOn);
  let duration = moment.duration(end.diff(start));
  return duration.asDays() + 1;
};

export const betweenSeveralDates = (
  period: { startOn: Date; endOn: Date }[]
) => {
  let allDate = [] as Date[];
  period.forEach((item) => {
    allDate.push(...enumerateDaysBetweenDates(item.startOn, item.endOn, true));
  });

  allDate = uniqBy(allDate, (item) => +moment(item).format("YYYYMMDD"));

  return allDate.length;
};

export const enumerateDaysBetweenDates = (
  startDate: Date,
  endDate: Date,
  withBorders?: true
) => {
  var dates = [];

  var currDate = moment(startDate).startOf("day");
  var lastDate = moment(endDate).startOf("day");

  if (withBorders) {
    currDate.add(-1, "days");
    lastDate.add(1, "days");
  }

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};

export const accountantDivineAmount = (
  number: number,
  amount: number,
  accuracy: number
) => {
  let stableAmount = roundNumberToAccuracy(number / amount, accuracy);
  let finalAmount = roundNumberToAccuracy(
    number - stableAmount * (amount - 1),
    accuracy
  );

  return [stableAmount, finalAmount];
};

export const mergeDatesToPeriods = (
  dates: Date[],
  data?: RepresentationDayValue[]
) => {
  let newDates = [...dates];
  let newPeriod = [] as {
    from: Date;
    to: Date;
    isBreakfast?: boolean;
    isDinner?: boolean;
    isLunch?: boolean;
  }[];

  if (newDates.length == 0) {
    return newPeriod;
  }

  newDates.sort((a, b) => +a - +b);
  uniqBy(newDates, (item) => item);

  let currPeriod = {
    from: moment(newDates[0]).startOf("day").clone().toDate(),
    to: moment(newDates[0]).startOf("day").clone().toDate(),
  };

  if (newDates.length == 1) {
    newPeriod.push(currPeriod);
  } else {
  for (let i = 1; i < newDates.length; i++) {
      const currDate = moment(newDates[i]).startOf("day");
      const lastDate = moment(newDates[i - 1]).startOf("day");
      const findLastDate =
        data &&
        data.find(
          (e) => e.date.toString() === lastDate.clone().toDate().toString()
        );
      const findCurrentDate =
        data &&
        data.find(
          (e) => e.date.toString() === currDate.clone().toDate().toString()
        );

      const isPeriod = moment.duration(currDate.diff(lastDate)).asDays() == 1;
      const isMealPeriod = findLastDate && findCurrentDate;
      const mergeMeals =
        isMealPeriod &&
        findLastDate.isBreakfast === findCurrentDate.isBreakfast &&
        findCurrentDate.isDinner === findLastDate.isDinner &&
        findCurrentDate.isLunch === findLastDate.isLunch;
      if ((!data && isPeriod) || (isPeriod && mergeMeals)) {
        currPeriod.to = currDate.clone().toDate();
      } else {
        newPeriod.push(currPeriod);
        currPeriod = {
          from: currDate.clone().toDate(),
          to: currDate.clone().toDate(),
        };
      }
  }
  newPeriod.push(currPeriod);
  }

  if (data) {
    newPeriod = newPeriod.map((item) => {
      const findMeal = data.filter(
        (element) => element.date >= item.from && element.date <= item.to
      );
      return {
        ...item,
        isBreakfast: findMeal.find((el) => el.isBreakfast === true)
          ? true
          : false,
        isDinner: findMeal.find((el) => el.isDinner === true) ? true : false,
        isLunch: findMeal.find((el) => el.isLunch === true) ? true : false,
      };
    });
  }

  return newPeriod;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getWithoutNull = <
  TObject extends object,
  TKey extends keyof TObject,
  TDefault,
>(
  object: TObject | null | undefined,
  path: TKey | [TKey],
  defaultValue: TDefault
): Exclude<TObject[TKey], undefined> | TDefault => {
  let result = get(object, path, defaultValue);
  return result == null ? defaultValue : result;
};

export const getCurrencieCode = (alfaCode: string): string => {
  return alfaCode || alfaCode.toUpperCase
    ? alfaCode.toUpperCase().slice(0, 3)
    : "";
};

export const getCurrencyRate = async (
  currencyid: number,
  alfaCode: string,
  dateRate: Date
) => {
  let params = {
    dateRate: formatter("YYYY-MM-DD", "ru", dateRate),
  };

  if (alfaCode != "rub") {
    let response = await getCurrenciesConversion(currencyid, { params });
    if (response.headers.success) {
      return response.data.rate;
    } else {
      return null;
    }
  } else {
    return 1;
  }
};

export const subtract = (firstNumber: number, secondNumber: number): number => {
  const x = firstNumber.toString();
  const dotIndexOfX = x.lastIndexOf(".") + 1;
  const precisionLengthOfX =
    dotIndexOfX === -1 ? 0 : x.substring(dotIndexOfX).length;

  const y = secondNumber.toString();
  const dotIndexOfY = y.lastIndexOf(".") + 1;
  const precisionLengthOfY =
    dotIndexOfY === -1 ? 0 : y.substring(dotIndexOfY).length;

  const precisionLength = max([precisionLengthOfX, precisionLengthOfY]);

  return +(firstNumber - secondNumber).toFixed(precisionLength);
};

export const getLogoPath = (
  data: any,
  isRussian: boolean,
  pathToCompany: string
) => {
  const language = isRussian ? "uriRu" : "uriEn";
  const logopath = (path: string) => {
    if (path.startsWith("http")) {
      return path;
    } else {
      return `${pathToCompany}${path}`;
    }
  };
  return [logopath(data.logos.full[language]), logopath(data.logos.mobile[language])];
};
