import React from "react";
import User from "./Icons/User";
import FingerPrint from "./Icons/FingerPrint";
import CheckCircle from "./Icons/CheckCircle";
import DangerCircle from "./Icons/DangerCircle";
import AlertCircle from "./Icons/AlertCircle";
import InfoCircle from "./Icons/InfoCircle";
import FileDownload from "./Icons/FileDownload";
import Paperclip from "./Icons/Paperclip";
import Image from "./Icons/Image";
import Camera from "./Icons/Camera";
import Spinner from "./Icons/Spinner";
import { Icons, IconsProps } from "./types";
import Plus from "./Icons/Plus";
import Vector from "./Icons/Vector";
import VectorLeft from "./Icons/VectorLeft";
import VectorRight from "./Icons/VectorRight";
import WarningCircle from "./Icons/WarningCircle";
import VectorDown from "./Icons/VectorDown";
import Signature from "./Icons/Signature";
import Browser from "./Icons/Browser";
import WarningTriangle from "./Icons/WarningTriangle";
import PDFicon from "./Icons/PDFicon";
import Fragment from "./Icons/Fragment";
import Download from "./Icons/Download";
import Sorter from "./Icons/Sorter";
import Close from "./Icons/Close";
import DocFilled from "./Icons/DocFilled";
import DocBlank from "./Icons/DocBlank";
import AlertTriangle from "./Icons/AlertTriangle";
import EyeIcon from "./Icons/EyeIcon";

const SvgIcon: React.FC<IconsProps> = (props) => {
  const { id, color = "#FFFFFF", size = 18, background, style } = props;

  const SvgIcons: Icons = {
    user: <User color={color} size={size} />,
    fingerprint: <FingerPrint color={color} size={size} />,
    checkcircle: <CheckCircle color={color} size={size} />,
    dangercircle: <DangerCircle color={color} size={size} />,
    alertcircle: <AlertCircle color={color} size={size} />,
    infoCircle: <InfoCircle color={color} size={size} />,
    fileDownload: <FileDownload color={color} size={size} />,
    paperclip: <Paperclip color={color} size={size} />,
    image: <Image color={color} size={size} />,
    camera: <Camera color={color} size={size} />,
    plus: <Plus color={color} size={size} />,
    spinner: <Spinner />,
    vector: <Vector color={color} size={size} />,
    vectorRight: <VectorRight color={color} size={size} />,
    vectorLeft: <VectorLeft color={color} size={size} />,
    vectorDown: <VectorDown color={color} size={size} style={style} />,
    warningCircle: <WarningCircle color={color} size={size} style={style} />,
    signature: <Signature color={color} size={size} background={background} />,
    browser: <Browser color={color} size={size} />,
    warningTriangle: <WarningTriangle color={color} size={size} />,
    pdfIcon: <PDFicon color={color} size={size} />,
    fragment: <Fragment color={color} size={size} />,
    download: <Download color={color} size={size} />,
    sorter: <Sorter color={color} size={size} />,
    close: <Close color={color} size={size} style={style} />,
    docFilled: <DocFilled color={color} size={size} />,
    docBlank: <DocBlank color={color} size={size} />,
    alertTriangle: <AlertTriangle color={color} size={size} />,
    eyeIcon: <EyeIcon color={color} size={size} />,
  };

  return <>{SvgIcons[id]}</>;
};

export default SvgIcon;
