import React from "react";
import { IconProps } from "../types";

const Close: React.FC<IconProps> = (props) => {
  const { size, color, style } = props;
  return (
    <div style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 10 10"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.09989 4.79921L9.43876 1.46028C9.74285 1.15618 9.74285 0.66463 9.43876 0.360525C9.13466 0.0564201 8.64312 0.0564201 8.33902 0.360525L5.00016 3.69946L1.6613 0.360525C1.3572 0.0564201 0.865668 0.0564201 0.56157 0.360525C0.257471 0.66463 0.257471 1.15618 0.56157 1.46028L3.90043 4.79921L0.56157 8.13814C0.257471 8.44225 0.257471 8.93379 0.56157 9.2379C0.71323 9.38956 0.912333 9.46578 1.11144 9.46578C1.31054 9.46578 1.50964 9.38956 1.6613 9.2379L5.00016 5.89896L8.33902 9.2379C8.49068 9.38956 8.68979 9.46578 8.88889 9.46578C9.08799 9.46578 9.2871 9.38956 9.43876 9.2379C9.74285 8.93379 9.74285 8.44225 9.43876 8.13814L6.09989 4.79921Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default Close;
