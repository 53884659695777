import React, { useState, useEffect, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "app/component/modal/ModalContainer";
import Validator from "../validator/Validator";

interface CommentModalProps {
  isOpen: boolean;
  title: string;
  onSubmit: (value: string) => void;
  onClose: () => void;
}

const CommentModal: React.FC<CommentModalProps> = (props) => {
  let { t } = useTranslation();

  let [comment, setComment] = useState("");
  let [notValid, setNotValid] = useState(false);
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  let commentRef = useRef({} as HTMLTextAreaElement);
  const commentValidatorRef = useRef({} as any);

  const closeHandler = () => {
    props.onClose();
  };

  const saveHandler = () => {
    commentValidatorRef.current.validate();
    if (comment && comment != "") {
      props.onSubmit(comment);
    }
  };

  const changeDescription = () => {
    setComment(commentRef.current.value);
    setNotValid(false);
  };

  useEffect(() => {
    if (props.isOpen) {
      setComment("");
    }
  }, [props.isOpen]);

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return (
    <ModalContainer
      onEnter={saveHandler}
      isOpen={props.isOpen}
      destroy={true}
      highestModal={isHighestModal}
      overlayClick={closeHandler}
    >
      <div className="box-modal" id="add-expenses">
        <div
          className="box-modal_close arcticmodal-close"
          onClick={props.onClose}
        />
        <div className="box-modal-title">{props.title}</div>
        <div className="box-modal-content">
          <div className="input-wrap">
            <div className="input-wrap">
              <div className="input-item">
                <Validator ref={commentValidatorRef} type={"textarea"}>
                  <Fragment>
                    <label className="input-label">
                      {t("modals.create_order_modal.comment")}
                      <i className="input-required">*</i>
                    </label>
                    <textarea
                      ref={commentRef}
                      onChange={changeDescription}
                      value={comment}
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                    />
                  </Fragment>
                </Validator>
              </div>
            </div>
          </div>
        </div>
        <div className="box-modal-footer">
          <div
            className="arcticmodal-close btn btn_black pointer"
            onClick={props.onClose}
          >
            {t("modals.create_order_modal.cancel")}
          </div>
          <div className="btn-expense btn_green pointer" onClick={saveHandler}>
            {t("modals.create_order_modal.complete")}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CommentModal;
