import { BaseMapper } from "./base.mapper";
import { AOType } from "infrastructure/interfaces";
import { get } from "lodash";

export class AOTypeMapper extends BaseMapper<AOType, any> {
  responseToEntity(response: any): AOType {
    return {
      id: get(response, "id", -1),
      name: {
        ru: get(response, "name.ru", "Имя[STUB]"),
        en: get(response, "name.en", "Name[STUB]"),
      },
      code: get(response, "code", "General"),
    };
  }
}
