import styled from "styled-components";
import { statusColorsStyles } from "./statusColorsStyles";
import { StatusColor, StyledStatusProps } from "./types";

const getColorsStyle = (selectedColor: StatusColor) => {
  return statusColorsStyles[selectedColor];
};

const StyledReportStatusContainer = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 6px;
`;

const StyledReportStatus = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.006em;
  text-transform: capitalize;
`;

const StyledReportStatusSpan = styled.span<StyledStatusProps>`
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
  border-radius: 50%;
  margin-left: 4px;
  background: ${(props) => getColorsStyle(props.color)};
`;

const StyledReportSign = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.006em;
  color: #000000;
  padding-top: 4px;
`;

export {
  StyledReportStatus,
  StyledReportStatusSpan,
  StyledReportStatusContainer,
  StyledReportSign,
};
