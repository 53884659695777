import React, { CSSProperties } from "react";

interface SvgIconProps {
  className?: string;
  href: string;
  x?: number;
  y?: number;
  type?: string;
  index?: number;
  onClick?: (event: any) => void;
  counter?: number;
  style?: CSSProperties;
}

const DefaultSvgIconProps = {
  className: "",
  href: "",
  x: 0,
  y: 0,
  type: "",
  onClick: () => {},
};

const SvgIcon: React.SFC<SvgIconProps> = (props = DefaultSvgIconProps) => {
  return (
    <div
      className={props.className}
      data-type={props.type}
      data-index={props.index}
      style={props.style ? props.style : undefined}
    >
      {props.counter ? (
        <div className="icon-counter">x{props.counter}</div>
      ) : null}
      <svg
        onClick={props.onClick}
        data-type={props.type}
        data-index={props.index}
      >
        <use
          style={{ pointerEvents: "none" }}
          x={props.x}
          y={props.y}
          href={props.href}
          data-type={props.type}
          data-index={props.index}
        ></use>
      </svg>
    </div>
  );
};

export default SvgIcon;
