import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SvgIcon } from "ui/SvgIcon";

interface StateProps {
  state: string;
  svgKey?: boolean;
}

const StatusContainer = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4px;
`;

const Status = styled.div`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.024em;
  padding: 2px 8px;
  border-radius: 4px;
`;

const State: React.FC<StateProps> = ({ state, svgKey }) => {
  const { t } = useTranslation();

  const [screenWidth, setScreenWidth] = useState(500);
  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const colors: { [key: string]: string } = {
    Draft: "#616161",
    SigningRequires: "#EA780E",
    Declined: "#E00000",
    Revoked: "#E00000",
    Signed: "#3fb553",
  };

  const backgrounds: { [key: string]: string } = {
    Draft: "#EDEDED",
    SigningRequires: "#FFF2E7",
    Declined: "#FFECEC",
    Revoked: "#FFECEC",
    Signed: "#DBF7E0",
  };
  const translations: { [key: string]: string } = {
    Draft: "power.draft",
    SigningRequires: "power.signing_requires",
    Declined: "power.declined",
    Revoked: "power.revoked",
    Signed: "power.signed",
  };
  return (
    <>
      {svgKey && (
        <SvgIcon
          id={"signature"}
          background={screenWidth < 480 ? "white" : backgrounds[state]}
          size={24}
          color={screenWidth < 480 ? "white" : colors[state]}
        />
      )}
      <StatusContainer>
        <Status
          style={{ color: colors[state], background: backgrounds[state] }}
        >
          {t(translations[state])}
        </Status>
      </StatusContainer>
    </>
  );
};

export default State;
