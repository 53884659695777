import DatePickerCaption from "app/component/date-picker/DatePickerCaption";
import i18next from "i18next";
import "moment/locale/ru";
import * as React from "react";
import { useEffect, useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import NavBar from "./DatePickerNavBar";
import "./style.css";
import moment from "moment";
import If from "../util/If";
import { enumerateDaysBetweenDates } from "app/utils";

/**
 * Configure property date picker
 */
interface NutritionPickerCalendarProps {
  isShown: boolean;
  onSelect: (date: Date) => void;
  nutritionDates?: { date: Date; count: number }[];
  onClose?: () => void;
  disableBeforeDate: Date;
  disableAfterDate: Date;
}

const modifiersStyles = {
  startDate: {
    background:
      "linear-gradient(to right, rgba(223, 33, 27, 0) 50%, rgb(223, 33, 27) 0%, rgba(249, 210, 211, 0.4) 0%, rgba(249, 210, 211, 0.4) 0%)",
  },
  endDate: {
    background:
      "linear-gradient(to left, rgba(223, 33, 27, 0) 50%, rgb(223, 33, 27) 0%, rgba(249, 210, 211, 0.4) 0%, rgba(249, 210, 211, 0.4) 0%)",
  },

  toDisable: {
    color: "rgb(217, 226, 231)",
    pointerEvents: "none",
  },
  disable: {
    color: "rgb(217, 226, 231)",
    pointerEvents: "none",
  },
  outside: {
    backgroundColor: "white",
  },
};

const NutritionPickerCalendar: React.FC<NutritionPickerCalendarProps> = (
  props,
) => {
  const getLanguage = () => i18next.language;
  let [language, setLanguage] = useState("en");
  let [marks, setMarks] = useState([] as number[]);
  let [disabledDays, setDisabledDays] = useState([] as Date[]);
  let [modifier, setModifier] = useState({} as any);

  useEffect(() => {
    setLanguage(getLanguage());
  });

  useEffect(() => {
    let newMarks = props.nutritionDates
      ? props.nutritionDates.map(
          (item) => +moment(item.date).format("YYYYMMDD"),
        )
      : [];
    setMarks(newMarks);
  }, [props.nutritionDates]);

  useEffect(() => {
    let modifier: any = {};

    modifier.toDisable = {
      before: props.disableBeforeDate,
      after: props.disableAfterDate,
    };
    setModifier(modifier);

    let allPeriodDate = enumerateDaysBetweenDates(
      props.disableBeforeDate,
      props.disableAfterDate,
      true,
    );

    if (props.nutritionDates && props.nutritionDates.length > 0) {
      let newDisabledDay = [] as Date[];
      let nutritionDateMarks = props.nutritionDates.map(
        (item) => +moment(item.date).format("YYYYMMDD"),
      );
      allPeriodDate.forEach((item) => {
        if (!nutritionDateMarks.includes(+moment(item).format("YYYYMMDD"))) {
          newDisabledDay.push(item);
        }
      });
      setDisabledDays(newDisabledDay);
    }
  }, [
    props.isShown,
    props.nutritionDates,
    props.disableBeforeDate,
    props.disableAfterDate,
  ]);

  let handleClick = (day: Date, modifier: any) => {
    if (!modifier.disabled) {
      props.onSelect(day);
    }
  };

  let missClickCalendar = () => {
    if (props.onClose !== undefined) {
      props.onClose();
    }
  };

  function renderDay(day: Date) {
    let count = 0;
    let flag = false;
    let currMark = +moment(day).format("YYYYMMDD");

    if (marks.includes(currMark)) {
      flag = true;
      let nutritionDate = props.nutritionDates
        ? props.nutritionDates.find(
            (item) => +moment(item.date).format("YYYYMMDD") == currMark,
          )
        : undefined;
      count = nutritionDate ? nutritionDate.count : 0;
    }

    return (
      <div className={"date-item-container"} style={{ position: "relative" }}>
        <span className={"date-item-text"}>{day.getDate()}</span>
        <If condition={flag}>
          <div className="notice-counter nutrition pointer">x{count}</div>
        </If>
      </div>
    );
  }

  return (
    <div>
      {props.isShown ? (
        <div style={{ position: "absolute" }}>
          <div className="miss-click-handler" onClick={missClickCalendar}></div>
          <DayPicker
            navbarElement={({
              className,
              onNextClick,
              onPreviousClick,
              month,
            }) => (
              <NavBar
                className={className}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                isDisableBeforeToday={false}
                month={month}
              />
            )}
            showOutsideDays
            className="style"
            modifiers={modifier}
            modifiersStyles={modifiersStyles}
            onDayClick={handleClick}
            captionElement={DatePickerCaption}
            localeUtils={MomentLocaleUtils}
            locale={language}
            renderDay={renderDay}
            initialMonth={props.disableBeforeDate}
            disabledDays={disabledDays}
          />
        </div>
      ) : null}
    </div>
  );
};

export default NutritionPickerCalendar;
