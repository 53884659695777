export abstract class BaseMapper<E, R> {
  abstract responseToEntity(response: R): E;

  responsesToEntitys(responses: R[]): E[] {
    let entitys: E[] = [];
    for (let index = 0; index < responses.length; index++) {
      entitys.push(this.responseToEntity(responses[index]));
    }
    return entitys;
  }
}
