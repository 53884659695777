import React from "react";
//component
import ButtonsControl from "app/component/buttons-control/ButtonsControl";

interface ButtonsControlProps {
  id: number;
  isPostingsMode: boolean;
  setPostingsMode: () => void;
  showRevokeButtonOnly?: boolean;
}

const ZNRButtonsControl: React.FC<ButtonsControlProps> = (props) => {
  return (
    <ButtonsControl
      showRevokeButtonOnly={props.showRevokeButtonOnly}
      id={props.id}
      isPostingsMode={props.isPostingsMode}
      setPostingsMode={props.setPostingsMode}
      isAdvance={false}
    />
  );
};

export default ZNRButtonsControl;
