import { Reducer } from "redux";
import { LogoActions, LOGO } from "./actionTypes";

export interface LogoState {
  logoMode: number;
}

export const initialState: LogoState = {
  logoMode: 0,
};

export const reducer: Reducer<LogoState> = (
  state: LogoState = initialState,
  action,
) => {
  switch ((action as LogoActions).type) {
    case LOGO.SET_NEXT:
      return state.logoMode === 5
        ? { ...state, logoMode: 0 }
        : { ...state, logoMode: state.logoMode + 1 };
    case LOGO.SET_CURRENT:
      return { ...state, logoMode: action.payload.mode };
    default:
      return state;
  }
};

export default reducer;
