import { ActionCreator } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  SetScannedChequeInfo,
  CHEQUE,
  ScannedChequeInfoActions,
  CheckIsChequeNotionEnabled,
  isLoaderEnabled,
  isSideModalEnabled,
  attachmentId,
} from "./actionsTypes";
import { ApplicationState } from "store/reducers";
import { getCheckInfo } from "services/ApiService";
import { NoticeItem } from "app/component/notice/NoticePanel2";
import { ScannedChequeInfoItemType } from "../../infrastructure/interfaces";
import i18n from "services/TranslateService";

export const setScannedChequeInfo: ActionCreator<SetScannedChequeInfo> = (
  ScannedChequeInfo,
) => ({
  type: CHEQUE.GET_DETAIL,
  payload: ScannedChequeInfo,
});

export const getScannedChequeInfo = (
  chequeId: number,
  t: any,
  currentExpensesId: number[],
): ThunkAction<
  Promise<void>,
  ApplicationState,
  any,
  ScannedChequeInfoActions
> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, ScannedChequeInfoActions>,
    getState,
  ): Promise<void> => {
    dispatch(setQRLoader(true));
    try {
      let result = await getCheckInfo(chequeId);
      if (result.headers.success) {
        dispatch(setQRLoader(false));
        const hasExpenseId = result.data.qrCheck.qrCheckItems.some(
          (item: ScannedChequeInfoItemType) =>
            item.expenseId !== null &&
            currentExpensesId.includes(item.expenseId),
        );

        if (result.data.qrCheck && hasExpenseId) {
          //Если заполнено хотя бы одно поле ExpenseId (в чеке), то чек привязан к расходу из АО, при этом расход АО не удален => модалка с предупреждением
          let message: NoticeItem = {
            noticeIconID: "alertcircle",
            color: "#FAB005",
            noticeTitle: t("QR.modal.attention_recorded_over_title"),
            actionButton: {
              text: t("QR.modal.attention_recorded_over_button_do"),
              color: "#FAB005",
              func: () => {
                dispatch(checkIsChequeNotionEnabled(false, null));
                dispatch(setScannedChequeInfo(result.data));
                dispatch(setSideModal(true));
              },
            },
            actionButton2: {
              text: t("QR.modal.attention_recorded_over_button_donot"),
              color: "#5D6C74",
              func: () => dispatch(checkIsChequeNotionEnabled(false, null)),
            },
          };
          dispatch(checkIsChequeNotionEnabled(true, message));
        } else {
          if (result.data.qrCodeValidated && result.data.qrCodeFound) {
            // QR проверен & распознан
            if (result.data.qrCheck.operationType === 1) {
              // если тип операции "Приход"
              dispatch(setScannedChequeInfo(result.data));
              dispatch(setSideModal(true));
            } else {
              // если не приход
              let message: NoticeItem = {
                noticeIconID: "alertTriangle",
                color: "#FA5252",
                noticeTitle: t("QR.modal.error_not_income"),
                actionButton: {
                  text: t("QR.modal.error_button"),
                  color: "#FA5252",
                  func: () => dispatch(checkIsChequeNotionEnabled(false, null)),
                },
              };
              dispatch(checkIsChequeNotionEnabled(true, message));
            }
          } else if (!result.data.qrCodeFound) {
            // QR не распознан
            let message: NoticeItem = {
              noticeIconID: "alertTriangle",
              color: "#FA5252",
              noticeTitle: t("QR.modal.error_not_recognized"),
              actionButton: {
                text: t("QR.modal.error_button"),
                color: "#FA5252",
                func: () => dispatch(checkIsChequeNotionEnabled(false, null)),
              },
            };
            dispatch(checkIsChequeNotionEnabled(true, message));
          } else if (!result.data.qrCodeValidated) {
            // QR не валидирован ФНС
            let message: NoticeItem = {
              noticeIconID: "alertTriangle",
              color: "#FA5252",
              noticeTitle: t("QR.modal.error_not_available"),
              actionButton: {
                text: t("QR.modal.error_button"),
                color: "#FA5252",
                func: () => dispatch(checkIsChequeNotionEnabled(false, null)),
              },
            };
            dispatch(checkIsChequeNotionEnabled(true, message));
          }
        }
      } else {
        dispatch(setQRLoader(false));
        // QR не распознан
        let message: NoticeItem = {
          noticeIconID: "alertTriangle",
          color: "#FA5252",
          noticeTitle:
            result.data.Messages && result.data.Messages[0]
              ? i18n.language === "ru"
                ? result.data.Messages[0].Ru
                : result.data.Messages[0].En
              : t("QR.modal.error_not_recognized"),
          actionButton: {
            text: t("QR.modal.error_button"),
            color: "#FA5252",
            func: () => dispatch(checkIsChequeNotionEnabled(false, null)),
          },
        };
        dispatch(checkIsChequeNotionEnabled(true, message));
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };
};

export const checkIsChequeNotionEnabled: ActionCreator<
  CheckIsChequeNotionEnabled
> = (isEnabled, notice) => ({
  type: CHEQUE.NOTION,
  payload: { isEnabled, notice },
});

export const setQRLoader: ActionCreator<isLoaderEnabled> = (isEnabled) => ({
  type: CHEQUE.LOADER,
  payload: isEnabled,
});

export const setSideModal: ActionCreator<isSideModalEnabled> = (isEnabled) => ({
  type: CHEQUE.SIDE_MODAL,
  payload: isEnabled,
});

export const setAttacmentId: ActionCreator<attachmentId> = (id) => ({
  type: CHEQUE.ATTACHMENT_ID,
  payload: id,
});
