import React, { useState } from "react";
import styled from "styled-components";
import { SvgIcon } from "ui/SvgIcon";

interface DropDownProps {
  lable: string;
  itemsPerPage: number;
  setItemsPerPage: (a: number) => void;
  setCurrentPage: (a: number) => void;
}

const DropDownContainer = styled.div`
  position: relative;
`;

const SelectedOption = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #2e363a;
  padding: 2px 8px;
  cursor: pointer;
`;

const DropDownOptions = styled.div`
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  display: flex;
  width: 73px;
  height: 196px;
  background: #ffffff;
  border: 1px solid #e9eff2;
  box-shadow:
    0px 4px 6px -2px rgba(41, 41, 64, 0.04),
    0px 10px 15px -3px rgba(41, 41, 64, 0.08);
  border-radius: 8px;
`;

const Option = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  &:hover {
    background: #f8f8f7;
  }
`;

const DropDown: React.FC<DropDownProps> = (props) => {
  const [isOpenDrop, setOpenDrop] = useState(false);
  const handleDrop = () => {
    setOpenDrop(!isOpenDrop);
  };

  const handleChoose = (el: number) => {
    props.setItemsPerPage(el);
    setOpenDrop(!isOpenDrop);
    props.setCurrentPage(1);
  };

  const options = [10, 20, 30, 40, 50];

  return (
    <DropDownContainer>
      <SelectedOption onClick={() => handleDrop()}>
        {props.lable}
        <SvgIcon id={"vectorDown"} color={"#0A0A0A"} />
      </SelectedOption>
      {isOpenDrop && (
        <DropDownOptions>
          {options.map((el, i) => (
            <Option onClick={() => handleChoose(el)} key={i}>
              {el}
            </Option>
          ))}
        </DropDownOptions>
      )}
    </DropDownContainer>
  );
};

export default DropDown;
