import React from "react";
import JournalDetailFilter from "app/component/journal-page/JournalDetailFilter";
import { RouteComponentProps } from "react-router";

interface JournalDetailReportFilter extends RouteComponentProps<any> {}

const JournalDetailReportFilter: React.FC<JournalDetailReportFilter> = (
  props,
) => {
  return <JournalDetailFilter id={props.match.params.id} isAdvance={true} />;
};

export default JournalDetailReportFilter;
