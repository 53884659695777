import React, { useState, useRef, Fragment, useEffect } from "react";
import { get } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EmployeeAutocomplete, {
  EmployeeAutocompleteOptionType,
} from "../autocomplete/EmployeeAutocomplete";
import DatePickerCalendar from "../date-picker/DatePickerCalendar";
import ModalContainer from "./ModalContainer";
import { formatter, nameFormatter, NameFormats } from "app/utils";
import SvgIcon from "../svg-icon/SvgIcon";
import Validator from "../validator/Validator";
import { loaderLock, loaderUnlock } from "store/common/actions";
import {
  updateSubstitutionList,
  updateSubstitutionListBySearchedEmployee,
  updateSubstitutionListWithAllDeputies,
} from "store/substitution/actions";
import { showErrors } from "store/exception/actions";
import Select, { SelectOptionType } from "../select/Select";
import Config, { ConfigTypes } from "services/ConfigService";
import { getCommonUserDetail } from "../../../store/selectors";
import {
  getEmployeeDeputiesById,
  getOccupationsShortByEmployeeId,
  postEmployeeDeputiesById,
  updateEmployeeDeputiesById,
} from "../../../services/ApiService";

import { ApplicationState } from "store/reducers";
import { MENU_FIELD_PERMISSIONS } from "infrastructure/enum/field-permissions.enum";

export interface OptionProp {
  id: number;
  name: {
    en: string;
    ru: string;
  };
  label?: string;
}

interface CreateSubstitutionProps {
  editId?: number;
  isOpen: boolean;
  onClose: () => void;
  employeeSearch: number;
}

const CreateSubstitution: React.FC<CreateSubstitutionProps> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let userDetail = useSelector(getCommonUserDetail);
  const substitutionManager = useSelector((state: ApplicationState) =>
    state.common.menuFieldPermissions.includes(
      MENU_FIELD_PERMISSIONS.SUBSTITUTION_MANAGER,
    ),
  );
  const privateSubstitutionPage = document.location
    .toString()
    .includes("/User/Substitution");

  let [start, setStart] = useState(null as any);
  let [end, setEnd] = useState(null as any);
  let [firstEmployee, setFirstEmployee] = useState(
    {} as EmployeeAutocompleteOptionType,
  );
  let [firstEmployeeOccupation, setFirstEmployeeOccupation] = useState(
    {} as SelectOptionType,
  );
  let [firstEmployeeOccupations, setFirstEmployeeOccupations] = useState(
    [] as SelectOptionType[],
  );
  let [secondEmployee, setSecondEmployee] = useState(
    {} as EmployeeAutocompleteOptionType,
  );
  let [secondEmployeeOccupation, setSecondEmployeeOccupation] = useState(
    {} as SelectOptionType,
  );
  let [secondEmployeeOccupations, setSecondEmployeeOccupations] = useState(
    [] as SelectOptionType[],
  );
  let [companyId, setCompanyId] = useState(0);
  let [showDateRange, setShowDateRange] = useState(false);
  let [isEdit, setIsEdit] = useState(false);
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);
  let [isFirstEmployeeOccupations, setIsFirstEmployeeOccupations] =
    useState(false);

  let firstEmployeeValidatorRef = useRef({} as any);
  let firstEmployeeOccupationValidatorRef = useRef({} as any);
  let secondEmployeeValidatorRef = useRef({} as any);
  let rangeDateValidatorRef = useRef({} as any);
  let firstEmployeeRef = useRef({} as any);
  let secondeEmployeeRef = useRef({} as any);

  useEffect(() => {
    if (props.isOpen) {
      if (props.editId) {
        editValues();
      } else {
        clearValues();
      }
    }
  }, [props.isOpen]);

  useEffect(() => {
    firstEmployeeOccupations.length >= 2 && setIsFirstEmployeeOccupations(true);
  }, [firstEmployeeOccupations, secondEmployeeOccupations]);

  const clearValues = () => {
    setStart(null);
    setEnd(null);
    setSecondEmployee({} as EmployeeAutocompleteOptionType);
    setSecondEmployeeOccupation({ text: "---" } as any);
    setSecondEmployeeOccupations([]);

    let occupationArray = userDetail.occupations
      .filter(
        (occupation) =>
          Config.getConfigToCompany(
            ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE,
            occupation.company.id,
          ) == 1,
      )
      .map((occupation) => {
        return {
          text: `${occupation.company.name[i18n.language as "ru"]} - ${occupation.name[i18n.language as "ru"]}`,
          value: occupation.occupationId,
          companyId: occupation.company.id,
        };
      });
    if (privateSubstitutionPage) {
      setFirstEmployee({
        text: nameFormatter(userDetail.name, NameFormats.FULL, i18n.language),
        value: userDetail.occupations[0].occupationId,
      } as EmployeeAutocompleteOptionType);
    } else {
      setFirstEmployee({} as EmployeeAutocompleteOptionType);
    }
    setFirstEmployeeOccupation(occupationArray[0]);
    setFirstEmployeeOccupations(occupationArray);
    setCompanyId(occupationArray[0].companyId);
  };

  const editValues = async () => {
    dispatch(loaderLock());
    setIsEdit(true);

    let response = await getEmployeeDeputiesById(props.editId);
    if (response.headers.success) {
      let result = response.data;
      setFirstEmployee({
        text: result.employeeOccupation.employee.name[i18n.language as "ru"],
        value: result.employeeOccupation.id,
      });
      setFirstEmployeeOccupation({
        text: `${result.employeeOccupation.company.name[i18n.language as "ru"]} - ${result.employeeOccupation.name[i18n.language as "ru"]}`,
        value: result.employeeOccupation.id,
        companyId: result.employeeOccupation.company.id,
      } as any);

      let occupationArray = userDetail.occupations
        .filter(
          (occupation) =>
            Config.getConfigToCompany(
              ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE,
              occupation.company.id,
            ) == 1,
        )
        .map((occupation) => {
          return {
            text: `${occupation.company.name[i18n.language as "ru"]} - ${occupation.name[i18n.language as "ru"]}`,
            value: occupation.occupationId,
            companyId: occupation.company.id,
          };
        });
      setFirstEmployeeOccupations(occupationArray);
      setCompanyId(result.employeeOccupation.company.id);

      let secondResult = await getOccupationsShortByEmployeeId(
        result.deputyOccupation.employee.id,
        result.employeeOccupation.company.id,
      );

      if (secondResult.headers.success) {
        let newOccupations = secondResult.data.map((item: any) => {
          return {
            text: `${get(item, `company.name.${i18n.language}`, "")} - ${get(item, `name.${i18n.language}`, "")}`,
            value: get(item, "id", 0),
            detail: {
              companyId: get(item, "company.id", 0),
            },
          };
        });

        setSecondEmployee({
          text: result.deputyOccupation.employee.name[i18n.language as "ru"],
          value: result.deputyOccupation.id,
        });
        setSecondEmployeeOccupation({
          text: `${result.deputyOccupation.company.name[i18n.language as "ru"]} - ${result.deputyOccupation.name[i18n.language as "ru"]}`,
          value: result.deputyOccupation.id,
          companyId: result.deputyOccupation.company.id,
        } as any);
        setSecondEmployeeOccupations(newOccupations);

        setStart(result.startOn);
        setEnd(result.endOn);
      }
    } else {
      dispatch(
        showErrors({
          code: "update_agreement_list",
          message: `Не удалось загрузить замещение`,
        }),
      );
    }

    dispatch(loaderUnlock());
  };

  const validate = () => {
    let isValid = true;
    let validateList = [
      firstEmployeeValidatorRef.current.validate(),
      secondEmployeeValidatorRef.current.validate(),
      rangeDateValidatorRef.current.validate(),
    ];
    validateList.forEach((item) => {
      if (!item) {
        isValid = false;
      }
    });
    return isValid;
  };

  const completeHandler = () => {
    if (props.editId) {
      editHandler();
    } else {
      saveHandler();
    }
  };

  const saveHandler = async () => {
    if (validate()) {
      let data = {
        employeeOccupationId: firstEmployeeOccupation.value,
        deputyOccupationId: secondEmployeeOccupation.value,
        startOn: start,
        endOn: end,
        companyId: companyId,
      };
      saveSubmit(data);
    }
    setIsEdit(false);
  };

  const onClose = () => {
    props.onClose();
    setIsEdit(false);
  };

  const getListOfSubstitutions = () => {
    if (privateSubstitutionPage) {
      dispatch(updateSubstitutionList());
    } else if (substitutionManager && !props.employeeSearch) {
      dispatch(updateSubstitutionListWithAllDeputies());
    } else if (substitutionManager && props.employeeSearch) {
      dispatch(updateSubstitutionListBySearchedEmployee(props.employeeSearch));
    }
  };

  const saveSubmit = async (data: any) => {
    dispatch(loaderLock());
    let response = await postEmployeeDeputiesById(data, moment().utcOffset());
    if (response.headers.success) {
      getListOfSubstitutions();
      onClose();
    } else {
      if (response.status == 403) {
        dispatch(
          showErrors({
            code: "save_employee_deputies",
            message: response.data.Errors[0],
          }),
        );
      }
      if (response.status != 403) {
        dispatch(
          showErrors({
            code: "save_employee_deputies",
            message: "Не удалось создать замещение",
          }),
        );
      }
      onClose();
    }
    dispatch(loaderUnlock());
  };

  const editHandler = async () => {
    if (validate()) {
      let data = {
        employeeOccupationId: firstEmployeeOccupation.value,
        deputyOccupationId: secondEmployeeOccupation.value,
        startOn: start,
        endOn: end,
        companyId: companyId,
      };
      editSubmit(data);
    }
    setIsEdit(false);
  };

  const editSubmit = async (data: any) => {
    dispatch(loaderLock());
    let response = await updateEmployeeDeputiesById(
      props.editId,
      data,
      moment().utcOffset(),
    );
    if (response.headers.success) {
      getListOfSubstitutions();
      onClose();
    } else {
      if (response.status == 403) {
        dispatch(
          showErrors({
            code: "save_employee_deputies",
            message: response.data.Errors[0],
          }),
        );
      }
      if (response.status != 403) {
        dispatch(
          showErrors({
            code: "save_employee_deputies",
            message: "Не удалось изменить замещение",
          }),
        );
      }
      onClose();
    }
    dispatch(loaderUnlock());
  };

  const firstEmployeeChooseHandler = async (
    option: EmployeeAutocompleteOptionType,
  ) => {
    if (substitutionManager && !privateSubstitutionPage) {
      setIsFirstEmployeeOccupations(false);
      if (!option.value) {
        setFirstEmployeeOccupation({} as SelectOptionType);
        setFirstEmployeeOccupations([] as SelectOptionType[]);
        return;
      }
      let result = await getOccupationsShortByEmployeeId(
        option.value,
        option.occupations.company.id,
      );

      if (result.headers.success) {
        let newOccupations = result.data.map((item: any) => {
          return {
            text: `${get(item, `company.name.${i18n.language}`, "")} - ${get(item, `name.${i18n.language}`, "")}`,
            value: get(item, "id", 0),
            detail: {
              companyId: get(item, "company.id", 0),
            },
          };
        });

        if (newOccupations.length > 0) {
          setFirstEmployee({
            text: option.text,
            value: newOccupations[0].value,
          });
          setFirstEmployeeOccupation(newOccupations[0]);
          setFirstEmployeeOccupations(newOccupations);
        } else {
          setFirstEmployee({ text: option.text, value: option.value });
          setFirstEmployeeOccupation({} as any);
          setFirstEmployeeOccupations([]);
          dispatch(
            showErrors({
              code: "expense_applications_error",
              message: t("sub_list.errors.empty_occupation"),
            }),
          );
        }
      } else {
        dispatch(
          showErrors({
            code: "expense_applications_error",
            message: "Не удалось получить список назначений",
          }),
        );
      }
    } else {
      setFirstEmployee(option);
    }
  };

  const firstEmployeeOccupationChooseHandler = (option: any) => {
    setIsFirstEmployeeOccupations(false);
    setFirstEmployeeOccupation(option);
    setCompanyId(option.companyId);
  };

  const secondEmployeeChooseHandler = async (
    option: EmployeeAutocompleteOptionType,
  ) => {
    if (!option.value) {
      setSecondEmployeeOccupation({} as any);
      setSecondEmployeeOccupations([]);
      return;
    }
    let result = await getOccupationsShortByEmployeeId(
      option.value,
      option.occupations.company.id,
    );

    if (result.headers.success) {
      let newOccupations = result.data.map((item: any) => {
        return {
          text: `${get(item, `company.name.${i18n.language}`, "")} - ${get(item, `name.${i18n.language}`, "")}`,
          value: get(item, "id", 0),
          detail: {
            companyId: get(item, "company.id", 0),
          },
        };
      });

      if (newOccupations.length > 0) {
        setSecondEmployee({
          text: option.text,
          value: newOccupations[0].value,
        });
        setSecondEmployeeOccupation(newOccupations[0]);
        setSecondEmployeeOccupations(newOccupations);
      } else {
        setSecondEmployee({ text: option.text, value: option.value });
        setSecondEmployeeOccupation({} as any);
        setSecondEmployeeOccupations([]);
        dispatch(
          showErrors({
            code: "expense_applications_error",
            message: t("sub_list.errors.empty_occupation"),
          }),
        );
      }
    } else {
      dispatch(
        showErrors({
          code: "expense_applications_error",
          message: "Не удалось получить список назначений",
        }),
      );
    }
  };

  const openDateRangeHandler = () => {
    setShowDateRange(true);
  };

  const closeDateRangeHandler = () => {
    setShowDateRange(false);
  };

  const selectRangeDateHandler = (value: any) => {
    setStart(
      moment(value.startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    );
    setEnd(
      value.endDate
        ? moment(value.endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : moment(value.endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    );
    closeDateRangeHandler();
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return (
    <ModalContainer
      onEnter={saveHandler}
      isOpen={props.isOpen}
      highestModal={isHighestModal}
      overlayClick={onClose}
      destroy={true}
    >
      <div className="box-modal" id="add-reassignment">
        <div
          className="box-modal_close arcticmodal-close"
          onClick={onClose}
        ></div>
        <div className="box-modal-title">
          {isEdit ? t("sub_list.edit_modal") : t("sub_list.add_modal")}
        </div>
        <div className="box-modal-content">
          <div className="box-modal-form">
            <div className="box-modal-form-block">
              <div className="input-item-row">
                <Validator
                  ref={firstEmployeeValidatorRef}
                  type={"text"}
                  className={"fl-grow"}
                >
                  <Fragment>
                    <label className="input-label">
                      {t("sub_list.person_charge")}
                      <i className="input-required">*</i>
                    </label>
                    <div
                      className={`input-search ${substitutionManager && !privateSubstitutionPage ? "" : "disabled"}`}
                    >
                      <EmployeeAutocomplete
                        ref={firstEmployeeRef}
                        placeholder={t("sub_list.enter_name")}
                        onChoose={firstEmployeeChooseHandler}
                        defaultText={firstEmployee.text}
                        readOnly={
                          substitutionManager && !privateSubstitutionPage
                            ? false
                            : true
                        }
                        tabIndex={isTabIndexDisabled ? -1 : 0}
                        dontLoop={
                          substitutionManager && !privateSubstitutionPage
                            ? false
                            : true
                        }
                        listOccupation={true}
                      />
                    </div>
                  </Fragment>
                </Validator>
              </div>
              {isFirstEmployeeOccupations && privateSubstitutionPage && (
                <div className="input-item-row">
                  <Validator
                    ref={firstEmployeeOccupationValidatorRef}
                    type={"select"}
                    className={"fl-grow"}
                  >
                    <Fragment>
                      <label className="input-label">
                        {t("sub_list.person_charge_ccupation")}
                        <i className="input-required">*</i>
                      </label>
                      <Select
                        ref={firstEmployeeRef}
                        options={firstEmployeeOccupations}
                        onChoose={firstEmployeeOccupationChooseHandler}
                        defaultValue={firstEmployeeOccupation}
                      />
                    </Fragment>
                  </Validator>
                </div>
              )}
              <div className="input-item-row">
                <Validator
                  ref={secondEmployeeValidatorRef}
                  type={"text"}
                  className={"fl-grow"}
                >
                  <Fragment>
                    <label className="input-label">
                      {t("sub_list.deputy")}
                      <i className="input-required">*</i>
                    </label>
                    <div className="input-search">
                      <EmployeeAutocomplete
                        ref={secondeEmployeeRef}
                        placeholder={t("sub_list.enter_name")}
                        onChoose={secondEmployeeChooseHandler}
                        defaultText={secondEmployee.text}
                        tabIndex={isTabIndexDisabled ? -1 : 0}
                        listOccupation={true}
                      />
                    </div>
                  </Fragment>
                </Validator>
              </div>
            </div>
            <div className="box-modal-form-block">
              <div className="input-item-row">
                <Validator ref={rangeDateValidatorRef} type={"text"}>
                  <Fragment>
                    <label className="input-label">
                      {"Период замещения"}
                      <i className="input-required">*</i>
                    </label>
                    <div className="input-date pointer">
                      <input
                        className="input pointer"
                        type="text"
                        onClick={openDateRangeHandler}
                        value={
                          start && end
                            ? formatter("D MMMM YYYY", i18n.language, start) +
                              " - " +
                              formatter("D MMMM YYYY", i18n.language, end)
                            : ""
                        }
                        id="datepicker_8"
                        readOnly
                        tabIndex={isTabIndexDisabled ? -1 : 0}
                      />
                      <SvgIcon
                        className={"icon icon-calendar pointer"}
                        href={"#svg_icon_calendar"}
                      />
                    </div>
                    <DatePickerCalendar
                      onClose={closeDateRangeHandler}
                      isShown={showDateRange}
                      isCleansed={false}
                      isMultiChoice={true}
                      onSelected={selectRangeDateHandler}
                      isDisableBeforeToday={true}
                    />
                  </Fragment>
                </Validator>
              </div>
            </div>
          </div>
        </div>
        <div className="box-modal-footer">
          <a
            className="arcticmodal-close btn btn_black"
            onClick={onClose}
            tabIndex={isTabIndexDisabled ? -1 : 0}
          >
            {t("sub_list.cancel")}
          </a>
          <a
            className="btn-expense btn_green"
            onClick={completeHandler}
            tabIndex={isTabIndexDisabled ? -1 : 0}
          >
            {t("sub_list.complete")}
          </a>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CreateSubstitution;
