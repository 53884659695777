export enum JOURNAL_FIELD_PERMISSIONS {
  JOURNAL_ARCHIVE = "JournalArchive",
  EXPENSE_APPLICATION = "ExpenseApplication",
  ADVANCE_REPORT_APPLICATION = "AdvanceReportApplication",
  DOWNLOADS = "Downloads",
  CREATE_DATE = "CreateDate",
  LOAD_DATE = "LoadDate",
  JOURNEY_NUM = "JourneyNum",
  JOURNEY_DATE = "JourneyDate",
  EMPLOYEE_ID = "EmployeeId",
  CITY_ID = "CityId",
  DOWNLOAD_BATCHES = "DownloadBatches",
}

export enum MENU_FIELD_PERMISSIONS {
  EXPENSE_APPLICATION = "ExpenseApplication",
  ADVANCE_REPORT_APPLICATION = "AdvanceReportApplication",
  AGREEMENT = "Agreement",
  JOURNAL = "Journal",
  REPORTS = "Reports",
  SUBSTITUTION_MANAGER = "SubstitutionManager",
  POWER_OF_ATTORNEY_MANAGER = "PowerOfAttorneyManager",
}

export enum FILTER_FIELD_PERMISSIONS {
  ADVANCE_REPORT_APPLICATION_ID = "AdvanceReportApplicationId",
  CREATING_APPLICATION = "Creating_Application",
  IS_PERSONAL = "IsPersonal",
  STATUS = "Status",
  EXPENSE_APPLICATION_ID = "ExpenseApplicationId",
  JOURNEY = "Journey",
  ORG_UNIT = "OrgUnit",
  LOCATION = "Location",
  COMPANY = "Company",
  LEGAL_ENTITY = "LegalEntity",
  CITY = "City",
  EMPLOYEE = "Employee",
  DATE_OF_BUSINESSTRIP = "Date_Of_Businesstrip",
}

export enum PROFILE_FIELD_PERMISSIONS {
  PROFILE = "Profile",
  SUBSTITUTION = "Substitution",
  ASSISTANCE = "Assistance",
  SETTLEMENT = "Settlement",
  FILTER_EMPLOYEE = "FilterEmployee",
  FILTER_DATE = "FilterDate",
}

export enum TRANSACTIONS_READ_FIELD_PERMISSIONS {
  EMPLOYEE = "Employee",
  DOC_NUMBER = "DocNumber",
  EXPENSE_ITEM = "ExpenseItem",
  AMOUNT_RUB = "AmountRub",
  LEDGER_OR_VENDOR = "LedgerOrVendor",
  POSTING_KEY = "PostingKey",
  TAX_CODE = "TaxCode",
  TRANS_UPDATE = "TransUpdate",
  TRANS_CREATE = "TransCreate",
  TRANS_CANCEL = "TransCancel",
  TRANS_DOWNLOAD = "TransDownload",
}

export enum TRANSACTIONS_UPDATE_FIELD_PERMISSIONS {
  EMPLOYEE = "Trans_Employee",
  DOC_DATE = "Trans_DocDate",
  DOC_NUMBER = "Trans_DocNumber",
  POSTING_DATE = "Trans_PostingDate",
  BUSINESS_TARGET = "Trans_BusinessTarget",
  EXPENSE_ITEM = "Trans_ExpenseItem",
  CURRENCY = "Trans_Currency",
  AMOUNT = "Trans_Amount",
  CUR_RATE = "Trans_CurRate",
  AMOUNT_RUB = "Trans_AmountRub",
  LEDGER_OR_VENDOR = "Trans_LedgerOrVendor",
  POSTING_KEY = "Trans_PostingKey",
  TAX_CODE = "Trans_TaxCode",
  EXPENSE = "Trans_Expense",
}

export enum TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS {
  EMPLOYEE = "Employee",
  DOC_NUMBER = "DocNumber",
  EXPENSE_ITEM = "ExpenseItem",
  AMOUNT_RUB = "AmountRub",
  LEDGER_OR_VENDOR = "LedgerOrVendor",
  POSTING_KEY = "PostingKey",
  TAX_CODE = "TaxCode",
  TRANS_DOWNLOAD = "TransDownload",
  TRANS_RETURN_FROM_ARCHIVE = "TransReturnFromArchive",
  POST_KEY = "PostKey",
  LAGER_ACCOUNT = "LagerAccount",
}
