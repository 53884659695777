import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "ui/Box";
import { Button } from "../../../ui/Button";
import CertificatesModal from "./CertificatesModal";
import { getCurrencieCode, toLocaleNumberFormatter } from "app/utils";
import {
  Text,
  TextBold,
  Cost,
  StyledDocument,
  CostWarp,
  ButtonWrapper,
} from "./StyledSignDocuments";
import { DocumentProps } from "./types";

import { setCertificateId } from "store/common/actions";

import {
  getApiRootCertificates,
  getClientCaCertificates,
  getClientRootCertificates,
} from "store/selectors";
import {
  setListOfApiRootCertificates,
  setListOfClientCertificates,
} from "store/certificates/actions";

const Document: React.FC<DocumentProps> = (props) => {
  const {
    thumbprint,
    documentInfo,
    setAllDoc,
    allDoc,
    userDocuments,
    signAllDocuments,
    documentDescription,
    approvedAmount,
    color,
    signed,
    id,
    certificates,
    isOpen,
    onClick,
    onClose,
    selectDocument,
    setOpenSignsModal,
    signature,
    signOneDocument,
    setNoticeType,
    setOpenNotice,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const apiRootCertificates = useSelector(getApiRootCertificates);
  const clientRootCertificates = useSelector(getClientRootCertificates);
  const clientCaCertificates = useSelector(getClientCaCertificates);

  const onClickDocument = () => {
    if (signature) {
      dispatch(setListOfApiRootCertificates());
      dispatch(setListOfClientCertificates("ROOT"));
      dispatch(setListOfClientCertificates("CA"));
      dispatch(setListOfClientCertificates("MY", setNoticeType, setOpenNotice));
    }
    if (allDoc) {
      setAllDoc(false);
    }
    dispatch(setCertificateId(id));
    if (!thumbprint) {
      selectDocument(id);
    }
    if (
      signature &&
      apiRootCertificates.length &&
      clientRootCertificates.length &&
      clientCaCertificates.length
    ) {
      setOpenSignsModal(true);
    }
  };

  return (
    <>
      <Box color={color}>
        <StyledDocument>
          <div>
            <Text>{documentInfo}</Text>
            <TextBold>{documentDescription}</TextBold>
          </div>
          <CostWarp>
            <Cost>
              {toLocaleNumberFormatter(approvedAmount)}{" "}
              {getCurrencieCode("rub")}
            </Cost>
            <ButtonWrapper>
              {signed ? (
                <Button buttonColor="green" iconId="checkcircle" />
              ) : (
                <>
                  <Button
                    buttonText={t(
                      "request_detail.request_button_controls.sign"
                    )}
                    buttonColor="gray"
                    iconId="fingerprint"
                    onClick={onClickDocument}
                  />
                  {!!signature && certificates.length ? (
                    <CertificatesModal
                      isOpen={isOpen}
                      certificates={certificates}
                      onClick={onClick}
                      onClose={onClose}
                      setOpenSignsModal={setOpenSignsModal}
                      allDoc={allDoc}
                      signAllDocuments={signAllDocuments}
                      signOneDocument={signOneDocument}
                      userDocuments={userDocuments}
                      setAllDoc={setAllDoc}
                    />
                  ) : null}
                </>
              )}
            </ButtonWrapper>
          </CostWarp>
        </StyledDocument>
      </Box>
    </>
  );
};

export default Document;
