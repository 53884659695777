import React from "react";
import Postings from "app/component/postings/Postings";
import { RouteComponentProps, withRouter } from "react-router";

interface JournalDetailReportProps extends RouteComponentProps<any> {}

const JournalDetailReport: React.FC<JournalDetailReportProps> = (props) => {
  const id = props.match.params.id;

  return <Postings id={id} isAdvance={true} isJournal={true} />;
};

export default withRouter(JournalDetailReport);
