import styled from "styled-components";

const StyledRadio = styled.div``;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #c2c2c2;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  &:hover ~ ${RadioButtonLabel} {
    background: #ffffff;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 4px;
      background: #ea780e;
    }
  }
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: #FFFFFF;
      border: 1px solid #C2C2C2;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin: 4px;
        background: #005029;
      }
    }
  `}
`;

export { StyledRadio, Item, RadioButtonLabel, RadioButton };
