import { Action } from "redux";
import { JournalItem } from "infrastructure/interfaces";

export const JOURNAL = {
  SET_JOURNAL_LIST: "JOURNAL/SET_JOURNAL_LIST",
  SET_JOURNAL_FILTER: "JOURNAL/SET_JOURNAL_FILTER",
  SET_JOURNAL_ALL_PAGE: "JOURNAL/SET_JOURNAL_ALL_PAGE",
  SET_JOURNAL_PAGE: "JOURNAL/SET_JOURNAL_PAGE",
  SET_JOURNAL_NEXT_PAGE: "JOURNAL/SET_JOURNAL_NEXT_PAGE",
  SET_JOURNAL_IS_ARCHIVE: "SET_JOURNAL_IS_ARCHIVE",
  SET_JOURNAL_FIELD_PERMISSIONS: "SET_JOURNAL_FIELD_PERMISSIONS",
};

export const REPORTS = {
  SET_REPORTS_REGISTRIES_PARAMETERS: "REPORTS/SET_REGISTRIES_PARAMETERS",
};

export interface SetRegistriesDownloadParameters extends Action {
  type: typeof REPORTS.SET_REPORTS_REGISTRIES_PARAMETERS;
  payload: { filter: any };
}

export interface SetJournalList extends Action {
  type: typeof JOURNAL.SET_JOURNAL_LIST;
  payload: { journalList: JournalItem[] };
}

export interface SetJournalFieldPermissions extends Action {
  type: typeof JOURNAL.SET_JOURNAL_FIELD_PERMISSIONS;
  payload: { fieldPermissions: string[] };
}

export interface SetJournalFilter extends Action {
  type: typeof JOURNAL.SET_JOURNAL_FILTER;
  payload: { filter: any };
}

export interface SetJournalAllPage extends Action {
  type: typeof JOURNAL.SET_JOURNAL_ALL_PAGE;
  payload: { pageAll: number };
}

export interface SetJournalPage extends Action {
  type: typeof JOURNAL.SET_JOURNAL_PAGE;
  payload: { page: number };
}

export interface SetJournalIsArchive extends Action {
  type: typeof JOURNAL.SET_JOURNAL_IS_ARCHIVE;
  payload: { isArchive: boolean };
}

export interface SetJournalNextPage extends Action {
  type: typeof JOURNAL.SET_JOURNAL_NEXT_PAGE;
}

export type JournalActions =
  | SetJournalList
  | SetJournalFilter
  | SetJournalAllPage
  | SetJournalPage
  | SetJournalNextPage
  | SetRegistriesDownloadParameters
  | SetJournalFieldPermissions;
