import React, { Fragment } from "react";
import SvgIcon from "../svg-icon/SvgIcon";
import { useSelector, useDispatch } from "react-redux";
import { setNoticePanelOpen } from "store/common/actions";
import { getCommonNoticePanelNewMessagesCount } from "../../../store/selectors";

const NoticePanel: React.SFC = () => {
  const newNoticesCount = useSelector(getCommonNoticePanelNewMessagesCount);
  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(setNoticePanelOpen(true));
  };

  return (
    <Fragment>
      <a className="header-controls__item pointer" onClick={clickHandler}>
        <span className="header-controls__item js-notice-dropdown">
          <SvgIcon className="icon icon-notice" href="#svg_icon_notice" />
          {newNoticesCount > 0 ? (
            newNoticesCount > 99 ? (
              <div className="notice-counter pointer">99+</div>
            ) : (
              <div className="notice-counter pointer">{newNoticesCount}</div>
            )
          ) : null}
        </span>
      </a>
    </Fragment>
  );
};

export default NoticePanel;
