import { STATUS_TYPE_EXPENSE } from "infrastructure/enum/status-expense.enum";
import { showInfo } from "store/exception/actions";

const showExpenseChangePopup = (
  showAlert: any,
  expense: any,
  dispatch: any,
  t: any,
) => {
  //@ts-ignore
  if (showAlert.current) {
    if (
      [
        STATUS_TYPE_EXPENSE.ON_APPROVAL,
        STATUS_TYPE_EXPENSE.APPROVED,
        STATUS_TYPE_EXPENSE.POSTED,
        STATUS_TYPE_EXPENSE.ON_REVISION,
      ].includes(expense)
    ) {
      dispatch(
        showInfo({
          code: "create_advance_report",
          //@ts-ignore
          title: t("modals.when_changing_the_advance_header"),
          message: t("modals.when_changing_the_advance_body"),
        }),
      );
    }
  }
};

export default showExpenseChangePopup;
