import React from "react";
import { IconProps } from "../types";

const WarningTriangle: React.FC<IconProps> = (props) => {
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height="19"
      viewBox="0 0 22 19"
      fill="none"
    >
      <path
        d="M21.1949 15.6221L12.9479 1.37207C12.75 1.0308 12.466 0.747514 12.1242 0.550585C11.7824 0.353656 11.3948 0.25 11.0003 0.25C10.6059 0.25 10.2183 0.353656 9.87653 0.550585C9.53473 0.747514 9.25068 1.0308 9.05284 1.37207V1.3728L0.805767 15.6221C0.607762 15.964 0.503313 16.3521 0.502931 16.7473C0.502548 17.1424 0.606245 17.5307 0.803588 17.873C1.00093 18.2154 1.28496 18.4997 1.62709 18.6974C1.96922 18.8951 2.35739 18.9992 2.75254 18.9993H19.2481C19.6433 18.9992 20.0315 18.8951 20.3736 18.6974C20.7157 18.4997 20.9998 18.2154 21.1971 17.873C21.3944 17.5307 21.4981 17.1424 21.4978 16.7473C21.4974 16.3521 21.3929 15.964 21.1949 15.6221ZM10.2496 7.75C10.2496 7.55108 10.3286 7.36032 10.4693 7.21967C10.6099 7.07902 10.8007 7 10.9996 7C11.1985 7 11.3893 7.07902 11.5299 7.21967C11.6706 7.36032 11.7496 7.55108 11.7496 7.75V11.5C11.7496 11.6989 11.6706 11.8897 11.5299 12.0303C11.3893 12.171 11.1985 12.25 10.9996 12.25C10.8007 12.25 10.6099 12.171 10.4693 12.0303C10.3286 11.8897 10.2496 11.6989 10.2496 11.5V7.75ZM11.0002 16.0003C10.7777 16.0003 10.5602 15.9343 10.3751 15.8107C10.1901 15.6871 10.0459 15.5114 9.9608 15.3058C9.87565 15.1002 9.85337 14.874 9.89678 14.6558C9.94019 14.4376 10.0473 14.2371 10.2047 14.0798C10.362 13.9224 10.5625 13.8153 10.7807 13.7719C10.9989 13.7285 11.2251 13.7508 11.4307 13.8359C11.6362 13.9211 11.8119 14.0652 11.9356 14.2503C12.0592 14.4353 12.1252 14.6528 12.1252 14.8753C12.1251 15.1736 12.0066 15.4598 11.7956 15.6707C11.5847 15.8817 11.2985 16.0003 11.0002 16.0003Z"
        fill={color}
      />
    </svg>
  );
};

export default WarningTriangle;
