/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewTopModal, delTopModal } from "store/common/actions";
import { useResize } from "app/hooks";
import {
  getCommonModalIdCounter,
  getCommonOpenModal,
} from "../../store/selectors";

interface ModalContainerProps {
  isOpen: boolean | undefined;
  destroy?: boolean;
  overlayClick?: () => void;
  onEnter?: () => void;
  highestModal?: (isHighest: boolean) => void;
  id?: number;
  style?: CSSProperties;
  styleForDialog?: boolean;
}

const ModalContainer: React.FC<ModalContainerProps> = (props) => {
  const dispatch = useDispatch();
  const modalIdCounter = useSelector(getCommonModalIdCounter);
  const openModal = useSelector(getCommonOpenModal);

  let modalRef = useRef({} as any);

  let [modalId, setModalId] = useState(props.id ? props.id : 0);
  let [withScroll, setWithScroll] = useState(false);
  let [showOverlay, setShowOverlay] = useState(false);

  const handleOverlayClick = () => {
    props.overlayClick && props.overlayClick();
  };

  const escFunction = useCallback((event: any) => {
    if (event.keyCode === 27) {
      props.overlayClick && props.overlayClick();
    }
  }, []);

  useEffect(() => {
    if (props.isOpen && modalId === 0) {
      setModalId(modalIdCounter);
      dispatch(addNewTopModal(modalIdCounter));
      window.addEventListener("keydown", escFunction, false);
    }
    if (props.isOpen && modalId !== 0) {
      dispatch(addNewTopModal(modalId));
      window.addEventListener("keydown", escFunction, false);
    }
    if (!props.isOpen) {
      dispatch(delTopModal(modalId));
      window.removeEventListener("keydown", escFunction, false);
    }
  }, [props.isOpen]);

  const handleWithScroll = () => {
    const offsetWidth = modalRef.current ? modalRef.current.offsetWidth : 0;
    const clientWidth = modalRef.current ? modalRef.current.clientWidth : 0;
    setWithScroll(Math.abs(offsetWidth - clientWidth) > 5);
  };

  useResize(() => {
    !!props.isOpen && handleWithScroll();
  });

  useEffect(() => {
    let isModalHighest: boolean =
      modalId === -1
        ? true
        : openModal.includes(-1)
          ? false
          : openModal[openModal.length - 1] === modalId;
    setShowOverlay(isModalHighest);
    props.highestModal && props.highestModal(isModalHighest);
  }, [openModal]);

  return props.isOpen || !props.destroy ? (
    <div style={{ display: props.isOpen ? "block" : "none" }}>
      <div className="arcticmodal-container" ref={modalRef} style={props.style}>
        <div
          className={"arcticmodal-overlay " + (showOverlay ? "active" : "")}
          style={{ width: withScroll ? "calc(100% - 17px)" : "100%" }}
        />
        <div
          className={"arcticmodal-overlay " + (showOverlay ? "active" : "")}
          style={{
            background: "none",
            width: withScroll ? "calc(100% - 17px)" : "100%",
          }}
          onClick={handleOverlayClick}
        />
        <table
          className="arcticmodal-container_i"
          style={{ width: props.styleForDialog ? "99%" : "" }}
        >
          <tbody>
            <tr>
              <td
                className="arcticmodal-container_i2"
                id="creating-application-3"
              >
                {props.children}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
};

export default ModalContainer;
