import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal, ModalText, ModalFooter } from "ui/Modal";
import { Button } from "ui/Button";
import Document from "./Document";
import { DocumentsModalProps } from "./types";
import {
  getAdvanceReportApplication,
  getApiRootCertificates,
  getClientCaCertificates,
  getClientRootCertificates,
  getCommonUserDetail,
} from "store/selectors";
import { IAO } from "infrastructure/interfaces";
import i18n from "services/TranslateService";
import { formatter } from "app/utils";
import { ADVANCE_APPLICATION_PROPETIES } from "infrastructure/enum/object-properties.enum";
import { setListOfApiRootCertificates, setListOfClientCertificates } from "store/certificates/actions";

const DocumentsModal: React.FC<DocumentsModalProps> = (props) => {
  const {
    signDocumentsData,
    thumbprint,
    isOpen,
    signAllDocuments,
    onClose,
    selectDocument,
    completeSign,
    isOpenCertificate,
    certificates,
    onClick,
    onCloseCertificate,
    setOpenSignsModal,
    setAllDoc,
    allDoc,
    signOneDocument,
    setNoticeType,
    setOpenNotice,
  } = props;
  const { t } = useTranslation();

  const apiRootCertificates = useSelector(getApiRootCertificates);
  const clientRootCertificates = useSelector(getClientRootCertificates);
  const clientCaCertificates = useSelector(getClientCaCertificates);

  const [signAdvanceReportApplication, setSignAdvanceReportApplication] =
    useState<any[]>([]);
  const [
    signAdvanceReportApplicationDocuments,
    setSignAdvanceReportApplicationDocuments,
  ] = useState<any[]>([]);
  const [documentInfo, setDocumentInfo] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [userDocuments, setUserDocuments] = useState<any[]>([]);
  const [allDocumentsSign, setAllDocumentsSign] = useState<boolean>();
  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication
  );
  const user = useSelector(getCommonUserDetail);
  const dispatch = useDispatch();

  const signature = signDocumentsData.approvalTierDocuments
    ? signDocumentsData.approvalTierDocuments.find(
        (item: any) =>
          item.signatureTypes.includes("AQES") ||
          item.signatureTypes.includes("AES")
      )
    : undefined;
  useEffect(() => {
    if (userDocuments && signDocumentsData.approvalTierDocuments) {
      setAllDocumentsSign(
        userDocuments.filter(
          (doc) =>
            doc.signedAt && doc.objectLogicalName !== "AdvanceReportApplication"
        ).length ===
          signDocumentsData.approvalTierDocuments.filter(
            (doc) =>
              doc.isSignatureRequired &&
              doc.objectLogicalName !== "AdvanceReportApplication"
          ).length
          ? true
          : false
      );
    }
  }, [signDocumentsData, userDocuments, selectDocument]);

  useEffect(() => {
    if (signDocumentsData.approvalTierDocuments) {
      const AdvanceReportApplicationDocuments =
        signDocumentsData.approvalTierDocuments.filter(
          (document: {
            objectLogicalName: string;
            isSignatureRequired: boolean;
          }) =>
            document.objectLogicalName === "AdvanceReportApplication" &&
            document.isSignatureRequired === true
        );
      setSignAdvanceReportApplication(AdvanceReportApplicationDocuments);
      const AdvanceReportAdditionalDocuments =
        signDocumentsData.approvalTierDocuments.filter(
          (document: {
            objectLogicalName: string;
            isSignatureRequired: boolean;
          }) =>
            document.objectLogicalName === "AdvanceReportAdditionalDocument" &&
            document.isSignatureRequired === true
        );
      setSignAdvanceReportApplicationDocuments(
        AdvanceReportAdditionalDocuments
      );
    }
    if (signDocumentsData.employees) {
      const userDocumentsData = signDocumentsData.employees.filter(
        (employe: { id: number }) => employe.id === user.id
      );
      setUserDocuments(
        userDocumentsData ? userDocumentsData[0].approvalItemSignatures : []
      );
    }
  }, [signDocumentsData, user.id]);

  useEffect(() => {
    const applicationType = advanceReportApplication.applicationType
      ? advanceReportApplication.applicationType.code
      : "";
    if (applicationType === "General") {
      setDocumentInfo(
        `№${advanceReportApplication.id} | ${
          advanceReportApplication.businessTarget.name[
            i18n.language as "en" | "ru"
          ]
        }`
      );
    }
    if (applicationType === "Travel") {
      setDocumentInfo(
        `№${advanceReportApplication.id} |` +
          formatter(
            "D MMMM",
            i18n.language,
            advanceReportApplication.startOn,
            advanceReportApplication.endOn,
            { withYear: true, withMonth: true }
          )
      );
      setDocumentDescription(
        `${
          advanceReportApplication.departureFromCityId.name[
            i18n.language as "ru" | "en"
          ]
        } — ${
          advanceReportApplication.businessTripDestinations.length > 0
            ? advanceReportApplication.businessTripDestinations[0].city.name[
                i18n.language as "ru" | "en"
              ]
            : advanceReportApplication.arrivalToCityId.name[
                i18n.language as "ru" | "en"
              ]
        }`
      );
    }
  }, [advanceReportApplication]);

  const onClickAll = async () => {
    if (!allDoc) {
      setAllDoc(true);
    }
    if (signature) {
      dispatch(setListOfApiRootCertificates());
      dispatch(setListOfClientCertificates("ROOT"));
      dispatch(setListOfClientCertificates("CA"));
      dispatch(setListOfClientCertificates("MY", setNoticeType, setOpenNotice));
    }
    if (
      signature &&
      apiRootCertificates.length &&
      clientRootCertificates.length &&
      clientCaCertificates.length
    ) {
      setOpenSignsModal(true);
      await signAllDocuments(userDocuments);
    } else {
      await signAllDocuments(userDocuments);
    }
  };

  return (
    <Modal
      title={t("request_detail.request_button_controls.approval_and_sign")}
      isOpen={isOpen}
      onClose={onClose}
    >
      {signAdvanceReportApplication.length && advanceReportApplication ? (
        <>
          <ModalText>{t("certificate.sign_advance_report")}</ModalText>
          <Document
            color="#E8EDEE"
            signed={userDocuments.find(
              (doc) =>
                doc.objectId === advanceReportApplication.id && doc.signedAt
            )}
            thumbprint={thumbprint}
            id={advanceReportApplication.id}
            documentInfo={documentInfo}
            documentDescription={documentDescription}
            approvedAmount={advanceReportApplication.approvedAmount}
            isOpen={isOpenCertificate}
            certificates={certificates}
            selectDocument={selectDocument}
            onClick={onClick}
            onClose={onCloseCertificate}
            signature={signature}
            setOpenSignsModal={setOpenSignsModal}
            allDoc={allDoc}
            signAllDocuments={signAllDocuments}
            signOneDocument={signOneDocument}
            userDocuments={userDocuments}
            setAllDoc={setAllDoc}
            setNoticeType={setNoticeType}
            setOpenNotice={setOpenNotice}
          />
        </>
      ) : null}
      {signAdvanceReportApplicationDocuments.length &&
      advanceReportApplication.additionalDocuments ? (
        <>
          <ModalText>{t("certificate.sign_acts")}</ModalText>
          {advanceReportApplication.additionalDocuments.map((document) => {
            for (
              let i = 0;
              i < signAdvanceReportApplicationDocuments.length;
              i++
            ) {
              if (
                signAdvanceReportApplicationDocuments[i].objectId ===
                document.id
              ) {
                return (
                  <Document
                    signed={userDocuments.find(
                      (doc) => doc.objectId === document.id && doc.signedAt
                    )}
                    thumbprint={thumbprint}
                    id={document.id}
                    documentInfo={`${
                      document.documentType ===
                      ADVANCE_APPLICATION_PROPETIES.SERVICE_NOTE
                        ? t("request_detail.application_expenses.memoTitle", {
                            documentNumber: document.documentNumber,
                          })
                        : document.documentType ===
                            ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT
                          ? t(
                              "request_detail.application_expenses.businessEventTitle",
                              {
                                documentNumber: document.documentNumber,
                              }
                            )
                          : t("request_detail.application_expenses.actTitle", {
                              documentNumber: document.documentNumber,
                            })
                    }`}
                    documentDescription={` ${document.description} ${
                      document.documentType !== "ServiceNote"
                        ? moment(document.meetingDate).format("DD.MM.YYYY")
                        : moment(document.serviceNote.serviceNoteDate).format(
                            "DD.MM.YYYY"
                          )
                    }`}
                    approvedAmount={document.totalAmount}
                    isOpen={isOpenCertificate}
                    certificates={certificates}
                    selectDocument={selectDocument}
                    onClick={onClick}
                    onClose={onCloseCertificate}
                    signature={signature}
                    setOpenSignsModal={setOpenSignsModal}
                    allDoc={allDoc}
                    signAllDocuments={signAllDocuments}
                    signOneDocument={signOneDocument}
                    userDocuments={userDocuments}
                    setAllDoc={setAllDoc}
                    setNoticeType={setNoticeType}
                    setOpenNotice={setOpenNotice}
                  />
                );
              }
            }
          })}
        </>
      ) : null}

      <ModalFooter>
        {allDocumentsSign ? (
          <Button
            big
            onClick={completeSign}
            buttonText={t(
              "request_detail.request_button_controls.complete_sign"
            )}
          />
        ) : (
          <>
            <Button
              big
              onClick={onClose}
              buttonText={t("request_detail.request_button_controls.cancel")}
            />
            <Button
              big
              buttonColor="gray"
              onClick={onClickAll}
              iconId="fingerprint"
              buttonText={t(
                "request_detail.request_button_controls.sign_all_now"
              )}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default DocumentsModal;
