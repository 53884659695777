import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "app/component/svg-icon/SvgIcon";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  let [currentDate, setCurrentDate] = useState(new Date());
  const version = process.env.REACT_APP_REVISION;

  return (
    <footer className="footer">
      {process.env.REACT_APP_IS_WHITE_LABEL === "false" && (
        <div className="container">
          <div className="footer-wrap">
            <div className="footer-left"></div>
            <div className="footer-center">
              <p>
                {t("footer.aeroclub", { year: currentDate.getFullYear() })}
                <br />
                {t("footer.adress")}
                <br />
                {t("footer.phone")}
              </p>
              <p>
                {t("footer.writeTo")}{" "}
                <a href="mailto:feedback@aeroclub.ru" tabIndex={-1}>
                  feedback@aeroclub.ru.
                </a>
                <br />
                {t("footer.comment")}
              </p>
            </div>
            <div className="footer-right">
              <div className="footer-links">
                <div>
                  <a href="mailto:support@expense.aero" tabIndex={-1}>
                    support@expense.aero
                  </a>
                </div>
                <div>
                  <a
                    href="https://aeroclub.ru/confidentiality/"
                    target="_blank"
                    tabIndex={-1}
                  >
                    {t("footer.policy")}
                  </a>
                </div>
              </div>
              <div className="tx-right">
                {/* <a className="support-link" href="/">
                                {t('footer.support')}
                                <SvgIcon className="icon icon-support" href="#svg_icon_support" />
                            </a> */}
              </div>
            </div>
          </div>
        </div>
      )}
	  <div style={{opacity: 0}}>{version}</div>
    </footer>
  );
};

export default Footer;
