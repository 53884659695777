import styled from "styled-components";

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
  margin: 10px;
`;

const RadioButtonLabelText = styled.div`
  padding-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #c2c2c2;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  width: 20px !important;
  &:hover ~ ${RadioButtonLabel} {
    background: #ffffff;
    border: 1px solid #ff3b30;
  }
  &:focus ~ ${RadioButtonLabel} {
    border: 1px solid #ff3b30;
    outline: 3px solid rgba(11, 92, 215, 0.5);
  }
  &:disabled ~ ${RadioButtonLabel} {
    border: 1px solid #e0e0e0;
    background: #f5f5f5;
  }

  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: #FFFFFF;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin: 4px;
        background: #FF3B30;
      }
    }
    &:focus ~ ${RadioButtonLabel} {
      border: 1px solid #FF3B30;
      outline: 3px solid rgba(11, 92, 215, 0.5);
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin: 4px;
        background: #FF3B30;
      }
    }
    &:disabled ~ ${RadioButtonLabel} {
      border: 1px solid #E0E0E0;
      background: #F5F5F5;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin: 4px;
        background: #C2C2C2;
      }
    }
  `}
`;

export { Item, RadioButtonLabel, RadioButton, RadioButtonLabelText };
