import React from "react";
import { IconProps } from "../types";

const DocBlank: React.FC<IconProps> = (props) => {
  const { color, size } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6668 7.45002C16.6581 7.37347 16.6414 7.29805 16.6168 7.22502V7.15002C16.5768 7.06434 16.5233 6.98557 16.4585 6.91669L11.4585 1.91669C11.3896 1.85187 11.3108 1.79842 11.2252 1.75835H11.1502C11.0655 1.7098 10.972 1.67864 10.8752 1.66669H5.8335C5.17045 1.66669 4.53457 1.93008 4.06573 2.39892C3.59689 2.86776 3.3335 3.50365 3.3335 4.16669V15.8334C3.3335 16.4964 3.59689 17.1323 4.06573 17.6011C4.53457 18.07 5.17045 18.3334 5.8335 18.3334H14.1668C14.8299 18.3334 15.4658 18.07 15.9346 17.6011C16.4034 17.1323 16.6668 16.4964 16.6668 15.8334V7.50002C16.6668 7.50002 16.6668 7.50002 16.6668 7.45002ZM11.6668 4.50835L13.8252 6.66669H12.5002C12.2791 6.66669 12.0672 6.57889 11.9109 6.42261C11.7546 6.26633 11.6668 6.05437 11.6668 5.83335V4.50835ZM15.0002 15.8334C15.0002 16.0544 14.9124 16.2663 14.7561 16.4226C14.5998 16.5789 14.3878 16.6667 14.1668 16.6667H5.8335C5.61248 16.6667 5.40052 16.5789 5.24424 16.4226C5.08796 16.2663 5.00016 16.0544 5.00016 15.8334V4.16669C5.00016 3.94567 5.08796 3.73371 5.24424 3.57743C5.40052 3.42115 5.61248 3.33335 5.8335 3.33335H10.0002V5.83335C10.0002 6.4964 10.2636 7.13228 10.7324 7.60112C11.2012 8.06996 11.8371 8.33335 12.5002 8.33335H15.0002V15.8334Z"
        fill="#545B5E"
      />
    </svg>
  );
};

export default DocBlank;
