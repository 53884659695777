import React from "react";
import Certificate from "./Certificate";
import { SignWrap } from "./StyledSertificate";
import { CertificateContainerProps } from "./types";

const CertificateContainer: React.FC<CertificateContainerProps> = (props) => {
  const { certificates, onClick } = props;
  return (
    <SignWrap certificates={certificates}>
      {certificates.map((el) => (
        <Certificate onClick={onClick} key={el.thumbprint} {...el} />
      ))}
    </SignWrap>
  );
};

export default CertificateContainer;
