import React from "react";
import { getCurrencieCode } from "app/utils";
import { memo } from "react";
import { CurrencyInput } from "ui/Input";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

type Values = {
  advanceCashAmountRemainder: number;
  advanceCashAmountOverspend: number;
  advanceCashAmountReceived: number;
};

type Props = {
  editPermissions: string[];
  formik: FormikProps<Values>;
  field: keyof Values;
  className?: string;
};

const AOCashAmountInput = memo<Props>(
  ({ field, className, formik, editPermissions }) => {
    const { t } = useTranslation();
    
    return (
      <CurrencyInput
        currency={getCurrencieCode("rub")}
        className={className}
        field={field}
        label={t(`request_detail.request_cost.${field}`)}
        id={field}
        value={formik.values[field]}
        onChange={formik.handleChange}
        disabled={
          !editPermissions.includes(field[0].toUpperCase() + field.slice(1))
        }
      />
    );
  }
);

export default AOCashAmountInput;
