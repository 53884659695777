import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Authorities,
  Authority,
  Code,
  Field,
  Name,
  SubTitle,
  Value,
} from "./PowerOfAttorneyItemStyled";
import { PowersOfAttorneyListItemProps } from "./types";
import { getPowerOfAttorneyDetail } from "store/selectors";

const PowerOfAttorneyItemInfo: React.FC<
  PowersOfAttorneyListItemProps
> = ({}) => {
  const { t, i18n } = useTranslation();

  const POW = useSelector(getPowerOfAttorneyDetail);

  const passportData =
    (Object.keys(POW).length !== 0 && POW.representative.employee.documents[0])
      ? `${POW.representative.employee.documents[0].series} 
  ${POW.representative.employee.documents[0].number} 
  Выдан ${moment(POW.representative.employee.documents[0].issuedOn)
    .utc()
    .format("DD.MM.YYYY")} 
  ${POW.representative.employee.documents[0].issuedBy} 
  Код подразделения: ${POW.representative.employee.documents[0].issuedByCode} 
  Гражданство: ${
    POW.representative.employee.documents[0].citizenshipCountry.name[
      i18n.language as "en" | "ru"
    ]
  }`
      : "";

  if (Object.keys(POW).length !== 0) {
    return (
      <>
        <SubTitle>{t("power.issuing_authority")}</SubTitle>
        <Field>
          <Name>{t("power.name_of_the_organization")}</Name>
          <Value>{POW.owner.company.name[i18n.language as "en" | "ru"]}</Value>
        </Field>
        <Field>
          <Name>{t("power.registered_address")}</Name>
          <Value>{POW.owner.company.legal.address}</Value>
        </Field>
        <Field>
          <Name>{t("power.INN")}</Name>
          <Value>{POW.owner.company.legal.inn}</Value>
        </Field>
        <Field>
          <Name>{t("power.OGRN")}</Name>
          <Value>{POW.owner.company.legal.ogrn}</Value>
        </Field>
        <Field>
          <Name>{t("power.KPP")}</Name>
          <Value>{POW.owner.company.legal.kpp}</Value>
        </Field>
        <Field>
          <Name>{t("power.manager_full_name")}</Name>
          <Value>
            {
              POW.owner.company.managerEmployee.fullName[
                i18n.language as "en" | "ru"
              ]
            }
          </Value>
        </Field>
        <Field style={{ border: "none" }}>
          <Name>{t("power.manager_SNILS")}</Name>
          <Value>{POW.owner.company.managerEmployee.snils}</Value>
        </Field>

        <SubTitle>{t("power.issued_to")}</SubTitle>

        <Field>
          <Name>{t("power.employee_full_name")}</Name>
          <Value>
            {POW.representative.employee.fullName[i18n.language as "en" | "ru"]}
          </Value>
        </Field>
        <Field>
          <Name>{t("power.employee's_position")}</Name>
          <Value>
            {
              POW.representative.employee.occupation.name[
                i18n.language as "en" | "ru"
              ]
            }
          </Value>
        </Field>
        <Field>
          <Name>{t("power.INN")}</Name>
          <Value>{POW.representative.employee.inn}</Value>
        </Field>
        <Field>
          <Name>{t("power.SNILS")}</Name>
          <Value>{POW.representative.employee.snils}</Value>
        </Field>
        <Field style={{ border: "none" }}>
          <Name>{t("power.passport")}</Name>
          <Value>{passportData}</Value>
        </Field>

        <SubTitle>{t("power.authorities")}</SubTitle>
        <div>
          {POW.authorities.map((el) => (
            <Field key={el.id}>
              <Authorities>
                <Code>{el.code}</Code>
                <Authority>{el.name[i18n.language as "en" | "ru"]}</Authority>
              </Authorities>
            </Field>
          ))}
        </div>

        <SubTitle>{t("power.no_entrustment_option")}</SubTitle>

        {/* <EditButtonContainer>
          <EditButton>Повторить попытку, устранив причину отказа</EditButton>
        </EditButtonContainer> */}
      </>
    );
  }
  return null;
};

export default PowerOfAttorneyItemInfo;
