import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Chat from "app/component/chat/Chat";
import { IAO } from "infrastructure/interfaces";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAdvanceReportApplication } from "../../../store/selectors";
import { ADVANCE_APPLICATION_PROPETIES } from "../../../infrastructure/enum/object-properties.enum";

const AOChat: React.FC<RouteComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication,
  );

  return advanceReportApplication &&
    advanceReportApplication.properties &&
    advanceReportApplication.properties.includes(
      ADVANCE_APPLICATION_PROPETIES.COMMUNICATIONS,
    ) ? (
    <Chat
      objectId={advanceReportApplication.id}
      logicalName={"AdvanceReportApplication"}
      title={t("chat.reportTitle")}
    />
  ) : null;
};

export default withRouter(AOChat);
