import styled from "styled-components";
import { StatusProps, ExpireProps, ActiveProps } from "./types";

export const BoxModal = styled.div`
  z-index: 1000;
  padding: 24px;
  background: #fff;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  width: 720px;
  position: relative;
  min-height: 10%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 980px) {
    width: 640px;
  }

  @media screen and (max-width: 640px) {
    min-width: 320px;
    width: 100%;
    position: relative;
  }
`;

export const BoxModalHeader = styled.div`
  position: relative;
  top: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const StatusBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 27px;
  @media screen and (max-width: 480px) {
    position: relative;
    top: -14px;
    right: -9px;
  }
`;

export const Status = styled.div<StatusProps>`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.024em;
  padding: 2px 8px;
  border-radius: 4px;
  margin: 0 19px 0 8px;
  ${(props) =>
    (props.declined || props.revoked) &&
    `
  color: #E00000;`}
  ${(props) =>
    props.signed &&
    `
  color: #3fb553;`}
  ${(props) =>
    props.signingRequires &&
    `
  color: #EA780E;`}
  ${(props) =>
    props.draft &&
    `
  color: #616161;`}
  ${(props) =>
    (props.declined || props.revoked) &&
    `
  background: #FFECEC;`}

  ${(props) =>
    props.signed &&
    `
  background: #DBF7E0;`}
  ${(props) =>
    props.draft &&
    `
  background: #EDEDED;`}
  ${(props) =>
    props.signingRequires &&
    `
  background: #FFF2E7;`}
  
  @media screen and (max-width: 480px) {
    width: 80%;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #0a0a0a;

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media screen and (max-width: 508px) {
    width: 120px;
  }
`;

export const Expire = styled.div<ExpireProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.expired ? "#a20000" : "#98a2a8")};
`;

export const DeclineBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: #ffecec;
  border: 1px solid #ffd7d7;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const ReasonTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
  color: #a20000;
`;

export const ReasonText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.006em;
  color: #a20000;
`;

export const TabContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  display: inline-flex;
  margin-bottom: -20px;
  background: white;
`;

export const Tab = styled.div<ActiveProps>`
  border-bottom: ${(props) => (props.active ? "3px solid red" : "none")};
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: auto;
  padding: 20px 10px;
  margin-right: 20px;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #0a0a0a;
  margin-top: 52px;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`;

export const Field = styled.div`
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0;
  &:last-of-type {
    border: none;
  }
`;

export const Name = styled.div`
  width: 50%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #0a0a0a;
`;

export const Value = styled.div`
  width: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #0a0a0a;
`;

export const Authorities = styled.div`
  width: 100%;
  height: 20px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.006em;
  color: #4d5a61;
`;

export const Code = styled.span`
  font-weight: 600;
  margin-right: 10px;
`;
export const Authority = styled.span`
  font-weight: 400;
`;

export const EditButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const EditButton = styled.button`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #5d6c74;
  border: 1px solid #5d6c74;
  border-radius: 4px;
  padding: 8px;
  background: white;
  width: 60%;
  @media screen and (max-width: 480px) {
    width: 80%;
  }
`;

export const Body = styled.div`
  margin-top: 8px;
`;

export const DownloadContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  gap: 32px;
  margin-bottom: 8px;
  height: 64px;
  background: #f5f5f5;
  border-radius: 4px;
`;

export const Download = styled.div`
  display: flex;
  align-items: center;
`;
export const DownloadSuffix = styled.div`
  font-style: normal;
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #424242;
  word-break: break-all;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 4px;
  height: 32px;
  background: #5d6c74;
  border-radius: 4px;
`;

export const Button = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #ffffff;
  cursor: pointer;
`;

export const DropDownOptions = styled.div`
  position: absolute;
  top: 36px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 4px;
  width: 216px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
export const DropDownOption = styled.div`
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px 12px 16px;
  gap: 8px;
  width: 202px;
  background: #ffffff;
  border-radius: 6px;
  &:hover {
    background: #f5f5f5;
    border-radius: 6px;
  }
`;
