import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "ui/SvgIcon";
import SvgIconS from "../../svg-icon/SvgIcon";
import {
  CheckboxLabelText,
  Checkmark,
  MultiSelectInput,
  MultiSelectOptions,
  StyledCheckbox,
  Container,
  Label,
  MultiSelectContainer,
  Option,
  MultiSelectInputValue,
  MultiSelectInputLength,
  MultiContainer,
  AllSelected,
} from "../ReportFormStyled";
import { cutStr } from "../utils";
import { OptionProp } from "../types";

interface MultiSelectProps {
  label: string;
  options: OptionProp[];
  selectedValues: OptionProp[];
  setSelectedValues: (a: OptionProp[]) => void;
  buttonClicked: boolean;
  report: OptionProp;
  reset: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  options,
  selectedValues,
  setSelectedValues,
  buttonClicked,
  report,
  reset,
}) => {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [optionsData, setOptionData] = useState<OptionProp[]>([]);

  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOptionData([]);
  }, [report, reset]);

  useEffect(() => {
    if (buttonClicked) {
      if (selectedValues.length) {
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [selectedValues.length, buttonClicked]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [inputRef]);

  useEffect(() => {
    if (optionsData.length) {
      setSelectedValues(optionsData);
      setShowAll(false);
    } else {
      setSelectedValues(options);
      setShowAll(true);
    }
  }, [optionsData]);

  const handleOptionClick = (value: OptionProp) => {
    if (optionsData.some((val) => val.id === value.id)) {
      setOptionData(optionsData.filter((val) => val.id !== value.id));
    } else {
      setOptionData([...optionsData, value]);
    }
  };

  const handleInputClick = () => {
    setIsOpen(true);
  };

  return (
    <MultiContainer>
      <Label error={error}>
        {label}
        {error ? "*" : ""}
      </Label>
      <MultiSelectContainer ref={inputRef} onClick={handleInputClick}>
        <SvgIcon
          style={{ position: "absolute", right: "8px", top: "14px", zIndex: 2 }}
          id={"vectorDown"}
          color={"#545B5E"}
          size={15}
        />
        <MultiSelectInput error={error}>
          {showAll ? (
            <AllSelected>{t("report.showAll")}</AllSelected>
          ) : (
            <>
              {selectedValues.slice(0, 2).map((el) => (
                <MultiSelectInputValue>
                  {cutStr(el.name[i18n.language as "ru" | "en"])}
                  <SvgIconS
                    className="icon icon-close"
                    href="#svg_icon_close"
                    onClick={() => handleOptionClick(el)}
                    style={{
                      position: "absolute",
                      right: "7px",
                      top: "3px",
                      zIndex: 2,
                      width: "9px",
                    }}
                  />
                </MultiSelectInputValue>
              ))}
              {selectedValues.length > 2 && (
                <MultiSelectInputLength>
                  +{selectedValues.length - 2}
                </MultiSelectInputLength>
              )}
            </>
          )}
        </MultiSelectInput>
        {isOpen ? (
          <MultiSelectOptions>
            {options.map((option) => (
              <Container
                key={option.id}
                onClick={() => handleOptionClick(option)}
              >
                <StyledCheckbox
                  type="checkbox"
                  value={option.name[i18n.language as "ru" | "en"]}
                  checked={optionsData.some((e) => e.id === option.id)}
                  onChange={() => handleOptionClick(option)}
                />
                <Checkmark />
                <CheckboxLabelText
                  htmlFor={option.name[i18n.language as "ru" | "en"]}
                >
                  <Option>{option.name[i18n.language as "ru" | "en"]}</Option>
                </CheckboxLabelText>
              </Container>
            ))}
          </MultiSelectOptions>
        ) : null}
      </MultiSelectContainer>
    </MultiContainer>
  );
};

export default MultiSelect;
