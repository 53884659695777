import React from "react";
import { IconProps } from "../types";

const Signature: React.FC<IconProps> = (props) => {
  const { color, size, background } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width={size} height={size} rx="4" fill={background} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63087 7.85222C9.6008 7.46201 11.363 8.60117 11.9609 10.2942H17.2162C17.9085 10.2942 18.4749 10.8606 18.4749 11.5529C18.4749 12.2452 17.9085 12.8117 17.2162 12.8117V14.0704C17.2162 14.7627 16.6497 15.3291 15.9574 15.3291C15.2651 15.3291 14.6987 14.7627 14.6987 14.0704V12.8117H11.9609C11.4449 14.2781 10.0477 15.3291 8.40499 15.3291C6.05115 15.3291 4.2008 13.1704 4.71688 10.7347C5.01898 9.29348 6.18961 8.14173 7.63087 7.85222ZM7.1463 11.5531C7.1463 12.2454 7.71273 12.8118 8.40504 12.8118C9.09735 12.8118 9.66378 12.2454 9.66378 11.5531C9.66378 10.8608 9.09735 10.2943 8.40504 10.2943C7.71273 10.2943 7.1463 10.8608 7.1463 11.5531Z"
        fill={color}
      />
    </svg>
  );
};

export default Signature;
