import styled from "styled-components";
import { StyledSignProps, SignWrapProps } from "./types";

const SignWrap = styled.div<SignWrapProps>`
  margin: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin-bottom: 20px;
  display: grid;
  justify-content: center;
  grid-template-columns: ${(props) =>
    props.certificates.length > 1 ? "repeat(2, 1fr)" : "repeat(1, 1fr)"};
  grid-gap: 9px;
  padding: 0 24px;
  @media screen and (min-width: 0px) and (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledSign = styled.div<StyledSignProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  width: 100%;
  height: 128px;
  border: 1px solid ${(props) => (props.checked ? "#008243" : "#E0E0E0")};
  box-sizing: border-box;
  border-radius: 5px;
  background: ${(props) => (props.checked ? "#DBF7E0" : "none")};

  &:hover {
    background: ${(props) => (props.checked ? "#DBF7E0" : "#F5F5F5")};
    border: 1px solid ${(props) => (props.checked ? "#008243" : "#EA780E")};
  }
  @media screen and (min-width: 0px) and (max-width: 760px) {
    overflow: hidden;
  }
`;

const StyledCertificate = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #0a0a0a;
  display: flex;
  width: 189px;
  height: 20px;
`;

const ThumbprintBox = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #424242;
  width: 220px;
`;

const StyledName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0a0a0a;
  margin-top: 6px;
`;

const StyledTitle = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #9e9e9e;
  margin-top: 4px;
`;

const StyledText = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #424242;
`;

const DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-content: space-between;
`;

const DateBoxStart = styled.div`
  margin-right: 41px;
`;

const DateBoxEnd = styled.div`
  position: absolute;
  right: 7px;
`;

const StyledRadio = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;
`;

const StyledType = styled.div`
  position: absolute;
  right: 7px;
  bottom: 11px;
  font-size: 12px;
  line-height: 16px;
`;

export {
  SignWrap,
  StyledSign,
  StyledCertificate,
  StyledName,
  StyledTitle,
  StyledText,
  DateBox,
  DateBoxStart,
  DateBoxEnd,
  StyledRadio,
  ThumbprintBox,
  StyledType,
};
