import React from "react";
import ZNRHistory from "app/pages/ZNR/ZNRHistory";
import AOHistory from "app/pages/report/AOHistory";
import { Switch, Route } from "react-router";

const HistoryBox: React.FC = () => {
  return (
    <Switch>
      <Route path="/ExpenseApplication/Detail/:id" component={ZNRHistory} />
      <Route
        path="/AdvanceReportApplication/Detail/:id"
        component={AOHistory}
      />
    </Switch>
  );
};

export default HistoryBox;
