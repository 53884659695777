import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { showErrors } from "store/exception/actions";
import FileSaver from "file-saver";
import analitiks from "services/AnaliticsService";
import moment from "moment";
import i18n from "services/TranslateService";
import { getCommonUserDetail } from "../../../store/selectors";
import {
  getAdvanceReportApplicationByIdReportsType,
  getAdvanceReportApplicationReportsTypesByCompanyId,
  getAdvanceReportApplicationsArchive,
  getExpenseApplicationsByIdReportsType,
  getExpenseApplicationsReportsTypesByCompanyId,
  getSinglePDF,
} from "../../../services/ApiService";
import Config, { ConfigTypes } from "services/ConfigService";

interface PrintingForm {
  name: {
    ru: string;
    en: string;
  };
  extensions: string[];
  type: string;
}

export interface PrintMenuProps {
  id: number;
  isOpen: boolean;
  onClose: () => void;
  isAdvance?: boolean;
  isApproved?: boolean;
  companyId: number;
  shouldStayOpened?: boolean;
  expenseApplicationId: number;
}

const PrintMenu: React.FC<PrintMenuProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getCommonUserDetail);

  let [utcOffset, setUtcOffset] = useState(0);
  let [printingForms, setPrintingForms] = useState([] as PrintingForm[]);
  let [visible, setVisible] = useState(false);

  const { companyId } = props;
  const isSigningEnabled = Config.getConfigToCompany(
    ConfigTypes.ADVANCE_REPORT_APPLICATION_SIGNING_ENABLED,
    companyId
  );
  const isSinglePDF = Config.getConfigToCompany(
    ConfigTypes.GENERATE_SINGLE_PDF_FILE,
    companyId
  );

  useEffect(() => {
    setUtcOffset(moment().utcOffset());
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      dispatch(loaderLock());
      build();
    } else {
      setVisible(false);
    }
  }, [props.isOpen]);

  const build = async () => {
    let response = props.isAdvance
      ? await getAdvanceReportApplicationReportsTypesByCompanyId(
          props.companyId,
          props.id,
          props.expenseApplicationId
        )
      : await getExpenseApplicationsReportsTypesByCompanyId(
          props.companyId,
          props.expenseApplicationId
        );
    if (response.headers.success) {
      setPrintingForms(response.data);
      setVisible(true);
      dispatch(loaderUnlock());
    } else {
      dispatch(loaderUnlock());
      dispatch(
        showErrors({
          code: "printing_action",
          message: t("modals.error.else"),
        })
      );
    }
  };

  const downloadHandler = (response: any) => {
    let contentDisposition = response.headers["content-disposition"];
    let fileName = contentDisposition.slice(
      contentDisposition.lastIndexOf("'") + 1
    );
    FileSaver.saveAs(response.data, decodeURI(fileName));
  };

  const printReport = async (type: string, extension: string) => {
    dispatch(loaderLock());
    let response = props.isAdvance
      ? await getAdvanceReportApplicationByIdReportsType(
          props.id,
          type,
          extension,
          utcOffset,
          { responseType: "blob" }
        )
      : await getExpenseApplicationsByIdReportsType(
          props.id,
          type,
          extension,
          utcOffset,
          { responseType: "blob" }
        );
    if (response.headers.success) {
      analitiks("CreatePrintingForms", {
        aoId: +props.id,
        type: type,
        employeeId: user.id,
        occupationId: user.occupations[0].occupationId,
        companyId: user.occupations[0].company.id,
      });
      downloadHandler(response);
      !props.shouldStayOpened && props.onClose && props.onClose();
    } else {
      dispatch(
        showErrors({
          code: "print_form_error",
          message: "Что-то пошло не так",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const close = () => {
    props.onClose();
    setPrintingForms([] as PrintingForm[]);
  };

  const downloadArchive = async () => {
    dispatch(loaderLock());
    const response = await getAdvanceReportApplicationsArchive(props.id, {
      responseType: "blob",
    });
    if (response.headers.success) {
      downloadHandler(response);
      !props.shouldStayOpened && props.onClose && props.onClose();
    } else {
      dispatch(
        showErrors({
          code: "print_form_error",
          message: "Что-то пошло не так",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const downloadSinglePdf = async () => {
    dispatch(loaderLock());
    const response = await getSinglePDF(props.id, {
      responseType: "blob",
    });
    if (response.headers.success) {
      downloadHandler(response);
      !props.shouldStayOpened && props.onClose && props.onClose();
    } else {
      dispatch(
        showErrors({
          code: "print_form_error",
          message: "Что-то пошло не так",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const render = () => {
    return printingForms.map((form: PrintingForm) => {
      return (
        <div className="modal-print-item">
          <div className="modal-print-title">{`${t("report_detail.print")} ${form.name[i18n.language as "ru" | "en"].toLowerCase()}`}</div>
          <div className="modal-print-content">
            {form.extensions.map((extension: any) => {
              return (
                <div
                  className="modal-print-content-item-wrap pointer"
                  onClick={() => printReport(form.type, extension)}
                >
                  <div>
                    <img src={`img/icon-${extension}.png`} />
                  </div>
                  <div className="modal-print-content-item-text">
                    <span>{`.${extension}`}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {visible ? <div className="miss-click-handler" onClick={close} /> : ""}
      <div
        className="request-files-item-dropdown modal-print-wrapper"
        id="modal-print"
        style={
          visible ? { display: "block", zIndex: 100 } : { display: "none" }
        }
      >
        {isSigningEnabled && props.isApproved && props.isAdvance ? (
          <div className="modal-print-item">
            <div className="modal-print-title">{`${t("report_detail.download_archive")}`}</div>
            <div className="modal-print-content"></div>
            <div
              className="modal-print-content-item-wrap pointer"
              onClick={() => downloadArchive()}
            >
              <div>
                <img src={`img/icon-zip.png`} />
              </div>
              <div className="modal-print-content-item-text">
                <span>&nbsp;.zip</span>
              </div>
            </div>
          </div>
        ) : null}
        {props.isAdvance && isSinglePDF ? (
          <div className="modal-print-item">
            <div className="modal-print-title">{`${t("report_detail.download_single_pdf")}`}</div>
            <div className="modal-print-content"></div>
            <div
              className="modal-print-content-item-wrap pointer"
              onClick={() => downloadSinglePdf()}
            >
              <div>
                <img src={`img/icon-pdf.png`} />
              </div>
              <div className="modal-print-content-item-text">
                <span>&nbsp;.pdf</span>
              </div>
            </div>
          </div>
        ) : null}
        {render()}
      </div>
    </>
  );
};
export default PrintMenu;
