import { combineReducers, Reducer } from "redux";

import { LogoState, reducer as logoReducer } from "./logo/reducer";
import { CommonState, reducer as commonReducer } from "./common/reducer";
import {
  ExceptionState,
  reducer as exceptionReducer,
} from "./exception/reducer";
import { ReportState, reducer as reportReducer } from "./report/reducer";
import {
  ApplicationForExpenseState,
  reducer as applicationForExpenseReducer,
} from "./applicationForExpense/reducer";
import {
  AgreementState,
  reducer as agreementReducer,
} from "./agreement/reducer";
import { JournalState, reducer as journalReducer } from "./journal/reducer";
import {
  SubstitutionState,
  reducer as substitutionReducer,
} from "./substitution/reducer";
import {
  PowerOfAttorneyState,
  reducer as powerOfAttorneyReducer,
} from "./power/reducer";
import {
  CertificatesState,
  reducer as certificatesReducer,
} from "./certificates/reducer";
import {
  QRScannerState,
  reducer as qrScannerReducer,
} from "./qrScanner/reducer";

export interface ApplicationState {
  logo: LogoState;
  common: CommonState;
  exception: ExceptionState;
  advanceReport: ReportState;
  applicationForExpense: ApplicationForExpenseState;
  agreement: AgreementState;
  journal: JournalState;
  substitution: SubstitutionState;
  power: PowerOfAttorneyState;
  certificates: CertificatesState;
  qrScanner: QRScannerState;
}

export const reducers: Reducer<ApplicationState> =
  combineReducers<ApplicationState>({
    logo: logoReducer,
    common: commonReducer,
    exception: exceptionReducer,
    advanceReport: reportReducer,
    applicationForExpense: applicationForExpenseReducer,
    agreement: agreementReducer,
    journal: journalReducer,
    substitution: substitutionReducer,
    power: powerOfAttorneyReducer,
    certificates: certificatesReducer,
    qrScanner: qrScannerReducer,
  });
