import styled from "styled-components";

interface StyleProps {
  backgroundColor?: string;
}

export const NoticePanelContainer = styled.div`
  border-radius: 8px;
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  position: relative;
`;
export const NoticePanelInfo = styled.div`
  display: inline-flex;
  padding: 28px 20px;
`;

export const NoticePanelInfoTitle = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 4px;
`;
export const NoticePanelInfoText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;
export const NoticePanelButtonGroup = styled.div`
  height: 64px;
  width: 100%;
  border-top: 1px solid #cad5db;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
`;
export const Button = styled.button<StyleProps>`
  height: 40px;
  border-radius: 8px;
  color: ${(props) => (props.backgroundColor ? "white" : "#2E363A")};
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
  padding: 10px, 16px, 10px, 16px;
  gap: 10px;
  cursor: pointer;
  border: ${(props) => (props.backgroundColor ? "none" : "1px solid #CAD5DB")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  margin-left: 12px;

  &:hover {
    box-shadow: 0px 3px 8px rgb(0 0 0 / 32%);
  }
`;
