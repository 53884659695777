import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { searchPowerOfAttorney } from "store/power/actions";

const AutocompleteBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px 6px 14px;
  gap: 8px;
  width: 568px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  @media screen and (max-width: 1230px) {
    width: 468px;
  }
  @media screen and (max-width: 1120px) {
    width: 319px;
  }
  @media screen and (max-width: 960px) {
    width: 468px;
  }
  @media screen and (max-width: 480px) {
    width: 395px;
  }
  @media screen and (max-width: 380px) {
    width: 274px;
  }
`;
const Autocomplete = styled.input`
  border: none;
  width: 90%;
  text-overflow: ellipsis;
`;

export interface PowerOfAttorneyAutocompleteProps {
  placeholder?: string;
  setSearched: (a: boolean) => void;
  setItemsCount: (a: number) => void;
  setActive: (a: string) => void;
  setCurrentPage: (a: number) => void;
}

const PowerOfAttorneyAutocomplete: React.FC<
  PowerOfAttorneyAutocompleteProps
> = (props) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState<number | string>();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!isNaN(inputValue) && inputValue != 0) {
      timeout = setTimeout(() => {
        dispatch(
          searchPowerOfAttorney(
            {
              QueryParameter: "id",
              QueryValue: Number(inputValue),
              pageSize: 1000,
            },
            props.setItemsCount,
            props.setSearched,
            props.setCurrentPage,
          ),
        );
      }, 1000);
    }
    if (
      isNaN(inputValue) &&
      inputValue &&
      String(inputValue).length > 2 &&
      inputValue != 0
    ) {
      timeout = setTimeout(() => {
        dispatch(
          searchPowerOfAttorney(
            {
              QueryParameter: "representativeFullName",
              QueryValue: inputValue,
              pageSize: 1000,
            },
            props.setItemsCount,
            props.setSearched,
            props.setCurrentPage,
          ),
        );
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [inputValue]);

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    props.setActive("All");
  };

  const clearInputHandler = () => {
    setInputValue("");
    props.setSearched(true);
    dispatch(
      searchPowerOfAttorney(
        { pageSize: 1000 },
        props.setItemsCount,
        props.setSearched,
        props.setCurrentPage,
      ),
    );
    props.setActive("All");
  };

  return (
    <div>
      <AutocompleteBox>
        <SvgIcon className="icon icon-search" href="#svg_icon_search" />
        <Autocomplete
          placeholder={props.placeholder}
          value={inputValue}
          onChange={changeInput}
          type="text"
        ></Autocomplete>
        {String(inputValue).length ? (
          <SvgIcon
            className="icon icon-close"
            href="#svg_icon_close"
            onClick={clearInputHandler}
            style={{ width: "12px" }}
          />
        ) : null}
      </AutocompleteBox>
    </div>
  );
};

export default PowerOfAttorneyAutocomplete;
