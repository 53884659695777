import "./Grid.css";
import GridBody from "app/component/grid/GridBody";
import GridHeader from "app/component/grid/GridHeader";
import { SortOrder } from "app/component/grid/GridHeaderCell";
import React from "react";

export interface HeaderOptions {
  headerText: string | any;
  sortable?: boolean;
  key: string;
  isBold?: boolean;
}

export interface GridOptions {
  headers: HeaderOptions[];
  tableSub?: boolean;
  bookkeeperTable?: boolean;
  isLastRowNarrow?: boolean;
  downloadsTable?: boolean;
}

interface GridProps {
  data: any[];
  options: GridOptions;
  isTransaction?: boolean;
  onSort?: (key: string, sortOrder: SortOrder) => void;
  onDelete?: (itemId: number) => void;
  substitution?: boolean;
}

const Grid: React.FC<GridProps> = (props) => {
  const sortHandler = (key: string, sortOrder: SortOrder) => {
    if (props.onSort) {
      props.onSort(key, sortOrder);
    }
  };

  return (
    <div
      className={`profile-calc-table ${props.options.tableSub ? "table-sub" : ""} ${props.options.bookkeeperTable ? "bookkeeper-table" : ""} ${props.options.downloadsTable ? "m-t-25" : ""}`}
    >
      <GridHeader options={props.options} onSort={sortHandler} />
      <GridBody
        data={props.data}
        options={props.options}
        isTransaction={props.isTransaction}
        substitution={props.substitution}
      />
    </div>
  );
};

export default Grid;
