import { Action } from "redux";
import {
  ExpenseType,
  BusinessGoal,
  PaymentForm,
  ConsumptionType,
  FilterParams,
  UserDetail,
  CurrencyList,
  Posting,
  AttachmentItem,
  AttachmentPermission,
  ManualExpenseCurrencyPermission,
  Clearing,
  BusinessTarget,
  ExpenseTypeItem,
  ICompany,
} from "infrastructure/interfaces";

export const COMMON = {
  SET_EXPENSE_TYPE: "COMMON/SET_EXPENSE_TYPE",
  UPDATE_EXPENSE_TYPE: "COMMON/UPDATE_EXPENSE_TYPE",
  SET_BUSINESS_GOAL: "COMMON/SET_BUSINESS_GOAL",
  UPDATE_BUSINESS_GOAL: "COMMON/UPDATE_BUSINESS_GOAL",
  SET_CONSUMPTION_TYPE: "COMMON/SET_CONSUMPTION_TYPE",
  UPDATE_CONSUMPTION_TYPE: "COMMON/UPDATE_CONSUMPTION_TYPE",
  SET_PAYMENT_FORM: "COMMON/SET_PAYMENT_FORM",
  UPDATE_PAYMENT_FORM: "COMMON/UPDATE_PAYMENT_FORM",
  SET_FILTER_PARAMS: "COMMON/SET_FILTER_PARAMS",
  SET_STATE_OPTIONS: "COMMON/SET_STATE_OPTIONS",
  SET_USER_DETAIL: "COMMON/SET_USER_DETAIL",
  INCREMENT_LOADER_COUNT: "COMMON/INCREMENT_LOADER_COUNT",
  DECREMENT_LOADER_COUNT: "COMMON/DECREMENT_LOADER_COUNT",
  SET_FILTER_SHOW: "COMMON/SET_FILTER_SHOW",
  SET_CURRENCIES: "COMMON/SET_CURRENCIES",
  SET_CHOSEN_ROLE: "COMMON/SET_CHOSEN_ROLE",
  SET_POSTINGS: "COMMON/SET_POSTINGS",
  SET_IS_ARCHIVED_POSTINGS: "COMMON/SET_IS_ARCHIVED_POSTINGS",
  SET_TRANSACTION_BATCH_ID: "COMMON/TRANSACTION_BATCH_ID",
  ADD_POSTING: "COMMON/ADD_POSTING",
  SET_ATTACHMENT_LIST: "COMMON/SET_ATTACHMENT_LIST",
  TOGGLE_ONBOARD: "COMMON/TOGGLE_ONBOARD",
  TOGGLE_ATTACHMENTS: "COMMON/TOGGLE_ATTACHMENTS",
  SET_ATTACHMENTS_EXPENSE: "COMMON/SET_ATTACHMENTS_EXPENSE",
  ADD_ATTACHMENTS_EXPENSE: "COMMON/ADD_ATTACHMENTS_EXPENSE",
  HISTORY_NEED_UPDATING: "COMMON/HISTORY_NEED_UPDATING",
  HISTORY_UPDATED: "COMMON/HISTORY_UPDATED",
  ADD_NEW_TOP_MODAL: "COMMON/ADD_NEW_TOP_MODAL",
  DEL_TOP_MODAL: "COMMON/DEL_TOP_MODAL",
  SET_CHAT_SHOW: "SET_CHAT_SHOW",
  SET_ATTACHMENT_PERMISSION: "COMMON/SET_ATTACHMENT_PERMISSION",
  SET_MANUAL_EXPENSE_CURRENCY_PERMISSION:
    "COMMON/SET_MANUAL_EXPENSE_CURRENCY_PERMISSION",
  SET_BUSINESS_TARGETS_CATALOG: "COMMON/SET_BUSINESS_TARGETS_CATALOG",
  SET_EXPENSE_TYPES_CATALOG: "COMMON/SET_EXPENSE_TYPES_CATALOG",
  SET_COMPANIES_CATALOG: "COMMON/SET_COMPANIES_CATALOG",
  SET_APPROVALS_EMPLOYEE: "COMMON/SET_APPROVALS_EMPLOYEE",
  SET_CURRENCY_RATE: "COMMON/SET_CURRENCY_RATE",
  SET_APPROVED_CURRENCY_RATE: "COMMON/SET_APPROVED_CURRENCY_RATE",
  UPDATE_CURRENCY_RATE: "COMMON/UPDATE_CURRENCY_RATE",
  SET_HISTORY_SHOW: "COMMON/SET_HISTORY_SHOW",
  SET_NOTICE_PANEL_OPEN: "COMMON/SET_NOTICE_PANEL_OPEN",
  SET_NOTICE_PANEL_NEW_MESSAGE_COUNT:
    "COMMON/SET_NOTICE_PANEL_NEW_MESSAGE_COUNT",
  SET_CLEARINGS: "COMMON/SET_CLEARINGS",
  SET_PROFILE_FIELD_PERMISSIONS: "COMMON/SET_PROFILE_FIELD_PERMISSIONS",
  SET_FILTER_FIELD_PERMISSIONS: "COMMON/SET_FILTER_FIELD_PERMISSIONS",
  SET_MENU_FIELD_PERMISSIONS: "COMMON/SET_MENU_FIELD_PERMISSIONS",
  SET_TRANSACTIONS_UPDATE_FIELD_PERMISSIONS:
    "COMMON/SET_TRANSACTIONS_UPDATE_FIELD_PERMISSIONS",
  SET_TRANSACTIONS_READ_FIELD_PERMISSIONS:
    "COMMON/SET_TRANSACTIONS_READ_FIELD_PERMISSIONS",
  SET_TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS:
    "COMMON/SET_TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS",
  SET_SUBCONTO_DATA: "COMMON/SET_SUBCONTO_DATA",
  SET_SUBCONTO_IS_CHANGED: "COMMON/SET_SUBCONTO_IS_CHANGED",
  SET_COST_CENTER_DATA: "COMMON/SET_COST_CENTER_DATA",
  SET_COST_CENTER_IS_CHANGED: "COMMON/SET_COST_CENTER_IS_CHANGED",
  SET_CERTIFICATE_ID: "COMMON/SET_CERTIFICATE_ID",
};

export interface SetSubcontoData extends Action {
  type: typeof COMMON.SET_SUBCONTO_DATA;
  payload: { subcontoData: any };
}

export interface SetSubcontoIsChanged extends Action {
  type: typeof COMMON.SET_SUBCONTO_IS_CHANGED;
  payload: { subcontoIsChanged: any };
}

export interface SetCostCenterData extends Action {
  type: typeof COMMON.SET_COST_CENTER_DATA;
  payload: { costCenterData: any };
}

export interface SetCostCenterIsChanged extends Action {
  type: typeof COMMON.SET_COST_CENTER_IS_CHANGED;
  payload: { costCenterIsChanged: any };
}

export interface SetExpenseType extends Action {
  type: typeof COMMON.SET_EXPENSE_TYPE;
  payload: { expenseTypes: ExpenseType[] };
}

export interface SetPaymentForm extends Action {
  type: typeof COMMON.SET_PAYMENT_FORM;
  payload: { paymentForms: PaymentForm[] };
}

export interface SetConsumptionType extends Action {
  type: typeof COMMON.SET_CONSUMPTION_TYPE;
  payload: { consumptionTypes: ConsumptionType[] };
}

export interface SetBusinessGoal extends Action {
  type: typeof COMMON.SET_BUSINESS_GOAL;
  payload: { businessGoals: BusinessGoal[] };
}

export interface SetFilterParams extends Action {
  type: typeof COMMON.SET_FILTER_PARAMS;
  payload: { filterParams: FilterParams };
}

export interface IncrementLoaderCount extends Action {
  type: typeof COMMON.INCREMENT_LOADER_COUNT;
}

export interface DecrementLoaderCount extends Action {
  type: typeof COMMON.DECREMENT_LOADER_COUNT;
}

export interface SetStateOption extends Action {
  type: typeof COMMON.SET_STATE_OPTIONS;
  payload: { stateOptions: string[] };
}

export interface SetUserDetail extends Action {
  type: typeof COMMON.SET_USER_DETAIL;
  payload: { userDetail: UserDetail };
}

export interface SetAttachmentPermission extends Action {
  type: typeof COMMON.SET_ATTACHMENT_PERMISSION;
  payload: { attachmentPermission: AttachmentPermission };
}

export interface SetManualExpenseCurrencyPermission extends Action {
  type: typeof COMMON.SET_MANUAL_EXPENSE_CURRENCY_PERMISSION;
  payload: { manualExpenseCurrencyPermission: ManualExpenseCurrencyPermission };
}

export interface SetBusinessTargetsCatalog extends Action {
  type: typeof COMMON.SET_BUSINESS_TARGETS_CATALOG;
  payload: { catalog: BusinessTarget[] };
}

export interface SetExpenseTypesCatalog extends Action {
  type: typeof COMMON.SET_EXPENSE_TYPES_CATALOG;
  payload: { catalog: ExpenseTypeItem[] };
}

export interface SetCompaniesList extends Action {
  type: typeof COMMON.SET_COMPANIES_CATALOG;
  payload: { catalog: ICompany[] };
}

export interface SetFilterShow extends Action {
  type: typeof COMMON.SET_FILTER_SHOW;
  payload: { filterState: boolean };
}

export interface SetCurrencies extends Action {
  type: typeof COMMON.SET_CURRENCIES;
  payload: { currencies: CurrencyList };
}

export interface SetPostings extends Action {
  type: typeof COMMON.SET_POSTINGS;
  payload: { postings: Posting[] };
}

export interface SetIsArchivedPostings extends Action {
  type: typeof COMMON.SET_IS_ARCHIVED_POSTINGS;
  payload: { isArchived: boolean };
}

export interface SetTransactionBatchId extends Action {
  type: typeof COMMON.SET_TRANSACTION_BATCH_ID;
  payload: { transactionBatchId: number };
}

export interface AddPosting extends Action {
  type: typeof COMMON.ADD_POSTING;
  payload: { posting: Posting };
}

export interface SetAttachmentList extends Action {
  type: typeof COMMON.SET_ATTACHMENT_LIST;
  payload: { attachmentList: AttachmentItem[] };
}

export interface ToggleOnboard extends Action {
  type: typeof COMMON.TOGGLE_ONBOARD;
  payload: { state: boolean };
}

export interface ToggleAttachments extends Action {
  type: typeof COMMON.TOGGLE_ATTACHMENTS;
}

export interface SetAttachmentsExpense extends Action {
  type: typeof COMMON.SET_ATTACHMENTS_EXPENSE;
}

export interface AddAttachmentsExpense extends Action {
  type: typeof COMMON.ADD_ATTACHMENTS_EXPENSE;
}

export interface HistoryNeedUpdating extends Action {
  type: typeof COMMON.HISTORY_NEED_UPDATING;
}

export interface HistoryUpdated extends Action {
  type: typeof COMMON.HISTORY_UPDATED;
}

export interface AddNewTopModal extends Action {
  type: typeof COMMON.ADD_NEW_TOP_MODAL;
  payload: { newTopModal: number };
}

export interface DelTopModal extends Action {
  type: typeof COMMON.DEL_TOP_MODAL;
  payload: { topModal: number };
}

export interface SetChatShow extends Action {
  type: typeof COMMON.SET_CHAT_SHOW;
  payload: { chatState: boolean };
}

export interface SetCurrencyRate extends Action {
  type: typeof COMMON.SET_CURRENCY_RATE;
  payload: { currencyRate: number };
}

export interface SetApprovedCurrencyRate extends Action {
  type: typeof COMMON.SET_APPROVED_CURRENCY_RATE;
  payload: { currencyRate: number };
}

export interface SetHistoryShow extends Action {
  type: typeof COMMON.SET_HISTORY_SHOW;
  payload: { historyState: boolean };
}

export interface SetNoticePanelOpen extends Action {
  type: typeof COMMON.SET_NOTICE_PANEL_OPEN;
  payload: { open: boolean };
}

export interface SetNoticePanelNewMessageCount extends Action {
  type: typeof COMMON.SET_NOTICE_PANEL_NEW_MESSAGE_COUNT;
  payload: { newMessageCount: number };
}

export interface SetMenuFieldPermissions extends Action {
  type: typeof COMMON.SET_MENU_FIELD_PERMISSIONS;
  payload: { menuFieldPermissions: string[] };
}

export interface SetFilterFieldPermissions extends Action {
  type: typeof COMMON.SET_FILTER_FIELD_PERMISSIONS;
  payload: { filterFieldPermissions: string[] };
}

export interface SetProfileFieldPermissions extends Action {
  type: typeof COMMON.SET_PROFILE_FIELD_PERMISSIONS;
  payload: { profileFieldPermissions: string[] };
}

export interface SetTransactionsUpdateFieldPermissions extends Action {
  type: typeof COMMON.SET_TRANSACTIONS_UPDATE_FIELD_PERMISSIONS;
  payload: { transactionsUpdateFieldPermissions: string[] };
}

export interface SetTransactionsReadFieldPermissions extends Action {
  type: typeof COMMON.SET_TRANSACTIONS_READ_FIELD_PERMISSIONS;
  payload: { transactionsReadFieldPermissions: string[] };
}

export interface SetTransactionsJournalFieldPermissions extends Action {
  type: typeof COMMON.SET_TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS;
  payload: { transactionsJournalFieldPermissions: string[] };
}

export interface SetClearings extends Action {
  type: typeof COMMON.SET_CLEARINGS;
  payload: {
    clearingData: {
      dateStart: Date;
      dateEnd: Date;
      clearings: Clearing[];
      balanceStart: number;
      balanceEnd: number;
    };
  };
}

export type CommonActions =
  | SetExpenseType
  | SetBusinessGoal
  | IncrementLoaderCount
  | DecrementLoaderCount
  | SetConsumptionType
  | SetPaymentForm
  | SetStateOption
  | SetUserDetail
  | SetFilterShow
  | SetCurrencies
  | SetPostings
  | AddPosting
  | SetAttachmentList
  | ToggleOnboard
  | ToggleAttachments
  | SetAttachmentsExpense
  | AddAttachmentsExpense
  | HistoryNeedUpdating
  | HistoryUpdated
  | AddNewTopModal
  | DelTopModal
  | SetChatShow
  | SetAttachmentPermission
  | SetManualExpenseCurrencyPermission
  | SetCurrencyRate
  | SetHistoryShow
  | SetNoticePanelOpen
  | SetNoticePanelNewMessageCount
  | SetClearings
  | SetIsArchivedPostings
  | SetTransactionBatchId
  | SetMenuFieldPermissions
  | SetFilterFieldPermissions
  | SetProfileFieldPermissions
  | SetTransactionsUpdateFieldPermissions
  | SetTransactionsReadFieldPermissions
  | SetTransactionsJournalFieldPermissions;
