import React, { useState, useEffect, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "app/component/modal/ModalContainer";
import { useSelector, useDispatch } from "react-redux";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { RouteComponentProps, withRouter } from "react-router";
import SelectWithSection from "../../select/SelectWithSection";
import SvgIcon from "../../svg-icon/SvgIcon";
import DatePickerCalendar from "../../date-picker/DatePickerCalendar";
import { IAO } from "infrastructure/interfaces";
import {
  formatter,
  getCurrencieCode,
  getNextKey,
  toLocaleNumberFormatter,
} from "app/utils";
import { get } from "lodash";
import moment from "moment";
import { getAdvanceReportApplication } from "../../../../store/selectors";
import {
  getAdvanceReportApplicationsDefaultServiceNoteRecipient,
  getAdvanceReportApplicationsReportDocumentsById,
  postAdvanceReportAdditionalDocuments,
  updateAdvanceReportAdditionalDocuments,
  updateAdvanceReportAdditionalDocumentsAttach,
  updateAdvanceReportAdditionalDocumentsById,
  updateAdvanceReportAdditionalDocumentsDetach,
} from "../../../../services/ApiService";
import Select from "app/component/select/Select";
import EmployeeAutocomplete, {
  EmployeeAutocompleteOptionType,
} from "app/component/autocomplete/EmployeeAutocomplete";

interface ADMemoModalProps extends RouteComponentProps<any> {
  isOpen: boolean;
  onClose: (isSimpleClose?: boolean) => void;
  onComplete: () => void;
  editId?: number;
  documentId: number;
}

interface Option {
  value: string;
  text: string;
}

const MemoModal: React.FC<ADMemoModalProps> = (props) => {
  let { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const advanceReportDetail: IAO =
    useSelector(getAdvanceReportApplication) || ({} as IAO);

  const [employee, setEmployee] = useState(
    {} as EmployeeAutocompleteOptionType,
  );
  const [openCalendar, setOpenCalendar] = useState(false);
  const [expenses, setExpenses] = useState([] as any[]);
  const [memoValues, setMemoValues] = useState({
    actNumber: "",
    actExpenses: [] as { id: number; key: number }[],
    type: "",
    recipient: "",
    date: undefined as Date | undefined,
    description: "",
  });
  const [startMemoValues, setStartMemoValues] = useState({
    actExpenses: [] as { id: number; key: number }[],
  });
  const [fieldsWithError, setFieldsWithError] = useState([] as string[]);
  const [isTabIndexDisabled, setTabIndexDisabled] = useState(false);
  const options: Option[] = [
    {
      value: "0",
      text: "Cвободная форма служебной записки",
    },
  ];
  const [selectedServiceNoteType, setSelectedServiceNoteType] =
    useState<Option>(options[0]);

  let employeeRef = useRef({} as any);

  const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
    if (option.value !== employee.value) {
      setEmployee(option);
    }
  };

  useEffect(() => {
    if (props.isOpen) {
      init();
    }
  }, [props.isOpen]);

  const getDefaultRecipient = async () => {
    const result =
      await getAdvanceReportApplicationsDefaultServiceNoteRecipient(
        advanceReportDetail.company.id,
        advanceReportDetail.assigneeEmployee.id,
      );
    if (result.headers.success) {
      return {
        text: `${
          result.data.recipientEmployee.name[i18n.language as "ru" | "en"]
        }, ${result.data.recipientEmployeePosition}`,
        value: result.data.recipientEmployee.id,
      };
    }
  };

  const init = async () => {
    dispatch(loaderLock());

    let newMemoValue = {
      actNumber: "",
      actExpenses: [] as { id: number; key: number }[],
      type: "",
      recipient: "",
      date: undefined as Date | undefined,
      description: "",
    };

    if (!props.editId) {
      let employeeDefault = await getDefaultRecipient();
      newMemoValue = {
        actNumber: "",
        actExpenses: [],
        type: "",
        date: undefined,
        recipient: employeeDefault && employeeDefault.text,
        description: "",
      } as typeof memoValues;

      let newExpenses = advanceReportDetail.expenses
        .filter((item) => item.expenseType.code !== "Daily allowance")
        .map((item) => ({
          text: `${item.expenseType.name[i18n.language as "ru"]} - ${
            item.description
          }`,
          value: item.id,
          section: `${toLocaleNumberFormatter(
            item.amountRub,
          )} ${getCurrencieCode("RUB")}`,
          isAvailable: true,
        }));

      setExpenses(newExpenses);
    }

    if (props.editId) {
      let currentMemo = await getAdvanceReportApplicationsReportDocumentsById(
        props.editId,
      );
      if (currentMemo.headers.success) {
        let data = currentMemo.data;
        newMemoValue = {
          actNumber: get(data, "actNumber", ""),
          actExpenses: get(data, "expenses", []).map((item: any) => ({
            id: item.id,
            key: getNextKey("actExpensesKey"),
          })),
          type: get(data, `documentType`, ""),
          date: moment(get(data, "serviceNote.serviceNoteDate", null)).toDate(),
          recipient: `${
            data.serviceNote.recipientEmployee.name[
              i18n.language as "ru" | "en"
            ]
          }, ${data.serviceNote.recipientEmployeePosition}`,
          description: get(data, "description", ""),
        };
        setEmployee({
          value: data.serviceNote.recipientEmployee.id,
          text: data.serviceNote.recipientEmployee.name.ru,
          occupations: [],
        });

        //TODO
        let newExpenses = advanceReportDetail.expenses
          .filter((item) => item.expenseType.code !== "Daily allowance")
          .map((item) => ({
            text: `${item.expenseType.name[i18n.language as "ru"]} - ${
              item.description
            }`,
            value: item.id,
            section: `${toLocaleNumberFormatter(
              item.amountRub,
            )} ${getCurrencieCode("RUB")}`,
            isAvailable: !newMemoValue.actExpenses.find(
              (i) => i.id === item.id,
            ),
          }));

        setExpenses(newExpenses);
      }
    }

    setMemoValues(newMemoValue);

    setStartMemoValues({
      actExpenses: newMemoValue.actExpenses,
    });

    dispatch(loaderUnlock());
  };

  const changeValue = (type: string, value: any) => {
    let newMemoValues = { ...memoValues };
    let newFieldsWithError = [...fieldsWithError];

    switch (type) {
      case "description":
        newMemoValues.description = value.target.value;
        newFieldsWithError = newFieldsWithError.filter(
          (item) => item !== "description",
        );
        break;
      case "date":
        newMemoValues.date = value.startDate;
        newFieldsWithError = newFieldsWithError.filter(
          (item) => item !== "date",
        );
        setOpenCalendar(false);
        break;
      case "expense":
        newMemoValues.actExpenses = memoValues.actExpenses.map((item) => {
          if (value.oldExpense.key === item.key) {
            newFieldsWithError = newFieldsWithError.filter(
              (element) => element !== `expense-${item.key}`,
            );
            return { id: value.option.value, key: item.key };
          } else {
            return item;
          }
        });
        setExpenses((expenses) =>
          expenses.map((el) =>
            el.value === value.option.value
              ? { ...el, isAvailable: false }
              : el,
          ),
        );
        break;
      case "addExpense":
        newMemoValues.actExpenses.push({
          id: 0,
          key: getNextKey("actExpensesKey"),
        });
        break;
      case "deleteExpense":
        newMemoValues.actExpenses = memoValues.actExpenses.filter(
          (item) => item.key !== value.key,
        );
        newFieldsWithError = newFieldsWithError.filter(
          (element) => element !== `expense-${value.key}`,
        );
        setExpenses((expenses) =>
          expenses.map((el) =>
            el.value === value.id ? { ...el, isAvailable: true } : el,
          ),
        );

        break;
    }

    setFieldsWithError(newFieldsWithError);
    setMemoValues(newMemoValues);
  };

  const getValueByExpenseId = (expenseId: number) => {
    let findeExpense = expenses.find((item: any) => item.value === expenseId);

    if (findeExpense) {
      return findeExpense;
    } else {
      return null;
    }
  };

  const save = async () => {
    dispatch(loaderLock());

    let saveData = {
      description: memoValues.description,
      documentType: 1,
      entityObjectId: props.documentId,
      entityLogicalName: "AdvanceReportAdditionalDocument",
      serviceNoteDate: memoValues.date,
      serviceNoteType: selectedServiceNoteType.value,
      assigneeEmployeeId: advanceReportDetail.assigneeEmployee.id,
      RecipientEmployeeId: employee.value,
    };

    let createdMemo = await postAdvanceReportAdditionalDocuments(saveData);

    if (createdMemo.headers.success) {
      let createdActId = createdMemo.data.id;
      for (let index = 0; index < memoValues.actExpenses.length; index++) {
        const actExpense = memoValues.actExpenses[index].id;
        await updateAdvanceReportAdditionalDocuments(createdActId, actExpense);
      }
    }

    props.onComplete();
    dispatch(loaderUnlock());
  };

  const edit = async () => {
    dispatch(loaderLock());

    let editData = {
      id: props.editId,
      documentType: 1,
      entityObjectId: props.documentId,
      entityLogicalName: "AdvanceReportAdditionalDocument",
      serviceNoteDate: memoValues.date,
      serviceNoteType: selectedServiceNoteType.value,
      assigneeEmployeeId: advanceReportDetail.assigneeEmployee.id,
      actNumber: memoValues.actNumber,
      description: memoValues.description,
      RecipientEmployeeId: employee.value,
    };

    let editedMemo = await updateAdvanceReportAdditionalDocumentsById(
      props.editId,
      editData,
    );

    if (editedMemo.headers.success) {
      let createdActId = props.editId;

      let attachExpense = memoValues.actExpenses.filter(
        (item) =>
          !startMemoValues.actExpenses.map((item) => item.id).includes(item.id),
      );
      let detachExpense = startMemoValues.actExpenses.filter(
        (item) =>
          !memoValues.actExpenses.map((item) => item.id).includes(item.id),
      );

      for (let index = 0; index < attachExpense.length; index++) {
        const actExpense = attachExpense[index].id;
        await updateAdvanceReportAdditionalDocumentsAttach(
          createdActId,
          actExpense,
        );
      }

      for (let index = 0; index < detachExpense.length; index++) {
        const actExpense = detachExpense[index].id;
        await updateAdvanceReportAdditionalDocumentsDetach(
          createdActId,
          actExpense,
        );
      }
    } else {
      console.log("fail to edit");
    }

    props.onComplete();
    dispatch(loaderUnlock());
  };

  useEffect(() => {
    if (employeeRef.current.value && employee) {
      const newFieldsWithError = fieldsWithError.filter(
        (item) => item !== "recipient",
      );
      setFieldsWithError(newFieldsWithError);
    }
  }, [employeeRef, employee]);

  const validate = () => {
    let newFieldsWithError = [] as string[];
    let validExpense = true;
    let validDate = true;
    let validRecipient = true;
    let validDescription = true;

    if (memoValues.actExpenses.filter((item) => !item.id).length) {
      validExpense = false;
      memoValues.actExpenses
        .filter((item) => !item.id)
        .forEach((item) => newFieldsWithError.push(`expense-${item.key}`));
    }

    if (!memoValues.date) {
      validDate = false;
      newFieldsWithError.push("date");
    }

    if (!employeeRef.current.value) {
      validDate = false;
      newFieldsWithError.push("recipient");
    }

    if (!memoValues.description.trim().length) {
      validDescription = false;
      newFieldsWithError.push("description");
    }

    setFieldsWithError(newFieldsWithError);
    return validExpense && validDate && validRecipient && validDescription;
  };

  const validStateField = (fieldName: string) => {
    return fieldsWithError.includes(fieldName) ? "error" : "";
  };

  const closeHandler = () => {
    props.onClose(true);
    setFieldsWithError([]);
  };

  const saveHandler = () => {
    if (!validate()) {
      console.log("Error: Одно из полей не заполнено");
      return;
    }
    if (props.editId) {
      edit();
    } else {
      save();
    }
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  const handleChange = async (option: any) => {
    setSelectedServiceNoteType(option);
  };

  return (
    <Fragment>
      <ModalContainer
        onEnter={saveHandler}
        isOpen={props.isOpen}
        destroy={true}
        highestModal={isHighestModal}
        overlayClick={closeHandler}
      >
        <div className="box-modal" id="add-expenses1">
          <div className="box-modal_close" onClick={closeHandler}></div>
          <div className="box-modal-title">{t("modals.memo_modal.memo")}</div>
          <div className="box-modal-content">
            <div className="input-item-row">
              <div className={`input-item ${validStateField("type")}`}>
                <label className="input-label">
                  {t("modals.memo_modal.type")}
                  <i className="input-required">*</i>
                </label>
                <Select
                  options={options}
                  defaultValue={selectedServiceNoteType}
                  onChoose={handleChange}
                />
              </div>
            </div>

            <div className="input-item-row">
              <div
                className={`input-item ${validStateField("recipient")}`}
                style={{ marginTop: "20px" }}
              >
                <label className="input-label">
                  {t("modals.memo_modal.recipient")}
                  <i className="input-required">*</i>
                </label>
                <div className="input-search">
                  <EmployeeAutocomplete
                    defaultText={memoValues.recipient}
                    ref={employeeRef}
                    onChoose={employeeChooseHandler}
                    placeholder={t("journal.filters.enter_name")}
                    companyId={advanceReportDetail.company.id}
                  />
                </div>
              </div>
            </div>
            <div className="box-modal-form">
              <div className="box-modal-form-block">
                <>
                  {memoValues.actExpenses.map((expense) => {
                    return (
                      <div
                        className="input-item-row"
                        key={getNextKey("actExpenses")}
                      >
                        <div
                          className={`input-item`}
                          style={{ marginBottom: "5px", marginTop: "20px" }}
                        >
                          <label className="input-label">
                            {t("modals.ad_act.expense")}
                          </label>
                          <SelectWithSection
                            defaultValue={getValueByExpenseId(expense.id)}
                            options={expenses}
                            onChoose={(option) =>
                              changeValue("expense", {
                                option: option,
                                oldExpense: expense,
                              })
                            }
                            style={{ marginRight: "0px" }}
                          />
                        </div>
                        <div
                          className="input-item input-remove"
                          style={{
                            width: "30px",
                            marginBottom: "5px",
                            marginTop: "20px",
                          }}
                        >
                          <label className="input-label" />
                          <a
                            onClick={() =>
                              changeValue("deleteExpense", expense)
                            }
                            title={t("report_detail.delete")}
                          >
                            <SvgIcon
                              className="icon icon-remove"
                              href="#svg_icon_remove"
                            />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                  <div className="input-item-row">
                    <div
                      className="cost-centers"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="tx-center" style={{ display: "" }}>
                        <a
                          className="btn-add-cost pointer"
                          onClick={() => changeValue("addExpense", {})}
                        >
                          <SvgIcon
                            className="icon icon-plus"
                            href="#svg_icon_plus"
                          />
                          {t("modals.ad_act.expense_delete")}
                        </a>
                      </div>
                    </div>
                  </div>
                </>
                <div className="input-item-row">
                  <div
                    className={`input-item ${validStateField("date")} 

                  `}
                  >
                    <label className="input-label">
                      {t("modals.memo_modal.date")}
                      <i className="input-required">*</i>
                    </label>
                    <div className={`input-date`}>
                      <input
                        value={
                          memoValues.date
                            ? formatter(
                                "D MMMM YYYY",
                                i18n.language,
                                memoValues.date,
                              )
                            : ""
                        }
                        onClick={() => setOpenCalendar(true)}
                        className="input datepicker pointer"
                        type="text"
                        id="datepicker"
                        readOnly={true}
                        tabIndex={isTabIndexDisabled ? -1 : 0}
                      />
                      <SvgIcon
                        className={"icon icon-calendar"}
                        href={"#svg_icon_calendar"}
                        onClick={() => setOpenCalendar(true)}
                      />
                    </div>
                    <DatePickerCalendar
                      startDate={memoValues.date}
                      isShown={openCalendar}
                      onClose={() => setOpenCalendar(false)}
                      isCleansed={false}
                      onSelected={(date) => changeValue("date", date)}
                      isMultiChoice={false}
                    />
                  </div>
                </div>
                <div className="input-item-row" id="add-expenses">
                  <div
                    className={`input-item ${validStateField("description")}`}
                  >
                    <label className="input-label">
                      {t("modals.memo_modal.description")}
                      <i className="input-required">*</i>
                    </label>
                    <textarea
                      maxLength={400}
                      onChange={(event) => changeValue("description", event)}
                      value={memoValues.description}
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-modal-footer">
            <a
              className="btn btn_black pointer"
              onClick={closeHandler}
              tabIndex={isTabIndexDisabled ? -1 : 0}
            >
              {t("modals.create_advance_report_modal.cancel")}
            </a>
            <a
              className="btn-expense btn_green pointer"
              onClick={saveHandler}
              tabIndex={isTabIndexDisabled ? -1 : 0}
            >
              {t("modals.create_advance_report_modal.complete")}
            </a>
          </div>
        </div>
      </ModalContainer>
    </Fragment>
  );
};

export default withRouter(MemoModal);
