import styled from "styled-components";

export const Autocomplete = styled.div`
  padding: 16px;
  gap: 15px;
  background: #ffffff;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;
