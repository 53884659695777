import React, { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { showErrors } from "store/exception/actions";
import { isEmpty } from "lodash";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { getCommonUserDetail } from "../../../store/selectors";
import { postBatches } from "../../../services/ApiService";

interface UploadButtonProps {
  onUpload?: () => void;
  onError?: () => void;
}

const UploadButton: React.FC<UploadButtonProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let currentUser = useSelector(getCommonUserDetail);

  let fileRef = useRef({} as any);

  const uploadHandler = async () => {
    dispatch(loaderLock());
    let file = [...fileRef.current.files];
    fileRef.current.value = "";

    const maxFileSize = 104857600 as number;
    const checkSizeAndFormatFiles: boolean[] = [];
    file.forEach((item: any, index: any) => {
      let itemNameArray = item.name.split(".");
      let itemType = itemNameArray[itemNameArray.length - 1].toLowerCase();
      if (
        item.size > maxFileSize ||
        (itemType != "xlsx" && itemType != "xls" && itemType != "csv")
      ) {
        checkSizeAndFormatFiles.push(false);
      }
    });
    if (!isEmpty(checkSizeAndFormatFiles)) {
      dispatch(
        showErrors({
          code: "",
          message: t("attach_file_error.message_excel"),
        }),
      );
      dispatch(loaderUnlock());
      return;
    }

    const promiseAttachArray: any = [];
    file.forEach((file) => {
      let formData = new FormData();
      formData.append("Content", file);
      formData.append("Actor.Id", String(currentUser.id));
      formData.append("Actor.FullName.Ru", currentUser.name.ru);
      formData.append("Actor.FullName.En", currentUser.name.en);
      formData.append("Actor.Login", currentUser.email);
      formData.append(
        "OccupationId",
        String(currentUser.occupations[0].occupationId),
      );
      promiseAttachArray.push(
        postBatches(formData, {
          headers: { "Content-Type": "multipart/form-data" },
        }),
      );
    });

    let uploadPromise = await Promise.all(promiseAttachArray);

    let error = false;
    let errorMessage = "";

    let warning = false;
    let warningMessage = "";

    uploadPromise.forEach((item: any) => {
      if (item.status == 200) {
        warning = true;
        warningMessage = item.data;
      }
      if (!item.headers.success && item.status != 502) {
        error = true;
        errorMessage = item.data.Errors[0];
      }
      if (item.status == 502) {
        error = true;
        errorMessage = "";
      }
    });

    if (warning) {
      dispatch(showErrors({ code: "", message: warningMessage }));
    }
    if (!error) {
      props.onUpload && props.onUpload();
    } else {
      if (errorMessage != "") {
        dispatch(showErrors({ code: "", message: errorMessage }));
      } else {
        dispatch(showErrors({ code: "", message: "Что-то пошло не так" }));
      }
      props.onError && props.onError();
    }

    dispatch(loaderUnlock());

    /*uploadPromise.then(uploadResults => {
            let error = false;
            uploadResults.forEach((uploadResult: any) => {
                if (uploadResult.status != 201) {
                    error = true;
                }
            });
            if(error){
                dispatch(showErrors({ code: '', message: 'Что-то пошло не так' }));
            }
            props.onUpload && props.onUpload();
        }).catch(error => {
            dispatch(showErrors({ code: '', message: 'Что-то пошло не так' }));
            props.onError && props.onError();
        });*/
  };

  return (
    <Fragment>
      <label htmlFor="file-input" className="btn-expense btn_green add-note">
        {t("downloads.filter.downloadPostings")}
      </label>
      <input
        id="file-input"
        ref={fileRef}
        onChange={uploadHandler}
        type="file"
        name="file"
        multiple
        title=""
        style={{ display: "none" }}
      />
    </Fragment>
  );
};

export default UploadButton;
