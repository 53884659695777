import React, { Fragment, useState, useEffect } from "react";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "app/component/grid/Grid";
import EditPostingModal from "../modal/EditPostingModal";
import { Transaction, Posting, IOccupations } from "infrastructure/interfaces";
import { updateDetailedAO } from "store/report/actions";
import { ApplicationState } from "store/reducers";
import { updateApplicationForExpenseDetail } from "store/applicationForExpense/actions";
import {
  expenseStylesMap,
  EXPENSE_TYPE,
  expenseHrefMap,
} from "infrastructure/enum/expense-type.enum";
import {
  statusExpenseStylesMap,
  statusExpenseNameMap,
} from "infrastructure/enum/status-expense.enum";
import {
  nameFormatter,
  NameFormats,
  numberFormatter,
  toLocaleNumberFormatter,
  capitalizeFirstLetter,
  getCurrencieCode,
} from "app/utils";
import {
  updatePostings,
  loaderLock,
  loaderUnlock,
  setTransactionBatchId,
  updateTransactionsReadFieldPermissions,
  updateTransactionsJournalFieldPermissions,
} from "store/common/actions";
import PostingsDownloadButton from "../buttons-control/PostingsDownloadButton";
import If from "../util/If";
import Else from "../util/Else";
import { showErrors } from "store/exception/actions";
import {
  OCCUPATION_ROLE_TYPE,
  ROLE_TYPE,
} from "infrastructure/enum/user-role.enum";
import analitiks from "services/AnaliticsService";
import qs from "qs";
import { get } from "lodash";
import moment from "moment";
import {
  getCommonPostings,
  getCommonTransactionBatchId,
  getCommonUserDetail,
} from "../../../store/selectors";
import {
  getBatchesInternalWithParams,
  getCustomPropertiesCodes,
  getLogicalName,
  postApiTransactions,
} from "../../../services/ApiService";
import { ITransactionHeader } from "../../../infrastructure/interfaces/TransactionHeader.interface";
import { TRANSACTIONS_READ_FIELD_PERMISSIONS } from "../../../infrastructure/enum/field-permissions.enum";

interface PostingsProps {
  id: number;
  isAdvance?: boolean;
  isJournal?: boolean;
}

const Postings: React.FC<PostingsProps> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let [selectedTransaction, setSelectedTransaction] = useState(
    {} as Transaction,
  );
  const postings = useSelector(getCommonPostings);
  const user = useSelector(getCommonUserDetail);
  const transactionBatchId = useSelector(getCommonTransactionBatchId);
  const transactionsReadFieldPermissions = useSelector(
    (state: ApplicationState) => state.common.transactionsReadFieldPermissions,
  );
  const transactionsJournalFieldPermissions = useSelector(
    (state: ApplicationState) =>
      state.common.transactionsJournalFieldPermissions,
  );

  let [transactionHeaders, setTransactionHeaders] = useState(
    [] as ITransactionHeader[],
  );
  let [isOpenEditModal, setOpenEditModal] = useState(false);
  let [gridDate, setGridDate] = useState([] as any[]);
  let [loadDate, setLoadDate] = useState("");
  let [lastLoadDate, setLastLoadDate] = useState("");

  useEffect(() => {
    if (transactionBatchId) {
      getTransactionLoadDates(transactionBatchId);
    }
  }, [transactionBatchId]);

  useEffect(() => {
    setTransactionHeaders(
      props.isAdvance ? fillAdvanceReportHeaders : fillExpenseHeaders,
    );
  }, [transactionsReadFieldPermissions, transactionsJournalFieldPermissions]);

  const fillExpenseHeaders = (): ITransactionHeader[] => {
    let headers = [] as ITransactionHeader[];
    const permissions = props.isJournal
      ? transactionsJournalFieldPermissions
      : transactionsReadFieldPermissions;
    headers.push({
      headerText: t("postings.cost_center"),
      key: "costCenter",
    });
    if (permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.EMPLOYEE)) {
      headers.push({
        headerText: t("postings.employee"),
        key: `employeeName`,
      });
    }
    headers.push(
      {
        headerText: t("postings.business_target"),
        key: `businessTargetName`,
      },
      {
        headerText: t("postings.text_operation"),
        key: `textOperation`,
      },
    );
    if (
      permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.LEDGER_OR_VENDOR)
    ) {
      headers.push({
        headerText: t("postings.ledger_account"),
        key: "ledgerAccount",
      });
    }
    if (permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.POSTING_KEY)) {
      headers.push({
        headerText: t("postings.type_transaction"),
        key: "typeOperation",
      });
    }
    if (permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.AMOUNT_RUB)) {
      headers.push({
        headerText: t("postings.amount_rub", {
          currency: getCurrencieCode("rub"),
        }),
        key: "amountMST",
      });
    }
    return headers;
  };

  const fillAdvanceReportHeaders = (): ITransactionHeader[] => {
    let headers = [] as ITransactionHeader[];
    const permissions = props.isJournal
      ? transactionsJournalFieldPermissions
      : transactionsReadFieldPermissions;
    headers.push({
      headerText: t("postings.cost_center"),
      key: "costCenter",
    });
    if (permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.EMPLOYEE)) {
      headers.push({
        headerText: t("postings.employee"),
        key: `employeeName`,
      });
    }
    if (
      permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.EXPENSE_ITEM)
    ) {
      headers.push({
        headerText: t("postings.expense_item"),
        key: `expenseItem`,
      });
    }
    if (permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.DOC_NUMBER)) {
      headers.push({
        headerText: t("postings.number"),
        key: `number`,
      });
    }
    if (
      permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.LEDGER_OR_VENDOR)
    ) {
      headers.push({
        headerText: t("postings.ledger_account"),
        key: "ledgerAccount",
      });
    }
    if (permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.TAX_CODE)) {
      headers.push({
        headerText: t("postings.tax_rate"),
        key: "taxCode",
      });
    }
    if (permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.POSTING_KEY)) {
      headers.push({
        headerText: t("postings.type_transaction"),
        key: "typeOperation",
      });
    }
    if (permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.AMOUNT_RUB)) {
      headers.push({
        headerText: t("postings.amount_rub", {
          currency: getCurrencieCode("rub"),
        }),
        key: "amountMST",
      });
    }
    if (
      permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.TRANS_UPDATE) ||
      props.isJournal
    ) {
      headers.push({
        headerText: "",
        key: "edit",
      });
    }
    return headers;
  };

  const getTransactionLoadDates = async (batchId: number) => {
    dispatch(loaderLock());
    let response = await getBatchesInternalWithParams({
      params: {
        ObjectType: props.isAdvance
          ? "AdvanceReportApplication"
          : "ExpenseApplication",
        BatchId: batchId,
      },
    });
    if (response.headers.success) {
      if (
        response.data.data &&
        response.data.data.length &&
        response.data.data[0].loadedAt
      ) {
        setLoadDate(
          moment
            .utc(response.data.data[0].loadedAt, "YYYY-MM-DDThh:mm:ss")
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
        );
      } else {
        setLoadDate("");
      }
      if (
        response.data.data &&
        response.data.data.length &&
        response.data.data[0].lastLoadedAt
      ) {
        setLastLoadDate(
          moment
            .utc(response.data.data[0].lastLoadedAt, "YYYY-MM-DDThh:mm:ss")
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
        );
      } else {
        setLastLoadDate("");
      }
    }
    dispatch(loaderUnlock());
  };

  useEffect(() => {
    if (props.isJournal) {
      dispatch(updateTransactionsJournalFieldPermissions());
    } else {
      dispatch(
        updateTransactionsReadFieldPermissions(
          props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
          props.id,
        ),
      );
    }
    analitiks("DetailPostings", {
      packageId: +props.id,
      employeeId: user.id,
      occupationId: user.occupations[0].occupationId,
      companyId: user.occupations[0].company.id,
    });
    return () => {
      dispatch(setTransactionBatchId(0));
    };
  }, []);

  useEffect(() => {
    generateData();
  }, [postings]);

  const toggleEditModal = () => {
    setOpenEditModal(!isOpenEditModal);
  };

  const editButtonClickHandler = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    toggleEditModal();
  };

  const getLogicalNameId = async () => {
    dispatch(loaderLock());
    let result = await getLogicalName({ params: { NameEn: "Transaction" } });

    if (result.headers.success) {
      dispatch(loaderUnlock());
      return result.data[0].id;
    } else {
      dispatch(loaderUnlock());
      return 0;
    }
  };

  const advanceReportData = async () => {
    dispatch(loaderLock());
    let logicalNameId = await getLogicalNameId();

    let result = [] as any;

    for (let index = 0; index < postings.transactions.length; index++) {
      const transaction = postings.transactions[index];

      let gridTransactionData = {
        costCenter: "", //transaction.costCenterName[i18n.language as 'en' | 'ru'],
        employeeName: nameFormatter(
          transaction.employeeName,
          NameFormats.LASTNAME_WITH_FULL_INITIALS,
          i18n.language,
        ), //transaction.employeeName[i18n.language as 'en' | 'ru'],
        expenseItem: transaction.expenseItem.name[i18n.language as "en" | "ru"],
        itemExpense: transaction.expenseItem,
        number: transaction.number,
        ledgerAccount: transaction.ledgerAccount,
        taxCode: transaction.taxCode.name[i18n.language as "en" | "ru"],
        typeOperation: `${t("transaction.postingKey." + transaction.postingKey)}(${transaction.postingKey})`,
        amountMST: transaction.amountRub,
        edit: (
          <div onClick={() => editButtonClickHandler(transaction)}>
            <SvgIcon className="icon icon-edit pointer" href="#svg_icon_edit" />
          </div>
        ),
        doesCostCenterDiffer: transaction.doesCostCenterDiffer,
      };

      let codesResult = await getCustomPropertiesCodes({
        params: {
          ObjectLogicalNameId: logicalNameId,
          ExternalObjectId: transaction.id,
          CustomPropertyCodes: ["CCT"],
        },
        paramsSerializer: (params: any) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });
      if (codesResult.headers.success) {
        gridTransactionData.costCenter = get(
          codesResult.data[0],
          "name" + capitalizeFirstLetter(i18n.language),
          "",
        );
      }

      result.push(gridTransactionData);
    }

    setGridDate(
      result.map(
        (item: {
          ledgerAccount: string;
          itemExpense: { [key: string]: string };
        }) => ({
          ...item,
          ledgerAccount: item.itemExpense
            ? `${item.itemExpense[t("postings.ledger_account_column")]}(${item.ledgerAccount})`
            : "",
        }),
      ),
    );
    dispatch(loaderUnlock());
  };

  const expenseData = async () => {
    dispatch(loaderLock());
    let logicalNameId = await getLogicalNameId();

    let result = [] as any;
    for (let index = 0; index < postings.transactions.length; index++) {
      const transaction = postings.transactions[index];

      let gridTransactionData = {
        itemExpense: transaction.expenseItem,
        costCenter: "", //transaction.costCenterName[i18n.language as 'en' | 'ru'],
        employeeName: nameFormatter(
          transaction.employeeName,
          NameFormats.LASTNAME_WITH_FULL_INITIALS,
          i18n.language,
        ), //transaction.employeeName[i18n.language as 'en' | 'ru'],
        businessTargetName:
          transaction.businessTargetName[i18n.language as "en" | "ru"],
        textOperation: transaction.textOperation,
        ledgerAccount: transaction.ledgerAccount,
        typeOperation: `${t("transaction.postingKey." + transaction.postingKey)}(${transaction.postingKey})`,

        amountMST: transaction.amountRub,
      };

      let codesResult = await getCustomPropertiesCodes({
        params: {
          ObjectLogicalNameId: logicalNameId,
          ExternalObjectId: transaction.id,
          CustomPropertyCodes: ["CCT"],
        },
        paramsSerializer: (params: any) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });
      if (codesResult.headers.success) {
        gridTransactionData.costCenter = get(
          codesResult.data[0],
          "name" + capitalizeFirstLetter(i18n.language),
          "",
        );
      }

      result.push(gridTransactionData);
    }
    setGridDate(
      result.map(
        (item: {
          ledgerAccount: string;
          itemExpense: { [key: string]: string };
        }) => ({
          ...item,
          ledgerAccount: item.itemExpense
            ? `${item.itemExpense[t("postings.ledger_account_column")]}(${item.ledgerAccount})`
            : "",
        }),
      ),
    );
    dispatch(loaderUnlock());
  };

  const onEditSubmit = () => {
    props.isAdvance
      ? dispatch(updateDetailedAO(props.id))
      : dispatch(updateApplicationForExpenseDetail(props.id));
  };

  const generateData = () => {
    props.isAdvance ? advanceReportData() : expenseData();
  };

  const generatePostings = async () => {
    dispatch(loaderLock());
    let response = await postApiTransactions({
      objectId: {
        id: props.id,
        logicalName: props.isAdvance
          ? "AdvanceReportApplication"
          : "ExpenseApplication",
        name: {
          ru: props.isAdvance
            ? "AdvanceReportApplication"
            : "ExpenseApplication",
          en: props.isAdvance
            ? "AdvanceReportApplication"
            : "ExpenseApplication",
        },
      },
    });
    if (response.headers.success) {
      dispatch(
        updatePostings(
          props.id,
          props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
          {},
        ),
      );
    } else {
      dispatch(
        showErrors({
          code: "generate_postings",
          message: "Не удалось создать проводки",
        }),
      );
    }
    dispatch(loaderUnlock());
  };

  const isPostingStatus = (statusArr: string[]) => {
    for (
      let postingAIndex = 0;
      postingAIndex < statusArr.length;
      postingAIndex++
    ) {
      const status = statusArr[postingAIndex];
      for (
        let postingBIndex = 0;
        postingBIndex < postings.transactions.length;
        postingBIndex++
      ) {
        const element = postings.transactions[postingBIndex];
        if (element.state == status) {
          return true;
        }
      }
    }
    return false;
  };

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  const canGeneratePostings = (): boolean => {
    if (isBookerOccupation() == false) {
      return false;
    }

    if (props.isAdvance) {
      return true;
    }

    const permissions = props.isJournal
      ? transactionsJournalFieldPermissions
      : transactionsReadFieldPermissions;
    if (
      permissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.TRANS_CREATE)
    ) {
      return true;
    }

    return false;
  };

  return (
    <Fragment>
      <div
        className="request-expenses request-expenses-bookkeeper"
        style={{ paddingBottom: "0" }}
      >
        <div className="request-expenses-wrap">
          <div className="request-bookkeeper-table-wrap">
            <div className="request-bookkeeper-info-wrap">
              <span>
                {loadDate
                  ? ` ${t("status.unload_first_date")}: ${loadDate}`
                  : null}
              </span>
              <span>
                {lastLoadDate
                  ? ` ${t("status.unload_last_date")}: ${lastLoadDate}`
                  : null}
              </span>
            </div>
            <If condition={postings.transactions.length != 0}>
              <Grid
                options={{
                  bookkeeperTable: props.isAdvance ? true : false,
                  downloadsTable: true,
                  headers: transactionHeaders,
                  isLastRowNarrow: props.isAdvance,
                }}
                isTransaction
                data={gridDate}
              />
            </If>
            <Else condition={postings.transactions.length != 0}>
              <div
                className="request-expenses-empty"
                style={{ marginTop: "30px" }}
              >
                <div className="tx-center">
                  <img src="/img/empty-img-2.png" />
                </div>
                <If condition={props.isJournal}>
                  {t("postings.empty_journal_message")}
                </If>
                <Else condition={props.isJournal}>
                  {t("postings.empty_message")}
                  <If condition={canGeneratePostings()}>
                    ,&nbsp;
                    <span
                      onClick={generatePostings}
                      style={{ cursor: "pointer" }}
                    >
                      {t("report_detail.generate_postings").toLocaleLowerCase()}
                    </span>
                  </If>
                </Else>
              </div>
            </Else>
          </div>
          <EditPostingModal
            onSubmit={onEditSubmit}
            isOpen={isOpenEditModal}
            onClose={toggleEditModal}
            transaction={selectedTransaction}
			isAdvance={props.isAdvance}
          />
        </div>
      </div>
      <If condition={props.isJournal}>
        <PostingsDownloadButton
          id={props.id}
          logicalName={
            props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication"
          }
        />
      </If>
    </Fragment>
  );
};

export default Postings;
