/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeErrorModal } from "store/exception/actions";
import { delTopModal } from "../../../store/common/actions";
import { getCommonModalIdCounter } from "../../../store/selectors";
import ModalContainer from "app/component/modal/ModalContainer";
import { Button, Modal } from "react-bootstrap";

interface ErrorModalProps {
  error: any;
  isWidget?: boolean;
}

const ErrorModal: React.FC<ErrorModalProps> = (props) => {
  let isOpen = props.error.isOpenErrorModal;
  const dispatch = useDispatch();

  const modalIdCounter = useSelector(getCommonModalIdCounter);

  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  const closeHandler = () => {
    clearTimeout(counter);
    dispatch(delTopModal(modalIdCounter - 1));
    dispatch(closeErrorModal(props.error.id));
  };

  const { t } = useTranslation();
  let [closeCounter, setCloseCounter] = useState(45);
  let counter = 0;

  useEffect(() => {
    if (isOpen) {
      if (closeCounter > 0) {
        counter = countdown();
      } else {
        closeHandler();
      }
    }
  }, [closeCounter, isOpen, props.error.id]);

  const countdown = () => {
    let counter = window.setTimeout(() => {
      if (closeCounter > 0) {
        setCloseCounter(closeCounter - 1);
        clearTimeout(counter);
      }
    }, 1000);
    return counter;
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return props.isWidget ? (
    <Modal show={isOpen} onHide={closeHandler} centered={true}>
      <Modal.Header closeButton={true} translate="no">
        <Modal.Title>O-ops</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="error-message-wrapper">
          <div
            className="tx-center"
            dangerouslySetInnerHTML={{
              __html:
                props.error.message && typeof props.error.message === "string"
                  ? props.error.message
                  : "<p>Что-то пошло не так</p>",
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button
          variant={"outline-dark"}
          onClick={closeHandler}
          tabIndex={isTabIndexDisabled ? -1 : 0}
        >
          {t("modals.error.okay")}
        </Button>
        <div className="tx-center">{`${t(
          "modals.error.close_message"
        )}  ...${closeCounter} ${t("modals.error.time_unit")}`}</div>
      </Modal.Footer>
    </Modal>
  ) : (
    <ModalContainer
      isOpen={isOpen}
      destroy={true}
      highestModal={isHighestModal}
      overlayClick={() => {
        closeHandler();
      }}
    >
      <div className="box-modal" id="modal-oops">
        <div
          className="box-modal_close arcticmodal-close"
          onClick={closeHandler}
        ></div>
        <div className="box-modal-title">O-ops</div>
        <div className="box-modal-content">
          <div className="text">
            <div
              className="tx-center"
              dangerouslySetInnerHTML={{
                __html:
                  props.error.message && typeof props.error.message === "string"
                    ? props.error.message
                    : "<p>Что-то пошло не так</p>",
              }}
            />
          </div>
        </div>
        <div className="box-modal-footer">
          <div className="tx-center">
            <a
              className="arcticmodal-close btn-expense btn_black pointer"
              onClick={() => {
                closeHandler();
              }}
              tabIndex={isTabIndexDisabled ? -1 : 0}
            >
              {t("modals.error.okay")}
            </a>
          </div>
        </div>
        <div className="box-modal-descr">
          <div className="tx-center">{`${t(
            "modals.error.close_message"
          )}  ...${closeCounter} ${t("modals.error.time_unit")}`}</div>
        </div>
      </div>
    </ModalContainer>
  );
};
export default ErrorModal;
