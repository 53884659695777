import React from "react";
import styled, { keyframes } from "styled-components";
import ModalContainer from "./ModalContainer";

interface SlideModalProps {
  isOpen: boolean;
}

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const SideModalWrapper = styled.div`
  border-radius: 8px 0 0 8px;
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  animation: ${slideIn} 1s forwards;
  transform: translateX(100%);
`;

const SlideModal: React.FC<SlideModalProps> = (props) => {
  return (
    <>
      <ModalContainer style={{ zIndex: 1001 }} isOpen={props.isOpen}>
        <SideModalWrapper>{props.children}</SideModalWrapper>
      </ModalContainer>
    </>
  );
};

export default SlideModal;
