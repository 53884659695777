import React from "react";
import { useTranslation } from "react-i18next";
import { FullCalculationProps } from "./types";
import { StyledFullCalculation } from "./StyledFullCalculation";

const FullCalculation: React.FC<FullCalculationProps> = ({ expense }) => {
  const { t } = useTranslation();

  if (expense) {
    return (
      <StyledFullCalculation>
        {t("report_detail.application_expense_item.currencyRate", {
          amount: expense.amount,
          currency: expense.amountCurrency.alphaCode,
          currencyRate: expense.amountCurrencyRate,
        })}
      </StyledFullCalculation>
    );
  }

  return null;
};

export default FullCalculation;
