import { Action } from "redux";
import { ApproveList } from "infrastructure/interfaces";

export const AGREEMENT = {
  SET_LIST: "AGREEMENT/SET_LIST",
  UPDATE_LIST: "AGREEMENT/UPDATE_LIST",
};

export interface SetAgreementList extends Action {
  type: typeof AGREEMENT.SET_LIST;
  payload: { agreementList: ApproveList; reloading: boolean };
}

export type AgreementActions = SetAgreementList;
