import styled from "styled-components";

export interface Error {
  error?: boolean;
}

export const SelectContainer = styled.div<Error>`
  position: relative;
  width: 100%;
  padding: 0px 12px;
  border-radius: 8px;
  border: 1px solid ${({ error }) => (error ? "#FF3B30" : "#cad5db")};
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 2px 0px rgba(41, 41, 64, 0.07);
  margin-bottom: 16px;
`;

export const SelectButton = styled.button`
  background-color: #fff;
  border: none;
`;

export const SelectedOption = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const OptionsContainer = styled.div`
  position: absolute;
  top: 42px;
  left: -1px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--neutrals-200, #e9eff2);
  background: var(--neutrals-0, #fff);
  z-index: 1002;
  box-shadow:
    0px 10px 15px -3px rgba(41, 41, 64, 0.08),
    0px 4px 6px -2px rgba(41, 41, 64, 0.04);
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  color: "black";
  background-color: "transparent";
  cursor: pointer;
  transition:
    transform 0.3s ease,
    font-weight 0.3s ease;
  &:hover {
    transform: scale(1.01);
    font-weight: 800;
  }
`;

export const Text = styled.span`
  flex: 1;
`;

export const Section = styled.span`
  color: #879196;
`;
