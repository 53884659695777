import { Action } from "redux";
import { ScannedChequeInfoType } from "infrastructure/interfaces";
import { NoticeItem } from "app/component/notice/NoticePanel2";

export const CHEQUE = {
  NOTION: "CHEQUE/NOTION",
  GET_DETAIL: "CHEQUE/GET_DETAIL",
  LOADER: "CHEQUE/LOADER",
  SIDE_MODAL: "CHEQUE/SIDE_MODAL",
  ATTACHMENT_ID: "CHEQUE/ATTACHMENT_ID",
};

export interface CheckIsChequeNotionEnabled extends Action {
  type: typeof CHEQUE.NOTION;
  payload: { isEnabled: boolean; notice: NoticeItem | null };
}

export interface SetScannedChequeInfo extends Action {
  type: typeof CHEQUE.GET_DETAIL;
  payload: { ScannedChequeInfo: ScannedChequeInfoType };
}

export interface isLoaderEnabled extends Action {
  type: typeof CHEQUE.LOADER;
  payload: { isEnabled: boolean };
}

export interface isSideModalEnabled extends Action {
  type: typeof CHEQUE.SIDE_MODAL;
  payload: { isEnabled: boolean };
}

export interface attachmentId extends Action {
  type: typeof CHEQUE.ATTACHMENT_ID;
  payload: { id: number };
}

export type ScannedChequeInfoActions =
  | SetScannedChequeInfo
  | CheckIsChequeNotionEnabled
  | isLoaderEnabled
  | isSideModalEnabled
  | attachmentId;
