import ModalContainer from "./ModalContainer";
import React from "react";
import { ModalBox, ModalClose, ModalTitle } from "./StyledModal";
import { ModalProps } from "./types";

const SubmitCustomModal: React.FC<ModalProps> = (props) => {
  const { isOpen, title, children, onClose, style } = props;

  return (
    <ModalContainer
      style={style}
      isOpen={isOpen}
      destroy={true}
      overlayClick={onClose}
    >
      <ModalBox>
        {onClose ? <ModalClose onClick={onClose} /> : null}
        <ModalTitle>{title}</ModalTitle>
        {children}
      </ModalBox>
    </ModalContainer>
  );
};

export default SubmitCustomModal;
