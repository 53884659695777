import styled from "styled-components";
import { ExpireProps, TabsProps } from "../types";

export const PictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 24px 12px;
  height: 355px;
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #d9e2e7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
`;

export const Title = styled.h1`
  margin-top: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -1.7%;
  color: #000000;
`;

export const SubTitle = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #000000;
`;

export const Autocomplete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 15px;
  background: #ffffff;
  margin-bottom: 16px;
  height: 80px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  @media screen and (max-width: 480px) {
    margin-top: 72px;
    position: relative;
    width: 73%;
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 380px) {
    width: 57%;
  }
`;

export const ButtomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: center;
  @media screen and (max-width: 480px) {
    position: relative;
    align-self: baseline;
    left: -24px;
    top: -26px;
    width: 112%;
  }
  @media screen and (max-width: 380px) {
    width: 117%;
  }
`;

export const Quantity = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 317px;
  width: 100%;
  height: 64px;
  background: #ffffff;
  border-bottom: 1px solid #d9e2e7;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.006em;
  color: #000000;
`;

export const StatusFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 20px;
  gap: 1.5%;
  width: 100%;
  height: 52px;
  background: #ffffff;
`;

export const Tab = styled.div<TabsProps>`
  height: 20px;
  font-style: normal;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  border-bottom: ${(props) => (props.active ? "4px solid red" : "none")};
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  cursor: pointer;
  padding: 16px;
  align-self: center;
  white-space: nowrap;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  border-radius: 4px;
`;

export const Number = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0a0a;
  width: 8%;
`;

export const Expire = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0a0a;
  width: 10%;
`;

export const ExpireDate = styled.div<ExpireProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  color: ${(props) => (props.expired ? "#E00000" : "#0a0a0a")};
`;

export const ExpireTerm = styled.div<ExpireProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => (props.expired ? "#E00000" : "#98a2a8")};
`;

export const Representative = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0a0a;
  width: 15%;
`;

export const RepresentativeName = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  color: #0a0a0a;
`;

export const RepresentativeOccupation = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #98a2a8;
`;

export const Authorities = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0a0a;
  width: 32%;
  @media screen and (max-width: 1275px) {
    width: 28%;
  }
`;

export const Authority = styled.span`
  font-weight: 400;
  color: #4d5a61;
`;

export const PowerStatus = styled.div`
  display: inline-flex;
  align-items: center;
  width: 21%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0a0a;
  @media screen and (max-width: 1275px) {
    width: 23%;
  }
`;

export const SigningContainer = styled.div`
  display: inline-flex;
  align-items: center;
  width: 14%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0a0a;
  @media screen and (max-width: 1275px) {
    width: 16%;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: 99px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  color: #5d6c74;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #5d6c74;
`;

export const SignDate = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0a0a;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  background: #ffffff;
  border-top: 1px solid #d9e2e7;
  width: 100%;
  padding: 20px 24px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #d9e2e7;
  padding: 14px 24px;
  cursor: pointer;
  &:hover ${Number} {
    color: #98a2a8;
  }
  &:hover ${SignDate} {
    color: #98a2a8;
  }

  &:hover ${RepresentativeName} {
    color: #98a2a8;
  }
  &:hover ${Representative} {
    color: #98a2a8;
  }
  &:hover ${Representative} {
    color: #98a2a8;
  }
  &:hover ${Authority} {
    color: #98a2a8;
  }
  &:hover ${ExpireDate} {
    color: #98a2a8;
  }
  &:hover ${ExpireTerm} {
    color: #98a2a8;
  }
`;

export const SortTitleContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  padding: 14px 24px;
  border-top: 1px solid #d9e2e7;
`;

export const ArrayBtn = styled.div`
  position: absolute;
  right: 2%;
  top: 44%;
`;
