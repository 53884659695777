import ModalContainer from "app/component/modal/ModalContainer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface SubmitCustomModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void;
  closeButtonText?: string;
  submitButtonText?: string;
}

const SubmitCustomModal: React.FC<SubmitCustomModalProps> = (props) => {
  const { t } = useTranslation();

  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return (
    <ModalContainer
      isOpen={props.isOpen}
      highestModal={isHighestModal}
      destroy={true}
      overlayClick={props.onClose}
    >
      <div className="box-modal" id="note-delete">
        <div
          className="box-modal_close arcticmodal-close pointer"
          onClick={props.onClose}
        ></div>
        <div className="box-modal-title">
          <div className="tx-center line-h-30">{props.title}</div>
        </div>
        <div className="box-modal-footer">
          <a
            className="arcticmodal-close btn btn_black pointer"
            onClick={props.onClose}
            tabIndex={isTabIndexDisabled ? -1 : 0}
          >
            {props.closeButtonText
              ? props.closeButtonText
              : t("modals.delete_attachment.no")}
          </a>
          <a
            className="btn-expense btn_green pointer"
            onClick={props.onSubmit}
            tabIndex={isTabIndexDisabled ? -1 : 0}
          >
            {props.submitButtonText
              ? props.submitButtonText
              : t("modals.delete_attachment.yes")}
          </a>
        </div>
      </div>
    </ModalContainer>
  );
};

export default SubmitCustomModal;
