import styled from "styled-components";
import { StyledButtonProps, ButtonColor } from "./types";
import { buttonColorsStyles } from "./buttonColorsStyles";

const getColorsStyle = (selectedColor: ButtonColor) => {
  return buttonColorsStyles[selectedColor];
};

const StyledButton = styled.div<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => (props.isIconButton ? "" : "padding: 7px 16px 6px 16px")};
  margin: 0px 10px;
  width: ${(props) => props.buttonWidth};
  height: ${(props) => (props.big ? "40px" : "32px")};
  ${(props) => (props.isIconButton ? "min-width: 32px" : "")};
  background: ${(props) => getColorsStyle(props.buttonColor).background};
  &:hover {
    background: ${(props) => getColorsStyle(props.buttonColor).hover};
    transition: 0.3s;
  }
  transition: 0.3s;
  box-shadow: ${(props) => getColorsStyle(props.buttonColor).boxShadow};
  border-radius: 4px;
  border: ${(props) => getColorsStyle(props.buttonColor).border};
  color: ${(props) => getColorsStyle(props.buttonColor).textColor};
  font-family: "HarmoniaSansProCyr";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.big ? "16px" : "14px")};
  cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"};
  pointer-events: ${(props) => props.disabled ? "none" : "auto"};
  opacity: ${(props) => props.disabled ? "0.5" : "1"};
`;

export default StyledButton;
