import DevPanel from "app/component/dev-panel/DevPanel";
import MenuHeader from "app/component/menu/MenuHeader";
import NoticePanelButton from "app/component/notice/NoticePanelButton";
import ProfileUserHeader from "app/component/profile-user/ProfileUserHeader";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import SupportRequest from "app/component/modal/SupportRequest";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getCommonCompaniesCatalog,
  getCommonUserDetail,
} from "store/selectors";
import axios from "axios";
import { getLogoPath } from "app/utils";

interface HeaderProps {
  accessDenied: boolean;
}

const getSettingJSON = async (
  pathSettings: string,
  pathToCompany: string,
  alternateKey: number,
  setLogoSettings: React.Dispatch<
    React.SetStateAction<{ logo: string; logoMobile: string }>
  >,
  isRussian: boolean
) => {
  if (pathSettings && pathToCompany) {
    try {
      const response = await axios.get(pathSettings);
      const [fullLogo, mobileLogo] = getLogoPath(
        response.data,
        isRussian,
        pathToCompany
      );
      setLogoSettings({
        logo: fullLogo,
        logoMobile: mobileLogo,
      });
    } catch (e) {
      console.error(
        `json is not valid for company with alternateKey ${alternateKey}`
      );
      return;
    }
  }
};
const Header: React.FC<HeaderProps> = ({ accessDenied }) => {
  const [logoSettings, setLogoSettings] = useState({
    logo: "/img/logo.svg",
    logoMobile: "/img/mobile-logo.svg",
  });

  const companies = useSelector(getCommonCompaniesCatalog);
  const userDetails = useSelector(getCommonUserDetail);

  const [alternateKey, setAlternateKey] = useState<number>();
  const [pathToCompany, setPathToCompany] = useState<string>();
  const [pathSettings, setPathSettings] = useState<string>();
  const isRussian = userDetails.language.code === "ru";

  useEffect(() => {
    if (companies && companies[0]) {
      setAlternateKey(companies[0].alternateKey);
      setPathToCompany(
        `/${process.env.REACT_APP_EXPENSE_STYLES_PATH}/company-${companies[0].alternateKey}/`
      );
    }
  }, [companies]);

  useEffect(() => {
    alternateKey && setPathSettings(`${pathToCompany}settings.json`);
  }, [pathToCompany]);

  useEffect(() => {
    pathSettings &&
      getSettingJSON(
        pathSettings,
        pathToCompany || "",
        alternateKey || 0,
        setLogoSettings,
        isRussian
      );
  }, [pathSettings]);

  return (
    <header className="header">
      <div className="container">
        <div className="header-wrap">
          <MenuHeader accessDenied={accessDenied} />
          <ProfileUserHeader accessDenied={accessDenied} />
          <div className="logo">
            {process.env.REACT_APP_IS_WHITE_LABEL === "false" && (
              <Link to="/" tabIndex={-1}>
                <img src={logoSettings.logo} alt="logo" />
              </Link>
            )}
          </div>
          <div className="logo-mob">
            {process.env.REACT_APP_IS_WHITE_LABEL === "false" && (
              <Link to="/">
                <img src={logoSettings.logoMobile} alt="logo" />
              </Link>
            )}
          </div>
          <div className="header-controls">
            {process.env.REACT_APP_ENVIRONMENT !== "PRODUCTION" &&
            !accessDenied ? (
              <Link
                className="header-controls__item"
                to="/Handbook"
                tabIndex={-1}
              >
                <SvgIcon
                  className="icon icon-list-header"
                  href="#svg_icon_list-header"
                />
              </Link>
            ) : null}
            <SupportRequest />
            {!accessDenied && <NoticePanelButton />}
            {process.env.REACT_APP_ENVIRONMENT !== "PRODUCTION" &&
            !accessDenied ? (
              <DevPanel />
            ) : null}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
