import React from "react";
import { PictureContainer, SubTitle, Title } from "./ManagerListStyled";

function NoFilteredPowersOfAttorneyPage() {
  return (
    <div>
      <PictureContainer>
        <img src="/img/no-searched-powers.svg" alt="no searched powers" />
        <Title>Ничего не найдено</Title>
        <SubTitle>Попробуйте ввести другие критерии поиска</SubTitle>
      </PictureContainer>
    </div>
  );
}

export default NoFilteredPowersOfAttorneyPage;
