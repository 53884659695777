import { useState, useEffect, RefObject } from "react";

export const useWidth = (ref: any) => {
  let [width, setWidth] = useState(0);

  const resizeHandler = () => {
    if (ref && ref.current && ref.current.offsetWidth) {
      setWidth(ref.current.offsetWidth);
    } else {
      setWidth(0);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  });

  useEffect(() => {
    resizeHandler();
  }, []);

  return width;
};

export const useResize = (resizeHandler: () => void) => {
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  });
};

export const useEventObserver = (
  handler: (event: any) => void,
  eventName: string,
  ref: RefObject<HTMLElement>,
) => {
  useEffect(() => {
    console.log("useEventObserver start listen");
    if (ref.current) {
      ref.current.addEventListener(eventName, handler);
    }
    return () => {
      console.log("useEventObserver end listen");
      if (ref.current) {
        ref.current.removeEventListener(eventName, handler);
      }
    };
  }, []);
};

export const useEventDispatcher = (
  eventName: string,
  ref: RefObject<HTMLElement>,
) => {
  return (data: any) => {
    if (ref.current) {
      ref.current.dispatchEvent(
        new CustomEvent(eventName, {
          bubbles: true,
          detail: data,
        }),
      );
    }
  };
};
