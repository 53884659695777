import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateApplicationForExpenseList } from "store/applicationForExpense/actions";
import SvgIcon from "../svg-icon/SvgIcon";
import { ApplicationForExpenseItem } from "infrastructure/interfaces";
import { useTranslation } from "react-i18next";
import { formatter, nameFormatter, NameFormats } from "app/utils";
import Slidebar from "../slidebar/Slidebar";
import { isEmpty } from "lodash";
import {
  getApplicationForExpenseList,
  getCommonUserDetail,
} from "../../../store/selectors";

interface SelectExpenseProps {
  defaultValue?: SelectOptionType | null;
  defaultText?: string;
  onChoose?: (option: SelectOptionType) => void;
  readOnly?: boolean;
  filterParams?: any;
}

interface SelectRef {
  clear(): void;
}

interface SelectOptionType {
  value: ApplicationForExpenseItem;
  text: string;
}

const SelectExpense: React.FC<SelectExpenseProps> = (props) => {
  const { t, i18n } = useTranslation();

  let expenseApplicationList = useSelector(getApplicationForExpenseList);
  const userDetail = useSelector(getCommonUserDetail);
  const dispatch = useDispatch();

  let [isOpen, setOpen] = useState(false);
  let [labelText, setLabelText] = useState(
    props.defaultValue
      ? props.defaultValue.text
      : props.defaultText
        ? props.defaultText
        : "---",
  );
  let [isLoading, setLoading] = useState(false);
  let [pageNumber, setPageNumber] = useState(1);

  const observer = useRef();

  const lastElement = useCallback(
    (node: any) => {
      if (isLoading) return;
      if (observer && observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          expenseApplicationList &&
          expenseApplicationList.itemsCount >
            pageNumber * expenseApplicationList.itemsPerPage
        ) {
          dispatch(
            updateApplicationForExpenseList(
              props.filterParams,
              ++pageNumber,
              true,
              0,
            ),
          );
          setPageNumber(++pageNumber);
          setLoading(true);
        }
      });
      if (node && observer && observer.current) {
        observer.current.observe(node);
      }
    },
    [expenseApplicationList],
  );

  useEffect(() => {
    setLoading(false);
  }, [expenseApplicationList]);

  useEffect(() => {
    if (props.filterParams && !isEmpty(props.filterParams)) {
      dispatch(
        updateApplicationForExpenseList(props.filterParams, 1, false, 0),
      );
      setPageNumber(1);
    }
  }, [props.filterParams]);

  const missClickHandler = () => {
    if (isOpen) {
      setOpen(false);
    }
  };

  const titleClickHandler = () => {
    if (props.readOnly) {
      return;
    }
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (props.defaultValue && props.defaultValue.text) {
      setLabelText(props.defaultValue.text);
    }
  }, [props.defaultValue]);

  const optionClickHandler = (option: SelectOptionType) => {
    setLabelText(option.text);
    setOpen(false);
    if (props.onChoose) props.onChoose(option);
  };

  return (
    <div className={"select " + (isOpen ? "active" : "")}>
      {isOpen ? (
        <div className="miss-click-handler" onClick={missClickHandler} />
      ) : (
        ""
      )}
      <div className="select-title" onClick={titleClickHandler}>
        <span>{labelText}</span>
        <SvgIcon className="icon icon-arrow-top" href="#svg_icon_arrow_top" />
      </div>
      <Slidebar
        className="select-content"
        style={{ zIndex: 20, maxHeight: "120px" }}
      >
        {expenseApplicationList &&
          expenseApplicationList.data.map((item, index) => {
            const value = item;
            let text = `№${item.id} (${item.applicationType.name[i18n.language as "en" | "ru"]}) от ${formatter("DD.MM.YYYY", i18n.language, item.createdAt)}, ${nameFormatter(item.assigneeEmployee.name, NameFormats.NAME_WITH_LASTNAME, i18n.language)}`;
            if (item.applicationType.code === "Travel") {
              text += `${item.startOn || item.endOn ? "," : ""}${item.startOn ? ` с ${formatter("DD.MM.YYYY", i18n.language, item.startOn)}` : ""}${item.endOn ? ` по ${formatter("DD.MM.YYYY", i18n.language, item.endOn)}` : ""}${item.arrivalToCity && item.arrivalToCity.name ? `, ${item.arrivalToCity.name[i18n.language as "en" | "ru"]}` : ""}`;
            }
            if (
              expenseApplicationList &&
              expenseApplicationList.data.length === index + 1
            ) {
              return (
                <div
                  className="select-content-item"
                  ref={lastElement}
                  key={index}
                  onClick={() => optionClickHandler({ value, text })}
                >
                  {text}
                </div>
              );
            } else {
              return (
                <div
                  className="select-content-item"
                  key={index}
                  onClick={() => optionClickHandler({ value, text })}
                >
                  {text}
                </div>
              );
            }
          })}
        {isLoading ? (
          <div className="select-content-item">
            <div
              className="lds-ellipsis"
              style={{ marginLeft: "calc(50% - 30px)" }}
            >
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        ) : null}
      </Slidebar>
    </div>
  );
};

export default SelectExpense;
