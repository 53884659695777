import ASN1 from "@lapo/asn1js";
import { Certificate } from "crypto";
export type Certificates = Certificate & {
  checked?: boolean;
  thumbprint: string;
  subjectKeyIdentifier?: string;
  authorityKeyIdentifier?: string;
};

interface CertificateAuthority {
  objid: number;
  subjectKeyIdentifier: string;
  authorityKeyIdentifier: string;
  subjectName: string;
  serialNumber: string;
  thumbprint: string;
  type: string;
}

// to get getSubjectName
export const getSubjectName = (arr: String[]) => {
  try {
    if (arr === null || arr === undefined) return false;

    let foundSubjectNameBlock = false;
    let subjectName = "";
    for (let i = 0; i <= arr.length; i++) {
      if (arr[i] === null || arr[i] === undefined) continue;

      if (arr[i].includes("2.5.4.3")) {
        //console.log('getSubjectName: arr[i]: ', i, arr[i]);
        //console.log('getSubjectName: arr[i+1]: ', i, arr[i+1]);
        foundSubjectNameBlock = true;
        i += 1;
        subjectName += arr[i] + " |";
      }
      if (i === arr.length && !foundSubjectNameBlock) {
        return false;
      }
    }

    return subjectName;
  } catch (e) {
    console.log("getSubjectName: exception: ", e);
  }
};

// to get subjectKeyIdentifier
export const getSKI = (arr: String[]) => {
  try {
    if (arr === null || arr === undefined) return false;

    const regex = /[A-Za-z0-9]{40}$/;
    let foundSKIBlock = false;
    for (let i = 0; i <= arr.length; i++) {
      if (arr[i] === null || arr[i] === undefined) continue;

      if (arr[i].includes("subjectKeyIdentifier")) {
        //console.log('getSKI: arr[i]: ', i, arr[i]);
        //console.log('getSKI: arr[i+2]: ', i, arr[i+2]);
        foundSKIBlock = true;
        i += 2;
      }
      if (foundSKIBlock) {
        var result = arr[i].match(regex);
        if (result === null) return false; // Если совпадений нет, то возвращается null
        //console.log('getSKI: arr[i].match(regex)[0]: ', i, result[0]);
        return result[0];
      }
      if (i === arr.length && !foundSKIBlock) {
        return false;
      }
    }
  } catch (e) {
    console.log("getSKI: exception: ", e);
  }
};

// to get authorityKeyIdentifier
export const getAKI = (arr: String[]) => {
  try {
    if (arr === null || arr === undefined) return false;

    const regex = /[A-Za-z0-9]{40}$/;
    let foundAkIBlock = false;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === null || arr[i] === undefined) continue;
      if (arr[i].includes("authorityKeyIdentifier")) {
        //console.log('getAKI: arr[i]: ', i, arr[i]);
        //console.log('getAKI: arr[i+3]: ', i, arr[i+3]);
        foundAkIBlock = true;
        i += 3;
      }
      if (foundAkIBlock) {
        var result = arr[i].match(regex);
        if (result === null) return false; // Если совпадений нет, то возвращается null
        //console.log('getAKI: arr[i].match(regex)[0]: ', i, result[0]);
        return result[0];
      }
      if (i === arr.length - 1 && !foundAkIBlock) {
        return "";
      }
    }
    return "";
  } catch (e) {
    console.log("getAKI: exception: ", e);
  }
};

// returns cert with new fields
export const getCertificateWithSKIAndAKI = async (certificate: Certificate) => {
  const certificatePublicKey = await certificate._cadesCertificate.Export(0);

  const buffer = atob(certificatePublicKey);
  const decodedCertificate = ASN1.decode(buffer).toPrettyString().split("\n");

  let subjectName = getSubjectName(decodedCertificate);

  let subjectKeyIdentifier = getSKI(decodedCertificate);

  let authorityKeyIdentifier = getAKI(decodedCertificate);

  return {
    ...certificate,
    subjectKeyIdentifier: subjectKeyIdentifier,
    authorityKeyIdentifier: authorityKeyIdentifier,
    subjectName: subjectName,
  };
};

export const getAllCertificates = async (
  certificates: Certificates[],
  type: string,
) => {
  let updatedCertificates = [];

  for (let i = 0; i < certificates.length; i++) {
    let caCertificate = await getCaAndRootCertificateWithSKIAndAKI(
      certificates[i],
      type,
    );
    updatedCertificates.push(caCertificate);
  }

  return updatedCertificates;
};

export const checkRootCertificate = (
  allInternalCertificates: CertificateAuthority[],
  clientAKI: String,
  apiRootCertificates: CertificateAuthority[],
  subjectName: String,
) => {
  let result = getCertificateChain(
    allInternalCertificates,
    clientAKI,
    apiRootCertificates,
    [subjectName],
  );

  console.log(
    "clientAKI: ",
    clientAKI,
    "\n",
    "result.isAQES: ",
    result.isAQES,
    "\n",
    "result.certificateChain: ",
    result.certificateChain,
  );

  return result.isAQES;
};

export const getCertificateChain = (
  allInternalCertificates: CertificateAuthority[],
  clientAKI: String,
  apiRootCertificates: CertificateAuthority[],
  certificateChain: String[],
) => {
  let isAQES = false;
  for (let i = 0; i < allInternalCertificates.length; i++) {
    if (allInternalCertificates[i].subjectKeyIdentifier === clientAKI) {
      certificateChain = [
        ...certificateChain,
        allInternalCertificates[i].subjectName,
      ];

      if (allInternalCertificates[i].authorityKeyIdentifier) {
        return getCertificateChain(
          allInternalCertificates,
          allInternalCertificates[i].authorityKeyIdentifier,
          apiRootCertificates,
          certificateChain,
        );
      }

      for (let j = 0; j < apiRootCertificates.length; j++) {
        if (
          apiRootCertificates[j].thumbprint.toUpperCase() ===
          allInternalCertificates[i].thumbprint.toUpperCase()
        ) {
          isAQES = true;
          break;
        }
      }

      break;
    }
  }

  return { isAQES, certificateChain };
};

export const getCaAndRootCertificateWithSKIAndAKI = async (
  certificate: Certificate,
  type: string,
) => {
  const certificatePublicKey = await certificate.Export(0);
  const thumbprint = await certificate.Thumbprint;

  const buffer = atob(certificatePublicKey);
  const decodedCertificate = ASN1.decode(buffer).toPrettyString().split("\n");

  let subjectName = getSubjectName(decodedCertificate);

  let subjectKeyIdentifier = getSKI(decodedCertificate);

  let authorityKeyIdentifier = getAKI(decodedCertificate);

  if (type === "CA") {
    return {
      ...certificate,
      subjectKeyIdentifier: subjectKeyIdentifier,
      authorityKeyIdentifier: authorityKeyIdentifier,
      subjectName: subjectName,
      thumbprint: thumbprint,
      type: "CA",
    };
  }
  if (type === "ROOT") {
    return {
      ...certificate,
      subjectKeyIdentifier: subjectKeyIdentifier,
      authorityKeyIdentifier: authorityKeyIdentifier,
      subjectName: subjectName,
      thumbprint: thumbprint,
      type: "ROOT",
    };
  }
};
