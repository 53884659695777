import React from "react";
import { StyledBox } from "./StyledBox";
import { BoxProps } from "./types";

const Box: React.FC<BoxProps> = (props) => {
  const { color } = props;

  return <StyledBox color={color}>{props.children}</StyledBox>;
};

export default Box;
