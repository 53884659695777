import { Reducer } from "redux";
import { PowerOfAttorneyType } from "infrastructure/interfaces";
import { POWEROFATTORNEY, PowerOfAttorneyActions } from "./actionsTypes";

export interface PowerOfAttorneyState {
  powerOfAttorneyList: PowerOfAttorneyType[];
  powerOfAttorneyDetail: any;
  isEnabled: boolean;
}

export const initialState: PowerOfAttorneyState = {
  powerOfAttorneyList: [],
  powerOfAttorneyDetail: {},
  isEnabled: false,
};

export const reducer: Reducer<PowerOfAttorneyState> = (
  state: PowerOfAttorneyState = initialState,
  action,
) => {
  switch ((action as PowerOfAttorneyActions).type) {
    case POWEROFATTORNEY.ENABLED:
      return { ...state, isEnabled: action.payload };
    case POWEROFATTORNEY.SET_LIST:
      return { ...state, powerOfAttorneyList: action.payload };
    case POWEROFATTORNEY.SET_DETAIL:
      return {
        ...state,
        powerOfAttorneyDetail: action.payload,
        powerOfAttorneyList: state.powerOfAttorneyList.map((el) =>
          action.payload.id === el.id ? action.payload : el,
        ),
      };
    default:
      return state;
  }
};

export default reducer;
