import { BaseMapper } from "./base.mapper";
import { ApproveList } from "infrastructure/interfaces";
import { ApproveListItemMapper } from "./ApproveListItem.mapper";
import { get } from "lodash";

export class ApproveListMapper extends BaseMapper<ApproveList, any> {
  responseToEntity(response: any): ApproveList {
    const approveListItemMapper = new ApproveListItemMapper();
    return {
      data: approveListItemMapper.responsesToEntitys(get(response, "data", [])),
      itemsCount: get(response, "itemsCount", 0),
      itemsPerPage: get(response, "itemsPerPage", 0),
      pageNumber: get(response, "pageNumber", 0),
    };
  }
}
