import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  CSSProperties,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SvgIconS from "../svg-icon/SvgIcon";
import { SvgIcon } from "ui/SvgIcon";
import Slidebar from "../slidebar/Slidebar";
import { getCompaniesWithParams } from "../../../services/ApiService";
import { getCommonUserDetail } from "../../../store/selectors";

interface CompanyAutocompleteProps {
  defaultText?: string;
  placeholder?: string;
  onChoose?: (option: CompanyAutocompleteOptionType) => void;
  readOnly?: boolean;
  ref?: any;
  miniVector?: any;
  height?: boolean;
  comboBox?: boolean;
  powerOfAttorney?: boolean;
  style?: CSSProperties;
  companyId?: number;
}

export interface CompanyAutocompleteOptionType {
  value: number;
  text: string;
  inn?: string;
  ogrn?: string;
  kpp?: string;
  address?: string;
}

const CompanyAutocomplete: React.FC<CompanyAutocompleteProps> =
  React.forwardRef((props: CompanyAutocompleteProps, ref) => {
    let { t, i18n } = useTranslation();

    let userDetail = useSelector(getCommonUserDetail);

    let [isOpen, setOpen] = useState(false);
    let [isLoading, setLoading] = useState(false);
    let [inputValue, setInputValue] = useState(
      props.defaultText ? props.defaultText : ""
    );
    let [companies, setCompanies] = useState(
      [] as CompanyAutocompleteOptionType[]
    );
    let [error, setError] = useState(false);
    let [companyTimer, setCompanyTimer] = useState({} as any);
    let inputRef = useRef({} as HTMLInputElement);

    useEffect(() => {
      setInputValue(props.defaultText ? props.defaultText : "");
    }, [props.defaultText]);

    const optionClickHandler = (option: CompanyAutocompleteOptionType) => {
      setInputValue(option.text);
      setOpen(false);
      if (props.onChoose) props.onChoose(option);
    };

    const changeInput = () => {
      props.onChoose && props.onChoose({} as CompanyAutocompleteOptionType);
      setInputValue(inputRef.current.value);
      if (inputRef.current.value.length > 2) {
        clearTimeout(companyTimer);
        let name = inputRef.current.value;
        (() => {
          setCompanyTimer(
            setTimeout(() => {
              getCompany(name);
            }, 800)
          );
        })();
      } else {
        setCompanies([] as CompanyAutocompleteOptionType[]);
        setOpen(false);
        setLoading(false);
      }
    };

    const openSlideBar = () => {
      getCompany();
    };

    const missClickHandler = () => {
      if (isOpen) {
        setOpen(false);
      }
    };

    const getCompany = async (nameValue?: string) => {
      setError(false);
      setLoading(true);
      setCompanies([] as CompanyAutocompleteOptionType[]);
      setOpen(true);

      if (nameValue) {
        try {
          let result = await getCompaniesWithParams({
            params: { Name: nameValue },
          });
          if (result.config.params.Name == inputRef.current.value) {
            if (props.powerOfAttorney) {
              const { data } = result.data;
              let enabledCompany = data;
              let findCompanies = enabledCompany.filter(
                (item: any) =>
                  item.name.ru
                    .toLowerCase()
                    .indexOf(inputRef.current.value.toLowerCase()) !== -1 ||
                  item.name.en
                    .toLowerCase()
                    .indexOf(inputRef.current.value.toLowerCase()) !== -1
              );
              setCompanies(
                findCompanies.map((item: any) => {
                  return {
                    value: item.id,
                    text: item.name[i18n.language as "ru"],
                    inn: item.legal ? item.legal.inn : "",
                    kpp: item.legal ? item.legal.kpp : "",
                    ogrn: item.legal ? item.legal.ogrn : "",
                    address: item.legal ? item.legal.address : "",
                  };
                })
              );
            } else {
              const { data } = result.data;
              let findCompanies = data.filter(
                (item: any) =>
                  item.name.ru
                    .toLowerCase()
                    .indexOf(inputRef.current.value.toLowerCase()) !== -1 ||
                  item.name.en
                    .toLowerCase()
                    .indexOf(inputRef.current.value.toLowerCase()) !== -1
              );
              setCompanies(
                findCompanies.map((item: any) => {
                  return {
                    value: item.id,
                    text: item.name[i18n.language as "ru"],
                    inn: item.legal ? item.legal.inn : "",
                    kpp: item.legal ? item.legal.kpp : "",
                    ogrn: item.legal ? item.legal.ogrn : "",
                    address: item.legal ? item.legal.address : "",
                  };
                })
              );
            }
          }
        } catch (e) {
          setError(true);
        }
      } else {
        try {
          let params = {};
          let result;
          if (props.companyId) {
            params = {
              CompanyId: props.companyId,
              IncludeHoldingCompanies: true,
              IncludeContractorCompanies: true,
            };
          } else {
            params = {
              OccupationEmployeeId: userDetail.id,
            };
          }

          result = await getCompaniesWithParams({
            params: params,
          });

          if (result.headers.success) {
            let companies = result.data.data;
            if (companies.length === 1) {
              setCompanies([
                {
                  value: companies[0].id,
                  text: companies[0].name[i18n.language as "ru"],
                  inn: companies[0].legal ? companies[0].legal.inn : "",
                  kpp: companies[0].legal ? companies[0].legal.kpp : "",
                  ogrn: companies[0].legal ? companies[0].legal.ogrn : "",
                  address: companies[0].legal ? companies[0].legal.address : "",
                },
              ]);
            } else {
              setCompanies(
                companies.map((item: any) => {
                  return {
                    value: item.id,
                    text: item.name[i18n.language as "ru"],
                    inn: item.legal ? item.legal.inn : "",
                    kpp: item.legal ? item.legal.kpp : "",
                    ogrn: item.legal ? item.legal.ogrn : "",
                    address: item.legal ? item.legal.address : "",
                  };
                })
              );
            }
          }
        } catch (e) {
          setError(true);
        }
      }
      setLoading(false);
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        setInputValue("");
      },
      value: () => inputRef.current.value,
    }));

    return (
      <div className={"select " + (isOpen ? "active" : "")}>
        {isOpen ? (
          <div className="miss-click-handler" onClick={missClickHandler} />
        ) : (
          ""
        )}

        <input
          style={{
            height: props.height ? "32px" : "",
            fontSize: props.height ? "14px" : "",
            ...props.style,
          }}
          className="input"
          type="text"
          ref={inputRef}
          value={inputValue}
          onClick={props.comboBox ? openSlideBar : undefined}
          onChange={changeInput}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
        />

        {props.miniVector ? (
          <div
            className="icon icon-search"
            onClick={props.comboBox ? openSlideBar : undefined}
            style={{ fill: "none", right: "8px" }}
          >
            <SvgIcon id={"vectorDown"} color="#0A0A0A" />
          </div>
        ) : (
          <SvgIconS href={"#svg_icon_search"} className={"icon icon-search"} />
        )}
        <Slidebar
          className="select-content"
          style={{ zIndex: 20, maxHeight: "260px" }}
        >
          {companies.length > 0
            ? companies.map((option, index) => {
                return (
                  <div
                    className="select-content-item"
                    key={index}
                    onClick={() => optionClickHandler(option)}
                  >
                    {option.text}
                  </div>
                );
              })
            : null}
          {!error && !isLoading && companies.length === 0 ? (
            <div className="select-content-item">
              {t("filters.no_company_found")}{" "}
            </div>
          ) : null}
          {error ? (
            <div className="select-content-item">
              {t("create_expense.search_error")}{" "}
            </div>
          ) : null}
          {isLoading ? (
            <div className="select-content-item">
              <div
                className="lds-ellipsis"
                style={{ marginLeft: "calc(50% - 30px)" }}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : null}
        </Slidebar>
      </div>
    );
  });

export default CompanyAutocomplete;
