import If from "app/component/util/If";
import { toLocaleNumberFormatter, getCurrencieCode } from "app/utils";
import { STATUS_TYPE_REPORT } from "infrastructure/enum/status-report.enum";
import { IAO } from "infrastructure/interfaces";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Config, { ConfigTypes } from "services/ConfigService";
import { useSelector } from "react-redux";
import { getAdvanceReportApplication } from "store/selectors";
import AOCashAmountModal from "./AOCashAmountModal";
import Tooltip from "app/component/tooltip/Tooltip";
import { Button } from "ui/Button";
import { accessInitialValues, generateFieldsAccess } from "infrastructure/constants/AORequestCostAccess";

interface RequestCostProps {
  advanceCashAmount: number;
  advanceCashAmountTotalReceived: number;
  advanceCashAmountReceived: number;
  totalAmount: number;
  approvedAmount: number;
  advanceCashAmountOverspend: number;
  advanceCashAmountRemainder: number;
  status: string;
  approvedTotalAmount: number;
  approvedReportAmount: number;
  id: number;
}


export const RequestCost: React.FC<RequestCostProps> = (props) => {
  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication
  );
  const { t } = useTranslation();
  const [fieldAccess, setFieldAccess] = useState(accessInitialValues);
  const [
    advanceReportAmountCalculationPermission,
    setAdvanceReportAmountCalculationPermission,
  ] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const tooltipString = (field: keyof RequestCostProps) =>
    `${t(`request_detail.request_cost.${field}`)}: ${toLocaleNumberFormatter(Number(props[field]))} ${getCurrencieCode("rub")}`;

  const tooltipArray = [
    tooltipString("advanceCashAmountRemainder"),
    tooltipString("advanceCashAmountOverspend"),
    tooltipString("advanceCashAmountReceived"),
  ];

  useEffect(() => {
    setAdvanceReportAmountCalculationPermission(
      Config.getConfigToCompany(
        ConfigTypes.ADVANCE_REPORT_AMOUNT_CALCULATION,
        advanceReportApplication.company.id
      )
    );
    generateFieldsAccess(advanceReportApplication, setFieldAccess);
  }, [advanceReportApplication]);

  const status = (field: keyof typeof STATUS_TYPE_REPORT) =>
    props.status !== STATUS_TYPE_REPORT[field];



  const CostBlock = ({
    field,
    text,
  }: {
    field: keyof RequestCostProps;
    text?: string;
  }) => {
    return (
      <div className="request-item-block cost">
        <span className="request-item-label">{t(`request_detail.request_cost.${text ? text : field}`)}</span>
        <span style={{ marginTop: "4px" }} className="money advance">
          {toLocaleNumberFormatter(Number(props[field]))}{" "}
          {getCurrencieCode("rub")}
        </span>
      </div>
    );
  };

  return (
    <div className="request-item-row space-between cost-row flex-wrap">
      <If condition={!advanceReportAmountCalculationPermission}>
        {fieldAccess.totalAmount && (
          <CostBlock field="totalAmount" text="commonAmountOnRequest" />
        )}
        {/* TODO: status === ''  */}
        {status("DRAFT") &&
        status("APPROVAL_REQUIRES") &&
        status("ON_APPROVAL") &&
        status("NOT_APPROVED") &&
        status("REVOKED") &&
        status("CANCELLED") ? (
          <CostBlock field="approvedAmount" text="approved" />
        ) : null}
      </If>
      <If condition={!!advanceReportAmountCalculationPermission}>
        <AOCashAmountModal open={openModal} setOpen={setOpenModal} {...props} />
        {fieldAccess.advanceCashAmountReceived && (
          <div className="w-100 d-flex align-items-center">
            <Tooltip
              width={400}
              notHovered={
                !fieldAccess.advanceCashAmountOverspend &&
                !fieldAccess.advanceCashAmountRemainder
              }
              textAlign="left"
              textArray={tooltipArray}
            >
              <CostBlock field="advanceCashAmountTotalReceived"  />
            </Tooltip>
            {fieldAccess.advanceCashAmountOverspend &&
              fieldAccess.advanceCashAmountRemainder && (
                <Button iconId="eyeIcon" onClick={() => setOpenModal(true)} />
              )}
          </div>
        )}
        {fieldAccess.expensesAccounting && (
          <CostBlock field="approvedTotalAmount" />
        )}
        {fieldAccess.reportExpenses && (
          <CostBlock field="approvedReportAmount" />
        )}
      </If>
    </div>
  );
};
