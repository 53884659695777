import React from "react";
import { RadioProps } from "./types";
import { Item, RadioButtonLabel, RadioButton } from "./StyledRadio";

const Radio: React.FC<RadioProps> = (props) => {
  const { disabled, name, checked, id, label, onClick } = props;

  const clickHandler = () => {
    if (disabled) return;
    onClick(id);
  };

  return (
    <Item>
      <RadioButton
        onChange={clickHandler}
        type="radio"
        name={name}
        id={name + "-" + id}
        checked={checked}
      />
      <RadioButtonLabel htmlFor={name + "-" + id}>
        <div></div>
        {label}
      </RadioButtonLabel>
    </Item>
  );
};

export default Radio;
