import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Chat from "app/component/chat/Chat";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getApplicationForExpenseDetail } from "../../../store/selectors";
import { EXPENSE_APPLICATION_PROPETIES } from "../../../infrastructure/enum/object-properties.enum";

const ZNRChat: React.FC<RouteComponentProps<any>> = (props) => {
  const { t } = useTranslation();
  const applicationForExpenseDetail = useSelector(
    getApplicationForExpenseDetail,
  );

  return applicationForExpenseDetail &&
    applicationForExpenseDetail.properties &&
    applicationForExpenseDetail.properties.includes(
      EXPENSE_APPLICATION_PROPETIES.COMMUNICATIONS,
    ) ? (
    <Chat
      objectId={applicationForExpenseDetail.id}
      logicalName={"ExpenseApplication"}
      title={t("chat.applicationTitle")}
    />
  ) : null;
};

export default withRouter(ZNRChat);
