import React, { useState, useRef, useEffect } from "react";
import { forEach } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { showErrors } from "store/exception/actions";
import AttachFileModal from "app/component/modal/AttachFileModal";
import { updateDetailedAO } from "../../../store/report/actions";
import { updateApplicationForExpenseDetail } from "store/applicationForExpense/actions";
import {
  getAdvanceReportApplication,
  getApplicationForExpense,
  getCommonUserDetail,
} from "../../../store/selectors";
import { postAttachments } from "../../../services/ApiService";
import { SvgIcon as NewSvgIcon } from "ui/SvgIcon";
import GetDocumentsFromTime from "../get-documets-from-time/GetDocumentsFromTime";
import Config, { ConfigTypes } from "services/ConfigService";
import AttachmentFilesList from "./AttachmentFilesList";
import { NoticeItem } from "../notice/NoticePanel2";
import {
  checkIsChequeNotionEnabled,
  getScannedChequeInfo,
  setAttacmentId,
} from "store/qrScanner/actions";
import { getExternalReport } from "../reports/utils";

interface AttachmentFilesPanelProps {
  isReport?: boolean;
  id: number;
}

const AttachmentFilesPanel: React.FC<AttachmentFilesPanelProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const report = useSelector(getAdvanceReportApplication);
  const expenseApp = useSelector(getApplicationForExpense);
  const reportCompanyId = report && report.company ? report.company.id : 0;
  const expenseAppCompanyId =
    expenseApp && expenseApp.applicationForExpenseDetail
      ? expenseApp.applicationForExpenseDetail.company.id
      : 0;

  const isTravelDocumentsSynchronization = Config.getConfigToCompany(
    ConfigTypes.ADVANCE_REPORT_APPLICATION_TRAVEL_DOCUMENTS_SYNCHRONIZATION,
    reportCompanyId,
  );

  const isCommonDocumentsSynchronization = Config.getConfigToCompany(
    ConfigTypes.ADVANCE_REPORT_APPLICATION_COMMON_DOCUMENTS_SYNCHRONIZATION,
    reportCompanyId,
  );

  const isExpenseAppTravelDocumentsSynchronization = Config.getConfigToCompany(
    ConfigTypes.EXPENSE_APPLICATION_TRAVEL_DOCUMENTS_SYNCHRONIZATION,
    expenseAppCompanyId,
  );

  const isExpenseAppCommonDocumentsSynchronization = Config.getConfigToCompany(
    ConfigTypes.EXPENSE_APPLICATION_COMMON_DOCUMENTS_SYNCHRONIZATION,
    expenseAppCompanyId,
  );

  let [isOpenAttachFile, setOpenAttachFile] = useState(false);
  let [attachFile, setAttachFile] = useState(null as any);
  let [isDragOver, setIsDragOver] = useState(false);
  let [isTrip, setIsTrip] = useState(false);

  const isBusinessTrip = () => {
    if (report.journey) {
      setIsTrip(
        report.journey.journeyNumber !== 0 &&
          report.journey.businessTripNumber !== 0,
      );
    }

    if (expenseApp.applicationForExpenseDetail) {
      setIsTrip(
        expenseApp.applicationForExpenseDetail.journeyNumber !== 0 &&
          expenseApp.applicationForExpenseDetail.businessTripNumber !== 0,
      );
    }
  };

  const toggleAttachFile = () => {
    setOpenAttachFile(!isOpenAttachFile);
    setAttachFile(null);
  };

  const loadingAttachFiles = (formData: any, contentType: string) => {
    const config = { headers: { "Content-Type": contentType } };
    return postAttachments(formData, config);
  };
  const toggleSubmitAttachFile = (expense: any) => {
    const promiseAttachArray: any = [];
    forEach(attachFile, (file) => {
      let formData = new FormData();
      formData.append("Content", file);
      formData.append("ObjectId.Id", expense.id);
      formData.append(
        "ObjectId.LogicalName",
        expense.expenseType.name.ru === "Без расхода"
          ? expense.expenseType.logicalName
          : "Expense",
      );
      promiseAttachArray.push(loadingAttachFiles(formData, file.type));
    });
    setOpenAttachFile(!isOpenAttachFile);
    dispatch(loaderLock());
    Promise.all(promiseAttachArray)
      .then((response) => {
        props.isReport
          ? dispatch(updateDetailedAO(props.id))
          : dispatch(updateApplicationForExpenseDetail(props.id));
        dispatch(loaderUnlock());
        return response;
      })
      .then((response) => {
        let suffix = response[0].data.name.toLowerCase();
        const currentExpensesId = report.expenses.map((el) => el.id);
        if (
          response.length === 1 &&
          (suffix.endsWith(".bmp") ||
            suffix.endsWith(".gif") ||
            suffix.endsWith(".jpeg") ||
            suffix.endsWith(".jpg") ||
            suffix.endsWith(".png") ||
            suffix.endsWith(".tiff") ||
            suffix.endsWith(".pdf") ||
            suffix.endsWith(".heic"))
        ) {
          // если в АО добавлен только 1 файл с изображением => модальное окно с предложением распознать QR-код
          let message: NoticeItem = {
            noticeIconID: "alertcircle",
            color: "#228BE6",
            noticeTitle: t("QR.modal.recognize_title"),
            noticeText: t("QR.modal.recognize_body"),
            actionButton: {
              text: t("QR.modal.recognize_button_do"),
              color: "#228BE6",
              func: () => {
                dispatch(checkIsChequeNotionEnabled(false, null));
                dispatch(
                  getScannedChequeInfo(
                    response[0].data.id,
                    t,
                    currentExpensesId,
                  ),
                );
                dispatch(setAttacmentId(response[0].data.id));
              },
            },
            actionButton2: {
              text: t("QR.modal.recognize_button_donot"),
              func: () => dispatch(checkIsChequeNotionEnabled(false, null)),
            },
          };
          dispatch(checkIsChequeNotionEnabled(true, message));
        }
      })
      .catch((e) => {
        console.log("Error:", e);
      });
  };

  let dropZone = useRef({} as any);

  useEffect(() => {
    isBusinessTrip();
  }, []);

  useEffect(() => {
    window.addEventListener("dragover", (e) => {
      e.preventDefault();
    });
    window.addEventListener("drop", (e) => {
      e.preventDefault();
    });
    return () => {
      window.removeEventListener("drop", (e) => {
        e.preventDefault();
      });
      window.removeEventListener("dragover", (e) => {
        e.preventDefault();
      });
    };
  }, []);

  const dropZoneChangeHandler = () => {
    let file = [...dropZone.current.files];
    checkSizeAndFormat(file);
    dropZone.current.value = "";
  };

  const dropZoneDropHandler = (e: any) => {
    setIsDragOver(false);
    let files = [...e.dataTransfer.files];
    checkSizeAndFormat(files);
    dropZone.current.value = "";
  };

  const checkFilesExtension = (file: any) => {
    const extensions = ["msg", "eml", "heic"]; // Расширения файлов у которых нет mime типа
    return extensions.includes(file.split(".").pop());
  };

  const checkSizeAndFormat = (files: any) => {
    if (files.length === 0) {
      return;
    }
    let maxFileSize = 104857600 as number;
    const checkSizeAndFormatFiles: boolean[] = [];
    forEach(files, (item: any, index: any) => {
      if (
        !(
          item.size <= maxFileSize &&
          (item.type === "" ||
            item.type === "image/png" ||
            item.type === "image/jpeg" ||
            item.type === "image/bmp" ||
            item.type === "image/gif" ||
            item.type === "image/tiff" ||
            item.type === "image/heic" ||
            item.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            item.type === "application/msword" ||
            item.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            item.type === "application/vnd.ms-excel" ||
            item.type === "application/pdf" ||
            checkFilesExtension(item.name))
        )
      ) {
        checkSizeAndFormatFiles.push(false);
      }
    });

    if (isEmpty(checkSizeAndFormatFiles)) {
      setAttachFile(files);
      setOpenAttachFile(true);
    } else {
      dispatch(
        showErrors({
          code: "",
          message: t("attach_file_error.message_QR"),
        }),
      );
    }
  };

  return (
    <>
      <div className="request-files">
        <div className="request-files-title">
          {t("request_detail.added_files.attachedFiles")}
        </div>
        {isTrip &&
        (isTravelDocumentsSynchronization ||
          isCommonDocumentsSynchronization ||
          isExpenseAppTravelDocumentsSynchronization ||
          isExpenseAppCommonDocumentsSynchronization) ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ flexBasis: "50%", flexGrow: 0 }}>
              <div
                className="request-files-dropzone"
                onDragOver={() => {
                  setIsDragOver(true);
                }}
                onDragLeave={() => {
                  setIsDragOver(false);
                }}
                onDrop={dropZoneDropHandler}
                style={
                  !isDragOver
                    ? {
                        position: "relative",
                        zIndex: 10,
                      }
                    : {
                        position: "relative",
                        zIndex: 10,
                        backgroundColor: "rgba(229, 230, 234, 1)",
                      }
                }
              >
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    position: "relative",
                    zIndex: -20,
                    paddingTop: "26px",
                    justifyContent: "center",
                  }}
                >
                  <NewSvgIcon id="paperclip" color="#757575" size={20} />
                  <NewSvgIcon id="image" color="#757575" size={20} />
                  <NewSvgIcon id="camera" color="#757575" size={20} />
                </div>
                <input
                  ref={dropZone}
                  onChange={dropZoneChangeHandler}
                  id="file-input"
                  type="file"
                  name="file"
                  multiple
                  title=""
                  tabIndex={-1}
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    position: "absolute",
                    zIndex: -11,
                    cursor: "pointer",
                    marginTop: "-46px",
                  }}
                />
                <label
                  htmlFor="file-input"
                  style={{
                    position: "absolute",
                    left: "10px",
                    right: "10px",
                    textAlign: "center",
                    fontSize: "14px",
                    cursor: "pointer",
                    color: "#5D6C74",
                    paddingTop: "20px",
                  }}
                >
                  {t("attach_file_message.massage")}
                </label>
              </div>
            </div>
            <div style={{ flexBasis: "50%", flexGrow: 0 }}>
              <GetDocumentsFromTime id={props.id} isReport={props.isReport} />
            </div>
          </div>
        ) : (
          <div
            className="request-files-dropzone"
            onDragOver={() => {
              setIsDragOver(true);
            }}
            onDragLeave={() => {
              setIsDragOver(false);
            }}
            onDrop={dropZoneDropHandler}
            style={
              !isDragOver
                ? {
                    position: "relative",
                    zIndex: 10,
                  }
                : {
                    position: "relative",
                    zIndex: 10,
                    backgroundColor: "rgba(229, 230, 234, 1)",
                  }
            }
          >
            <div
              style={{
                display: "flex",
                gap: "20px",
                position: "relative",
                zIndex: -20,
                paddingTop: "26px",
                justifyContent: "center",
              }}
            >
              <NewSvgIcon id="paperclip" color="#757575" size={20} />
              <NewSvgIcon id="image" color="#757575" size={20} />
              <NewSvgIcon id="camera" color="#757575" size={20} />
            </div>
            <input
              ref={dropZone}
              onChange={dropZoneChangeHandler}
              id="file-input"
              type="file"
              name="file"
              multiple
              title=""
              tabIndex={-1}
              style={{
                width: "100%",
                height: "100%",
                opacity: 0,
                position: "absolute",
                zIndex: -11,
                cursor: "pointer",
                marginTop: "-46px",
              }}
            />
            <label
              htmlFor="file-input"
              style={{
                position: "absolute",
                left: "10px",
                right: "10px",
                textAlign: "center",
                fontSize: "14px",
                cursor: "pointer",
                color: "#5D6C74",
                paddingTop: "20px",
              }}
            >
              {t("attach_file_message.massage")}
            </label>
          </div>
        )}
        <AttachmentFilesList id={props.id} isReport={props.isReport} />
      </div>
      <AttachFileModal
        isOpen={isOpenAttachFile}
        onClose={toggleAttachFile}
        onSubmit={toggleSubmitAttachFile}
        isReport={props.isReport}
      />
    </>
  );
};

export default AttachmentFilesPanel;
